import React from "react";
import { useSearchParams } from "react-router-dom";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

//^ hook
import useDebounce from "../../../hooks/use-debounce";

//^ actions
import { userNetworkAction } from "../../../store/slices/user-networks-slice";

//^ http request
import { getInvitationHandler } from "../../../http/post-api";

//^ data-table
import { columns } from "./column";
import DataTable from "./data-table";
import { Stack } from "@mui/material";
import Search from "../../../components/search";
import { responseErrorHandler } from "../../../utils/Utils";

export default function RequestTable() {
  const [searchText, setSearchText] = React.useState(undefined);
  const [pagination, setPagination] = React.useState(1);
  const dispatch = useDispatch();

  // eslint-disable-next-line
  const [searchParams, _setSearchParams] = useSearchParams();
  const requestTab = searchParams.get("requestTab") || "sent";

  const debounceSearch = useDebounce({ value: searchText, delay: 200 });

  //^ get invitation query fn =========================================================================
  const queryKey = [`get-invitation-${requestTab}`, { type: requestTab, debounceSearch }];
  const {
    data: getInvitationData,
    isLoading: getInvitationIsLoading,
    isRefetching: getInvitationIsRefetching,
    isError: getInvitationIsError,
    error: getInvitationError,
    fetchNextPage: getInvitationFetchNextPage,
    isFetchingNextPage: getInvitationIsFetchingNextPage,
    hasNextPage: getInvitationHasNextPage,
    // refetch: getInvitationRefetch,
  } = useInfiniteQuery({
    queryKey,
    queryFn: async (props) => getInvitationHandler({ type: requestTab, page: props.pageParam }),
    initialPageParam: 1,
    enabled: !!requestTab,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.status ? allPages.length + 1 : undefined;
    },
  });

  React.useEffect(() => {
    if (!getInvitationIsLoading || !getInvitationIsRefetching) {
      dispatch(userNetworkAction.setUserInvitationsHandler({ type: "SET_INVITATION_QUERY_KEY", queryKey: queryKey }));

      const flattenedData =
        getInvitationData?.pages
          ?.flatMap((page) => {
            return page?.data?.requests || [];
          })
          .filter((item) => item !== undefined) || [];

      if (requestTab === "received") {
        dispatch(
          userNetworkAction.setUserInvitationsHandler({
            type: "RECEIVED",
            received: flattenedData,
          })
        );
      } else if (requestTab === "sent") {
        dispatch(
          userNetworkAction.setUserInvitationsHandler({
            type: "SENT",
            sent: flattenedData,
          })
        );
      }
    }

    // eslint-disable-next-line
  }, [getInvitationData, getInvitationIsLoading, getInvitationIsRefetching]);

  React.useEffect(() => {
    responseErrorHandler(getInvitationIsError, getInvitationError);
  }, [getInvitationIsError, getInvitationError]);

  //^ get invitation query fn =========================================================================

  function handleSearchChange(event) {
    const text = event.target.value;

    if (text?.trim()?.length <= 0) {
      setSearchText(undefined);
      setPagination(1);
    } else {
      setPagination(0);
      setSearchText(text);
    }
  }

  return (
    <>
      <DataTable
        columns={columns}
        isLoading={getInvitationIsLoading}
        getInvitationIsFetchingNextPage={getInvitationIsFetchingNextPage}
        getInvitationFetchNextPage={getInvitationFetchNextPage}
        getInvitationHasNextPage={getInvitationHasNextPage}
        isRefetching={getInvitationIsRefetching}
        searchComponent={
          <Stack>
            <Search width={"20ch"} onChange={handleSearchChange} />
          </Stack>
        }
        pagination={pagination}
      />
    </>
  );
}
