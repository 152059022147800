import React from "react";
import Head from "../../layout/head/Head";

//^ mui component
import { Stack } from "@mui/material";
import ConnectionTable from "../../tables/account-users/connections/table";

export default function Connections() {
  return (
    <>
      <Head title={`${process.env.REACT_APP_PROJECT_SHORT_NAME} Connections`} />
      <Stack>
        <ConnectionTable />
      </Stack>
    </>
  );
}
