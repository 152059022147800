export default function NoUsers({ className, ...props }) {
  return (
    <>
      <svg
        width="492"
        height="502"
        viewBox="0 0 492 502"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className={className}
        {...props}
      >
        <g id="Group 1">
          <g id="No Invitation Black-02 1" clipPath="url(#clip0_3_87)">
            <g id="Plants">
              <path
                id="Vector"
                d="M346.35 472.48C346.35 472.48 348.35 463.3 352.24 461.88C356.13 460.46 358.56 464.59 358.56 464.59C358.56 464.59 359.49 455.82 365.06 453.19C370.63 450.56 374.82 455.94 374.82 455.94C374.82 455.94 379.09 444.81 386.73 444.81C394.37 444.81 398.22 461.1 398.22 461.1C398.22 461.1 402.73 456.24 407.35 458.99C411.97 461.74 412 472.48 412 472.48H346.35Z"
                fill="#DBDBDB"
              />
              <path
                id="Vector_2"
                d="M395 344.14C395 344.14 396.18 338.62 398.54 337.77C400.9 336.92 402.34 339.39 402.34 339.39C402.34 339.39 402.9 334.12 406.25 332.54C409.6 330.96 412.11 334.2 412.11 334.2C412.11 334.2 414.68 327.5 419.28 327.5C423.88 327.5 426.19 337.29 426.19 337.29C426.19 337.29 428.9 334.38 431.67 336.02C434.44 337.66 434.45 344.14 434.45 344.14H395Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_3"
                d="M73.3101 345.36H131.12C131.12 345.36 129.22 337.21 125.39 336.63C121.56 336.05 119.77 339.83 119.77 339.83C119.77 339.83 119.94 328.47 112.77 326.53C105.6 324.59 99.6101 334.64 99.6101 334.64C99.6101 334.64 95.1201 328.91 89.0001 329.28C82.8801 329.65 81.3301 339.11 81.3301 339.11C81.3301 339.11 75.7701 335.82 73.3101 345.36Z"
                fill="#DBDBDB"
              />
              <path
                id="Vector_4"
                d="M407.73 472.48H444.09C444.09 472.48 444.01 467.07 440.43 465.63C439.46 465.21 438.379 465.117 437.351 465.364C436.323 465.611 435.404 466.185 434.73 467C434.73 467 433.28 457.05 426.61 457.05C419.94 457.05 416.76 465.63 416.76 465.63C416.194 464.811 415.402 464.175 414.48 463.8C413.558 463.426 412.547 463.328 411.57 463.52C407.88 464 407.73 472.48 407.73 472.48Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_5"
                d="M49.4301 345.36H79.8701C79.8701 345.36 79.8001 340.83 76.8001 339.63C75.9852 339.27 75.075 339.187 74.2082 339.392C73.3415 339.598 72.566 340.082 72.0001 340.77C72.0001 340.77 70.7801 332.44 65.2101 332.44C59.6401 332.44 57.0001 339.63 57.0001 339.63C56.529 338.943 55.8667 338.409 55.0954 338.095C54.324 337.78 53.4773 337.698 52.6601 337.86C49.5501 338.26 49.4301 345.36 49.4301 345.36Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_6"
                d="M129.87 317.79H147.54C147.54 317.79 147.54 315.16 145.76 314.46C145.288 314.255 144.762 314.209 144.262 314.33C143.762 314.451 143.316 314.732 142.99 315.13C142.99 315.13 142.28 310.29 139.05 310.29C135.82 310.29 134.26 314.46 134.26 314.46C133.982 314.067 133.596 313.763 133.149 313.585C132.701 313.408 132.212 313.364 131.74 313.46C129.94 313.67 129.87 317.79 129.87 317.79Z"
                fill="#EBEBEB"
              />
            </g>
            <g id="Background Complete">
              <path
                id="Vector_7"
                d="M453.757 246.128C448.487 246.128 316.807 245.208 279.607 245.208C277.007 245.208 280.297 240.708 281.727 240.398C286.307 239.398 291.067 239.338 295.727 238.858C300.313 238.425 304.808 237.299 309.057 235.518C312.717 233.978 316.367 232.188 320.327 231.568C327.987 230.368 334.717 234.518 341.897 236.358C344.079 236.989 346.37 237.155 348.621 236.847C350.872 236.539 353.034 235.762 354.967 234.568C358.287 232.568 361.307 230.188 364.777 228.428C371.421 225.156 378.669 223.289 386.068 222.945C393.467 222.601 400.857 223.787 407.777 226.428C411.079 227.696 414.237 229.311 417.197 231.248C420.707 233.548 423.727 236.488 427.247 238.768C435.047 243.768 444.697 242.168 453.197 245.068C453.687 245.248 454.217 246.128 453.757 246.128Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_8"
                d="M195.317 94.3178C188.317 94.3178 70.9465 95.0878 63.5665 94.8578C63.0365 94.8578 62.7565 94.0578 63.2765 93.7978C67.9265 91.4978 73.2065 91.9578 78.0365 90.3278C80.8865 89.3278 82.6265 87.5078 84.6065 85.3278C86.2407 83.4627 88.3535 82.0796 90.7165 81.3278C92.8769 80.6789 95.1695 80.6065 97.3665 81.1178C99.7465 81.6878 101.817 83.0478 104.087 83.9078C109.727 86.0378 115.207 84.0178 119.237 79.8478C122.557 76.4078 125.817 73.1578 130.337 71.3078C132.18 70.4736 134.176 70.0289 136.2 70.0014C138.223 69.9738 140.231 70.3641 142.097 71.1478C146.397 73.1478 149.357 77.0178 153.397 79.3778C158.197 82.1778 163.927 81.5178 169.227 82.3778C174.527 83.2378 178.587 87.6778 183.227 90.2478C184.744 91.2183 186.496 91.7607 188.297 91.8178C189.297 91.8178 190.347 91.5078 191.377 91.3778C192.977 91.1878 194.497 91.2278 195.567 92.5578C195.837 92.9378 195.777 94.3178 195.317 94.3178Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_9"
                d="M439.567 131.118C434.477 131.118 348.757 131.678 343.377 131.508C342.987 131.508 342.777 130.928 343.167 130.738C346.557 129.058 350.407 129.388 353.937 128.198C356.017 127.508 357.287 126.198 358.727 124.588C359.92 123.229 361.462 122.223 363.187 121.678C364.766 121.204 366.441 121.152 368.047 121.528C369.787 121.938 371.296 122.938 372.956 123.528C377.076 125.088 381.077 123.608 384.017 120.528C386.437 118.018 388.827 115.648 392.117 114.298C393.464 113.69 394.921 113.366 396.399 113.345C397.877 113.325 399.343 113.608 400.706 114.178C403.846 115.608 406.006 118.468 408.956 120.178C412.456 122.178 416.647 121.748 420.517 122.358C424.387 122.968 427.346 126.238 430.736 128.108C431.844 128.817 433.122 129.214 434.437 129.258C435.197 129.258 435.937 129.028 436.687 128.938C437.227 128.791 437.797 128.793 438.336 128.944C438.875 129.096 439.362 129.391 439.747 129.798C439.937 130.108 439.897 131.118 439.567 131.118Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_10"
                d="M184.496 187.518C179.406 187.518 93.6864 188.078 88.3064 187.908C87.9164 187.908 87.7064 187.328 88.0964 187.138C91.4864 185.448 95.3364 185.788 98.8664 184.598C100.946 183.898 102.216 182.598 103.666 180.988C104.856 179.625 106.4 178.618 108.126 178.078C109.702 177.604 111.375 177.552 112.976 177.928C114.716 178.338 116.226 179.338 117.886 179.928C122.006 181.488 126.006 180.008 128.946 176.928C131.366 174.418 133.756 172.048 137.046 170.698C138.394 170.09 139.851 169.766 141.329 169.745C142.807 169.724 144.273 170.008 145.636 170.578C148.776 172.008 150.936 174.868 153.886 176.578C157.386 178.628 161.576 178.148 165.446 178.758C169.316 179.368 172.276 182.638 175.666 184.508C176.774 185.217 178.052 185.614 179.366 185.658C180.126 185.658 180.866 185.428 181.616 185.338C182.156 185.188 182.727 185.189 183.266 185.341C183.806 185.493 184.293 185.789 184.676 186.198C184.866 186.508 184.826 187.518 184.496 187.518Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_11"
                d="M273.707 152.538C273.617 152.828 138.617 152.538 140.317 152.538C141.097 152.538 139.147 151.378 139.907 151.008C144.297 148.818 149.107 148.758 153.907 148.318C156.738 148.195 159.518 147.516 162.087 146.318C164.627 144.988 166.217 142.678 168.017 140.538C170.793 137.249 174.362 134.722 178.386 133.194C182.41 131.667 186.757 131.19 191.017 131.808C200.617 133.308 208.187 140.618 217.797 141.968C223.577 142.778 229.037 140.538 234.687 139.748C239.049 139.151 243.488 139.964 247.357 142.068C250.407 143.578 253.217 144.848 256.667 145.068C259.307 145.258 261.957 144.948 264.607 144.918C268.943 145.002 273.1 146.666 276.297 149.598C276.757 150.018 278.117 152.538 273.707 152.538Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_12"
                d="M68.0565 314.418C68.0565 314.418 140.317 291.508 193.057 291.508C244.717 291.508 314.787 303.078 333.827 305.668C383.827 312.478 448.237 314.418 448.237 314.418H68.0565Z"
                fill="#DBDBDB"
              />
              <path
                id="Vector_13"
                d="M462.716 314.418C462.716 314.418 434.426 299.058 402.476 299.058C380.166 299.058 348.156 305.238 318.006 308.718C306.526 310.038 246.756 314.418 246.756 314.418H462.716Z"
                fill="#C7C7C7"
              />
            </g>
            <g id="Road">
              <path id="Vector_14" d="M309.43 501.04H40.5601L352.36 300.71H391.76L309.43 501.04Z" fill="#EBEBEB" />
              <path id="Vector_15" d="M335.05 438.7L363.87 368.57L444.09 359.27V380.55L335.05 438.7Z" fill="#EBEBEB" />
              <path
                id="Vector_16"
                d="M300.15 334.25L332.26 313.62L265.74 300.65H249.03L300.15 334.25Z"
                fill="#EBEBEB"
              />
              <g id="Group" opacity="0.6">
                <path id="Vector_17" d="M309.43 501.04H40.5601L352.36 300.71H391.76L309.43 501.04Z" fill="#EAEAEA" />
                <path
                  id="Vector_18"
                  d="M335.05 438.7L363.87 368.57L444.09 359.27V380.55L335.05 438.7Z"
                  fill="#EBEBEB"
                />
                <path
                  id="Vector_19"
                  d="M300.15 334.25L332.26 313.62L265.74 300.65H249.03L300.15 334.25Z"
                  fill="#EBEBEB"
                />
              </g>
            </g>
            <g id="Question mark">
              <path
                id="Vector_20"
                d="M388.76 236H353L352.82 225C352.82 210.267 354.763 198.333 358.65 189.2C362.537 180.067 370.06 170 381.22 159C393.667 146.673 401.147 138.223 403.66 133.65C406.252 128.748 407.55 123.264 407.43 117.72C407.43 107.78 404.203 99.6133 397.75 93.22C391.297 86.8267 383.16 83.63 373.34 83.63C352.213 83.63 339.213 97.6767 334.34 125.77L298.19 120.29C299.223 98.87 306.617 81.2933 320.37 67.56C334.123 53.8267 351.38 46.9733 372.14 47C393.267 47 410.853 53.91 424.9 67.73C438.947 81.55 445.98 98.7967 446 119.47C446.025 129.885 443.498 140.147 438.64 149.36C433.727 158.78 423.163 171.657 406.95 187.99C398.61 196.437 393.44 203.233 391.44 208.38C389.44 213.527 388.547 222.733 388.76 236ZM352.36 300.76V252.57H391.76V300.71L352.36 300.76Z"
                fill="#E14954"
              />
            </g>
            <g id="Floor">
              <path
                id="Vector_21"
                d="M47.55 300.71C175.18 300.09 318.35 300.09 445.97 300.71C318.35 301.32 175.18 301.32 47.55 300.71Z"
                fill="#EBEBEB"
              />
            </g>
            <g id="Shadow">
              <path
                id="Vector_22"
                opacity="0.1"
                d="M197.69 477.21C248.561 477.21 289.8 472.299 289.8 466.24C289.8 460.181 248.561 455.27 197.69 455.27C146.819 455.27 105.58 460.181 105.58 466.24C105.58 472.299 146.819 477.21 197.69 477.21Z"
                fill="black"
              />
            </g>
            <g id="Character">
              <path
                id="Vector_23"
                d="M137.38 396.63C137.38 396.63 135.98 442.12 135.98 442.15C136.15 450.56 136.45 457.92 136.98 461.72C136.975 461.729 136.972 461.739 136.972 461.75C136.972 461.761 136.975 461.771 136.98 461.78C137.01 462.68 137.243 463.562 137.66 464.36C137.66 464.36 137.66 464.41 137.71 464.42C140.24 466.06 207.45 466.68 209.8 465.91C212.15 465.14 210.15 458.38 207.56 456.28C204.97 454.18 179.34 442.43 179.34 442.43V396.96L137.38 396.63Z"
                fill="#EB9481"
              />
              <path
                id="Vector_24"
                d="M209.78 465.91C207.43 466.68 140.23 466.06 137.69 464.42C137.69 464.42 137.69 464.42 137.64 464.36C137.212 463.566 136.982 462.681 136.97 461.78C136.965 461.76 136.965 461.74 136.97 461.72C136.366 456.073 136.052 450.399 136.03 444.72C147.39 444.53 178.31 444.91 179.35 444.89C180.39 444.87 190.85 450.73 198.43 451.43C201.564 452.862 204.61 454.482 207.55 456.28C210.13 458.38 212.14 465.15 209.78 465.91Z"
                fill="#4E4F94"
              />
              <path
                id="Vector_25"
                opacity="0.4"
                d="M209.75 465.85C207.4 466.62 140.2 466 137.66 464.36C137.66 464.36 137.66 464.36 137.66 464.3C137.232 463.506 137.002 462.621 136.99 461.72C136.985 461.7 136.985 461.68 136.99 461.66C136.386 456.013 136.072 450.339 136.05 444.66C147.41 444.47 178.33 444.85 179.37 444.83C180.41 444.81 190.87 450.67 198.45 451.37C201.582 452.806 204.628 454.426 207.57 456.22C210.1 458.32 212.11 465.09 209.75 465.85Z"
                fill="black"
              />
              <path
                id="Vector_26"
                opacity="0.1"
                d="M209.78 465.91C208.93 466.19 199.7 466.28 188.09 466.21L179.34 444.89C180.34 444.89 190.84 450.73 198.42 451.43C201.555 452.862 204.6 454.482 207.54 456.28C210.13 458.38 212.14 465.15 209.78 465.91Z"
                fill="black"
              />
              <path
                id="Vector_27"
                d="M132.58 443.07L181.2 443.53C181.2 443.53 183.27 347.88 183.48 321.53C183.77 285.75 182.61 285.75 181 200.93H144.66C144.66 200.93 139.87 216.43 138.77 227.82C138.25 233.09 139.9 238.43 140.47 240.7C140.47 240.7 143.83 318.42 143.68 322.7C143.61 324.76 132.58 443.07 132.58 443.07Z"
                fill="#3A3B6F"
              />
              <path
                id="Vector_28"
                d="M136.18 434.56C137.463 434.387 138.755 434.283 140.05 434.25C141.49 434.15 142.92 434.04 144.36 433.98C147.13 433.86 149.89 433.82 152.66 433.8C158.24 433.8 163.83 433.8 169.41 434C172.58 434.1 175.76 434.23 178.93 434.41C179.05 434.41 179.05 434.6 178.93 434.61C173.4 434.81 167.86 434.9 162.32 435C156.78 435.1 151.25 435.22 145.73 435.11C144.14 435.11 142.55 435.11 140.97 434.98C139.39 434.85 137.76 434.98 136.21 434.75C136.11 434.73 136.07 434.58 136.18 434.56Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_29"
                d="M177.35 335.08C178.07 308.89 178.24 277.08 177.91 250.85C177.73 236.15 177.34 227.08 176.91 212.39C176.91 212.22 177.16 212.23 177.17 212.39C178.26 233.09 179.08 259.39 179.17 291.39C179.17 317.59 177.34 415.76 176.24 430.39C176.19 431.02 175.84 430.61 175.85 430.48C176.38 417.3 177 348.18 177.35 335.08Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_30"
                d="M188 398.15L188.37 442.15C188.37 450.56 188.57 457.93 189.01 461.73C189.005 461.75 189.005 461.77 189.01 461.79C189.017 462.694 189.233 463.583 189.64 464.39C189.64 464.39 189.64 464.39 189.64 464.44C192.15 466.13 259.33 467.87 261.7 467.14C264.07 466.41 262.17 459.62 259.62 457.48C257.07 455.34 231.62 443.15 231.62 443.15L230.83 399.22L188 398.15Z"
                fill="#EB9481"
              />
              <path
                id="Vector_31"
                d="M261.72 467.14C259.35 467.87 192.17 466.14 189.66 464.43C189.66 464.43 189.66 464.43 189.66 464.38C189.255 463.576 189.04 462.69 189.03 461.79C189.025 461.77 189.025 461.75 189.03 461.73C188.541 456.304 188.334 450.857 188.41 445.41C205.41 445.41 231.95 446 233.02 446C234.09 446 243.71 451.62 251.2 452.75C254.124 454.147 256.96 455.724 259.69 457.47C262.2 459.61 264.09 466.42 261.72 467.14Z"
                fill="#3A3B6F"
              />
              <path
                id="Vector_32"
                opacity="0.4"
                d="M261.72 467.14C259.35 467.87 192.17 466.14 189.66 464.43C189.66 464.43 189.66 464.43 189.66 464.38C189.255 463.576 189.04 462.69 189.03 461.79C189.025 461.77 189.025 461.75 189.03 461.73C188.541 456.304 188.334 450.857 188.41 445.41C205.41 445.41 231.95 446 233.02 446C234.09 446 243.71 451.62 251.2 452.75C254.124 454.147 256.96 455.724 259.69 457.47C262.2 459.61 264.09 466.42 261.72 467.14Z"
                fill="#131425"
              />
              <path
                id="Vector_33"
                d="M165.74 241.78C165.74 241.78 189.74 315.01 190 321.78C190.34 331.62 185.1 443.31 185.1 443.31L235.59 443.61C235.59 443.61 232.67 327.87 231.54 317.61C230.63 309.24 212.79 248.76 202.77 211.55C202.6 210.91 200.7 200.8 200.7 200.8H145.78C146.635 213.807 150.636 226.412 157.44 237.53C157.44 237.53 162.5 240 165.74 241.78Z"
                fill="#3A3B6F"
              />
              <path
                id="Vector_34"
                d="M187.78 434.05C193.61 433.88 199.66 433.71 205.49 433.85C211.32 433.99 217.08 434.09 222.86 434.43C226.14 434.63 229.41 434.74 232.68 435.06C232.698 435.066 232.713 435.078 232.724 435.093C232.735 435.108 232.74 435.126 232.74 435.145C232.74 435.164 232.735 435.182 232.724 435.197C232.713 435.212 232.698 435.224 232.68 435.23C226.85 435.57 221 435.51 215.17 435.45C209.34 435.39 203.58 435.34 197.8 435.07C194.52 434.92 191.25 434.76 187.98 434.53C187.61 434.51 187.43 434.06 187.78 434.05Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_35"
                d="M178.23 243C175.715 242.988 173.206 242.761 170.73 242.32C168.23 241.85 165.9 241.02 163.47 240.32C161.12 239.78 158.739 239.389 156.34 239.15C153.917 238.807 151.558 238.106 149.34 237.07C149.24 237.02 149.15 237.18 149.26 237.23C151.404 238.384 153.642 239.354 155.95 240.13C158.34 240.89 160.84 240.99 163.27 241.59C165.725 242.318 168.223 242.892 170.75 243.31C173.241 243.612 175.761 243.568 178.24 243.18C178.36 243.14 178.32 243 178.23 243Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_36"
                d="M181.35 290.29C180.88 288.7 172.35 258.45 170 252.17C168.66 248.61 167.45 245 165.87 241.51C165.87 241.42 165.69 241.51 165.71 241.51C166.5 244.77 167.63 247.95 168.64 251.16C169.65 254.37 179.71 288.08 180.42 289.83C181.13 291.58 181.72 293.46 182.59 295.2C182.59 295.29 182.79 295.25 182.77 295.15C182.43 293.54 181.84 291.92 181.35 290.29Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_37"
                d="M195.6 204.57C196.6 214.04 199.6 223.08 202.02 232.25L209.42 259.73L216.73 287.23C219.14 296.4 221.57 305.57 223.9 314.77L224.33 316.5C224.45 317.1 224.63 317.7 224.69 318.3C224.76 319.53 224.79 320.71 224.83 321.89L225 329L225.32 343.22L225.88 371.68L226.34 400.13L226.68 428.58L225.85 400.14L225.11 371.69L224.41 343.25L224.06 329L223.85 321.9C223.85 320.71 223.77 319.52 223.71 318.38C223.65 317.24 223.22 316.14 222.93 314.98C218.31 296.59 213.42 278.26 208.68 259.89L201.55 232.34L198.05 218.55C197.76 217.4 197.46 216.25 197.2 215.09C196.94 213.93 196.76 212.76 196.57 211.59C196.19 209.263 195.867 206.923 195.6 204.57Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_38"
                d="M189.64 121.98L205.91 121.47L205 110.73L188.88 112.95L189.64 121.98Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_39"
                d="M196.83 123.52L220.75 122.76L219.42 106.98L195.72 110.23L196.83 123.52Z"
                fill="#37474F"
              />
              <path
                id="Vector_40"
                d="M213.44 123.76L243.23 122.82L241.57 103.16L212.04 107.22L213.44 123.76Z"
                fill="#455A64"
              />
              <path
                id="Vector_41"
                d="M245.92 112.69C246.38 118.12 245.17 122.69 243.23 122.82C241.29 122.95 239.34 118.72 238.88 113.29C238.42 107.86 239.63 103.29 241.57 103.16C243.51 103.03 245.46 107.27 245.92 112.69Z"
                fill="#455A64"
              />
              <path
                id="Vector_42"
                d="M238.07 103.64C238.291 103.647 238.506 103.71 238.695 103.824C238.884 103.938 239.041 104.098 239.15 104.29C239.413 104.642 239.638 105.02 239.82 105.42C240.161 106.22 240.438 107.046 240.65 107.89C241.079 109.574 241.37 111.289 241.52 113.02C241.65 114.755 241.63 116.498 241.46 118.23C241.374 119.096 241.227 119.955 241.02 120.8C240.908 121.225 240.76 121.639 240.58 122.04C240.4 122.41 240.14 122.87 239.69 122.93C240.12 122.81 240.31 122.37 240.46 121.99C240.602 121.587 240.709 121.172 240.78 120.75C240.935 119.907 241.035 119.056 241.08 118.2C241.19 116.491 241.17 114.776 241.02 113.07C240.888 111.359 240.641 109.658 240.28 107.98C240.111 107.141 239.88 106.315 239.59 105.51C239.45 105.108 239.265 104.722 239.04 104.36C238.951 104.169 238.815 104.003 238.646 103.877C238.477 103.752 238.279 103.67 238.07 103.64Z"
                fill="#37474F"
              />
              <path
                id="Vector_43"
                d="M194.6 124.41L210.88 124.56L210.41 113.79L194.21 115.35L194.6 124.41Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_44"
                d="M201.73 126.23L225.65 126.45L224.96 110.63L201.15 112.92L201.73 126.23Z"
                fill="#37474F"
              />
              <path
                id="Vector_45"
                d="M218.31 127.15L248.11 127.42L247.25 107.71L217.59 110.56L218.31 127.15Z"
                fill="#455A64"
              />
              <path
                id="Vector_46"
                d="M251.21 117.41C251.45 122.85 250.06 127.33 248.11 127.41C246.16 127.49 244.39 123.15 244.11 117.71C243.83 112.27 245.27 107.78 247.21 107.71C249.15 107.64 251 112 251.21 117.41Z"
                fill="#455A64"
              />
              <path
                id="Vector_47"
                d="M243.73 108.05C243.952 108.061 244.167 108.131 244.353 108.252C244.539 108.374 244.69 108.542 244.79 108.74C245.038 109.103 245.245 109.492 245.41 109.9C245.717 110.714 245.964 111.55 246.15 112.4C246.499 114.101 246.72 115.826 246.81 117.56C246.872 119.298 246.778 121.038 246.53 122.76C246.411 123.622 246.23 124.474 245.99 125.31C245.862 125.73 245.698 126.138 245.5 126.53C245.31 126.9 245.03 127.34 244.57 127.39C245.01 127.29 245.22 126.85 245.38 126.47C245.539 126.075 245.663 125.667 245.75 125.25C245.943 124.417 246.077 123.572 246.15 122.72C246.333 121.016 246.386 119.302 246.31 117.59C246.241 115.873 246.06 114.163 245.77 112.47C245.639 111.626 245.441 110.793 245.18 109.98C245.055 109.574 244.887 109.181 244.68 108.81C244.599 108.613 244.47 108.44 244.304 108.307C244.137 108.174 243.94 108.085 243.73 108.05Z"
                fill="#37474F"
              />
              <path
                id="Vector_48"
                d="M246.09 107.88C241.35 108.433 236.607 108.95 231.86 109.43C227.11 109.88 222.36 110.38 217.61 110.8L217.82 110.55V111.32V111.55H217.6L209.36 112.3L201.12 112.98L201.2 112.89V114.65V114.71H201.15L198.43 114.92L201.14 114.6L201.09 114.66L200.98 112.9V112.82H201.06L209.29 111.92L217.52 111.09L217.3 111.33V110.56V110.34H217.52C222.27 109.86 227.03 109.46 231.78 109.02C236.53 108.58 241.33 108.22 246.09 107.88Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_49"
                d="M179.61 146.72C176.27 147.29 172.78 141.95 171.41 138.05C171.3 137.75 171.89 134.84 172.46 131.25C172.8 129.07 173.13 126.64 173.27 124.4L187.11 128.69C186.136 131.443 185.501 134.304 185.22 137.21C185.194 137.519 185.194 137.83 185.22 138.14C185.22 138.14 185.22 138.23 185.22 138.29C185.45 140.05 182.84 146.16 179.61 146.72Z"
                fill="#EB9481"
              />
              <path
                id="Vector_50"
                d="M185.26 138.14V138.29C184.781 138.334 184.299 138.334 183.82 138.29C176.19 137.65 174.4 128.29 174 124.65L187.1 128.71C186.126 131.462 185.495 134.324 185.22 137.23C185.204 137.534 185.218 137.839 185.26 138.14Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_51"
                d="M168.76 105.16C166.93 111.1 169.32 125.99 173.54 129.84C179.65 135.41 189.69 135.97 194.43 128.61C199.02 121.49 195.76 102.7 191.05 99.2C184.1 94.05 171.47 96.39 168.76 105.16Z"
                fill="#EB9481"
              />
              <path
                id="Vector_52"
                d="M194.61 107.9C194.61 107.9 194.84 111.49 192.49 112.54C192.3 112.62 184.33 129.82 171.74 128.14C171.74 128.14 162.53 117.14 165.22 107.39C166.95 101.1 171.34 99.39 171.34 99.39C171.34 99.39 180.46 91.62 190.7 95.81C200.94 100 198.48 107.53 194.61 107.9Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_53"
                d="M199.53 182.5L201.76 200.88L206.36 213.57L165.36 216.82L161.69 208.1L157.58 216L139.23 211.23L143.63 200.87C143.63 200.87 151.52 156.87 166.22 140.87C173.57 132.87 180.22 134.49 185.68 140.07C187.08 141.49 201.07 160.88 202.82 169.26C204.35 176.66 199.53 182.5 199.53 182.5Z"
                fill="#E14954"
              />
              <path
                id="Vector_54"
                opacity="0.1"
                d="M162.18 197.43C162.06 196.76 162.95 182.71 163.18 180.37C163.41 178.03 165.66 166.64 165.66 166.64"
                fill="black"
              />
              <path
                id="Vector_55"
                d="M230.63 181.94C235.01 176.87 234.71 164.03 233.22 137.49C233.02 133.82 207.22 131.38 207.22 135.31C207.22 140.26 208.4 159.48 208.66 160.88C210.85 172.64 227.66 185.39 230.63 181.94Z"
                fill="#EB9481"
              />
              <path
                id="Vector_56"
                d="M205 106.26C205.732 105.924 206.548 105.817 207.342 105.952C208.136 106.088 208.871 106.46 209.45 107.02C210.149 105.842 211.239 104.947 212.53 104.49C214.84 103.8 216.95 107.12 216.95 107.12C217.933 106.05 219.215 105.301 220.63 104.97C222.9 104.53 225.51 107.58 225.51 107.58C226.952 107.093 228.522 107.143 229.93 107.72C233.63 109.42 234.42 115.59 234.93 119.5C235.44 123.41 233.36 135.03 233.18 137.28L208.12 141C208.12 141 200.68 124.14 200.69 119.45C200.7 115.5 202.25 107.68 205 106.26Z"
                fill="#EB9481"
              />
              <path
                id="Vector_57"
                d="M209.34 107.07C208.34 111.27 208.6 115.54 208.64 119.83C208.64 119.849 208.647 119.866 208.66 119.879C208.674 119.893 208.691 119.9 208.71 119.9C208.729 119.9 208.746 119.893 208.759 119.879C208.773 119.866 208.78 119.849 208.78 119.83C208.7 115.58 209.46 111.36 209.55 107.11C209.549 107.085 209.539 107.062 209.523 107.043C209.507 107.025 209.485 107.012 209.461 107.007C209.436 107.003 209.411 107.006 209.39 107.018C209.368 107.029 209.35 107.048 209.34 107.07Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_58"
                d="M216.84 107.11C216.71 108.086 216.71 109.074 216.84 110.05C216.9 111.05 216.98 112.14 217.07 113.18C217.257 115.26 217.5 117.337 217.8 119.41C217.8 119.5 217.96 119.5 217.95 119.41C217.763 117.337 217.617 115.26 217.51 113.18C217.51 112.18 217.42 111.1 217.39 110.05C217.419 109.065 217.342 108.079 217.16 107.11C217.16 107.068 217.143 107.027 217.113 106.997C217.083 106.967 217.042 106.95 217 106.95C216.958 106.95 216.917 106.967 216.887 106.997C216.857 107.027 216.84 107.068 216.84 107.11Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_59"
                d="M225.5 107.66C225.388 108.547 225.425 109.446 225.61 110.32C225.72 111.26 225.82 112.21 225.92 113.16C226.11 115.06 226.3 116.95 226.43 118.85C226.43 118.95 226.58 118.94 226.58 118.85C226.46 116.92 226.37 114.98 226.23 113.05L226.02 110.21C226.028 109.339 225.886 108.473 225.6 107.65C225.594 107.642 225.586 107.636 225.576 107.632C225.567 107.628 225.557 107.627 225.547 107.628C225.537 107.629 225.528 107.632 225.519 107.638C225.511 107.644 225.504 107.651 225.5 107.66Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_60"
                d="M204.93 139.24L235.18 149.55C235.18 149.55 235.47 178.64 230.75 183.55C227.64 186.75 209.06 173.41 207.22 160.38L204.93 139.24Z"
                fill="#E14954"
              />
              <path
                id="Vector_61"
                d="M233.37 155.62C230.08 154.62 226.85 153.55 223.62 152.41C220.39 151.27 217.16 150.15 213.95 148.93C212.15 148.25 210.33 147.57 208.55 146.82C208.5 146.82 208.55 146.72 208.55 146.73C211.84 147.73 215.08 148.8 218.31 149.91C221.54 151.02 224.78 152.17 228 153.35C229.81 154.01 231.62 154.71 233.4 155.46C233.51 155.5 233.47 155.65 233.37 155.62Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_62"
                d="M182.16 151.35C196.24 172.26 207.08 184.68 222.07 185.93C236.88 187.15 232.35 172.18 231.52 170.93C228.41 166.36 205.13 148.58 188.8 140.93C172.47 133.28 178.68 146.18 182.16 151.35Z"
                fill="#E14954"
              />
              <path
                id="Vector_63"
                d="M206.53 151.18C208.35 152.18 210.11 153.29 211.85 154.41C213.59 155.53 215.31 156.71 217 157.93C218.69 159.15 220.34 160.4 221.93 161.74C223.552 163.044 225.076 164.464 226.49 165.99C224.89 164.67 223.24 163.42 221.58 162.19L216.58 158.53L211.58 154.88C209.85 153.66 208.18 152.44 206.53 151.18Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_64"
                d="M183.93 154L188.76 160.08C190.38 162.08 191.97 164.14 193.61 166.13C195.25 168.12 196.88 170.13 198.55 172.13C200.22 174.13 201.94 176.03 203.78 177.86C201.758 176.229 199.865 174.444 198.12 172.52C196.35 170.62 194.69 168.63 193.03 166.64C191.37 164.65 189.83 162.56 188.31 160.47C186.79 158.38 185.3 156.17 183.93 154Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_65"
                d="M183.89 156.37C184.62 156.94 185.27 157.54 185.95 158.13L186.95 159.01L187.95 159.88C187.062 159.6 186.246 159.129 185.56 158.5C184.883 157.893 184.317 157.172 183.89 156.37Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_66"
                d="M170.54 137.19L171.4 132.13L185.74 136.73L186.81 140.28L170.54 137.19Z"
                fill="#FFC727"
              />
              <path
                id="Vector_67"
                d="M184.92 95.2C184.92 95.2 176.14 90.41 167.02 98.57C156.17 108.27 158.61 145.37 157.93 148.34C157.25 151.31 155.93 152.03 155.52 154.69C155.11 157.35 157.29 159.1 157.52 160.05C157.75 161 155.13 165.87 155.13 165.87C155.13 165.87 169.62 170.54 178.96 169.87C188.12 169.19 198.79 167.4 198.79 167.4C198.79 167.4 196.7 164.4 196.7 163.05C196.7 161.7 198.34 159.79 198.21 157.26C198.08 154.73 194.21 151.63 193.85 150.26C193.49 148.89 196.11 112.37 193.91 103.56C191.71 94.75 184.92 95.2 184.92 95.2Z"
                fill="#1A2433"
              />
              <path
                id="Vector_68"
                d="M199.28 166.64C198.474 165.7 197.801 164.654 197.28 163.53C197.142 163.216 197.084 162.872 197.11 162.53C197.57 160.53 199.35 158.7 199.01 156.53C198.56 154.86 196.93 153.48 195.83 152.18C195.106 151.486 194.584 150.608 194.32 149.64C194.32 149.28 194.32 148.99 194.32 148.64C194.67 138.16 196.1 116.24 194.38 105.85C194.015 103.428 193.179 101.101 191.92 99C193.319 101.068 194.272 103.404 194.72 105.86C196.84 116.24 195.5 138.25 195 148.75C194.985 149.043 194.985 149.337 195 149.63C195.251 150.453 195.701 151.202 196.31 151.81C197.45 153.24 199.06 154.63 199.49 156.49C199.81 158.82 197.97 160.57 197.34 162.64C197.298 162.928 197.336 163.222 197.45 163.49C197.927 164.626 198.545 165.698 199.29 166.68L199.28 166.64Z"
                fill="#EBEBEB"
              />
              <path
                id="Vector_69"
                d="M154.43 164.55C155.03 163.27 155.89 161.65 156.38 160.35C156.28 160.152 156.156 159.967 156.01 159.8C154.693 158.421 153.974 156.577 154.01 154.67C154.16 152.42 155.71 150.52 156.45 148.54C156.866 147.178 157.005 145.747 156.86 144.33C156.53 132.06 157.3 107.45 166.73 98.49C167.806 97.4878 169.048 96.6792 170.4 96.1C169.093 96.7495 167.901 97.6074 166.87 98.64C158 107.88 157.42 132.07 157.55 144.32C157.705 145.82 157.528 147.336 157.03 148.76C156.2 150.85 154.7 152.48 154.47 154.71C154.373 156.128 154.757 157.537 155.56 158.71C155.84 159.18 156.19 159.6 156.48 160.08C156.533 160.156 156.561 160.247 156.56 160.34C155.914 161.792 155.183 163.204 154.37 164.57L154.43 164.55Z"
                fill="#EBEBEB"
              />
            </g>
          </g>
        </g>
        <defs>
          <clipPath id="clip0_3_87">
            <rect width="491.17" height="501.15" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </>
  );
}
