import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  validity: false,
  purchase: true,
  auger_fee: 2.9,
  token_value: null,
  account_tokens: 0,
  planList: [],
  plan: null,
  upgradePackageSubscription: null,
  upgradeServiceSubscription: null,
};

const packageSubscriptionSlice = createSlice({
  initialState,
  name: "package-subscriptions",
  reducers: {
    setPackageSubUpgradePlan: (state, action) => {
      state.upgradePackageSubscription = action.payload;
    },
    setServiceSubUpgradePlan: (state, action) => {
      state.upgradeServiceSubscription = action.payload;
    },
    setPlanList: (state, action) => {
      state.planList = action.payload;
    },
    setPlan: (state, action) => {
      state.plan = action.payload;
    },
    setValidity: (state, action) => {
      state.validity = action.payload;
    },
    setPurchase: (state, action) => {
      state.purchase = action.payload;
    },
    setTokenValue: (state, action) => {
      state.token_value = action.payload;
    },
    setAugerFee: (state, action) => {
      state.auger_fee = action.payload;
    },
    setAccountTokens: (state, action) => {
      state.account_tokens = action.payload;
    },
  },
});

export const {
  setPlanList,
  setPlan,
  setValidity,
  setPurchase,
  setTokenValue,
  setAugerFee,
  setAccountTokens,
  setPackageSubUpgradePlan,
  setServiceSubUpgradePlan,
} = packageSubscriptionSlice.actions;

export default packageSubscriptionSlice.reducer;
