import React, { useEffect, useState } from "react";
import { DropdownToggle, DropdownMenu, Dropdown } from "reactstrap";
import Cookies from "js-cookie";

//^ utils
import { getCookie, jsonStringConvertor } from "../../../../utils/Utils";

//^ components
import { Icon } from "../../../../components/Component";
import { LinkList, LinkItem } from "../../../../components/links/Links";
import { useTheme, useThemeUpdate } from "../../../provider/Theme";
import AccountAvatar from "../../../../pages/pre-built/user-manage/AccountAvatar";
import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import WhiteCoin from "../../../../components/svg/white-coin";
import { handleOldClick } from "../app/App";

const User = () => {
  const location = useLocation();

  const pathname = location.pathname;

  // eslint-disable-next-line
  const showApp = useSelector((state) => state.siteSetting.showApp);
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();

  const ssoTokenUrl = getCookie("ssoTokenURL");

  const initialAppLinks = [
    {
      link: `https://silocloud.com/enumblockchain-explorer`,
      showSvg: true,
      Svg: WhiteCoin,
      text: "BlockChain",
      oldsite: true,
      showLink: true,
    },
    {
      showLink: true,
      oldsite: false,
      link: `https://silocloud.com/wallet-dashboard`,
      showSvg: false,
      icon: "wallet",
      text: "Silo Wallet",
    },
    {
      showLink: false,
      showSvg: false,
      link: ``,
      darkIcon: "moon",
      lightIcon: "sun",
      textDark: "Dark Mode",
      textLight: "Light Mode",
      oldsite: false,
    },
  ];

  if (ssoTokenUrl) {
    initialAppLinks.splice(2, 0, {
      showLink: true,
      showSvg: false,
      link: `${ssoTokenUrl}`,
      oldsite: false,
      icon: "cloud",
      text: `${process.env.REACT_APP_ACCOUNT_SECOND_DOMAIN}`,
    });
  }

  // eslint-disable-next-line
  const [appLinks, setAppLink] = useState(initialAppLinks);

  useEffect(() => {
    if (pathname !== process.env.REACT_APP_SETTINGS_URL) {
      setAppLink((prevAppLinks) => {
        const existingLinkIndex = prevAppLinks.findIndex(
          (link) => link.text === "Account Settings" || link.link.includes("/user-profile-regular")
        );

        const newLink = {
          showLink: true,
          link: `${process.env.PUBLIC_URL}/user-profile-regular`,
          showSvg: false,
          icon: "setting-alt",
          text: "Account Settings",
          oldsite: false,
        };

        if (existingLinkIndex !== -1) {
          const updatedLinks = [...prevAppLinks];
          updatedLinks[existingLinkIndex] = newLink;
          return updatedLinks;
        } else {
          return [...prevAppLinks.slice(0, 2), newLink, ...prevAppLinks.slice(2)];
        }
      });
    } else {
      const updatedAppLink = appLinks.filter((link) => link.text !== "Account Settings");

      setAppLink(updatedAppLink);
    }
  }, [pathname, appLinks]);

  const [open, setOpen] = useState(false);

  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen((prevState) => !prevState);
  };

  function handleSignOut() {
    const cookieKeysString = process.env.REACT_APP_ACCOUNT_COOKIES_KEYS || "";
    const convertedJsonCookieKeys = jsonStringConvertor(cookieKeysString);
    const cookieKeys = JSON.parse(convertedJsonCookieKeys);
    if (cookieKeys) {
      cookieKeys?.forEach((key) => Cookies.remove(key));
    }
  }

  const handleClick = (app, event) => {
    if (app.oldsite) {
      handleOldClick(app.link, event);
    } else {
      toggle();
    }
  };

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle
        tag="a"
        href="#toggle"
        className="dropdown-toggle"
        onClick={(ev) => {
          ev.preventDefault();
        }}
      >
        <AccountAvatar profileName={getCookie("username", "John")} />
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-md dropdown-menu-s1">
        <div className="dropdown-inner user-card-wrap bg-lighter d-none d-md-block">
          <Stack direction={"row"} alignItems={"center"}>
            <AccountAvatar profileName={getCookie("username", "John")} />
            <div className="user-info">
              <span className="lead-text">{getCookie("username", "John")}</span>
              <span className="sub-text">{getCookie("email", "john@mail.io")}</span>
            </div>
          </Stack>
        </div>
        <div>
          <LinkList className="profile-menus">
            {/* <LinkItem link="/user-profile-regular" icon="user-alt" onClick={toggle} className="d-none">
              View Profile
            </LinkItem> */}

            {appLinks.map((link, index) => {
              if (link.showLink) {
                return link.showSvg ? (
                  <React.Fragment key={index}>
                    <LinkItem
                      showSvg={true}
                      SvgIcon={<link.Svg style={{ width: "18px", height: "18px", marginRight: "10px" }} />}
                      link={link.link}
                      icon={link.icon}
                      onClick={(event) => handleClick(link, event)}
                    >
                      {link.text}
                    </LinkItem>
                  </React.Fragment>
                ) : (
                  <React.Fragment key={index}>
                    <LinkItem link={link.link} icon={link.icon} onClick={toggle}>
                      {link.text}
                    </LinkItem>
                  </React.Fragment>
                );
              } else {
                return (
                  <React.Fragment key={index}>
                    <LinkItem
                      linkClassName={`dark-switch ${theme.skin === "dark" ? "active" : ""}`}
                      onClick={(ev) => {
                        ev.preventDefault();
                        themeUpdate.skin(theme.skin === "dark" ? "light" : "dark");
                      }}
                    >
                      {theme.skin === "dark" ? (
                        <>
                          <div className="d-flex align-items-center">
                            <Icon name={link.lightIcon} />
                            <span>{link.textLight}</span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="d-flex align-items-center">
                            <Icon name={link.darkIcon} />
                            <span>{link.textDark}</span>
                          </div>
                        </>
                      )}
                    </LinkItem>
                  </React.Fragment>
                );
              }
            })}

            {/* <LinkItem link="/user-profile-activity" icon="activity-alt" className="d-none" onClick={toggle}>
              Login Activity
            </LinkItem> */}
          </LinkList>
        </div>
        <div className="dropdown-inner">
          <LinkList>
            <Link to={`${process.env.REACT_APP_ACCOUNT_URL}logout`} onClick={handleSignOut}>
              <Icon name="signout"></Icon>
              <span>Sign Out</span>
            </Link>
          </LinkList>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default User;
