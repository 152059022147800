import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import { parsePhoneNumber } from "libphonenumber-js";
import Cookies from "js-cookie";
import * as yup from "yup";

//^ http request
import { postVerifyOTP, postVerify2faOtp } from "../../../http/post-api";
import { queryClient } from "../../../http";

//^ component
import { BlockDes } from "../../Component";

//^ models
import { getIpv4IpAddress } from "../../../http/get-api";
import { Box, Button } from "@mui/material";
import { responseErrorHandler } from "../../../utils/Utils";

let currentOtpInx = 0;

export default function AuthOtp({
  otpSendOn,
  type = "email",
  codeLength = 4,
  email,
  verifyButtonLabel = "Verify OTP",
  username,
  twoFactAuth,
  maskOtpSendOn,
}) {
  const navigate = useNavigate();

  const [otp, setOtp] = useState(new Array(codeLength).fill(""));
  const [activeOtpIdx, setActiveOtpIdx] = useState(0);

  //^ error states

  const inputRef = useRef(null);
  const submitBtnRef = useRef(null);

  const isOtpFilled = otp.every((item) => item !== "");

  const schema = yup.object().shape({
    inputOneVal: yup.string(),
    inputTwoVal: yup.string(),
    inputThreeVal: yup.string(),
    inputFourVal: yup.string(),
  });

  let initialValues = {};

  const {
    data: getIPv4Data,
    isLoading: getIPv4IsLoading,
    isRefetching: getIPv4IsRefetching,
    isError: getIPv4IsError,
    error: getIPv4Error,
  } = useQuery({
    queryKey: ["get-ipv4-ip-address"],
    queryFn: ({ signal }) => getIpv4IpAddress({ signal }),
    gcTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    otp.forEach((_, index) => {
      initialValues[`input${index}Val`] = null;
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOtpIdx]);

  //^ verify 2fa otp mutation query
  const {
    isPending: verifyOtpIsPending,
    isError: verifyOtpIsError,
    error: verifyOtpError,
    mutate: verifyOtpMutate,
    reset: verifyOtpReset,
  } = useMutation({
    mutationKey: ["verify-otp"],
    mutationFn: postVerifyOTP,
    onSuccess: (data) => {
      if (data.toast) {
        if (data.type === "error") {
          responseErrorHandler(true, data);
        } else if (data.type === "success") {
          queryClient.invalidateQueries({ queryKey: ["get-user-profile-index-app"], exact: true });
          queryClient.invalidateQueries({ queryKey: ["get-user-profile"], exact: true });
          navigate(-1);
          toast.success(data?.message);
        } else if (data.message === "Validation failed") {
          responseErrorHandler(true, data);
        }
      }
      verifyOtpReset();
    },
  });

  //^ verify 2fa for login mutation query
  const {
    isPending: loginTwoFactIsPending,
    isError: loginTwoFactIsError,
    error: loginTwoFactError,
    mutate: loginTwoFactMutate,
    reset: loginTwoFactReset,
  } = useMutation({
    mutationKey: ["post-verify-2fa-login-otp"],
    mutationFn: postVerify2faOtp,
    onSuccess: (data) => {
      if (data.toast) {
        if (data.status) {
          if (data.type === "error") {
            responseErrorHandler(true, data);
          } else if (data.type === "success") {
            const now = new Date();
            const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);

            Cookies.set("authToken", data.data.authToken, {
              domain: process.env.REACT_APP_COOKIE_DOMAIN,
              expires: expires,
            });
            Cookies.set("username", data.data.username, {
              domain: process.env.REACT_APP_COOKIE_DOMAIN,
              expires: expires,
            });
            Cookies.set("email", data.data.email, { domain: process.env.REACT_APP_COOKIE_DOMAIN, expires: expires });
            if (data?.data?.ssoTokenURL)
              Cookies.set("ssoTokenURL", data?.data?.ssoTokenURL, {
                domain: process.env.REACT_APP_COOKIE_DOMAIN,
                expires: expires,
              });
            Cookies.set("profile_pic", data?.data?.profile_pic, {
              domain: process.env.REACT_APP_COOKIE_DOMAIN,
              expires: expires,
            });
            Cookies.set("twoFaData", data?.data?.twoFaData, {
              domain: process.env.REACT_APP_COOKIE_DOMAIN,
              expires: expires,
            });
            const currentURL = Cookies.get("currentURL");
            if (currentURL) {
              window.location = currentURL;
            } else {
              window.location = process.env.REACT_APP_DEFAULT_REDIRECT_URL;
            }

            toast.success(data?.message);
          } else if (data.message === "Validation failed") {
            responseErrorHandler(true, data);
          }
        } else {
          responseErrorHandler(true, data);
        }
      }
      loginTwoFactReset();
    },
  });

  useEffect(() => {
    if (verifyOtpIsError && loginTwoFactIsError && getIPv4IsError) {
      responseErrorHandler(verifyOtpIsError, verifyOtpError);
    } else {
      responseErrorHandler(verifyOtpIsError, verifyOtpError);
      responseErrorHandler(loginTwoFactIsError, loginTwoFactError);
      responseErrorHandler(getIPv4IsError, loginTwoFactError);
    }
  }, [verifyOtpIsError, loginTwoFactIsError, getIPv4IsError, verifyOtpError, loginTwoFactError, getIPv4Error]);

  const formik = useFormik({
    initialValues,
    validationSchema: schema,

    onSubmit() {
      const otpCode = parseInt(otp.join(""));
      let mobileNumber = null;
      let number = null;
      let phoneCode = null;

      if (typeof otpSendOn === "string" && type === "mobile") {
        mobileNumber = parsePhoneNumber(otpSendOn);
        if (mobileNumber) {
          number = mobileNumber.nationalNumber;
          phoneCode = mobileNumber.countryCallingCode;
        } else {
          console.error("Invalid phone number");
          return;
        }
      }

      if (
        (type === "login-two-fact" && typeof otpSendOn === "string") ||
        typeof otpSendOn === "number" ||
        typeof otpSendOn === "object"
      ) {
        let ipv4Add = null;

        if (!getIPv4IsLoading || !getIPv4IsRefetching) {
          // eslint-disable-next-line
          ipv4Add = getIPv4Data?.ip;
        }

        loginTwoFactMutate({
          otp: otpCode,
          username,
        });
      }

      if (type === "mobile" && typeof otpSendOn === "string") {
        verifyOtpMutate({
          factType: type,
          otp: otpCode,
          phoneCode: phoneCode,
          twoFactPhone: number,
        });
      } else if (type === "email") {
        verifyOtpMutate({
          factType: type,
          otp: otpCode,
          twoFactEmail: email,
        });
      } else if (type === "both" && typeof otpSendOn === "string") {
        verifyOtpMutate({
          factType: type,
          otp: otpCode,
          phoneCode: phoneCode,
          twoFactPhone: number,
          twoFactEmail: email,
        });
      }
    },
  });

  const inputChangeHandler = (event, fieldName) => {
    const value = event.target.value;
    const val = value.substring(value.length - 1);

    const newOTP = [...otp];
    newOTP[currentOtpInx] = val;

    setOtp(newOTP);

    if (!value) setActiveOtpIdx(currentOtpInx - 1);
    else setActiveOtpIdx(currentOtpInx + 1);

    formik.setFieldValue(fieldName, val);

    // Check if the last input is filled
    if (currentOtpInx === otp.length - 1) {
      if (val.trim().length > 0) {
        // Programmatically submit the form using Formik context
        formik.submitForm();
      }
    }
  };

  function handleOnKeyDown(event, index) {
    const key = event.key;

    currentOtpInx = index;

    if (key === "Backspace") {
      setActiveOtpIdx(currentOtpInx - 1);
    }
  }

  return (
    <>
      <Box className="w-100">
        <form onSubmit={formik.handleSubmit} className="d-flex flex-column" style={{ gap: "0.25rem" }}>
          <BlockDes>
            Enter the code we just send on your{" "}
            {`${
              type === "email"
                ? "Email"
                : type === "mobile"
                ? "Mobile phone"
                : type === "both"
                ? "Mobile phone and Email"
                : type === "login-two-fact" && twoFactAuth === "1"
                ? "email"
                : type === "login-two-fact" && twoFactAuth === "2"
                ? "Mobile phone"
                : type === "login-two-fact" && twoFactAuth === "3"
                ? "Mobile phone"
                : ""
            }`}{" "}
            {twoFactAuth === "3" ? (
              <span>
                <strong>{maskOtpSendOn?.maskedPhoneNumber}</strong>
                {` and Email ID `} <strong>{maskOtpSendOn?.maskedEmail}</strong>
              </span>
            ) : (
              <strong>{maskOtpSendOn || otpSendOn}</strong>
            )}
          </BlockDes>
          <div className="d-flex flex-column" style={{ gap: "1rem" }}>
            <div>
              <div className="d-flex flex-row" style={{ gap: "0.5rem", width: "15rem" }}>
                {otp.map((_, index) => {
                  return (
                    <input
                      ref={index === activeOtpIdx ? inputRef : null}
                      key={index}
                      type="number"
                      name={`input${index}Val`}
                      defaultValue={formik.values[`input${index}Val`]}
                      className="form-control spin-button-none text-center"
                      autoFocus=""
                      onChange={(event) => inputChangeHandler(event, `input${index}Val`)}
                      onKeyDown={(event) => handleOnKeyDown(event, index)}
                      value={otp[index]}
                      required
                    />
                  );
                })}
              </div>
            </div>
            <div>
              <Button
                variant="contained"
                ref={submitBtnRef}
                type={"submit"}
                color={"primary"}
                className={"d-flex"}
                style={{
                  gap: "0.5rem",
                  alignItems: "center",
                  justifyContent: "center",
                  cursor: verifyOtpIsPending || loginTwoFactIsPending ? "not-allowed" : "",
                  whiteSpace: "nowrap",
                }}
                disabled={!isOtpFilled || verifyOtpIsPending || loginTwoFactIsPending}
                startIcon={
                  verifyOtpIsPending || loginTwoFactIsPending ? (
                    <Spinner style={{ borderWidth: "2px", color: "inherit" }} size={"sm"} />
                  ) : (
                    ""
                  )
                }
              >
                <span>{verifyButtonLabel}</span>
              </Button>
            </div>
          </div>
        </form>
      </Box>
    </>
  );
}
