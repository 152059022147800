import { createSlice } from "@reduxjs/toolkit";

const paymentSlice = createSlice({
  initialState: {
    paymentModal: false,
    paymentModalTitle: `${process.env.REACT_APP_PROJECT_NAME} Payment`,
    users_account_tokens: 0,
    orderDescription: "Order",
    price: 10,
    auger_fee: 2.9,
    token_value: 0,
    payWithTokensSpinner: false,
    section: "accountTokens",
    isPaymentTokenClicked: false,
    account_token: 0,
    showPaymentLoader: false,
    promocode: "",
  },
  name: "payment",
  reducers: {
    setAccountToken: (state, { payload }) => {
      state.account_token = payload;
    },
    setPromoCode: (state, { payload }) => {
      state.promocode = payload.promocode;
    },

    setPaymentSlice: (state, action) => {
      return {
        ...state,
        ...action.payload,
      };
    },
    setPaymentModal: (state, action) => {
      state.paymentModal = action.payload;
    },
    setPayWithTokensSpinner: (state, action) => {
      state.payWithTokensSpinner = action.payload;
      state.showPaymentLoader = action.payload;
    },

    setSection: (state, action) => {
      state.section = action.payload;
    },

    clickPaymentToken: (state) => {
      state.isPaymentTokenClicked = true;
    },
  },
});

export const {
  setPaymentModal,
  setPaymentModalTitle,
  setPayWithTokens,
  setPaymentSlice,
  setAccountToken,
  clickPaymentToken,
  setPayWithTokensSpinner,
  setSection,
  setPromoCode,
} = paymentSlice.actions;

export default paymentSlice.reducer;
