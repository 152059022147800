import { Button } from "@mui/material";

export default function CircularButton({ text, variant = "outlined", sx, size = "small", onClick, ...props }) {
  return (
    <Button
      variant={variant}
      sx={{
        borderRadius: "10rem",
        minWidth: "7rem",
        display: "none",
        "@media (min-width: 65.5rem)": {
          display: "block",
        },
        ...sx,
      }}
      size={size}
      onClick={onClick}
      {...props}
    >
      {text}
    </Button>
  );
}
