import React from "react";
import classNames from "classnames";
import SimpleBar from "simplebar-react";
import { useSelector } from "react-redux";

//^ @mui component
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme as useMuiTheme } from "@mui/material/styles";
import {
  // ChevronRight,
  ContentCopy,
  Facebook,
  LinkedIn,
} from "@mui/icons-material";
import XIcon from "@mui/icons-material/X";

import { useTheme, useThemeUpdate } from "../provider/Theme";
import UserProfileAside from "../../pages/pre-built/user-manage/UserProfileAside";

//^ svg
import ReferAndEarn from "../../components/svg/refer-and-earn";
import ReferAndEarnDark from "../../components/svg/refer-and-earn-dark";
import HomeDark from "../../components/svg/home/dark";
import HomeWhite from "../../components/svg/home/light";
import { jsonStringConvertor } from "../../utils/Utils";
import { Homelightlogo, home_logo } from "../../images/applogos";
import useStorageUsage from "../../utils/useStorageUsage";
import { Icon } from "../../components/Component";
import { Progress } from "reactstrap";

const Sidebar = ({ className, subscribedPackageData, updateSm, sm, mobileView, fieldValue }) => {
  const [open, setOpen] = React.useState(false);
  const { storageDetails, loading } = useStorageUsage();

  const theme = useTheme();
  const muiTheme = useMuiTheme();
  const themeUpdate = useThemeUpdate();
  const referAndEarnData = useSelector((state) => state.userProfile.referAndEarnData);
  const referAndEarn = useSelector((state) => state.siteSetting.referAndEarn);
  const siteSettingIsLoading = useSelector((state) => state.siteSetting.siteSettingIsLoading);
  // eslint-disable-next-line
  const fullScreen = useMediaQuery(muiTheme.breakpoints.down("md"));

  const isDark = muiTheme.palette.mode === "dark";

  const classes = classNames({
    "nk-sidebar": true,
    "nk-sidebar-active": theme.sidebarVisibility,
    "nk-sidebar-mobile": theme.sidebarMobile,
    [`is-light`]: theme.sidebar === "white",
    [`is-${theme.sidebar}`]: theme.sidebar !== "white" && theme.sidebar !== "light",
    [`${className}`]: className,
  });

  const accountLinksString = process.env.REACT_APP_ACCOUNT_LINKS || "";
  const jsonAccountLinksString = jsonStringConvertor(accountLinksString);
  const accountLinks = JSON.parse(jsonAccountLinksString);
  const searchLink = accountLinks?.find((link) => link?.name === "search");

  function handleClose() {
    setOpen(false);
  }

  return (
    <>
      <Dialog
        // fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          elevation: 1,
          sx: {
            width: "100%",
            minWidth: "unset",
            maxWidth: "unset",
            "@media (min-width: 65.5rem)": {
              width: "600px",
            },
          },
        }}
      >
        <DialogTitle
          component={Typography}
          sx={{
            display: "none",
            "@media (min-width: 65.5rem)": {
              display: "block",
            },
          }}
        >
          Refer a friend and get upto 25 tokens as reward
        </DialogTitle>
        <DialogTitle
          component={Typography}
          variant="h6"
          fontSize={"1rem"}
          sx={{
            display: "block",
            "@media (min-width: 65.5rem)": {
              display: "none",
            },
          }}
        >
          Refer a friend and get upto 25 tokens as reward
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Stack gap={"1.25rem"}>
            <Stack>{isDark ? <ReferAndEarnDark /> : <ReferAndEarn />}</Stack>
            <Typography variant="h6" textAlign={"center"}>
              {referAndEarnData?.description}
            </Typography>

            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"} gap={"1.25rem"}>
              {referAndEarnData?.socials?.map((social, index) => {
                return (
                  <a key={index} href={social.url} target={"_blank"} rel="noreferrer">
                    <Fab sx={{ boxShadow: "none" }}>
                      {social.name === "linkedin" ? (
                        <LinkedIn />
                      ) : social.name === "twitter" ? (
                        <XIcon />
                      ) : social.name === "facebook" ? (
                        <Facebook />
                      ) : (
                        ""
                      )}
                    </Fab>
                  </a>
                );
              })}
            </Stack>

            <Stack gap={"1.25rem"}>
              <Stack direction={"row"} justifyContent={"space-between"}>
                <Typography variant="overline" fontWeight={600} letterSpacing={2}>
                  refer link
                </Typography>
                <Button
                  endIcon={<ContentCopy />}
                  variant="contained"
                  onClick={() => navigator.clipboard.writeText(referAndEarnData.refer_link)}
                >
                  <span>copy link</span>
                </Button>
              </Stack>
              <Card elevation={6} sx={{ padding: "1rem", overflow: "auto" }}>
                <Typography whiteSpace={"nowrap"}>{referAndEarnData?.refer_link}</Typography>
              </Card>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: muiTheme.spacing(2) }}>
          <Button variant="contained" type="button" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <Paper variant="outlined" elevation={2} sx={{ borderRadius: "none", height: "100%" }} className={`${classes}`}>
        <Stack height={"100%"} justifyContent={"space-between"} position={"relative"}>
          <SimpleBar className="h-100">
            <UserProfileAside
              fieldValue={fieldValue}
              isUserSubscribed={subscribedPackageData?.status}
              updateSm={updateSm}
              sm={sm}
            />
          </SimpleBar>
          {siteSettingIsLoading ? (
            ""
          ) : referAndEarn === "1" && referAndEarnData !== null ? (
            <Stack padding={"1.25rem"}>
              <Button variant="contained" onClick={() => setOpen(true)}>
                Refer & Earn 🎁
              </Button>
            </Stack>
          ) : (
            ""
          )}
          {/* <a href={searchLink?.link}>
            <Box padding={muiTheme.spacing(1)} sx={{ cursor: "pointer" }}>
              <img style={{ height: "36px", objectFit: "contain" }} src={homelogo} alt="home-logo" />
              {isDark ? (
                <HomeDark style={{ width: "2.5rem", height: "2.5rem" }} />
              ) : (
                <HomeWhite style={{ width: "2.5rem", height: "2.5rem" }} />
              )}
            </Box>
          </a> */}
          <div className="d-flex align-items-center my-auto section-footer px-1">
            <a href="https://silocloud.io/" className="home_icon">
              <img style={{ height: "36px", objectFit: "contain" }} className="homeicon" src={home_logo} alt="" />
            </a>
            <div className="storage-block d-none d-lg-block rounded-3 px-2 py-1 ms-1">
              {" "}
              <a href="https://storage.silocloud.io/">
                <div className="d-flex align-items-center mb-1">
                  <Icon name="db-fill" className="fs-3" />
                  {loading ? (
                    <Skeleton width={100} height={8} baseColor="#c5bfe0" />
                  ) : (
                    <p className={`fs-9px mb-0 ms-1 opacity-75 ${theme.skin === "light" ? "text-dark" : ""}`}>
                      Cloud Storage {storageDetails.usedStorageText}/{storageDetails.planStorageText}
                    </p>
                  )}
                </div>
                <div className="">
                  <Progress
                    value={
                      storageDetails.percentageUsed === 0
                        ? storageDetails.percentageUsed + 1
                        : storageDetails.percentageUsed
                    }
                    color="info"
                    className="progress-storage"
                  />
                </div>
              </a>
            </div>
          </div>
          {/* <IconButton
            size="small"
            sx={{
              position: "absolute",
              borderRadius: "0rem",
              height: "100%",
              // width: "0rem",
              right: "0rem",
              background: muiTheme.palette.mode === "dark" ? "#15202f" : "#ffffff",
            }}
          >
            <ChevronRight />
          </IconButton> */}
        </Stack>
      </Paper>
      {theme.sidebarVisibility && <div onClick={themeUpdate.sidebarVisibility} className="nk-sidebar-overlay"></div>}
    </>
  );
};
export default Sidebar;
