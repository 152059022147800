import { Skeleton } from "@mui/material";
import React from "react";
import { Block, BlockContent, BlockHead, PreviewCard } from "../../../components/Component";
import Logo from "../../../images/logo.png";
import LogoDark from "../../../images/logo-dark.png";
import { Link } from "react-router-dom";
const OTPHomeSkelaton = () => {
  return (
    <>
      <Block className="nk-block-middle nk-auth-body  wide-xs">
        <div className="brand-logo pb-2 text-center">
          <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
            <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" loading="lazy" />
            <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" loading="lazy" />
          </Link>
        </div>
        <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
          <BlockHead>
            <BlockContent>
              <Skeleton variant="text" width={`50%`} sx={{ fontSize: "1.33rem" }} />
              <Skeleton variant="text" width={`100%`} sx={{ fontSize: "1rem" }} />
            </BlockContent>
          </BlockHead>
          <div className="form-group">
            <div className="form-label-group">
              <Skeleton width={`33%`} variant="text" sx={{ fontSize: "1.1rem" }} />
              <Skeleton width={`33%`} variant="text" sx={{ fontSize: "1.1rem" }} />
            </div>
            <Skeleton variant="rounded" width={`100%`} height={40} />
          </div>
          <div className="form-group">
            <div className="form-label-group">
              <Skeleton width={`33%`} variant="text" sx={{ fontSize: "1.1rem" }} />
              <Skeleton width={`33%`} variant="text" sx={{ fontSize: "1.1rem" }} />
            </div>
            <Skeleton variant="rounded" width={`100%`} height={40} />
          </div>
          <div className="form-group">
            <Skeleton variant="rounded" width={`100%`} height={40} />
          </div>
          <div className="form-note-s2 text-center">
            <Skeleton variant="text" width={`80%`} className=" m-auto" />
          </div>
        </PreviewCard>
      </Block>
    </>
  );
};

export default OTPHomeSkelaton;
