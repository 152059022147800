import { useQuery } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";

//^ ui
import { useTheme } from "@mui/material/styles";
import { Box, Card, FormControl, FormControlLabel, Radio, RadioGroup, Skeleton, Stack } from "@mui/material";
import Title from "../../../../components/title/Title";
import { Block } from "../../../../components/Component";

//^ http request
import { getUserProfileHandler } from "../../../../http/get-api";

//^ redux action
import { twoFactorServicePlanAction } from "../../../../store/slices/twoFactor-user-profile-reducer";

//^ components
import EmailTwoFactorAuth from "./two-factor-options/EmailTwoFactorAuth";
import MobileTwoFactorAuth from "./two-factor-options/MobileTwoFactorAuth";
import BothTwoFactorAuth from "./two-factor-options/BothTwoFactorAuth";
import Head from "../../../../layout/head/Head";
import BackButton from "../../../../components/button/back-button";

export default function TwoFactorSettings() {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const navigate = useNavigate();

  const [twoFactorTarget, setTwoFactorTarget] = useState("email");

  function getRadioDataHandler(event) {
    const data = event.target.value;

    if (data === "email") {
      setTwoFactorTarget(data);
    } else if (data === "mobile") {
      setTwoFactorTarget(data);
    } else if (data === "both") {
      setTwoFactorTarget(data);
    } else {
      setTwoFactorTarget("");
    }
  }

  return (
    <>
      <Title
        title={"Two Factor Authentication Setting"}
        endComponent={<BackButton title={"Back"} variant={"text"} onClick={() => navigate(-1)} />}
      />
      <Head title={"Two Factor Settings"} />

      <Block className={"w-100"}>
        <Box
          sx={{
            display: "none",
            "@media (min-width: 65.5rem)": {
              display: "block",
            },
          }}
        >
          <Card
            variant="outlined"
            elevation={isDark ? 0.6 : 3}
            sx={{
              padding: "0rem",
              "@media (min-width: 65.5rem)": {
                ...theme.palette.cardInner.main,
              },
            }}
          >
            <div className="d-flex flex-column w-100" style={{ gap: "1rem" }}>
              <Stack gap={4}>
                <Stack direction={"row"} gap={3} alignItems={"center"}>
                  <FormControl>
                    <RadioGroup row aria-labelledby="two-fact-option-group" defaultValue="email" name="2fa-group">
                      <FormControlLabel
                        value="email"
                        control={<Radio />}
                        label="Email"
                        onClick={getRadioDataHandler}
                      />
                      <FormControlLabel
                        value="mobile"
                        control={<Radio />}
                        label="Mobile"
                        onClick={getRadioDataHandler}
                      />
                      <FormControlLabel value="both" control={<Radio />} label="Both" onClick={getRadioDataHandler} />
                    </RadioGroup>
                  </FormControl>
                </Stack>
                <Box>
                  {twoFactorTarget === "email" ? (
                    <EmailTwoFactorAuth />
                  ) : twoFactorTarget === "mobile" ? (
                    <MobileTwoFactorAuth />
                  ) : twoFactorTarget === "both" ? (
                    <div>
                      <BothTwoFactorAuth />
                    </div>
                  ) : (
                    ""
                  )}
                </Box>
              </Stack>
            </div>
          </Card>
        </Box>
        <Box
          sx={{
            display: "block",
            "@media (min-width: 65.5rem)": {
              display: "none",
            },
          }}
        >
          <Card
            sx={{
              padding: "0rem",
              backgroundColor: "transparent",
              "@media (min-width: 65.5rem)": {
                borderRadius: "0.625rem",
                ...theme.palette.cardInner.main,
              },
            }}
          >
            <div className="d-flex flex-column w-100" style={{ gap: "1rem" }}>
              <Stack gap={4}>
                <Stack direction={"row"} gap={3} alignItems={"center"}>
                  <FormControl>
                    <RadioGroup row aria-labelledby="two-fact-option-group" defaultValue="email" name="2fa-group">
                      <FormControlLabel
                        value="email"
                        control={<Radio />}
                        label="Email"
                        onClick={getRadioDataHandler}
                      />
                      <FormControlLabel
                        value="mobile"
                        control={<Radio />}
                        label="Mobile"
                        onClick={getRadioDataHandler}
                      />
                      <FormControlLabel value="both" control={<Radio />} label="Both" onClick={getRadioDataHandler} />
                    </RadioGroup>
                  </FormControl>
                </Stack>
                <Box>
                  {twoFactorTarget === "email" ? (
                    <EmailTwoFactorAuth />
                  ) : twoFactorTarget === "mobile" ? (
                    <MobileTwoFactorAuth />
                  ) : twoFactorTarget === "both" ? (
                    <div>
                      <BothTwoFactorAuth />
                    </div>
                  ) : (
                    ""
                  )}
                </Box>
              </Stack>
            </div>
          </Card>
        </Box>
      </Block>
    </>
  );
}
