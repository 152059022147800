import { configureStore } from "@reduxjs/toolkit";

//^ slices
import userProfileSlice from "./slices/user-profile-slice";
import packageSubscriptionSlice from "./slices/package-subscription-slice";
import paymentSlice from "./slices/payment-slice";
import muiReducer from "./slices/mui-slice";
import servicePlanReducer from "./slices/service-slice";
import twoFactorUserProfileReducer from "./slices/twoFactor-user-profile-reducer";
import siteSettingReducer from "./slices/site-setting-slice";
import userNetworkReducer from "./slices/user-networks-slice";

const store = configureStore({
  reducer: {
    userProfile: userProfileSlice,
    packageSubscription: packageSubscriptionSlice,
    payment: paymentSlice,
    mui: muiReducer,
    servicePlan: servicePlanReducer,
    twoFactorUserProfile: twoFactorUserProfileReducer,
    siteSetting: siteSettingReducer,
    userNetwork: userNetworkReducer,
  },
});

export default store;
