import React from "react";
import { useMutation } from "@tanstack/react-query";

//^ http request
import { followUserHandler, requestConnectionHandler } from "../../../../http/post-api";

//^ utils
import { responseErrorHandler, shortenString } from "../../../../utils/Utils";

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Stack,
  Typography,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { AccessTimeRounded, FiberManualRecord, PersonAddRounded } from "@mui/icons-material";

//^ components
import { TooltipComponent } from "../../../Component";
import ImageWithPlaceholder from "../../auth-otp/image/image-with-placeholder";

export default function UserListProfile({
  userProfileData,
  otherUserLists,
  type,
  dispatch,
  hasNextPage,
  fetchNextPage,
  isFetchNextPage,
}) {
  const laptopMatches = useMediaQuery("(min-width: 64rem)");
  const [idx, setIdx] = React.useState(undefined);
  const [otherUserId, setOtherUserId] = React.useState(undefined);
  const [connReq, setConnReq] = React.useState(false);

  const followersData = userProfileData?.user_profile?.connection_summary.find(
    (summary) => summary?.label === "Followers"
  );
  const connectionData = userProfileData?.user_profile?.connection_summary.find(
    (summary) => summary?.label === "Connections"
  );
  const followerCount = followersData?.values?.find((value) => value?.label === "Followers")?.amount;
  const connectionCount = connectionData?.values?.find((value) => value?.label === "Connections")?.amount;

  //^ follow user mutation query
  const {
    isPending: followUserPending,
    isError: followUserIsError,
    error: followUserError,
    mutate: followUserMutate,
    reset: followUserReset,
  } = useMutation({
    mutationKey: ["follow-user-query"],
    mutationFn: followUserHandler,
    onSuccess: (data) => {
      if (data?.toast) {
        if (!data?.status) {
          responseErrorHandler(true, data);
        } else {
          setIdx(undefined);
          if (otherUserLists) {
            if (isFetchNextPage) {
              dispatch({ type, user_id: userProfileData?.user_id });
              if (hasNextPage) {
                fetchNextPage();
              }
            }
          }
        }
      }
      followUserReset();
    },
  });

  //^ connect request mutation query
  const {
    isPending: connReqIsPending,
    isError: connReqIsError,
    error: connReqError,
    mutate: connReqMutate,
    reset: connReqReset,
  } = useMutation({
    mutationKey: ["connect-request"],
    mutationFn: requestConnectionHandler,
    onSuccess: (data) => {
      if (data?.toast) {
        if (data?.status) {
          setIdx(undefined);
          if (isFetchNextPage) {
            if (connReq) {
              dispatch({ type, user_id: otherUserId });
            } else {
              dispatch({ type, user_id: userProfileData?.user_id });
            }
          }
        } else {
          responseErrorHandler(true, data);
        }
      }
      connReqReset();
    },
  });

  React.useEffect(() => {
    if (connReqIsError) {
      responseErrorHandler(true, connReqError);
      connReqReset();
    }
  }, [connReqError, connReqIsError, connReqReset]);

  React.useEffect(() => {
    if (followUserIsError) {
      responseErrorHandler(true, followUserError);
      followUserReset();
    }
  }, [followUserIsError, followUserError, followUserReset]);

  function userClickHandler({ index, userId, connReq = false }) {
    if (typeof index !== "undefined") {
      setIdx(index);
    } else {
      if (idx) {
        setIdx(undefined);
      }
    }
    if (type === "FOLLOW_USER") {
      followUserMutate({ userId: userProfileData?.user_id, status: 1, type: "single" });
    } else if (type === "CONNECT_USER") {
      setOtherUserId(userId);
      setConnReq(connReq);
      connReqMutate({ user_id: userProfileData?.user_id });
    }
  }

  return (
    <Box width={"100%"}>
      <Stack>
        <Box position={"relative"} width={"100%"}>
          <Box width={"inherit"} height={"10rem"} sx={{ background: `${userProfileData?.cover_img}` }} />
          <Stack
            spacing={2}
            position={"absolute"}
            bottom={"-5rem"}
            paddingLeft={laptopMatches ? "5rem" : "1rem"}
            height={"100%"}
          >
            <Box>
              <ImageWithPlaceholder
                showAvatarPlaceholder={true}
                src={userProfileData?.avatar_url}
                alt={userProfileData?.username}
                width={128}
                height={128}
              />
            </Box>
            <Stack width={"100%"} spacing={2}>
              <Stack spacing={1}>
                <Stack>
                  <Typography variant="h5">{`${
                    userProfileData?.user_profile?.member
                      ? `${userProfileData?.username} | ${userProfileData?.user_profile?.member}`
                      : userProfileData?.username
                  }`}</Typography>
                  {userProfileData?.user_profile?.bio && (
                    <Typography variant="body2" fontWeight={400}>
                      {userProfileData?.user_profile?.bio || "N/A"}
                    </Typography>
                  )}
                  {userProfileData?.user_profile?.city &&
                    userProfileData?.user_profile?.state &&
                    userProfileData?.user_profile?.country && (
                      <Typography>
                        {userProfileData?.user_profile?.city &&
                        userProfileData?.user_profile?.state &&
                        userProfileData?.user_profile?.country
                          ? `${userProfileData?.user_profile?.city}, ${userProfileData?.user_profile?.state}, ${userProfileData?.user_profile?.country}`
                          : "N/A"}
                      </Typography>
                    )}
                  <Stack direction={"row"} alignItems={"center"} gap={1}>
                    <Typography variant="body2" fontWeight={400}>
                      {`${followerCount} ${followerCount === 1 ? "Follower" : "Followers"}`}
                    </Typography>
                    <FiberManualRecord sx={{ fontSize: "0.65rem" }} color="disabled" />
                    <Typography variant="body2" fontWeight={400}>
                      {`${connectionCount} ${connectionCount === 1 ? "Connection" : "Connections"}`}
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
              <Box sx={{ width: "100%" }}>
                {type === "FOLLOW_USER" && (
                  <Button
                    size="medium"
                    variant="contained"
                    type="button"
                    width={"16rem"}
                    sx={{ borderRadius: "10rem", minWidth: "7rem" }}
                    endIcon={
                      followUserPending && typeof idx === "undefined" && <CircularProgress size={16} color="inherit" />
                    }
                    onClick={() => {
                      userClickHandler({ index: idx, userId: userProfileData?.user_id, connReq: false });
                    }}
                    disabled={followUserPending && typeof idx === "undefined"}
                  >
                    {followUserPending && typeof idx === "undefined" ? "Following..." : "Follow"}
                  </Button>
                )}

                {type === "CONNECT_USER" && (
                  <Button
                    startIcon={userProfileData?.connection_status?.status === "0" ? <AccessTimeRounded /> : null}
                    variant={userProfileData?.connection_status?.status === "0" ? "outlined" : "contained"}
                    size="medium"
                    type="button"
                    sx={{ minWidth: "7rem", borderRadius: "10rem" }}
                    disabled={userProfileData?.connection_status?.status === "0"}
                    endIcon={connReqIsPending && !idx && <CircularProgress size={16} color="inherit" />}
                    onClick={() => {
                      userClickHandler({ index: idx, userId: userProfileData?.user_id, connReq: true });
                    }}
                  >
                    {userProfileData?.connection_status?.status === "0" ? "Pending" : "Connect"}
                  </Button>
                )}
              </Box>
            </Stack>
          </Stack>
        </Box>
        <Grid
          container
          width={"100%"}
          spacing={2}
          justifyContent={"center"}
          position={"relative"}
          top={"12rem"}
          paddingLeft={laptopMatches ? "5rem" : "1rem"}
          paddingRight={laptopMatches ? "5rem" : "0rem"}
        >
          {otherUserLists?.map((user, index) => {
            return (
              <Grid item xs={12} md={3}>
                <Stack alignItems={"center"} justifyContent={"center"} sx={{ cursor: "pointer" }}>
                  <Card
                    key={index}
                    variant="outlined"
                    elevation={3}
                    sx={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "0.725rem",
                    }}
                  >
                    <Stack height={"100%"} justifyContent={"space-between"} gap={0}>
                      <Stack gap={3}>
                        <Stack position={"relative"} width={"100%"}>
                          <Box width={"100%"} height={"6rem"} sx={{ background: user?.cover_img }} />
                          <Stack
                            direction={"row"}
                            position={"absolute"}
                            zIndex={1}
                            bottom={"-2rem"}
                            width={"100%"}
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Box>
                              {" "}
                              <ImageWithPlaceholder
                                showAvatarPlaceholder={true}
                                src={user?.avatar_url}
                                alt={user?.username}
                                width={88}
                                height={88}
                              />
                            </Box>
                          </Stack>
                        </Stack>
                        <CardContent>
                          <Stack alignItems={"center"} justifyContent={"flex-start"} marginTop={"0.225rem"}>
                            {user?.username?.length > 12 ? (
                              <TooltipComponent
                                type="text"
                                content={
                                  <Typography
                                    textTransform={"capitalize"}
                                    textAlign={"center"}
                                    gutterBottom
                                    variant="h6"
                                    component="div"
                                  >
                                    {shortenString(user?.username, 12)}
                                  </Typography>
                                }
                                direction="top"
                                id={`notification-${index}`}
                                text={user?.username}
                              />
                            ) : (
                              <Typography
                                textTransform={"capitalize"}
                                textAlign={"center"}
                                gutterBottom
                                variant="h6"
                                component="div"
                              >
                                {user?.username}
                              </Typography>
                            )}
                          </Stack>
                        </CardContent>
                      </Stack>
                      <CardActions
                        sx={{
                          padding: "1.25rem",
                          paddingTop: "0rem",
                          width: "100%",
                        }}
                      >
                        <Stack gap={1} alignItems={"center"} width={"100%"}>
                          {user?.mutualConnect > 0 ? (
                            <Typography>{user?.mutual_connection} mutual connection</Typography>
                          ) : (
                            ""
                          )}

                          {type === "FOLLOW_USER" ? (
                            <Button
                              startIcon={<PersonAddRounded />}
                              variant={`contained`}
                              size="small"
                              type="button"
                              sx={{
                                minWidth: "7rem",
                                borderRadius: "10rem",
                              }}
                              onClick={() => {
                                userClickHandler({ index });
                              }}
                              disabled={followUserPending && idx === index}
                              endIcon={
                                followUserPending && idx === index && <CircularProgress size={16} color="inherit" />
                              }
                            >
                              Follow
                            </Button>
                          ) : type === "CONNECT_USER" && user?.connection_status?.status === "0" ? (
                            <Button
                              startIcon={<AccessTimeRounded />}
                              variant={`outlined`}
                              size="small"
                              type="button"
                              sx={{
                                minWidth: "7rem",
                                borderRadius: "10rem",
                              }}
                              disabled={true}
                            >
                              Pending
                            </Button>
                          ) : (
                            <Button
                              startIcon={<PersonAddRounded />}
                              variant={`contained`}
                              size="small"
                              type="button"
                              sx={{
                                minWidth: "7rem",
                                borderRadius: "10rem",
                              }}
                              onClick={() => userClickHandler({ index, userId: user?.user_id, connReq: true })}
                              disabled={connReqIsPending && idx === index}
                              endIcon={
                                connReqIsPending && idx === index && <CircularProgress size={16} color="inherit" />
                              }
                            >
                              Connect
                            </Button>
                          )}
                        </Stack>
                      </CardActions>
                    </Stack>
                  </Card>
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </Box>
  );
}
