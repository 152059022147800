import React from "react";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { InputWrapperSkeleton } from "../../../components/ui/loader/Skeleton/PersonalInfoFormSkeleton";
import InputWrapper from "../../../components/input-wrapper/InputWrapper";
import { LockOutlined } from "@mui/icons-material";
import { Input } from "reactstrap";

const EditProfileDetails = ({ userInfoData, userInfoIsRefetching, userInfoIsLoading }) => {
  const laptopMatches = useMediaQuery("(min-width: 65.5rem)");
  return (
    <>
      <Grid padding={laptopMatches ? "0rem 2.5125rem" : "0rem"} container spacing={3} rowSpacing={1.5}>
        <Grid item xs={12} md={6}>
          {userInfoIsLoading || userInfoIsRefetching ? (
            <InputWrapperSkeleton />
          ) : (
            <InputWrapper
              placeholder={laptopMatches ? undefined : "First Name"}
              label={"First Name"}
              htmlFor={"firstName"}
              value={userInfoData?.data?.profile?.first_name}
              name={"firstName"}
              inputProps={{ disabled: true }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {userInfoIsLoading || userInfoIsRefetching ? (
            <InputWrapperSkeleton />
          ) : (
            <InputWrapper
              placeholder={laptopMatches ? undefined : "Last Name"}
              label={"Last Name"}
              htmlFor={"lastname"}
              value={userInfoData?.data?.profile?.last_name}
              name={"lastName"}
              inputProps={{ disabled: true }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {userInfoIsLoading || userInfoIsRefetching ? (
            <InputWrapperSkeleton />
          ) : (
            <InputWrapper
              placeholder={laptopMatches ? undefined : "Username"}
              label={
                <Stack direction={"row"} alignItems={"center"} spacing={0.5} justifyContent={"center"}>
                  <Typography>{"Username"}</Typography>
                  <LockOutlined sx={{ fontSize: "0.8rem" }} />
                </Stack>
              }
              htmlFor={"username"}
              value={userInfoData?.data?.profile?.username}
              name={"username"}
              inputProps={{ disabled: true }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {userInfoIsLoading || userInfoIsRefetching ? (
            <InputWrapperSkeleton />
          ) : (
            <InputWrapper
              placeholder={laptopMatches ? undefined : "DOB"}
              label={"DOB"}
              htmlFor={"DOB"}
              value={
                userInfoData?.data?.profile?.dob
                  ? (([year, month, day]) => `${day}/${month}/${year}`)(userInfoData?.data?.profile?.dob?.split("-"))
                  : ""
              }
              name={"DOB"}
              inputProps={{ disabled: true }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {userInfoIsLoading || userInfoIsRefetching ? (
            <InputWrapperSkeleton />
          ) : (
            <InputWrapper label={"Phone Number"} showInput={false}>
              <Input
                id="phoneNumber"
                name="phone_number"
                placeholder={!laptopMatches ? "Phone Number" : undefined}
                value={
                  userInfoData?.data?.profile?.phone_number ? String(userInfoData?.data?.profile?.phone_number) : ""
                }
                size="small"
                style={{ height: "2.5rem", letterSpacing: "0px", boxShadow: "none", fontSize: "1rem" }}
                className={`form-control bg-transparent`}
                inputProps={{ disabled: true }}
              />
            </InputWrapper>
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {userInfoIsLoading || userInfoIsRefetching ? (
            <InputWrapperSkeleton />
          ) : (
            <InputWrapper
              placeholder={laptopMatches ? undefined : "Email ID"}
              label={
                <Stack direction={"row"} alignItems={"center"} spacing={0.5} justifyContent={"center"}>
                  <Typography>{"Email Address"}</Typography>
                  <LockOutlined sx={{ fontSize: "0.8rem" }} />
                </Stack>
              }
              htmlFor={"emailId"}
              value={userInfoData?.data?.profile?.email}
              name={"email"}
              inputProps={{ disabled: true }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {userInfoIsLoading || userInfoIsRefetching ? (
            <InputWrapperSkeleton />
          ) : (
            <InputWrapper
              placeholder={laptopMatches ? undefined : "Country"}
              label={"Country"}
              htmlFor={"Country"}
              value={userInfoData?.data?.profile?.country}
              name={"Country"}
              inputProps={{ disabled: true }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {userInfoIsLoading ? (
            <InputWrapperSkeleton />
          ) : userInfoIsLoading || userInfoIsRefetching ? (
            <InputWrapperSkeleton />
          ) : (
            <InputWrapper
              placeholder={laptopMatches ? undefined : "State"}
              label={"State"}
              htmlFor={"State"}
              value={userInfoData?.data?.profile?.state}
              name={"State"}
              inputProps={{ disabled: true }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {userInfoIsLoading || userInfoIsRefetching ? (
            <InputWrapperSkeleton />
          ) : (
            <InputWrapper
              placeholder={laptopMatches ? undefined : "City"}
              label={"City"}
              htmlFor={"City"}
              value={userInfoData?.data?.profile?.city}
              name={"City"}
              inputProps={{ disabled: true }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {userInfoIsLoading || userInfoIsRefetching ? (
            <InputWrapperSkeleton />
          ) : (
            <InputWrapper
              placeholder={laptopMatches ? undefined : "ZipCode"}
              label={"ZipCode"}
              htmlFor={"ZipCode"}
              value={userInfoData?.data?.profile?.pin_code}
              name={"ZipCode"}
              inputProps={{ disabled: true }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {userInfoIsLoading || userInfoIsRefetching ? (
            <InputWrapperSkeleton height={"4.5rem"} />
          ) : (
            <InputWrapper
              type="textarea"
              placeholder={laptopMatches ? undefined : "Address"}
              label={"Address"}
              htmlFor={"address"}
              value={userInfoData?.data?.profile?.address_1}
              name={"address_1"}
              textareaProps={{ disabled: true }}
            />
          )}
        </Grid>
        <Grid item xs={12} md={6}>
          {userInfoIsLoading || userInfoIsRefetching ? (
            <InputWrapperSkeleton height={"4.5rem"} />
          ) : (
            <InputWrapper
              type="textarea"
              placeholder={laptopMatches ? undefined : "About me"}
              label={"About me"}
              htmlFor={"aboutMe"}
              value={userInfoData?.data?.profile?.about_me}
              name={"about_me"}
              textareaProps={{ disabled: true }}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default EditProfileDetails;
