export default function UploadFileSvg() {
  return (
    <svg width="649" height="360" viewBox="0 0 649 360" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Group">
        <g id="Group_2">
          <g id="Floor">
            <path
              id="Vector"
              d="M0 359.5L81.1 359.3L162.2 359.2L324.3 359L486.4 359.2L567.5 359.3L648.6 359.5L567.5 359.7L486.4 359.8L324.3 360L162.2 359.8L81.1 359.7L0 359.5Z"
              fill="#263238"
            />
          </g>
          <g id="Plant">
            <g id="Group_3">
              <g id="Group_4">
                <g id="Group_5">
                  <g id="Group_6">
                    <path
                      id="Vector_2"
                      d="M607.9 274.1C606.9 278.3 605.9 282.3 605.7 286.6C605.8 286.8 605.8 287.6 605.7 287.4C605.7 288.1 604.6 288 604.6 287.3C604.6 286.9 604.6 286.4 604.6 286C602.2 283 598.6 281 595.7 278.4C593.4 276.3 591.7 273.7 590.8 270.7C588.4 262.5 590.7 253.8 587.8 245.6C585.3 238.5 580.9 232.4 577.2 226C573.2 219.2 570.3 212.1 570 204.2C569.8 198.1 570.5 190.1 575.1 185.5C580 180.7 586.1 184.3 589.8 188.6C600.7 201.1 605.7 216.8 607.7 232.9C608.8 241.8 610 250.8 609.7 259.8C609.7 264.6 609 269.4 607.9 274.1Z"
                      fill="#E14954"
                    />
                    <path
                      id="Vector_3"
                      d="M605.7 289.9C606.3 281.5 606.2 273 605.4 264.6V264.5C605.5 257.7 606.1 250.9 605.8 244C605.5 236.6 604.4 229.3 602.8 222C602.8 221.9 602.7 221.9 602.7 222C604 229.1 604.8 236.3 605.1 243.5C605.2 247.4 605.2 251.2 605 255.1C604.9 256.4 604.8 257.6 604.7 258.9C601.9 239.7 594.9 221.4 584.4 205.1C584.3 204.9 583.9 205.1 584 205.3C591.5 217.6 597.1 231.1 600.5 245.2C595.3 235.5 587.4 227.6 581.5 218.3C581.5 218.2 581.4 218.3 581.4 218.4C586.7 228.8 595.4 236.9 600.9 247.1C601.9 251.3 602.6 255.5 603.2 259.7C604 265.5 604.4 271.3 604.5 277.2C601.3 271.3 598 265.6 595.4 259.3C595.3 259.1 595.1 259.2 595.1 259.4C597.3 265.9 600.3 273 604.5 278.6C604.5 282.7 604.4 286.9 604.2 291C603.5 302 601.7 312.9 600.8 323.9C600.8 324.2 601.2 324.3 601.3 324C603.7 312.8 605 301.2 605.7 289.9Z"
                      fill="#263238"
                    />
                    <path
                      id="Vector_4"
                      d="M594 204.8C594 204.7 593.8 204.8 593.9 204.9C595.9 210.5 597.1 216.1 598.5 221.8C598.6 222.1 598.9 222 598.9 221.7C598.3 215.9 596.3 210.1 594 204.8Z"
                      fill="#263238"
                    />
                  </g>
                  <g id="Group_7">
                    <path
                      id="Vector_5"
                      d="M641 229.5C639.8 232.7 638.3 235.9 636.8 238.9C633.5 245.4 630.1 251.4 629.4 258.9C629.1 262.3 628.9 265.6 627.9 268.9C627.1 271.7 625.7 274.3 624.2 276.8C620.7 282.5 616.3 287.4 613 293.3C609.6 299.4 607 306.1 605.4 313C605.3 313.4 604.8 313.3 604.9 312.9V312.8C604.9 312.8 604.9 312.8 604.9 312.7C604.7 296.6 606.2 280.4 610.8 264.9C612.8 258.1 615.3 251.4 618.1 244.9C620.5 239.3 623.6 233.8 627.7 229.5C630.4 226.7 636.3 221.5 640 224.1C642 225.4 641.8 227.5 641 229.5Z"
                      fill="#E14954"
                    />
                    <path
                      id="Vector_6"
                      d="M626 266.3C623.5 271.1 620.5 275.5 617.4 279.9C616 282 614.5 284 613.1 286.1C612.4 287.1 611.7 288.1 611.1 289.1C611.5 287.4 611.9 285.7 612.3 284C614.3 275.8 616.2 267.6 619.1 259.7C621.9 252 625.6 244.7 629.4 237.6C629.5 237.4 629.2 237.3 629.1 237.5C626 243.2 622.8 248.8 620.1 254.8C618.6 258.1 617.4 261.5 616.2 265C617.6 257.6 619.2 250.4 621.5 243.2C621.5 243.1 621.4 243.1 621.4 243.2C618.6 250.3 616.6 258.2 615.9 265.8C615.2 267.9 614.6 270 614.1 272.2C611.7 281.3 609.5 290.4 607.5 299.6C607.7 293.6 608.1 287.6 609.2 281.7C609.2 281.6 609 281.5 609 281.7C607.6 287.9 606.8 294.3 607.2 300.6C606.4 304.1 605.7 307.6 605 311.1C603.4 318.6 601.6 326.1 600.6 333.7C600.6 333.9 600.8 333.9 600.9 333.7C602 329.6 602.6 325.4 603.5 321.3C604.4 317.2 605.3 313 606.2 308.9C607.6 302.9 609 296.9 610.4 290.9C611.3 288.7 613.1 286.7 614.4 284.8C615.8 282.8 617.3 280.7 618.7 278.6C621.3 274.7 623.7 270.7 625.8 266.5C626.1 266.3 626 266.2 626 266.3Z"
                      fill="#263238"
                    />
                    <path
                      id="Vector_7"
                      d="M626.7 259.4C623.5 263.9 620.6 269 618.3 274.1C618.3 274.2 618.4 274.2 618.4 274.2C621.2 269.3 624 264.4 626.8 259.5C626.9 259.4 626.8 259.3 626.7 259.4Z"
                      fill="#263238"
                    />
                  </g>
                  <g id="Group_8">
                    <path
                      id="Vector_8"
                      d="M559.3 243.7C561.4 247.4 563.9 251.8 562.9 256.2C562.3 259 560.3 261.3 559.9 264.1C559.1 270.4 565.3 274.5 569.9 277.5C581.2 284.9 596.7 291.5 598.7 306.7C598.8 307.2 599.7 307.1 599.6 306.6V306.5C599.6 306.5 599.7 306.5 599.7 306.4C601.2 298.7 601.1 290.9 599.5 283.3C597.8 275.5 594.4 268.5 590.4 261.6C583.6 250 576.4 238.1 566.1 229.2C563.3 226.7 557.4 222.3 555.2 227.9C553 233.3 556.7 239.3 559.3 243.7Z"
                      fill="#E14954"
                    />
                    <path
                      id="Vector_9"
                      d="M569.7 268.3C577.2 276.4 589.1 281 595.5 290C591.7 276.1 585.4 263.6 575.6 252.7C575.4 252.4 575.7 252 576 252.3C583.1 259.1 588.4 267.1 592.2 275.8C590.5 269.6 588.1 263.8 585.2 257.9C585.1 257.7 585.4 257.5 585.5 257.7C589.1 263.9 591.5 270.8 593 277.8C601.5 298.4 602.3 322.5 600.5 344.1C600.5 344.4 600 344.4 600 344.1C600.5 327 600.2 309.6 596.1 293C596 292.7 595.9 292.3 595.8 292C588.8 282.2 576.9 277.7 569.3 268.4C569.5 268.4 569.6 268.2 569.7 268.3Z"
                      fill="#263238"
                    />
                    <path
                      id="Vector_10"
                      d="M567.9 261.4C571.8 265 575.7 268.7 579.6 272.3C579.6 272.3 579.6 272.4 579.5 272.4C575.2 269.5 570.9 265.7 567.6 261.7C567.6 261.4 567.8 261.2 567.9 261.4Z"
                      fill="#263238"
                    />
                    <path
                      id="Vector_11"
                      d="M567.3 245.7C570.9 251.4 574.6 256.8 579.1 261.9C579.2 262 579.1 262.1 579 262C574.2 257.4 570 251.8 567.1 245.8C567 245.7 567.2 245.6 567.3 245.7Z"
                      fill="#263238"
                    />
                  </g>
                </g>
                <g id="Group_9">
                  <g id="Group_10">
                    <path id="Vector_12" d="M585 359.5H619.4L614.8 313.2H590.3L585 359.5Z" fill="#455A64" />
                    <path id="Vector_13" d="M589.5 320.7H615.6L614.8 313.2H590.3L589.5 320.7Z" fill="#263238" />
                  </g>
                  <path id="Vector_14" d="M586.066 315.484H618.366V308.085H586.066V315.484Z" fill="#455A64" />
                </g>
              </g>
            </g>
          </g>
          <g id="Device">
            <g id="Group_11">
              <path id="Vector_15" d="M630.6 337.2H17.8999V359.5H630.6V337.2Z" fill="#C7C7C7" />
              <path id="Vector_16" d="M576.3 0H72.2V337.2H576.3V0Z" fill="#DBDBDB" />
              <path id="Vector_17" d="M572.7 4.09998H75.8999V333.1H572.7V4.09998Z" fill="#263238" />
              <g id="Group_12">
                <path id="Vector_18" d="M550.7 25.2H97.8999V311.9H550.7V25.2Z" fill="white" />
                <g id="Group_13">
                  <path id="Vector_19" d="M550.7 25.2H97.8999V36.2H550.7V25.2Z" fill="#455A64" />
                  <g id="Group_14">
                    <path
                      id="Vector_20"
                      d="M108.3 30.7C108.3 31.9 107.4 32.8 106.2 32.8C105 32.8 104.1 31.9 104.1 30.7C104.1 29.5 105 28.6 106.2 28.6C107.4 28.7 108.3 29.6 108.3 30.7Z"
                      fill="#C7C7C7"
                    />
                    <path
                      id="Vector_21"
                      d="M116.8 30.7C116.8 31.9 115.9 32.8 114.7 32.8C113.5 32.8 112.6 31.9 112.6 30.7C112.6 29.5 113.5 28.6 114.7 28.6C115.9 28.7 116.8 29.6 116.8 30.7Z"
                      fill="white"
                    />
                    <path
                      id="Vector_22"
                      d="M125.3 30.7C125.3 31.9 124.4 32.8 123.2 32.8C122 32.8 121.1 31.9 121.1 30.7C121.1 29.5 122 28.6 123.2 28.6C124.4 28.7 125.3 29.6 125.3 30.7Z"
                      fill="#FFC727"
                    />
                  </g>
                </g>
                <g id="Group_15">
                  <g id="Group_16">
                    <g id="Group_17">
                      <g id="Group_18">
                        <path
                          id="Vector_23"
                          d="M240.6 240.7V242.7H242.6"
                          stroke="#C7C7C7"
                          strokeWidth="0.75"
                          strokeMiterlimit="10"
                        />
                        <path
                          id="Vector_24"
                          d="M246.6 242.7H404"
                          stroke="#C7C7C7"
                          strokeWidth="0.75"
                          strokeMiterlimit="10"
                          strokeDasharray="3.99 3.99"
                        />
                        <path
                          id="Vector_25"
                          d="M406 242.7H408V240.7"
                          stroke="#C7C7C7"
                          strokeWidth="0.75"
                          strokeMiterlimit="10"
                        />
                        <path
                          id="Vector_26"
                          d="M408 236.7V79.2999"
                          stroke="#C7C7C7"
                          strokeWidth="0.75"
                          strokeMiterlimit="10"
                          strokeDasharray="3.99 3.99"
                        />
                        <path
                          id="Vector_27"
                          d="M408 77.2999V75.2999H406"
                          stroke="#C7C7C7"
                          strokeWidth="0.75"
                          strokeMiterlimit="10"
                        />
                        <path
                          id="Vector_28"
                          d="M402 75.2999H244.6"
                          stroke="#C7C7C7"
                          strokeWidth="0.75"
                          strokeMiterlimit="10"
                          strokeDasharray="3.99 3.99"
                        />
                        <path
                          id="Vector_29"
                          d="M242.6 75.2999H240.6V77.2999"
                          stroke="#C7C7C7"
                          strokeWidth="0.75"
                          strokeMiterlimit="10"
                        />
                        <path
                          id="Vector_30"
                          d="M240.6 81.2V238.7"
                          stroke="#C7C7C7"
                          strokeWidth="0.75"
                          strokeMiterlimit="10"
                          strokeDasharray="3.99 3.99"
                        />
                      </g>
                    </g>
                  </g>
                  <g id="Group_19">
                    <path id="Vector_31" d="M361 200.1H287.6V177.5H292.3V195.4H356.3V178.7H361V200.1Z" fill="#E14954" />
                    <g id="Group_20">
                      <g id="Group_21">
                        <path
                          id="Vector_32"
                          d="M264.7 151.8C264.7 159.9 271 166.5 278.9 167.1H365.8C375.8 167.1 383.8 159 383.8 149.1C383.8 139.7 376.6 132 367.4 131.1C366.9 114.8 353.5 101.7 337 101.7C323.9 101.7 312.7 110 308.5 121.6C306.1 120.4 303.3 119.7 300.4 119.7C290.8 119.7 283 127.2 282.4 136.6C281.6 136.5 280.8 136.4 279.9 136.4C271.6 136.4 264.7 143.3 264.7 151.8Z"
                          stroke="#E14954"
                          strokeWidth="2"
                          strokeMiterlimit="10"
                        />
                      </g>
                    </g>
                    <g id="Group_22">
                      <path id="Vector_33" d="M335.599 157H313V186H335.599V157Z" fill="#E14954" />
                      <path id="Vector_34" d="M301.5 157H347.099L324.299 133.5L301.5 157Z" fill="#E14954" />
                    </g>
                  </g>
                  <g id="Group_23">
                    <path id="Vector_35" d="M433 258.6H215.6V276.3H433V258.6Z" fill="#EBEBEB" />
                    <g id="Group_24">
                      <path id="Vector_36" d="M347.2 262.7H220.7V272.2H347.2V262.7Z" fill="#E14954" />
                      <path
                        id="Vector_37"
                        opacity="0.2"
                        d="M234.4 272.2H227.9L220.7 262.7H227.2L234.4 272.2Z"
                        fill="white"
                      />
                      <path
                        id="Vector_38"
                        opacity="0.2"
                        d="M248 272.2H241.6L234.4 262.7H240.9L248 272.2Z"
                        fill="white"
                      />
                      <path
                        id="Vector_39"
                        opacity="0.2"
                        d="M261.7 272.2H255.2L248 262.7H254.5L261.7 272.2Z"
                        fill="white"
                      />
                      <path
                        id="Vector_40"
                        opacity="0.2"
                        d="M275.3 272.2H268.9L261.7 262.7H268.2L275.3 272.2Z"
                        fill="white"
                      />
                      <path
                        id="Vector_41"
                        opacity="0.2"
                        d="M289 272.2H282.5L275.3 262.7H281.8L289 272.2Z"
                        fill="white"
                      />
                      <path
                        id="Vector_42"
                        opacity="0.2"
                        d="M302.7 272.2H296.2L289 262.7H295.5L302.7 272.2Z"
                        fill="white"
                      />
                      <path
                        id="Vector_43"
                        opacity="0.2"
                        d="M316.3 272.2H309.8L302.7 262.7H309.1L316.3 272.2Z"
                        fill="white"
                      />
                      <path
                        id="Vector_44"
                        opacity="0.2"
                        d="M330 272.2H323.5L316.3 262.7H322.8L330 272.2Z"
                        fill="white"
                      />
                      <path
                        id="Vector_45"
                        opacity="0.2"
                        d="M343.6 272.2H337.2L330 262.7H336.5L343.6 272.2Z"
                        fill="white"
                      />
                      <path id="Vector_46" opacity="0.2" d="M347.2 262.7V267.4L343.6 262.7H347.2Z" fill="white" />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
          <g id="Character">
            <g id="Group_25">
              <g id="Group_26">
                <path
                  id="Vector_47"
                  d="M174.3 52.3C181.8 59.1 179.4 68.7 182.5 74C184.4 77.2 190.7 80.8 191.9 84.5C193.8 90.6 188.7 92.6 189.7 96C191.2 100.7 197.5 100.5 198.4 108.2C199.3 115.9 190.7 123.7 170.8 125.4C150.8 127.1 140.1 119.5 139.5 113C139 106.5 145.6 104.5 146.2 99.7C146.6 96.6 142.5 94.4 142.5 88.7C142.4 83 148 81.3 148.9 76.5C149.8 71.7 147.6 59.9 154.8 54.1C162.1 48.3 170.6 48.9 174.3 52.3Z"
                  fill="#263238"
                />
                <path
                  id="Vector_48"
                  d="M173.4 52.4C175.6 54 177.2 56 178.4 58.4C179.6 60.9 180.4 63.6 181 66.3C181.4 67.9 181.7 69.5 182.4 71C183 72.3 184 73.4 185 74.4C187.1 76.4 189.6 78 191.5 80.2C193.3 82.3 194.6 85.1 193.8 87.9C193.4 89.4 192.3 90.7 191.7 92.1C191.1 93.4 190.8 95.1 191.9 96.3C192.9 97.3 194.3 97.8 195.4 98.6C196.5 99.4 197.5 100.4 198.2 101.5C199.8 103.8 200.4 106.5 199.8 109.2C198.5 115.4 192.6 118.7 187.1 120.8C186.3 121.1 185.6 121.4 184.8 121.7C184.7 121.7 184.6 121.6 184.7 121.5C190.1 119.2 196.5 116.6 198.7 110.6C199.7 107.9 199.5 105 198 102.6C197.3 101.4 196.3 100.4 195.2 99.5C194.1 98.7 192.9 98.1 191.8 97.4C190.7 96.6 190.1 95.5 190.2 94.1C190.3 92.6 191 91.4 191.8 90.2C192.6 88.9 193.2 87.6 193.2 86.1C193.1 84.7 192.5 83.3 191.7 82.1C190.1 79.7 187.6 78.1 185.5 76.2C183.3 74.3 181.6 72.2 180.8 69.4C180.4 68 180.2 66.5 179.9 65.1C179.6 63.8 179.3 62.4 178.8 61.1C177.9 58.5 176.7 56 174.8 54.1C174.3 53.6 173.8 53.1 173.2 52.7C173.3 52.5 173.3 52.4 173.4 52.4Z"
                  fill="#263238"
                />
                <path
                  id="Vector_49"
                  d="M138.7 108.2C139 107.2 139.5 106.2 140.2 105.3C140.9 104.4 141.8 103.7 142.6 102.9C143.3 102.2 144.1 101.3 144.3 100.3C144.6 99 144.1 97.8 143.5 96.7C142.9 95.5 142.2 94.4 141.8 93.2C141.4 92 141.2 90.7 141.1 89.5C141 86.8 141.9 84.4 143.3 82.1C144 81 144.8 80 145.5 78.9C146.3 77.7 146.9 76.4 147.4 75.1C148.4 72.1 148.6 69 149.5 66C150.3 62.9 151.5 59.9 153 57.1C153 57 153.1 57.1 153.1 57.2C151.8 59.8 150.8 62.5 150.1 65.3C149.4 67.9 149.1 70.7 148.5 73.3C148.2 74.6 147.9 75.9 147.4 77.2C146.9 78.4 146.3 79.4 145.6 80.5C144.2 82.7 142.8 84.8 142.3 87.4C141.9 90 142.2 92.6 143.4 94.9C144.4 96.9 145.8 99.1 144.8 101.4C144 103.3 142.1 104.5 140.8 106.1C139.4 107.9 138.9 110.2 139.3 112.4C140.2 118 145.7 120.9 151 121.3C151.2 121.3 151.2 121.6 151 121.6C146.5 121.4 141.6 119.3 139.5 115C138.5 112.7 138.2 110.3 138.7 108.2Z"
                  fill="#263238"
                />
              </g>
              <g id="Group_27">
                <path
                  id="Vector_50"
                  d="M168.2 97.7001C176.7 102.2 206.3 114.9 214.8 110.3C220.4 107.3 246.7 84.6001 247.6 80.0001C248.2 76.9001 230.1 61.8001 228.1 64.5001C226 67.2001 206 88.7001 203.7 89.7001C201.4 90.7001 176.3 86.2001 167.7 86.7001C152.7 87.5001 163.6 95.3001 168.2 97.7001Z"
                  fill="#F7A9A0"
                />
                <g id="Group_28">
                  <path
                    id="Vector_51"
                    d="M221.6 69.4L241.4 89.7C241.4 89.7 218.8 112.1 212.3 112.4C205.8 112.7 181.2 105.6 167.2 97.7C153.3 89.7 159.1 84.2 171 85.7C182.9 87.3 201.6 89.6 202.9 88.7C204.3 87.9 221.6 69.4 221.6 69.4Z"
                    fill="#E14954"
                  />
                  <g id="Group_29">
                    <path
                      id="Vector_52"
                      d="M234.4 92.4C231.6 89.7 229 87 226.4 84.2C225.1 82.8 223.8 81.5 222.5 80.1C221.2 78.7 220 77.1 218.7 75.7C218.6 75.6 218.8 75.4 218.9 75.5C220.2 76.8 221.6 78.1 222.9 79.4C224.3 80.8 225.6 82.2 226.9 83.6C229.5 86.4 232.1 89.2 234.6 92.2C234.6 92.3 234.5 92.4 234.4 92.4Z"
                      fill="#263238"
                    />
                  </g>
                  <path
                    id="Vector_53"
                    opacity="0.1"
                    d="M196.5 109.4C186.9 106.6 175.3 102.3 167.2 97.7C156.1 91.4 157.5 86.6 164.6 85.6L196.5 109.4Z"
                    fill="black"
                  />
                </g>
              </g>
              <path
                id="Vector_54"
                d="M167.2 85.8C169.6 89.2 178.4 103 181.4 110.7C184.4 118.5 201.4 206.4 197.8 210.5C194.2 214.6 162.2 213.7 157.4 207.3C148.4 195.1 155.7 139.2 155.7 139.2C155.7 139.2 154.3 108.9 160.6 93.3C163.5 86.7 167.2 85.8 167.2 85.8Z"
                fill="#E14954"
              />
              <g id="Group_30">
                <g id="Group_31">
                  <path
                    id="Vector_55"
                    d="M247.9 99.1001C247.6 97.2001 247.6 95.3001 247.7 93.4001C247.8 91.5001 248 89.6 248.5 87.8C248.8 89.7 248.8 91.6001 248.7 93.5001C248.6 95.3001 248.4 97.2001 247.9 99.1001Z"
                    fill="#263238"
                  />
                </g>
                <g id="Group_32">
                  <path
                    id="Vector_56"
                    d="M239.3 100.7C238.1 99.2001 237.2 97.6001 236.3 95.9001C235.5 94.2001 234.7 92.5001 234.2 90.6001C235.4 92.1001 236.4 93.7001 237.2 95.4001C238 97.2001 238.8 98.9001 239.3 100.7Z"
                    fill="#263238"
                  />
                </g>
                <g id="Group_33">
                  <path
                    id="Vector_57"
                    d="M232.8 106.5C231 105.8 229.4 104.9 227.8 103.8C226.2 102.8 224.7 101.7 223.3 100.3C225.1 101 226.7 101.9 228.3 103C229.9 104 231.4 105.1 232.8 106.5Z"
                    fill="#263238"
                  />
                </g>
                <g id="Group_34">
                  <path
                    id="Vector_58"
                    d="M230 114.7C228.1 115 226.2 115 224.3 114.9C222.4 114.8 220.5 114.6 218.7 114.1C220.6 113.8 222.5 113.8 224.4 113.9C226.2 114 228.1 114.2 230 114.7Z"
                    fill="#263238"
                  />
                </g>
                <g id="Group_35">
                  <path
                    id="Vector_59"
                    d="M231.6 123.2C230.1 124.4 228.5 125.3 226.8 126.2C225.1 127 223.4 127.8 221.5 128.3C223 127.1 224.6 126.1 226.3 125.3C228.1 124.5 229.8 123.8 231.6 123.2Z"
                    fill="#263238"
                  />
                </g>
                <g id="Group_36">
                  <path
                    id="Vector_60"
                    d="M237.4 129.8C236.7 131.6 235.8 133.2 234.7 134.8C233.7 136.4 232.6 137.9 231.2 139.3C231.9 137.5 232.8 135.9 233.9 134.3C234.9 132.7 236 131.2 237.4 129.8Z"
                    fill="#263238"
                  />
                </g>
                <g id="Group_37">
                  <path
                    id="Vector_61"
                    d="M245.6 132.6C245.9 134.5 245.9 136.4 245.8 138.3C245.7 140.2 245.5 142.1 245 143.9C244.7 142 244.7 140.1 244.8 138.2C244.9 136.4 245.1 134.5 245.6 132.6Z"
                    fill="#263238"
                  />
                </g>
                <g id="Group_38">
                  <path
                    id="Vector_62"
                    d="M254.1 131C255.3 132.5 256.2 134.1 257.1 135.8C257.9 137.5 258.7 139.2 259.2 141.1C258 139.6 257 138 256.2 136.3C255.4 134.5 254.6 132.8 254.1 131Z"
                    fill="#263238"
                  />
                </g>
                <g id="Group_39">
                  <path
                    id="Vector_63"
                    d="M260.7 125.2C262.5 125.9 264.1 126.8 265.7 127.9C267.3 128.9 268.8 130 270.2 131.4C268.4 130.7 266.8 129.8 265.2 128.7C263.6 127.7 262.1 126.6 260.7 125.2Z"
                    fill="#263238"
                  />
                </g>
                <g id="Group_40">
                  <path
                    id="Vector_64"
                    d="M263.5 117C265.4 116.7 267.3 116.7 269.2 116.8C271.1 116.9 273 117.1 274.8 117.6C272.9 117.9 271 117.9 269.1 117.8C267.3 117.7 265.4 117.5 263.5 117Z"
                    fill="#263238"
                  />
                </g>
                <g id="Group_41">
                  <path
                    id="Vector_65"
                    d="M261.8 108.4C263.3 107.2 264.9 106.3 266.6 105.4C268.3 104.6 270 103.8 271.9 103.3C270.4 104.5 268.8 105.5 267.1 106.3C265.4 107.2 263.7 107.9 261.8 108.4Z"
                    fill="#263238"
                  />
                </g>
                <g id="Group_42">
                  <path
                    id="Vector_66"
                    d="M256.1 101.9C256.8 100.1 257.7 98.5 258.8 96.9C259.8 95.3 260.9 93.8 262.3 92.4C261.6 94.2 260.7 95.8 259.6 97.4C258.6 99 257.5 100.5 256.1 101.9Z"
                    fill="#263238"
                  />
                </g>
              </g>
              <g id="Group_43">
                <g id="Group_44">
                  <g id="Group_45">
                    <path
                      id="Vector_67"
                      d="M213.7 359.1C211.8 359.7 158 359 155.9 357.7C155.1 357.2 154.8 349.4 154.6 339.9C154.6 337.8 154.5 335.7 154.5 333.6C154.4 319.6 154.6 304.8 154.6 304.8L189.6 305L189.5 334L189.3 340.3C189.3 340.3 209.8 349.8 211.9 351.5C214 353 215.6 358.5 213.7 359.1Z"
                      fill="#F7A9A0"
                    />
                    <path
                      id="Vector_68"
                      d="M213.7 359.1C211.8 359.7 158 359 155.9 357.7C155 357.1 154.6 345.6 154.5 333.5L189.5 333.9L189.3 340.2C189.3 340.2 209.8 349.7 211.9 351.4C214 353 215.6 358.5 213.7 359.1Z"
                      fill="#EBEBEB"
                    />
                    <g id="Group_46">
                      <path
                        id="Vector_69"
                        d="M213.7 359.1C211.8 359.7 158 359 155.9 357.7C155.1 357.2 154.8 349.4 154.6 339.9L189.3 340.3C189.3 340.3 209.8 349.8 211.9 351.5C214 353 215.6 358.5 213.7 359.1Z"
                        fill="#E14954"
                      />
                      <g id="Group_47">
                        <g id="Group_48">
                          <path
                            id="Vector_70"
                            d="M213 356.9C204 356.6 167.5 356 158.6 356.4C158.5 356.4 158.5 356.5 158.6 356.5C167.5 357 204 357.3 213 357.1C213.2 357 213.2 356.9 213 356.9Z"
                            fill="#263238"
                          />
                          <path
                            id="Vector_71"
                            d="M208.5 349.4C206.4 349.3 204.3 350.3 203.1 351.5C202 352.7 201 354.4 201 356.3C201 356.4 201.1 356.4 201.1 356.3C201.7 352.8 204.9 349.7 208.5 349.5C208.6 349.5 208.6 349.4 208.5 349.4Z"
                            fill="#263238"
                          />
                          <path
                            id="Vector_72"
                            d="M192.9 341.1C189.3 341 185.1 342.2 182.8 345.1C182.7 345.2 182.9 345.3 183 345.3C186.3 343.7 189.4 342.4 193 341.5C193.2 341.5 193.1 341.1 192.9 341.1Z"
                            fill="#263238"
                          />
                          <path
                            id="Vector_73"
                            d="M195.6 342.2C192 342.1 187.8 343.3 185.5 346.2C185.4 346.3 185.6 346.4 185.7 346.4C189 344.8 192.1 343.5 195.7 342.6C195.9 342.6 195.9 342.2 195.6 342.2Z"
                            fill="#263238"
                          />
                          <path
                            id="Vector_74"
                            d="M198.4 343.3C194.8 343.2 190.6 344.4 188.3 347.3C188.2 347.4 188.4 347.5 188.5 347.5C191.8 345.9 194.9 344.6 198.5 343.7C198.7 343.7 198.7 343.3 198.4 343.3Z"
                            fill="#263238"
                          />
                          <path
                            id="Vector_75"
                            d="M201.2 344.5C197.6 344.4 193.4 345.6 191.1 348.5C191 348.6 191.2 348.7 191.3 348.7C194.6 347.1 197.7 345.8 201.3 344.9C201.5 344.8 201.4 344.5 201.2 344.5Z"
                            fill="#263238"
                          />
                          <path
                            id="Vector_76"
                            d="M161 347.8C156.9 347.8 156.9 354.1 160.9 354.1C165 354.2 165 347.9 161 347.8Z"
                            fill="white"
                          />
                        </g>
                        <path
                          id="Vector_77"
                          d="M196.9 331.5C195.1 330 192.8 331.4 191.6 333C189.7 335.5 188.4 339.1 188.2 342.2C188.2 342.3 188.3 342.3 188.3 342.3C188.4 342.6 188.7 342.8 188.9 342.6C191.5 340.9 194.5 339.3 196.5 336.8C197.7 335.3 198.6 333 196.9 331.5ZM192.4 339.1C191.1 340 189.9 340.8 188.6 341.7C189.3 339.9 189.9 338.1 190.7 336.4C191.1 335.6 191.5 334.7 192.1 334C192.9 332.8 196.5 329.8 196.8 333.8C196.9 336 193.9 338.1 192.4 339.1Z"
                          fill="#263238"
                        />
                        <path
                          id="Vector_78"
                          d="M179.2 343.4C182.3 343.9 185.6 343.1 188.7 342.6C189 342.6 189.1 342.2 189 341.9C189 341.9 189.1 341.8 189 341.7C186.7 339.5 183.4 337.8 180.3 337.1C178.4 336.7 175.7 337.2 175.4 339.5C175.1 341.9 177.4 343.1 179.2 343.4ZM177.1 341.3C174.7 338.2 179.4 338 180.7 338.3C181.6 338.5 182.5 338.9 183.3 339.2C185.1 339.9 186.7 340.9 188.4 341.8C186.9 341.9 185.4 342.2 183.8 342.4C182.1 342.5 178.4 343 177.1 341.3Z"
                          fill="#263238"
                        />
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_49">
                  <g id="Group_50">
                    <path
                      id="Vector_79"
                      d="M145.7 138.4C145.7 138.4 152.6 235.8 153.8 243.2C154.1 244.9 154.1 254.1 154 265.9C153.8 291.7 153.7 338.2 153.7 338.2L191 338.9C191 338.9 193.7 258.4 192.2 243.5C190.7 228.6 180.8 139 180.8 139L145.7 138.4Z"
                      fill="#37474F"
                    />
                    <g id="Group_51">
                      <path
                        id="Vector_80"
                        d="M189.3 331.8C183.5 331.6 185.2 331.5 179.4 331.4C176.6 331.3 157.9 330.8 155.3 331.2C155.2 331.2 155.2 331.3 155.3 331.3C157.9 331.8 176.6 332 179.4 332C185.2 332 183.5 332 189.3 331.9C189.4 332 189.4 331.8 189.3 331.8Z"
                        fill="#263238"
                      />
                      <path
                        id="Vector_81"
                        d="M161.6 285.3C161.7 279.4 161.7 273.5 161.8 267.6C161.9 261.9 162 256.2 161.8 250.5C161.5 239 159.3 227.6 157.9 216.2C156.4 204.8 155.3 193.3 154.2 181.8C153 168.9 152 156 150.9 143.2C150.9 143.1 150.8 143.1 150.8 143.2C152.3 166.2 154 189.2 156.8 212.1C157.5 217.8 158.2 223.5 159.1 229.1C159.9 234.8 160.8 240.6 161.1 246.4C161.4 252.1 161.4 257.8 161.4 263.4C161.4 269.3 161.3 275.2 161.3 281C161.2 294.1 160.9 314.2 160.8 327.2C160.8 327.5 161.2 327.4 161.2 327.2C161.2 315.6 161.4 296.9 161.6 285.3Z"
                        fill="#263238"
                      />
                    </g>
                  </g>
                  <path
                    id="Vector_82"
                    d="M137.2 138.3C137.2 138.3 152.6 235.8 153.9 243.3C154.2 245 154.2 254.2 154.1 266L175.8 139L137.2 138.3Z"
                    fill="#263238"
                  />
                </g>
                <g id="Group_52">
                  <g id="Group_53">
                    <g id="Group_54">
                      <g id="Group_55">
                        <path
                          id="Vector_83"
                          d="M117.6 359.5C115.7 359 71 328.9 70 326.7C69.6 325.9 73.6 319.1 78.7 311.1C79.8 309.4 80.9 307.6 82.1 305.8C89.7 294.1 98 281.8 98 281.8L127.2 301.1L111.3 325.3L107.6 330.5C107.6 330.5 119.6 349.7 120.4 352.2C121.1 354.6 119.5 360 117.6 359.5Z"
                          fill="#F7A9A0"
                        />
                        <path
                          id="Vector_84"
                          d="M117.6 359.5C115.7 359 70.9999 328.9 69.9999 326.7C69.4999 325.7 75.4999 315.9 82.0999 305.7L111.2 325.2L107.5 330.4C107.5 330.4 119.5 349.6 120.3 352.1C121.1 354.6 119.5 360 117.6 359.5Z"
                          fill="#EBEBEB"
                        />
                        <g id="Group_56">
                          <path
                            id="Vector_85"
                            d="M117.6 359.5C115.7 359 71 328.9 70 326.7C69.6 325.9 73.6 319.1 78.7 311.1L107.6 330.4C107.6 330.4 119.6 349.6 120.4 352.1C121.1 354.6 119.5 360 117.6 359.5Z"
                            fill="#E14954"
                          />
                          <g id="Group_57">
                            <g id="Group_58">
                              <path
                                id="Vector_86"
                                d="M118.1 357.2C110.7 352 80.5001 331.6 72.8001 327C72.7001 327 72.7001 327 72.8001 327C80.0001 332.3 110.4 352.5 118 357.3C118.2 357.5 118.3 357.4 118.1 357.2Z"
                                fill="#263238"
                              />
                              <path
                                id="Vector_87"
                                d="M118.5 348.5C116.8 347.2 114.5 346.9 112.8 347.3C111.2 347.7 109.4 348.6 108.4 350.2L108.5 350.3C110.9 347.6 115.3 346.8 118.5 348.5C118.6 348.5 118.5 348.6 118.5 348.5Z"
                                fill="#263238"
                              />
                              <path
                                id="Vector_88"
                                d="M109.9 333C106.9 330.9 102.7 329.7 99.2 330.9C99.1 330.9 99.1 331.1 99.3 331.2C103 331.6 106.2 332.3 109.7 333.5C110 333.5 110.2 333.2 109.9 333Z"
                                fill="#263238"
                              />
                              <path
                                id="Vector_89"
                                d="M111.6 335.5C108.6 333.4 104.4 332.2 100.9 333.4C100.8 333.4 100.8 333.6 101 333.7C104.7 334.1 107.9 334.8 111.4 336C111.7 336 111.9 335.6 111.6 335.5Z"
                                fill="#263238"
                              />
                              <path
                                id="Vector_90"
                                d="M113.4 337.9C110.4 335.8 106.2 334.6 102.7 335.8C102.6 335.8 102.6 336 102.8 336.1C106.5 336.5 109.7 337.2 113.2 338.4C113.4 338.4 113.6 338.1 113.4 337.9Z"
                                fill="#263238"
                              />
                              <path
                                id="Vector_91"
                                d="M115.1 340.4C112.1 338.3 107.9 337.1 104.4 338.3C104.3 338.3 104.3 338.5 104.5 338.6C108.2 339 111.4 339.7 114.9 340.9C115.1 340.9 115.3 340.5 115.1 340.4Z"
                                fill="#263238"
                              />
                              <path
                                id="Vector_92"
                                d="M79.6 321.2C76.2 318.9 72.7 324.2 76.1 326.4C79.4 328.7 82.9 323.5 79.6 321.2Z"
                                fill="white"
                              />
                            </g>
                            <path
                              id="Vector_93"
                              d="M118.6 327.2C117.9 324.9 115.2 324.9 113.4 325.5C110.5 326.6 107.4 328.9 105.5 331.3C105.5 331.4 105.5 331.4 105.5 331.4C105.4 331.7 105.6 332 105.9 332C109 332 112.4 332.3 115.4 331.3C117.1 330.8 119.2 329.3 118.6 327.2ZM110.6 331.1C109.1 331.2 107.5 331.1 106 331.3C107.5 330.2 109 329 110.7 328C111.5 327.5 112.3 327.1 113.2 326.7C114.5 326.1 119.1 325.6 117.2 329.1C116.1 330.9 112.4 331 110.6 331.1Z"
                              fill="#263238"
                            />
                            <path
                              id="Vector_94"
                              d="M97.3001 327.5C99.6001 329.7 102.8 330.7 105.7 332.1C106 332.2 106.3 332 106.3 331.7C106.4 331.7 106.4 331.7 106.4 331.6C105.7 328.6 103.9 325.2 101.6 323C100.2 321.6 97.7001 320.6 96.2001 322.4C94.6001 324 95.9001 326.2 97.3001 327.5ZM96.6001 324.5C96.3001 320.6 100.3 323 101.3 324C101.9 324.7 102.5 325.4 103 326.2C104.1 327.8 105 329.5 105.9 331.1C104.5 330.4 103.1 329.7 101.8 329.1C100.1 328.3 96.8001 326.7 96.6001 324.5Z"
                              fill="#263238"
                            />
                          </g>
                        </g>
                      </g>
                    </g>
                    <path
                      id="Vector_95"
                      d="M110.6 328.6L79.2 309.1C79.2 309.1 119.2 249.1 119.5 243.6C120 235.5 124.5 202 128.2 174.9C128.2 174.9 122.3 160.1 134.3 138.1L180.9 139C180.9 139 177.4 160.1 171.8 173.4C171.8 173.4 167.7 174.6 161.4 177.9C161.4 177.9 155.7 238.3 155.3 245.9C154.7 260.9 110.6 328.6 110.6 328.6Z"
                      fill="#37474F"
                    />
                    <g id="Group_59">
                      <path
                        id="Vector_96"
                        d="M114.1 320.4C109.2 317.2 110.6 318 105.7 314.9C103.3 313.4 87.3 303.2 84.8 302.2C84.7 302.2 84.7 302.3 84.7 302.3C86.7 304.1 102.9 314 105.3 315.5C110.3 318.6 108.9 317.6 114 320.6C114.1 320.6 114.2 320.5 114.1 320.4Z"
                        fill="#263238"
                      />
                      <g id="Group_60">
                        <path
                          id="Vector_97"
                          d="M173.6 172.5C169.4 173.9 165.3 175.8 161.4 177.9C161.2 178 161.3 178.2 161.5 178.2C165.6 176.5 169.8 174.9 173.8 173C174.1 172.9 173.9 172.4 173.6 172.5Z"
                          fill="#263238"
                        />
                        <path
                          id="Vector_98"
                          d="M168 165.7C168.4 163.3 168.8 160.8 169.1 158.4C169.6 153.8 169.7 149.2 169.2 144.6C169.2 144.4 169 144.5 169 144.6C168.9 151 168.6 157.3 167.5 163.6C167 166.6 165.4 171.1 168.5 171.7C171.7 172.2 173.9 164.6 174.4 162.6C175.6 156.8 175.8 150.5 175.4 144.6C175.4 144.5 175.1 144.4 175.1 144.6C175 149.4 174.7 153.7 174.2 158.2C174 160.3 172.1 171.9 168.4 171C167.8 170.8 167.5 170.2 167.4 169.3C167.3 168.1 167.8 166.8 168 165.7Z"
                          fill="#263238"
                        />
                        <path
                          id="Vector_99"
                          d="M168.5 171.2C168.2 172 167.9 172.8 167.6 173.6C167.4 174.2 167.2 174.9 167.2 175.6C167.2 175.7 167.4 175.7 167.5 175.7C167.9 175.2 168.1 174.4 168.4 173.8C168.7 173.1 169 172.3 169.3 171.6C169.4 171 168.7 170.8 168.5 171.2Z"
                          fill="#263238"
                        />
                      </g>
                      <path
                        id="Vector_100"
                        d="M139.7 141.2C138.2 143.6 137 146.2 136.1 148.8C135.1 151.4 134.4 154.2 133.9 156.9C133.6 158.3 133.5 159.7 133.3 161.1C133.2 162.5 133.1 163.9 133.1 165.3C133 168.1 133.4 170.9 133.9 173.6C132.6 185.3 131.3 197 129.9 208.8C128.6 220.5 127.2 232.2 125.8 243.9L125.7 245L125.6 245.5C125.6 245.7 125.6 245.9 125.5 246.1C125.1 246.9 124.8 247.5 124.4 248.1C123.6 249.4 122.8 250.6 122 251.9C120.4 254.4 118.8 256.8 117.2 259.3C114 264.2 95.0999 292.7 88.3999 302.3L116.6 258.8C118.2 256.3 119.8 253.9 121.4 251.4C122.2 250.2 123 248.9 123.7 247.7C124.1 247.1 124.5 246.4 124.7 245.8C124.8 245.7 124.8 245.5 124.8 245.3L124.9 244.8L125 243.7C126.3 232 127.7 220.3 129.1 208.6C130.5 196.9 131.9 185.2 133.3 173.5V173.6C132.8 170.8 132.5 168 132.6 165.1C132.6 163.7 132.7 162.3 132.8 160.9C133 159.5 133.2 158.1 133.5 156.7C134.1 153.9 134.9 151.2 135.9 148.6C137 146.1 138.2 143.6 139.7 141.2Z"
                        fill="#263238"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <g id="Group_61">
                <g id="Group_62">
                  <path
                    id="Vector_101"
                    d="M180.8 139.1L182.1 127.2C182.1 127.2 188.1 121.3 184.6 112.9C183.2 109.4 174.1 91.1999 168.1 87.0999C162.5 83.2999 157.9 84.5999 154.8 87.6999C145 97.4999 138.5 114.5 134.2 138.3L180.8 139.1Z"
                    fill="#EBEBEB"
                  />
                  <g id="Group_63">
                    <path
                      id="Vector_102"
                      d="M170.3 123.9C172.2 124.1 174.1 124.4 175.9 124.9C176.8 125.2 177.7 125.5 178.6 125.9C179.1 126.1 179.5 126.3 180 126.4C180.5 126.6 181 126.7 181.4 126.7V126.8C180.9 126.8 180.5 126.9 180 126.8C179.6 126.8 179.1 126.6 178.7 126.5C177.8 126.2 176.8 125.9 175.9 125.6C174 125 172.1 124.4 170.2 124C170.2 124.1 170.2 123.9 170.3 123.9Z"
                      fill="#263238"
                    />
                    <path
                      id="Vector_103"
                      d="M165.8 126.5C167.3 126.2 168.9 126 170.4 126C171.2 126 171.9 126 172.7 126.1C173.1 126.1 173.4 126.2 173.8 126.2C174.2 126.3 174.6 126.4 175 126.4V126.5C174.6 126.5 174.2 126.5 173.8 126.5C173.4 126.5 173 126.5 172.6 126.5C171.8 126.5 171.1 126.5 170.3 126.5C168.8 126.5 167.2 126.5 165.7 126.6C165.7 126.7 165.7 126.5 165.8 126.5Z"
                      fill="#263238"
                    />
                  </g>
                  <path
                    id="Vector_104"
                    d="M158 85.5C159.2 89.5 164.3 110.6 164.8 118.9C165.3 127.2 156.3 214.8 151.6 217.6C146.9 220.4 116.7 209.8 114.1 202.2C109.2 187.8 133.2 136.8 133.2 136.8C133.2 136.8 139.9 104.5 150.7 91.6C155.2 86 158 85.5 158 85.5Z"
                    fill="#E14954"
                  />
                </g>
                <g id="Group_64">
                  <path
                    id="Vector_105"
                    d="M306.364 62.664L191.564 62.3379L191.238 177.138L306.038 177.464L306.364 62.664Z"
                    fill="#EBEBEB"
                  />
                  <g id="Group_65">
                    <path
                      id="Vector_106"
                      d="M298.745 69.4447L197.445 69.1569L197.157 170.457L298.457 170.745L298.745 69.4447Z"
                      fill="#E14954"
                    />
                    <path
                      id="Vector_107"
                      opacity="0.2"
                      d="M263.5 106.6C265.6 117.7 256 127.3 244.8 125.2C238.6 124 233.6 119 232.4 112.7C230.3 101.6 239.9 92.0001 251.1 94.1001C257.4 95.3001 262.4 100.4 263.5 106.6Z"
                      fill="black"
                    />
                    <path
                      id="Vector_108"
                      opacity="0.2"
                      d="M275.5 154.2V163.7H220.5V154C220.5 138.8 232.8 126.6 248 126.6H248.1C263.3 126.7 275.5 139.1 275.5 154.2Z"
                      fill="black"
                    />
                  </g>
                </g>
                <g id="Group_66">
                  <g id="Group_67">
                    <g id="Group_68">
                      <path
                        id="Vector_109"
                        d="M150.2 97.1999C144.3 111.8 137.7 142.9 141.1 148.1C147 157.2 161.4 167.7 170.1 172.1C173 173.6 184.4 151 181 150.1C176.9 149.1 160.6 136.4 160.5 135.5C160.3 134.3 159.5 114.9 159 101.6C158.2 85.6999 152.5 91.2999 150.2 97.1999Z"
                        fill="#F7A9A0"
                      />
                      <g id="Group_69">
                        <path
                          id="Vector_110"
                          d="M167.8 171.1C175.3 175.8 184.4 182.1 194.4 182.3C196.4 182.3 197.5 181.4 197.5 181.4C202 181.6 203.4 179.5 203.4 179.5C207.6 178.4 208.8 176.1 208.8 176.1C208.8 176.1 214.1 176.3 214.7 173.1C215.4 169.5 207.9 170 203.6 167.7C193.7 162.5 181.2 152.3 178.5 150.5L167.8 171.1Z"
                          fill="#F7A9A0"
                        />
                        <path
                          id="Vector_111"
                          d="M189.5 169.5C192.4 171 195.3 172.5 198.4 173.6C201.8 174.8 205.3 175.3 208.8 176C208.9 176 208.9 176.2 208.8 176.2C202.2 176 194.8 173.4 189.4 169.7C189.3 169.6 189.4 169.5 189.5 169.5Z"
                          fill="#263238"
                        />
                        <path
                          id="Vector_112"
                          d="M185.5 175C188.4 176.3 193.6 178.5 203.5 179.4V179.5C197 179.8 191.1 178.3 185.4 175.2C185.3 175.1 185.3 174.9 185.5 175Z"
                          fill="#263238"
                        />
                        <path
                          id="Vector_113"
                          d="M183.4 178C188.5 180 191.9 180.6 197.6 181.4V181.5C191.1 181.3 188.7 180.5 183.3 178.2C183.1 178.1 183.2 177.9 183.4 178Z"
                          fill="#263238"
                        />
                        <path
                          id="Vector_114"
                          d="M192.2 160.7C191.2 159.5 191.8 159 194.5 159.5C197.3 160 207.5 164.5 208.8 160.8C210.1 157.1 195.4 151 190.8 150.4C186.2 149.8 178.6 150.5 178.6 150.5C181.2 152.3 187.2 157.2 192.2 160.7Z"
                          fill="#F7A9A0"
                        />
                      </g>
                    </g>
                  </g>
                  <path
                    id="Vector_115"
                    d="M179.4 148L166.6 172.9C166.6 172.9 139.8 155.2 138.5 146.8C137.2 138.4 145.2 108.4 149 99.0999C152.8 89.7999 157.8 86.5999 159.4 99.0999C161 111.6 161.2 134.7 161.3 135.3C161.3 135.8 179.4 148 179.4 148Z"
                    fill="#E14954"
                  />
                  <g id="Group_70">
                    <path
                      id="Vector_116"
                      d="M163 167.1C164.6 163.6 166.2 160.2 168 156.8C168.9 155.1 169.7 153.4 170.6 151.8C171.5 150.1 172.6 148.4 173.5 146.7C173.6 146.6 173.8 146.7 173.7 146.8C172.9 148.5 172.2 150.2 171.4 151.9C170.6 153.6 169.7 155.4 168.8 157.1C167.1 160.5 165.2 163.9 163.3 167.2C163.1 167.3 162.9 167.2 163 167.1Z"
                      fill="#263238"
                    />
                  </g>
                  <g id="Group_71">
                    <g id="Group_72">
                      <path
                        id="Vector_117"
                        d="M166.4 139.1C165 138.3 163.7 137.5 162.4 136.6C162.1 136.4 161.8 136.1 161.5 135.9C161.3 135.8 161.2 135.6 161.1 135.4C161 135.1 161 135 161 134.8C160.9 134 160.8 133.2 160.8 132.5C160.6 129.4 160.4 126.3 160.3 123.1C160.2 120 160.1 116.9 160 113.7C160 110.6 159.9 107.4 160 104.4L160.3 109L160.6 113.7L161 123.1L161.3 132.5C161.3 133.3 161.4 134.1 161.4 134.8C161.4 135 161.4 135.2 161.5 135.3C161.6 135.4 161.7 135.6 161.8 135.7C162.1 136 162.4 136.2 162.7 136.5C163.9 137.3 165.2 138.2 166.4 139.1Z"
                        fill="#263238"
                      />
                    </g>
                    <g id="Group_73">
                      <path
                        id="Vector_118"
                        d="M161.3 107.1C161.4 107.8 161.3 108.5 161.1 109.2C161 109.5 160.9 109.9 160.7 110.2C160.5 110.5 160.4 110.8 160.1 111.1L160.6 109C160.8 108.5 161 107.8 161.3 107.1Z"
                        fill="#263238"
                      />
                    </g>
                  </g>
                  <g id="Group_74">
                    <g id="Group_75">
                      <path
                        id="Vector_119"
                        d="M146.5 106.1C145.5 109 144.7 111.9 144 114.8C143.3 117.7 142.6 120.7 142 123.6C140.8 129.5 139.6 135.5 138.9 141.5C138.8 143 138.6 144.5 138.8 145.9C139 147.3 139.5 148.7 140.3 150C141.8 152.6 143.9 154.7 146.1 156.8C148.3 158.9 150.6 160.8 153 162.7C155.4 164.6 157.7 166.5 160.2 168.3C155.2 164.8 150.3 161.3 145.7 157.3C143.5 155.3 141.3 153.1 139.7 150.4C138.9 149.1 138.3 147.6 138.1 146C137.9 144.4 138.1 142.9 138.2 141.4C138.9 135.4 140.2 129.4 141.5 123.5C142.9 117.7 144.4 111.8 146.5 106.1Z"
                        fill="#263238"
                      />
                    </g>
                    <g id="Group_76">
                      <path
                        id="Vector_120"
                        d="M145.2 107.2C145 108 144.9 108.7 144.8 109.5C144.8 109.9 144.8 110.3 144.8 110.7C144.8 111.1 144.8 111.5 144.8 111.9C144.4 111.2 144.3 110.3 144.3 109.5C144.3 109.1 144.4 108.7 144.6 108.3C144.8 107.9 145 107.6 145.2 107.2Z"
                        fill="#263238"
                      />
                    </g>
                  </g>
                </g>
                <g id="Group_77">
                  <g id="Group_78">
                    <g id="Group_79">
                      <path
                        id="Vector_121"
                        d="M158 88.0999C158.1 89.0999 164.1 95.8999 168.4 96.3999C169.8 96.4999 168.5 88.6999 168.5 88.6999L168.7 88.1999L171.6 80.5999L163.2 73.3999L162 72.3999C162 72.3999 161.4 74.4999 160.6 77.1999C160.6 77.2999 160.5 77.3999 160.5 77.5999C160.5 77.6999 160.4 77.8999 160.4 77.9999C160.3 78.3999 160.2 78.7999 160 79.1999C159.9 79.4999 159.8 79.7999 159.7 80.0999C159.6 80.3999 159.5 80.6999 159.4 81.0999C158.5 84.2999 157.9 87.6999 158 88.0999Z"
                        fill="#F7A9A0"
                      />
                      <path
                        id="Vector_122"
                        d="M160.4 77.0999C161.8 83.6999 166.4 86.8999 168.6 88.0999L171.5 80.4999L163.1 73.2999L161.9 72.2999C161.9 72.2999 161.2 74.4999 160.4 77.0999Z"
                        fill="#263238"
                      />
                    </g>
                    <g id="Group_80">
                      <path
                        id="Vector_123"
                        d="M184.2 68.3C185.2 82.6 176.8 84.8 173.7 85C170.9 85.2 161.4 85.5 158.9 71.4C156.4 57.3 162.7 52.3 169.1 51.6C175.4 50.8 183.2 54 184.2 68.3Z"
                        fill="#F7A9A0"
                      />
                      <g id="Group_81">
                        <path
                          id="Vector_124"
                          d="M181.7 66.6C181.4 66.6 181.1 66.5 180.7 66.4C180.3 66.3 180 66.3 179.8 66C179.7 65.8 179.7 65.5 179.8 65.4C180.1 65 180.6 65 181 65.1C181.5 65.2 181.8 65.4 182.2 65.7C182.5 66.1 182.1 66.7 181.7 66.6Z"
                          fill="#263238"
                        />
                        <path
                          id="Vector_125"
                          d="M172 66.9C172.3 66.8 172.6 66.7 173 66.7C173.4 66.6 173.7 66.6 173.9 66.3C174 66.1 174 65.8 173.9 65.7C173.6 65.3 173.1 65.4 172.6 65.5C172.1 65.6 171.8 65.8 171.5 66.2C171.2 66.4 171.6 66.9 172 66.9Z"
                          fill="#263238"
                        />
                        <g id="Group_82">
                          <g id="Group_83">
                            <path
                              id="Vector_126"
                              d="M174.2 69.4C174.1 69.5 174.1 69.4 174.2 69.4C174.2 70.5 174.1 71.6 173.2 71.9V72C174.3 71.8 174.4 70.3 174.2 69.4Z"
                              fill="#263238"
                            />
                            <path
                              id="Vector_127"
                              d="M173.3 68.4C171.7 68.3 171.7 71.5 173.2 71.5C174.5 71.6 174.6 68.5 173.3 68.4Z"
                              fill="#263238"
                            />
                          </g>
                          <path
                            id="Vector_128"
                            d="M172.6 68.6C172.3 68.8 172.1 69.1 171.7 69.1C171.3 69.1 171 68.8 170.8 68.5H170.7C170.7 69.1 170.9 69.7 171.6 69.8C172.2 69.9 172.6 69.4 172.8 68.8C172.8 68.7 172.8 68.5 172.6 68.6Z"
                            fill="#263238"
                          />
                        </g>
                        <g id="Group_84">
                          <g id="Group_85">
                            <path
                              id="Vector_129"
                              d="M179.4 69.4C179.5 69.5 179.5 69.4 179.4 69.4C179.4 70.5 179.5 71.6 180.4 71.9V72C179.4 71.8 179.2 70.3 179.4 69.4Z"
                              fill="#263238"
                            />
                            <path
                              id="Vector_130"
                              d="M180.3 68.4C181.9 68.3 181.9 71.5 180.5 71.5C179.1 71.6 179 68.5 180.3 68.4Z"
                              fill="#263238"
                            />
                          </g>
                          <path
                            id="Vector_131"
                            d="M181 68.6C181.3 68.8 181.5 69.1 181.8 69.1C182.1 69.1 182.4 68.8 182.6 68.4C182.6 69 182.4 69.6 181.9 69.8C181.4 69.9 181 69.4 180.8 68.8C180.8 68.7 180.9 68.6 181 68.6Z"
                            fill="#263238"
                          />
                        </g>
                        <path
                          id="Vector_132"
                          d="M174.1 77.2999C174.3 77.4999 174.5 77.8999 174.9 77.8999C175.2 77.8999 175.6 77.7999 176 77.6999C176 77.6999 176.1 77.6999 176 77.7999C175.7 78.1999 175.2 78.3999 174.7 78.2999C174.3 78.0999 174.1 77.6999 174.1 77.2999Z"
                          fill="#263238"
                        />
                        <g id="Group_86">
                          <path
                            id="Vector_133"
                            d="M178.2 73.8C178.2 73.8 178.4 75.3 178.4 75.9C178.4 76 178.2 76 178 76C176.8 76.2 175.6 75.8 174.8 74.8C174.8 74.7 174.8 74.7 174.9 74.7C175.8 75.4 176.8 75.5 177.9 75.5C177.9 75.3 177.5 73 177.6 73C178 73 178.7 73.2 179.2 73.3C178.9 70.3 178 67.3 177.8 64.3C177.8 64.2 177.9 64.2 178 64.3C179.1 67.3 179.5 70.8 180 74C179.9 74.4 178.5 73.9 178.2 73.8Z"
                            fill="#263238"
                          />
                        </g>
                        <g id="Group_87">
                          <path
                            id="Vector_134"
                            d="M177.5 75.5999C177.5 75.5999 176.9 76.3999 176.1 76.7999C175.8 76.8999 175.5 76.9999 175.1 76.8999C174.4 76.6999 174.4 76.0999 174.5 75.5999C174.6 75.0999 174.8 74.7999 174.8 74.7999C174.8 74.7999 175.9 75.5999 177.5 75.5999Z"
                            fill="#263238"
                          />
                          <path
                            id="Vector_135"
                            d="M176.2 76.8C175.9 76.9 175.6 77 175.2 76.9C174.5 76.7 174.5 76.1 174.6 75.6C175.3 75.6 176.1 76.1 176.2 76.8Z"
                            fill="#FF9CBD"
                          />
                        </g>
                      </g>
                      <g id="Group_88">
                        <path
                          id="Vector_136"
                          d="M160.3 73.9C163.3 72.2 163.2 69 163.2 69C166.9 67.1 168.8 63.9 169 63.6C168.9 63.9 168.4 66 168.1 67.1C176.9 64.8 179.2 57.8 179.2 57.8C182.3 60.5 183 62.3 183.5 63.2C183.5 63.2 183.2 57.7 180.1 54.2C176.3 49.9 171.7 51.1 171.7 51.1C171.7 51.1 164.6 49.5 159.6 55.7C154.3 62.5 160.3 73.9 160.3 73.9Z"
                          fill="#263238"
                        />
                        <path
                          id="Vector_137"
                          d="M174.8 65.0999C173.4 65.9999 171.9 66.5999 170.3 67.0999C172.8 66.1999 174.9 64.7 176.6 62.7C177.3 61.8 177.9 60.9 178.3 59.9C178.8 58.8 179.2 57.6 179.3 56.4C179.3 56.3 179.4 56.3 179.4 56.4C179.4 57.6 179.4 58.8 178.9 60C178.5 61.1 177.9 62.1999 177.1 63.0999C176.5 63.7999 175.7 64.4999 174.8 65.0999Z"
                          fill="#263238"
                        />
                      </g>
                      <g id="Group_89">
                        <g id="Group_90">
                          <path
                            id="Vector_138"
                            d="M162.4 71.4999C162.4 71.4999 159.3 66.9999 157.3 68.0999C155.3 69.1999 157.5 75.6999 159.9 76.5999C162.3 77.4999 163.2 75.2999 163.2 75.2999L162.4 71.4999Z"
                            fill="#F7A9A0"
                          />
                          <path
                            id="Vector_139"
                            d="M158 69.8999C157.9 69.8999 157.9 69.8999 158 69.8999C159.6 70.6999 160.5 72.1999 161.1 73.7999C160.6 73.1999 159.9 72.7999 159.1 73.3999V73.4999C159.8 73.3999 160.3 73.4999 160.7 73.9999C161 74.3999 161.2 74.8999 161.5 75.2999C161.6 75.4999 161.8 75.3999 161.8 75.1999C161.9 73.0999 160.3 70.0999 158 69.8999Z"
                            fill="#263238"
                          />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
                <g id="Group_91">
                  <g id="Group_92">
                    <g id="Group_93">
                      <path
                        id="Vector_140"
                        d="M222.8 64.6999C226.2 62.5999 230.1 57.8999 233 57.2999C235.6 56.6999 236.7 58.7999 236.7 58.7999C236.7 58.7999 237.8 55.7999 240.5 55.5999C241.9 55.4999 243.2 57.6999 243.2 57.6999C243.2 57.6999 244.2 55.2999 246.6 55.2999C248.5 55.2999 249.6 57.3999 249.6 57.3999C249.6 57.3999 251.7 55.5999 253.6 56.1999C257.6 57.4999 259.3 73.7999 253.5 76.9999C250.8 78.4999 250.6 74.4999 250.6 74.4999C250.6 74.4999 249.5 80.2999 246.1 80.7999C242.7 81.1999 242.8 75.9999 242.8 75.9999C242.8 75.9999 241.7 81.6999 238.1 81.7999C234.4 81.8999 235.4 76.6999 235.4 76.6999C235.4 76.6999 233.3 80.5999 230.6 80.0999C226.1 79.1999 230.9 71.6999 229.6 68.1999L223.5 66.7999L222.8 64.6999Z"
                        fill="#F7A9A0"
                      />
                      <path
                        id="Vector_141"
                        d="M227.6 60.9C224.2 62.6 219.5 58.7 216 59.8C211.3 61.3 216.6 66.2 223.6 66.7L229.1 66.1L227.6 60.9Z"
                        fill="#F7A9A0"
                      />
                      <g id="Group_94">
                        <path
                          id="Vector_142"
                          d="M249.9 57.7C251.9 62.9 252.3 69.3 250.7 74.6C250.6 74.8 250.3 74.8 250.4 74.6C251 68.8 251 63.5 249.9 57.7Z"
                          fill="#263238"
                        />
                        <path
                          id="Vector_143"
                          d="M243.3 57.5999C243.3 57.4999 243.4 57.4999 243.4 57.5999C245.3 62.9999 245.6 70.1999 243.1 76.0999C243 76.2999 242.7 76.3999 242.8 76.1999C244.2 71.9999 244.3 62.7999 243.3 57.5999Z"
                          fill="#263238"
                        />
                        <path
                          id="Vector_144"
                          d="M235.2 76.8999C237.4 71.0999 237.5 65.0999 236.8 58.9999C236.8 58.8999 236.9 58.8999 236.9 58.9999C238.1 61.8999 238.2 65.8999 238 68.8999C237.8 71.8999 237 74.4999 235.4 76.9999C235.4 77.0999 235.2 77.0999 235.2 76.8999Z"
                          fill="#263238"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
