export default function EmptyFolder({ className }) {
  return (
    <svg
      width="556"
      height="420"
      viewBox="0 0 556 420"
      className={className}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="OBJECTS">
        <path
          id="Vector"
          d="M106.25 416.07C164.93 416.07 212.5 408.75 212.5 399.72C212.5 390.69 164.93 383.37 106.25 383.37C47.5697 383.37 0 390.69 0 399.72C0 408.75 47.5697 416.07 106.25 416.07Z"
          fill="#DFDFEB"
        />
        <path
          id="Vector_2"
          d="M279.91 419.34C385.921 419.34 471.86 412.02 471.86 402.99C471.86 393.96 385.921 386.64 279.91 386.64C173.899 386.64 87.96 393.96 87.96 402.99C87.96 412.02 173.899 419.34 279.91 419.34Z"
          fill="#DFDFEB"
        />
        <path
          id="Vector_3"
          d="M227.7 0.0199966C220.153 0.227268 212.833 2.64969 206.652 6.98573C200.471 11.3218 195.702 17.3799 192.938 24.4061C190.174 31.4322 189.538 39.1161 191.108 46.5011C192.679 53.8862 196.386 60.6463 201.77 65.94C202.324 66.4818 202.738 67.1494 202.978 67.8858C203.219 68.6223 203.278 69.4059 203.15 70.17L200.3 87.08L217.84 76.86C218.835 76.2679 220.014 76.0649 221.15 76.29C226.465 77.359 231.946 77.2935 237.234 76.0976C242.522 74.9018 247.498 72.6026 251.835 69.3506C256.173 66.0986 259.775 61.9671 262.406 57.2266C265.036 52.4861 266.636 47.2434 267.1 41.8418C267.564 36.4403 266.882 31.0016 265.099 25.8818C263.316 20.762 260.471 16.0766 256.752 12.1323C253.032 8.18795 248.522 5.07366 243.515 2.99327C238.509 0.912884 233.119 -0.0866869 227.7 0.0599976V0.0199966Z"
          fill="#D3DDF7"
        />
        <path
          id="Vector_4"
          d="M227.02 51.84L226.91 42.63C228.171 42.3297 229.397 41.9008 230.57 41.35C231.614 40.8666 232.592 40.2516 233.48 39.52C234.295 38.8628 235.003 38.0836 235.58 37.21C236.14 36.3616 236.572 35.4349 236.86 34.46C237.156 33.45 237.304 32.4025 237.3 31.35C237.302 29.8057 236.905 28.287 236.15 26.94C235.379 25.5515 234.25 24.3949 232.88 23.59C231.343 22.7057 229.592 22.2627 227.82 22.31C225.741 22.3106 223.709 22.9265 221.98 24.08C219.976 25.5322 218.526 27.6237 217.87 30.01L216.87 29.36C217.561 26.9225 219.033 24.7793 221.06 23.26C222.85 22.015 224.948 21.2857 227.124 21.1517C229.3 21.0177 231.471 21.4841 233.4 22.5C234.972 23.3584 236.282 24.6267 237.19 26.17C238.102 27.7498 238.572 29.546 238.55 31.37C238.56 32.7675 238.309 34.1545 237.81 35.46C237.319 36.7303 236.619 37.9098 235.74 38.95C234.833 40.0202 233.763 40.9414 232.57 41.68C231.236 42.4872 229.786 43.0842 228.27 43.45L228.16 51.84H227.02ZM226.37 58.7C226.351 58.2769 226.486 57.8612 226.75 57.53C226.887 57.386 227.051 57.2712 227.233 57.1928C227.415 57.1144 227.612 57.074 227.81 57.074C228.009 57.074 228.205 57.1144 228.387 57.1928C228.569 57.2712 228.734 57.386 228.87 57.53C229.093 57.8797 229.211 58.2856 229.211 58.7C229.211 59.1144 229.093 59.5203 228.87 59.87C228.747 60.0346 228.584 60.1657 228.398 60.2513C228.211 60.3369 228.005 60.3743 227.8 60.36C227.602 60.3747 227.404 60.3435 227.22 60.2689C227.036 60.1943 226.872 60.0784 226.74 59.93C226.476 59.5762 226.345 59.1407 226.37 58.7Z"
          fill="black"
        />
        <path
          id="Vector_5"
          d="M116.29 376.72L117.99 389.59C117.99 389.59 149.99 403.23 147.05 403.43C144.11 403.63 98.0498 400.66 98.0498 400.66L100.76 377.29L116.29 376.72Z"
          fill="black"
        />
        <path
          id="Vector_6"
          d="M107.37 166.56C107.37 166.56 104.81 191.12 102.9 192.4C101.514 193.251 100.012 193.894 98.4399 194.31L102.57 166.24L107.37 166.56Z"
          stroke="#424242"
          strokeMiterlimit="10"
        />
        <path
          id="Vector_7"
          d="M56.8001 149.68C56.8001 149.68 43.8001 178.99 47.5901 195.32L119.59 184.32L118.09 153.88L56.8001 149.68Z"
          fill="#3A3B6F"
        />
        <path
          id="Vector_8"
          d="M40.8099 375.17L43.5699 386.27C43.5699 386.27 63.7799 410.57 60.7999 410.57C57.8199 410.57 24.6499 395.57 24.6499 395.57L28.8999 372.43L40.8099 375.17Z"
          fill="black"
        />
        <path
          id="Vector_9"
          d="M119.57 184.34C119.57 184.34 119.97 254.66 120.47 279.16C120.97 303.66 125.26 379.31 125.26 379.31C125.26 379.31 111.82 386.72 97.8302 380.73C96.7102 380.25 84.6602 183.73 84.6602 183.73L119.57 184.34Z"
          fill="#3A3B6F"
        />
        <path
          id="Vector_10"
          d="M48.7799 186.61L18.4199 379.95C18.4199 379.95 31.6799 392.37 48.4199 381.07L79.2899 223.15L94.2899 213.08L96.5699 184.27L48.7799 186.61Z"
          fill="#3A3B6F"
        />
        <path
          id="Vector_11"
          d="M59.8698 161.54C59.8698 161.54 40.7398 241.98 26.5698 384"
          stroke="#092C60"
          strokeMiterlimit="10"
        />
        <path
          id="Vector_12"
          d="M72.9901 170.2C72.9901 170.2 66.2901 186.94 54.8501 187.2"
          stroke="#092C60"
          strokeMiterlimit="10"
        />
        <path id="Vector_13" d="M101.28 381.89L91.4902 215.46L101.06 205.43" stroke="#092C60" strokeMiterlimit="10" />
        <path
          id="Vector_14"
          d="M101.16 65.48L109.77 63.73L140.82 80.73L140.98 80.81C151.15 86.43 155.65 95.95 155.38 109.3L156.89 128.64L140.06 114.98L125.84 142.41L116.84 174.79C68.6302 192.32 49.9102 160.85 49.9102 160.85L65.9102 126.27L72.3602 94.34C75.3702 77.92 84.8302 68.82 101.16 65.48Z"
          fill="#F3B6BB"
        />
        <path
          id="Vector_15"
          d="M261.07 173.31C261.07 173.31 284.65 191.1 324.5 180.58C322.073 176.113 318.634 172.276 314.458 169.378C310.281 166.479 305.484 164.6 300.45 163.89C283.1 161.42 256.74 169.67 261.07 173.31Z"
          fill="#7E9E6E"
        />
        <path
          id="Vector_16"
          d="M261.07 173.31C261.07 173.31 288.4 162.63 324.5 180.58C327.545 182.383 330.127 184.873 332.04 187.85"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          id="Vector_17"
          d="M347.66 161.08C353.929 161.081 359.999 158.88 364.81 154.86L378.17 143.72C382.733 139.785 388.544 137.595 394.57 137.54H460.85C463.737 137.543 466.506 138.691 468.547 140.733C470.589 142.774 471.737 145.543 471.74 148.43V388.37C471.74 393.354 469.761 398.135 466.237 401.66C462.714 405.185 457.934 407.167 452.95 407.17H122.51C118.634 407.167 114.918 405.626 112.178 402.884C109.439 400.143 107.9 396.426 107.9 392.55V175.67C107.905 171.799 109.447 168.088 112.186 165.352C114.925 162.617 118.639 161.08 122.51 161.08H347.66Z"
          fill="#71252A"
        />
        <path
          id="Vector_18"
          d="M107.9 393.77C107.9 393.77 130.38 351.6 132.63 346.77L194.09 221.58C194.844 219.951 196.05 218.572 197.563 217.606C199.076 216.64 200.835 216.128 202.63 216.13H430.99C439.29 216.13 451.3 211.77 457.67 206.46L466.89 198.77C471.446 194.851 477.241 192.669 483.25 192.61H546.32C547.867 192.611 549.389 192.992 550.754 193.72C552.118 194.449 553.282 195.502 554.143 196.787C555.003 198.072 555.535 199.549 555.69 201.087C555.844 202.626 555.618 204.179 555.03 205.61L478.76 390.08C476.67 395.137 473.127 399.46 468.579 402.503C464.03 405.545 458.682 407.169 453.21 407.17H112.02C110.917 407.153 109.861 406.717 109.068 405.95C108.274 405.183 107.803 404.142 107.75 403.04L107.9 393.77Z"
          fill="#E14954"
        />
        <path id="Vector_19" d="M200.78 228.01L110.61 403.29" stroke="black" strokeWidth="2" strokeLinejoin="round" />
        <path
          id="Vector_20"
          d="M237.94 258.22H315.57C316.609 258.222 317.631 257.964 318.545 257.469C319.458 256.975 320.233 256.26 320.8 255.39C321.393 254.463 321.724 253.392 321.757 252.291C321.79 251.191 321.524 250.102 320.987 249.141C320.451 248.18 319.664 247.382 318.71 246.832C317.756 246.283 316.671 246.002 315.57 246.02H237.94C236.902 246.016 235.88 246.273 234.967 246.765C234.053 247.258 233.278 247.971 232.71 248.84C232.118 249.768 231.788 250.839 231.756 251.94C231.723 253.041 231.989 254.13 232.525 255.091C233.061 256.053 233.848 256.852 234.801 257.402C235.755 257.953 236.839 258.236 237.94 258.22Z"
          stroke="black"
          strokeWidth="2"
          strokeLinejoin="round"
        />
        <path
          id="Vector_21"
          d="M383.23 318.46L322.76 354.49"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_22"
          d="M333.79 316.72L371.79 354.71"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_23"
          d="M362.882 369.345C387.086 362.651 402.606 342.398 397.547 324.108C392.489 305.818 368.767 296.418 344.564 303.112C320.36 309.806 304.839 330.059 309.898 348.349C314.956 366.639 338.678 376.039 362.882 369.345Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_24"
          d="M123.33 147.23L134.57 159.82C134.57 159.82 124.04 172.23 113.36 164.88L111.64 162.42L123.33 147.23Z"
          fill="#9EC68A"
        />
        <path
          id="Vector_25"
          d="M97.27 72.08C97.27 72.08 112.64 76.08 100.55 120.53"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          id="Vector_26"
          d="M124.27 148.55C115.98 139.23 105.84 127.55 99.8501 119.44"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          id="Vector_27"
          d="M113.34 164.88C105.34 156.72 94.6198 145.1 89.9198 140.79C82.5698 134.08 75.0098 123.28 77.4798 109.41L77.1898 98.29"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          id="Vector_28"
          d="M161.13 158.92C151.33 153.62 146.81 147.06 143.03 142.2C134.03 130.6 130.81 129.2 133.46 115.67L133.6 101.54L153.17 122.72C160.64 132.64 177.87 150.41 184.24 157.41C184.24 157.41 176.77 167.37 161.13 158.92Z"
          fill="#F3B6BB"
        />
        <path
          id="Vector_29"
          d="M121.22 155.34C122.122 155.098 123.061 155.023 123.99 155.12C127.35 155.47 136.34 156.42 137.35 156.43C138.61 156.43 139.62 154.58 142.3 161.34C144.98 168.1 144.5 168.74 144.49 169.62C144.48 170.5 142.49 183.5 140.49 181.54C138.49 179.58 138.68 178.46 138.68 178.46C138.68 178.46 137.55 183.73 136.96 183.53C136.37 183.33 134.17 183.32 134.52 179.59C134.52 179.59 133.32 183.22 132.09 182.31C130.86 181.4 131.09 177.5 131.09 177.5C131.09 177.5 129.77 180.44 128.26 178.5C126.75 176.56 127.87 171.99 127.87 171.99L126.57 168.6L123.83 167.41C123.83 167.41 114.62 169.82 113.03 163.41C111.84 158.52 118.21 156.17 121.22 155.34Z"
          fill="#DB9C7F"
        />
        <path
          id="Vector_30"
          d="M139.75 163.63L141.48 170.9L138.62 178.45"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          id="Vector_31"
          d="M135.31 163.36L137.55 170.81L134.47 179.59"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          id="Vector_32"
          d="M131.41 164.19L132.72 171.36L131.22 176.72"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          id="Vector_33"
          d="M123.89 26.08C121.967 27.8396 120.78 30.2618 120.57 32.86C119.894 32.0491 119.028 31.4171 118.05 31.02"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          id="Vector_34"
          d="M170.57 153.26C173.694 152.55 176.968 152.983 179.8 154.48C183.55 156.4 189.4 159.37 190.16 159.6C191.29 159.93 192.03 159.32 192.16 166.2C192.3 168.001 192.236 169.813 191.97 171.6C191.73 172.4 186.23 180.6 184.43 179.45L184.3 179.37C183.809 180.052 183.249 180.682 182.63 181.25C182.18 181.63 179.63 181.58 180.95 178.25C180.95 178.25 179.21 182.07 177.86 181.56C176.1 180.9 176.38 179.2 177.02 177.77C176.21 178.87 175.19 179.77 174.47 178.82C173.06 176.94 176.26 170.26 176.26 170.26L176.63 166.21L174.23 164.08C174.23 164.08 165.49 164.3 165.7 158.08C165.8 155.19 168.11 153.86 170.57 153.26Z"
          fill="#DB9C7F"
        />
        <path
          id="Vector_35"
          d="M189.77 163.68L189.66 171.06L184.28 178.89"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          id="Vector_36"
          d="M185.95 163.74L185.53 171.34L180.99 178.28"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          id="Vector_37"
          d="M182.07 163.94L181.53 170.34L177.65 176.77"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          id="Vector_38"
          d="M171.01 162.23C171.01 162.23 171.89 168.83 169.76 170.23C167.63 171.63 164.2 158.03 166.56 157.86C170.02 157.65 171.01 162.23 171.01 162.23Z"
          fill="#DB9C7F"
        />
        <path
          id="Vector_39"
          d="M173.92 161.28C173.241 161.439 172.622 161.79 172.137 162.292C171.653 162.794 171.324 163.425 171.19 164.11"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          id="Vector_40"
          d="M132.8 129.33L143.1 115.91C152.51 103.45 146.24 90.28 146.24 90.28"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          id="Vector_41"
          d="M156.89 128.6C156.89 128.6 150.82 120.18 149.44 123.91"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path id="Vector_42" d="M159.23 58.58L157.48 58.21" stroke="white" strokeMiterlimit="10" />
        <path
          id="Vector_43"
          d="M122.71 56.96L109.77 63.73C109.77 63.73 109.43 79.09 123.96 90.73L130.49 84.39L137.07 90.97C137.07 90.97 139.57 88.97 140.31 80.09L139.14 69.26L122.71 56.96Z"
          fill="white"
        />
        <path
          id="Vector_44"
          d="M139.13 69.29L122.7 56.95L109.77 63.73"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_45"
          d="M118.95 59.29C118.95 59.29 122.95 82.68 128.88 84.29C134.81 85.9 138.5 74.29 138.5 74.29L127.03 42.36L118.95 59.29Z"
          fill="#DB9C7F"
        />
        <path
          id="Vector_46"
          d="M140.7 77.08L140.77 79.99C140.82 79.99 140.65 80.89 137.77 81.22C125.1 80.84 124.1 66.58 123.07 55.22C123.06 55.27 141.87 74.85 140.7 77.08Z"
          fill="black"
        />
        <path
          id="Vector_47"
          d="M158.43 45.31C158.43 45.31 161.68 74.96 144.29 79.78C126.9 84.6 122.73 56.23 123.29 46.42C123.85 36.61 128.79 31.76 140.98 31.32C159.05 30.67 158.43 45.31 158.43 45.31Z"
          fill="#DB9C7F"
        />
        <path
          id="Vector_48"
          d="M132.39 37.27C132.39 37.27 132.06 43.5 127.93 44.75L124.93 53.91C124.93 53.91 122.44 49.84 119.7 48.39C119.7 48.39 116.7 36.29 120.48 31.48C124.26 26.67 129.58 24.24 142.39 25.3C145.969 25.6309 149.413 26.827 152.427 28.7851C155.441 30.7431 157.933 33.4045 159.69 36.54C160.419 37.7327 160.684 39.1516 160.435 40.527C160.186 41.9023 159.44 43.1383 158.34 44C158.204 42.1309 157.507 40.3465 156.34 38.88C156.34 38.88 151.68 48.42 132.34 37.27"
          fill="black"
        />
        <path
          id="Vector_49"
          d="M115.71 55.48C116.78 59.4 119.86 61.98 122.59 61.26C125.32 60.54 126.65 56.78 125.59 52.87C124.53 48.96 121.44 46.37 118.71 47.09C115.98 47.81 114.64 51.57 115.71 55.48Z"
          fill="#DB9C7F"
        />
        <path
          id="Vector_50"
          d="M140.79 56.79C140.74 57.79 141.22 58.64 141.86 58.67C142.5 58.7 143.06 57.92 143.11 56.91C143.16 55.9 142.68 55.06 142.04 55.02C141.4 54.98 140.84 55.78 140.79 56.79Z"
          fill="black"
        />
        <path
          id="Vector_51"
          d="M154.21 57.62C154.11 58.56 154.49 59.38 155.07 59.44C155.65 59.5 156.19 58.78 156.29 57.84C156.39 56.9 156.01 56.08 155.43 56.02C154.85 55.96 154.31 56.67 154.21 57.62Z"
          fill="black"
        />
        <path
          id="Vector_52"
          d="M136.96 48.81C136.96 48.81 141.57 49.81 141.88 45.42"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path
          id="Vector_53"
          d="M152.57 48.45C152.57 48.45 153.75 52.51 157.21 50.16"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
        <path id="Vector_54" d="M139.99 73.08C139.99 73.08 138.47 66.9 133.75 70.44L139.99 73.08Z" fill="black" />
        <path
          id="Vector_55"
          d="M118.45 52.08C118.45 52.08 121.51 49.44 123.18 54.26C123.18 54.26 121.39 56.16 122.52 57.65C122.017 57.7562 121.493 57.7024 121.021 57.4962C120.549 57.2901 120.154 56.9418 119.89 56.5"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_56"
          d="M119.77 53.92C119.995 53.0424 120.467 52.2477 121.13 51.63"
          stroke="black"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_57"
          d="M151.57 57.47C151.57 60.33 153.33 62.65 155.5 62.65C157.67 62.65 159.43 60.33 159.43 57.47C159.43 54.61 157.67 52.28 155.5 52.28C153.33 52.28 151.57 54.6 151.57 57.47Z"
          stroke="white"
          strokeMiterlimit="10"
        />
        <path
          id="Vector_58"
          d="M136.83 56.76C136.927 57.7217 137.3 58.6347 137.905 59.3886C138.51 60.1425 139.32 60.705 140.238 61.008C141.156 61.3109 142.142 61.3414 143.076 61.0956C144.011 60.8499 144.855 60.3385 145.505 59.6233C146.155 58.9081 146.584 58.0199 146.74 57.066C146.895 56.1121 146.771 55.1335 146.383 54.2486C145.994 53.3638 145.357 52.6105 144.549 52.0801C143.741 51.5497 142.797 51.2648 141.83 51.26C140.439 51.33 139.132 51.9477 138.195 52.9784C137.258 54.009 136.767 55.3686 136.83 56.76Z"
          stroke="white"
          strokeMiterlimit="10"
        />
        <path
          id="Vector_59"
          d="M151.72 58.97C151.72 58.97 150.51 53.8 146.56 58.6"
          stroke="white"
          strokeMiterlimit="10"
        />
        <path id="Vector_60" d="M136.93 54.67L125.3 52.05" stroke="white" strokeMiterlimit="10" />
        <path
          id="Vector_61"
          d="M152.21 61.25C152.49 61.6048 152.675 62.0253 152.747 62.4717C152.818 62.918 152.775 63.3753 152.62 63.8C152.458 64.2478 152.157 64.632 151.762 64.8965C151.366 65.161 150.896 65.2919 150.42 65.27C149.98 65.2433 149.546 65.1558 149.13 65.01C148.94 64.95 148.58 64.76 148.38 64.84C147.38 65.27 146.31 64.43 146.63 63.78"
          stroke="black"
          strokeMiterlimit="10"
          strokeLinecap="round"
        />
      </g>
    </svg>
  );
}
