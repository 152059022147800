//^ hooks
import { useEffect } from "react";
import useTimer from "../../../../../hooks/use-timer";
import { Typography } from "@mui/material";

export default function ResendTimer({ onOtpTimer, resendContentNm = "OTP" }) {
  const { isCompleted, sec, min } = useTimer({ min: 1 });

  useEffect(() => {
    if (isCompleted) {
      onOtpTimer(isCompleted);
    } else {
      onOtpTimer(isCompleted);
    }
    // eslint-disable-next-line
  }, [isCompleted]);

  return (
    <Typography variant="subtitle2" className="sub-text">
      Resend {resendContentNm} in {min}:{sec < 10 ? `0${sec}` : sec}
    </Typography>
  );
}
