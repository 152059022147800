import Head from "../../layout/head/Head";

export default function Invitations() {
  return (
    <>
      <Head title={`${process.env.REACT_APP_PROJECT_SHORT_NAME} Invitations`} />
      Invitations
    </>
  );
}
