import React from "react";
import ErrorImage from "../../images/gfx/error-404.svg";
import { Link } from "react-router-dom";
import { Block, BlockContent, Button } from "../../components/Component";

const Error404Modern = ({
  title = "Oops! Why you’re here?",
  description = "We are very sorry for inconvenience. It looks like you’re try to access a page that either has been deleted or never existed.",
  backLink = `${process.env.PUBLIC_URL}/`,
}) => {
  return (
    <>
      <Block className="nk-block-middle wide-md mx-auto">
        <BlockContent className="nk-error-ld text-center">
          <img className="nk-error-gfx" src={ErrorImage} alt="error" />
          <div className="wide-xs mx-auto">
            <h3 className="nk-error-title">{title}</h3>
            <p className="nk-error-text">{description}</p>
            <Link to={backLink}>
              <Button color="primary" size="lg" className="mt-2">
                Back To Home
              </Button>
            </Link>
          </div>
        </BlockContent>
      </Block>
    </>
  );
};
export default Error404Modern;
