import { toast } from "react-toastify";
import { getCookie } from "../utils/Utils";
export const fetchProfileAPI = async (formData, route) => {
  let res = await fetch(`${process.env.REACT_APP_API_URL}api/v1/${route}`, {
    method: "POST",
    cache: "no-store",
    body: formData,
    headers: {
      authToken: getCookie("authToken", null),
    },
  });
  return await res.json();
};
export const setNotification = async (e, Nkey, loggedInUser, setData) => {
  const { checked } = e.target;

  let value = `${Nkey}__${checked ? "1" : "0"}`;

  setData({
    ...loggedInUser,
    notifications: {
      ...loggedInUser.notifications,
      [Nkey]: checked ? "1" : "0",
    },
  });

  let formData = new FormData();
  formData.append("notifications", value);
  let res = await fetchProfileAPI(formData, "account/set-profile");
  if (res.status) {
    setData({
      ...loggedInUser,
      notifications: {
        ...loggedInUser.notifications,
        [Nkey]: checked ? "1" : "0",
      },
    });
    toast.success("Notification status updated");
  } else {
    getUserprofile(setData);
  }
};
export const getUserprofile = async (setData) => {
  let formData = new FormData();
  let res = await fetchProfileAPI(formData, "account/get-profile");
  if (res.status) {
    var notifications = res.data.profile.notifications;
    setData({
      first_name: res.data.profile.first_name,
      last_name: res.data.profile.last_name,
      phone_number: res.data.profile.phone_number,
      dob: res.data.profile.dob,
      address_1: res.data.profile.address_1,
      address_2: res.data.profile.address_2,
      notifications: notifications,
      state: res.data.profile.state,
      country: res.data.profile.country,
      country_value: res.data.profile.country_value,
      account_tokens: res.data.account_tokens,
      about_me: res.data?.profile?.bio,
    });
  }
};
