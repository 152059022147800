import React from "react";
import { Row, Col } from "../../components/Component";
import { Link } from "react-router-dom";

import colors from "../../assets/scss/_themes-vars.module.scss";

//^ mui components
import { useTheme } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

const AuthFooter = () => {
  const theme = useTheme();
  const lgMatches = useMediaQuery("(min-width: 65rem)");

  return (
    <div
      className="nk-footer nk-auth-footer-full"
      style={{
        width: "100%",
        background:
          theme.palette.mode === "dark"
            ? colors.darkModeColor
            : lgMatches
            ? colors.bodyBackgroundColor
            : colors.bodyBackgroundColor,
      }}
    >
      <div className="container wide-lg">
        <Row className="g-3">
          <Col lg={6} className="order-lg-last">
            <ul className="nav nav-sm justify-content-center justify-content-lg-end">
              <li className="nav-item d-none">
                <Link target="_blank" className="nav-link" to={`/terms`}>
                  Terms &amp; Conditions
                </Link>
              </li>
              <li className="nav-item d-none">
                <Link target="_blank" className="nav-link" to={`/privacy`}>
                  Privacy Policy
                </Link>
              </li>
              <li className="nav-item d-none">
                <Link className="nav-link" to={`#`}>
                  Help
                </Link>
              </li>
            </ul>
          </Col>
          <Col lg="6">
            <div className="nk-block-content text-center text-lg-start">
              <p className="text-soft">
                &copy;{new Date().getFullYear()} {process.env.REACT_APP_PROJECT_NAME}. All Rights Reserved.
              </p>
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};
export default AuthFooter;
