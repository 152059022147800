import React, { useState } from "react";
import { useMutation } from "@tanstack/react-query";

//^ utils
import { responseErrorHandler, shortenString } from "../../../utils/Utils";

//^ @mui components
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListSubheader,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { AccessTimeRounded, PersonAddRounded } from "@mui/icons-material";

//^ http requests
import { followUserHandler, requestConnectionHandler } from "../../../http/post-api";

//^ custom components
import UserListSkeletonLoader from "../../../components/ui/loader/Skeleton/UserListSkeletonLoader";
import { TooltipComponent } from "../../../components/Component";
import ImageWithPlaceholder from "../../../components/ui/auth-otp/image/image-with-placeholder";
import { useSelector } from "react-redux";
import { userNetworkAction } from "../../../store/slices/user-networks-slice";
import { useDispatch } from "react-redux";

function ResolutionBasedUserList({ showUserProfileHandler, handleFollowUserHandler, view, ...props }) {
  const followAndConnectionOnHomeMutationProgress = useSelector((state) => state.userNetwork.followAndConnectionOnHomeMutationProgress);

  return (
    <Box
      sx={{
        display: view === "sm" ? "block" : "none",
        "@media (min-width: 64rem)": {
          display: view === "sm" ? "none" : "block",
        },
      }}
    >
      <Card
        variant={view === "sm" ? "elevation" : "outlined"}
        sx={
          view === "sm"
            ? {
              width: "100%",
              border: "none",
              background: "none",
              boxShadow: "none",
              borderRadius: "0.325rem",
            }
            : {
              width: "100%",
              borderRadius: "0.325rem",
            }
        }
      >
        <List
          sx={{
            width: "100%",
          }}
          component={view === "sm" ? "div" : Paper}
          elevation={view === "sm" ? 0 : 0.6}
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component={view === "sm" ? "div" : Paper}
              elevation={view === "sm" ? 0 : 0.6}
              sx={
                view === "sm"
                  ? {
                    boxShadow: "none",
                    background: "none",
                    paddingLeft: "0",
                  }
                  : {
                    boxShadow: "none",
                    background: "none",
                  }
              }
              id="nested-list-subheader"
            >
              <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <span>{props.text}</span>
                <Button size="small" onClick={props.seeAllOnclick}>
                  See all
                </Button>
              </Stack>
            </ListSubheader>
          }
        >
          <>
            <Divider />
            <ListItem
              sx={{
                width: "100%",
                padding: "1rem 0rem 1rem 0rem",
                "@media (min-width: 64rem)": {
                  padding: "0rem 1rem",
                },
                "@media (min-width: 768px)": {
                  padding: "1rem 1rem",
                },
              }}
            >
              <Box width={"100%"} sx={props.userListGridStyle}>
                {props.props?.userListIsLoading ? (
                  <UserListSkeletonLoader />
                ) : props.props?.notFollowedPeoples?.length > 0 ? (
                  props.props?.notFollowedPeoples?.map((user, index) => {
                    return (
                      <Stack
                        alignItems={"center"}
                        justifyContent={"center"}
                        sx={{
                          cursor: "pointer",
                        }}
                      >
                        <Card
                          key={index}
                          variant="outlined"
                          elevation={3}
                          sx={{
                            height: "100%",
                            width: "100%",
                            borderRadius: "0.725rem",
                          }}
                        >
                          <Stack height={"100%"} justifyContent={"space-between"} gap={0}>
                            <Stack gap={3} onClick={() => showUserProfileHandler(user.user_id, props.type)}>
                              <Stack position={"relative"} width={"100%"}>
                                <Box
                                  width={"100%"}
                                  height={"6rem"}
                                  sx={{
                                    background: user?.cover_img,
                                  }}
                                />
                                <Stack
                                  direction={"row"}
                                  position={"absolute"}
                                  zIndex={1}
                                  bottom={"-2rem"}
                                  width={"100%"}
                                  alignItems={"center"}
                                  justifyContent={"center"}
                                >
                                  <Box>
                                    <ImageWithPlaceholder
                                      src={user?.avatar_url}
                                      alt={user?.username}
                                      width={88}
                                      height={88}
                                      showAvatarPlaceholder={true}
                                    />
                                  </Box>
                                </Stack>
                              </Stack>
                              <CardContent>
                                <Stack alignItems={"center"} justifyContent={"flex-start"} marginTop={"0.625rem"}>
                                  {user?.username?.length > 12 ? (
                                    <TooltipComponent
                                      type="text"
                                      content={
                                        <Typography
                                          textTransform={"capitalize"}
                                          textAlign={"center"}
                                          gutterBottom
                                          variant="h6"
                                          component="div"
                                        >
                                          {shortenString(user?.username, 12)}
                                        </Typography>
                                      }
                                      direction="top"
                                      id={`notification-${Math.random().toString(36).substr(2, 9)}`}
                                      text={user?.username}
                                    />
                                  ) : (
                                    <Typography
                                      textTransform={"capitalize"}
                                      textAlign={"center"}
                                      gutterBottom
                                      variant="h6"
                                      component="div"
                                    >
                                      {user?.username}
                                    </Typography>
                                  )}
                                </Stack>
                              </CardContent>
                            </Stack>
                            <CardActions
                              sx={{
                                padding: "1.25rem",
                                paddingTop: "0rem",
                                width: "100%",
                              }}
                            >
                              <Stack gap={1} alignItems={"center"} width={"100%"}>
                                {user?.mutualConnect > 0 ? (
                                  <Typography>{user?.mutual_connection} mutual connection</Typography>
                                ) : (
                                  ""
                                )}

                                {user?.connection_status &&
                                  (user?.connection_status?.status === "0" ? (
                                    <Button
                                      startIcon={<AccessTimeRounded />}
                                      variant={`outlined`}
                                      size="small"
                                      type="button"
                                      sx={{
                                        minWidth: "7rem",
                                        borderRadius: "10rem",
                                      }}
                                      onClick={() =>
                                        handleFollowUserHandler({
                                          userId: user.user_id,
                                          index: index,
                                        })
                                      }
                                      disabled={true}
                                      endIcon={
                                        (props.isPending || props.requestConnectionIsPending) &&
                                        props.buttonClickIdx === index && <CircularProgress size={16} color="inherit" />
                                      }
                                    >
                                      Pending
                                    </Button>
                                  ) : (
                                    <Button
                                      startIcon={<PersonAddRounded />}
                                      variant={`contained`}
                                      size="small"
                                      type="button"
                                      sx={{
                                        minWidth: "7rem",
                                        borderRadius: "10rem",
                                      }}
                                      onClick={() =>
                                        handleFollowUserHandler({
                                          userId: user.user_id,
                                          index: index,
                                        })
                                      }
                                      disabled={
                                        (props.buttonClickIdx === index &&
                                          (props.isPending || props.requestConnectionIsPending)) || followAndConnectionOnHomeMutationProgress
                                      }
                                      endIcon={
                                        (props.isPending || props.requestConnectionIsPending) &&
                                        props.buttonClickIdx === index && <CircularProgress size={16} color="inherit" />
                                      }
                                    >
                                      {props.buttonText}
                                    </Button>
                                  ))}
                                {!user?.connection_status && (
                                  <Button
                                    startIcon={<PersonAddRounded />}
                                    variant={`contained`}
                                    size="small"
                                    type="button"
                                    sx={{
                                      minWidth: "7rem",
                                      borderRadius: "10rem",
                                    }}
                                    onClick={() =>
                                      handleFollowUserHandler({
                                        userId: user.user_id,
                                        index: index,
                                      })
                                    }
                                    disabled={
                                      (props.buttonClickIdx === index &&
                                        (props.isPending || props.requestConnectionIsPending)) || followAndConnectionOnHomeMutationProgress
                                    }
                                    endIcon={
                                      (props.isPending || props.requestConnectionIsPending) &&
                                      props.buttonClickIdx === index && <CircularProgress size={16} color="inherit" />
                                    }
                                  >
                                    {props.buttonText}
                                  </Button>
                                )}
                              </Stack>
                            </CardActions>
                          </Stack>
                        </Card>
                      </Stack>
                    );
                  })
                ) : (
                  <Stack
                    width={"100%"}
                    direction={"row"}
                    alignItems={"center"}
                    justifyContent={"center"}
                    gridColumn={"span 5"}
                  >
                    <Typography>{props.emptyText}</Typography>
                  </Stack>
                )}
              </Box>
            </ListItem>
          </>
        </List>
      </Card>
    </Box>
  );
}

export default function UserList({
  text,
  crossType,
  seeAllOnclick,
  buttonText,
  dispatch,
  type,
  followLimit,
  connectLimit,
  followersFetchNextPage,
  getUserFetchNextPage,
  followersHasNextPage,
  getUserHasNextPage,
  limit,
  userLimit,
  initialLimit,
  emptyText,
  ...props
}) {
  const followAndConnectionOnHomeMutationProgress = useSelector((state) => state.userNetwork.followAndConnectionOnHomeMutationProgress);

  const reduxDispatch = useDispatch();

  const [cancelUserText, setCancelUserText] = useState("");
  const [buttonClickIdx, setButtonClickIdx] = useState(undefined);
  const [userId, setUserId] = useState(undefined);

  const lgMatches = useMediaQuery("(min-width: 64rem)");
  const tabletMatches = useMediaQuery("(min-width: 48rem)");
  const smMatches = useMediaQuery("(min-width: 23.4375rem)");

  //^ follow user mutation fn ================================================================

  const { isPending, isError, error, mutate, reset } = useMutation({
    mutationKey: ["follow-user"],
    mutationFn: followUserHandler,
    onSuccess: (data) => {
      if (data.toast) {
        if (!data.status) {
          responseErrorHandler(true, data);
        } else {
          if (followersHasNextPage) {
            props.followersRefetch();
            dispatch({ type, user_id: userId });
            if (cancelUserText.trim().length > 0) {
              setCancelUserText("");
            }
          }
        }
      }
      reset();
    },
    onSettled: () => {
      reduxDispatch(userNetworkAction.setNetworkMutationProgressValue({ stateValue: "followAndConnectionOnHomeMutationProgress", status: false }));
    }
  });

  React.useEffect(() => {
    if (isError) {
      responseErrorHandler(isError, error);
      reset();
    }
  }, [isError, error, reset]);

  //^ follow user mutation fn ================================================================

  //^ connection mutation fn ================================================================

  const {
    isPending: requestConnectionIsPending,
    isError: requestConnectionIsError,
    error: requestConnectionError,
    mutate: requestConnectionMutate,
    reset: requestConnectionReset,
  } = useMutation({
    mutationKey: ["request-connection"],
    mutationFn: requestConnectionHandler,
    onSuccess: (data) => {
      if (data?.toast) {
        if (data?.status) {
          dispatch({ type, user_id: userId });
        } else {
          responseErrorHandler(true, requestConnectionError);
        }
      }
      requestConnectionReset();
    },
    onSettled: () => {
      reduxDispatch(userNetworkAction.setNetworkMutationProgressValue({ stateValue: "followAndConnectionOnHomeMutationProgress", status: false }));
    }
  });

  React.useEffect(() => {
    if (requestConnectionIsError) {
      responseErrorHandler(true, requestConnectionIsError);

      requestConnectionReset();
    }
  }, [requestConnectionIsError, requestConnectionError, requestConnectionReset]);

  //^ connection mutation fn ================================================================

  function handleFollowUserHandler({ userId, index }) {
    if (followAndConnectionOnHomeMutationProgress) return;
    reduxDispatch(userNetworkAction.setNetworkMutationProgressValue({ stateValue: "followAndConnectionOnHomeMutationProgress", status: true }));

    setUserId(userId);
    setButtonClickIdx(index);
    if (type === "FOLLOW_USER") {
      mutate({ userId: userId, type: "single", status: 1 });

      if (cancelUserText.trim().length > 0) {
        setCancelUserText("");
      }
    } else if (type === "CONNECT_USER") {
      requestConnectionMutate({ user_id: userId });
      if (cancelUserText.trim().length > 0) {
        setCancelUserText("");
      }
    }
  }

  const userListGridStyle = {
    display: "grid",
    gridTemplateColumns: lgMatches
      ? "repeat(5, 1fr)"
      : tabletMatches
        ? "repeat(2, 1fr)"
        : smMatches
          ? "1fr 1fr"
          : "1fr",
    gap: "1rem",
    alignItems: "center",
    justifyContent: "center",
  };

  function showUserProfileHandler(userId, category) {
    const userProfileData = props?.notFollowedPeoples?.find((user) => user.user_id === userId);

    dispatch({
      type: "USER_PROFILE",
      user_id: userId,
      showUserProfile: true,
      userCategory: category,
      userLists: props?.notFollowedPeoples,
      userProfileData: userProfileData,
    });
  }

  return (
    <>
      <ResolutionBasedUserList
        text={text}
        seeAllOnclick={seeAllOnclick}
        buttonText={buttonText}
        type={type}
        emptyText={emptyText}
        props={props}
        buttonClickIdx={buttonClickIdx}
        isPending={isPending}
        requestConnectionIsPending={requestConnectionIsPending}
        userListGridStyle={userListGridStyle}
        showUserProfileHandler={showUserProfileHandler}
        handleFollowUserHandler={handleFollowUserHandler}
        view={"lg"}
      />
      <ResolutionBasedUserList
        text={text}
        seeAllOnclick={seeAllOnclick}
        buttonText={buttonText}
        type={type}
        emptyText={emptyText}
        props={props}
        buttonClickIdx={buttonClickIdx}
        isPending={isPending}
        requestConnectionIsPending={requestConnectionIsPending}
        userListGridStyle={userListGridStyle}
        showUserProfileHandler={showUserProfileHandler}
        handleFollowUserHandler={handleFollowUserHandler}
        view={"sm"}
      />
    </>
  );
}
