import React from "react";
import classNames from "classnames";

const Button = React.forwardRef(({ color, size, className, outline, disabled, onClick, ...props }, ref) => {
  const buttonClass = classNames({
    btn: true,
    [`btn-${color}`]: !outline,
    [`btn-outline-${color}`]: outline,
    [`btn-${size}`]: size,
    disabled: disabled,
    [`${className}`]: className,
  });
  return (
    <button ref={ref} className={buttonClass} onClick={onClick} {...props}>
      {props.children}
    </button>
  );
});
export default Button;
