import React from "react";
import { getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import SimpleBar from "simplebar-react";

//^ mui components
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { useTheme } from "@mui/material/styles";
import { useInView } from "react-intersection-observer";

//^ skeleton
import RequestListSkeleton from "../../../components/ui/loader/Skeleton/follow-list-skeleton";

//^ no result placeholder
import NoUsers from "../../../components/svg/account-network/no-invitations";
import UserListSkeleton from "../../../components/ui/loader/Skeleton/follow-list-skeleton";
import NoUserDark from "../../../components/svg/account-network/no-users-dark";

function RequestList({
  table,
  columns,
  getInvitationIsFetchingNextPage,
  getInvitationFetchNextPage,
  getInvitationHasNextPage,
  pagination,
}) {
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
  const { ref, inView } = useInView();
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  React.useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    if (inView && getInvitationHasNextPage) {
      getInvitationFetchNextPage();
    }

    // eslint-disable-next-line
  }, [inView, getInvitationFetchNextPage]);

  const adjustedHeight = screenHeight - 21.9 * parseFloat(getComputedStyle(document.documentElement).fontSize);

  return (
    <>
      <SimpleBar style={{ maxHeight: `${adjustedHeight}px`, width: "100%" }}>
        {table.getRowModel()?.rows?.length ? (
          table.getRowModel()?.rows?.map((row) =>
            row?.getVisibleCells()?.map((cell, index) => {
              if (row?.getVisibleCells()?.length === index - 1) {
                <Box
                  ref={ref}
                  key={cell.id}
                  sx={{
                    display:
                      cell.getContext().column.id === "username" ||
                      cell.getContext().column.id === "lastName" ||
                      cell.getContext().column.id === "created_at" ||
                      cell.getContext().column.id === "email"
                        ? "none"
                        : "",
                  }}
                >
                  <Typography variant="subtitle2" fontWeight={400}>
                    {cell.getValue()}
                  </Typography>
                </Box>;
              }

              return (
                <Box
                  key={cell.id}
                  sx={{
                    display:
                      cell.getContext().column.id === "username" ||
                      cell.getContext().column.id === "lastName" ||
                      cell.getContext().column.id === "created_at" ||
                      cell.getContext().column.id === "email"
                        ? "none"
                        : "",
                  }}
                >
                  <Typography variant="subtitle2" fontWeight={400}>
                    {cell.getValue()}
                  </Typography>
                </Box>
              );
            })
          )
        ) : getInvitationIsFetchingNextPage ? (
          ""
        ) : (
          <Box colSpan={columns.length} sx={{ height: "30rem", padding: "1.25rem" }}>
            <Stack alignItems={"center"} justifyContent={"center"} height={"100%"}>
              {isDark ? <NoUserDark /> : <NoUsers />}
            </Stack>
          </Box>
        )}
        {getInvitationHasNextPage && pagination === 1 && (
          <Stack ref={ref} direction={"row"} padding={"1.25rem"} width={"100%"}>
            {getInvitationIsFetchingNextPage ? (
              <Stack width={"100%"}>
                <UserListSkeleton padding length={1} />
              </Stack>
            ) : (
              ""
            )}
          </Stack>
        )}
      </SimpleBar>
    </>
  );
}

export default function DataTable({
  columns,
  isLoading,
  getInvitationIsFetchingNextPage,
  getInvitationFetchNextPage,
  getInvitationHasNextPage,
  searchComponent,
  isRefetching,
  pagination,
}) {
  const invitationData = useSelector((state) => state.userNetwork.invitations);
  const requestUsersValue = useSelector((state) => state.userNetwork.requestValues);

  const [connectionFilter, setConnectionFilter] = React.useState("none");
  const [sorting, setSorting] = React.useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const requestTab = searchParams.get("requestTab") || "sent";

  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const addFollowTypeParam = ({ value, type = "append" }) => {
    if (type === "append") {
      const params = new URLSearchParams(searchParams);
      params.append("requestTab", value);
      setSearchParams(params.toString());
    } else if (type === "set") {
      const params = new URLSearchParams(searchParams);
      params.set("requestTab", value);
      setSearchParams(params.toString());
    }
  };

  React.useEffect(() => {
    if (requestTab === null) {
      addFollowTypeParam({ value: "sent" });
    } else {
      addFollowTypeParam({ value: requestTab, type: "set" });
    }
    // eslint-disable-next-line
  }, []);

  // const pageSizes = [10, 20, 30, 40, 50];

  const table = useReactTable({
    data: invitationData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  function handleStatusSelectChange(event) {
    const recentValues = event.target.value;

    setConnectionFilter(recentValues);
    if (recentValues !== "none") {
      setSorting([
        {
          id: recentValues,
          asc: true,
        },
      ]);
    } else {
      setSorting([]);
    }
  }
  function handleFollowListTabChange(_event, value) {
    if (value !== requestTab) {
      addFollowTypeParam({ value, type: "set" });
    }
  }

  return (
    <Box>
      <Box
        sx={{
          display: "none",
          "@media (min-width: 65.5rem)": {
            display: "block",
          },
        }}
      >
        <Paper
          variant={isDark ? "elevation" : "outlined"}
          elevation={isDark ? 0.6 : 3}
          sx={{ padding: "1.25rem", borderRadius: "0.425rem", width: "100%" }}
        >
          <Stack gap={2}>
            <Stack gap={2}>
              <Stack
                gap={2}
                sx={{
                  "@media (min-width: 65.5rem)": {
                    justifyContent: "flex-end",
                    alignItems: "center",
                    flexDirection: "row",
                  },
                }}
              >
                {searchComponent ? searchComponent : ""}
              </Stack>
            </Stack>
            <Stack>
              <TabContext value={requestTab === null ? "" : requestTab}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={requestTab === null ? "" : requestTab}
                    onChange={handleFollowListTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    aria-label="scrollable auto tabs example"
                  >
                    {requestUsersValue?.map((requestUsers, index) => (
                      <Tab
                        key={index}
                        label={
                          <Stack alignItems={"center"} direction={"row"} gap={2}>
                            <Typography variant="subtitle2" fontWeight={400} textTransform={"capitalize"}>
                              {requestUsers?.label}
                            </Typography>
                            <Chip
                              sx={{ minWidth: "1.25rem", height: "1.45rem" }}
                              label={`${requestUsers?.amount || 0}`}
                            />
                          </Stack>
                        }
                        value={requestUsers?.value}
                      />
                    ))}
                  </Tabs>
                </Box>

                <TabPanel sx={{ padding: "0rem" }} value={"sent"}>
                  {isLoading || (invitationData?.length === 0 && isRefetching) ? (
                    <RequestListSkeleton />
                  ) : (
                    <RequestList
                      table={table}
                      columns={columns}
                      getInvitationFetchNextPage={getInvitationFetchNextPage}
                      getInvitationHasNextPage={getInvitationHasNextPage}
                      getInvitationIsFetchingNextPage={getInvitationIsFetchingNextPage}
                      pagination={pagination}
                    />
                  )}
                </TabPanel>
                <TabPanel sx={{ padding: "0rem" }} value={"received"}>
                  {isLoading || (invitationData?.length === 0 && isRefetching) ? (
                    <RequestListSkeleton />
                  ) : (
                    <RequestList
                      table={table}
                      columns={columns}
                      getInvitationFetchNextPage={getInvitationFetchNextPage}
                      getInvitationHasNextPage={getInvitationHasNextPage}
                      getInvitationIsFetchingNextPage={getInvitationIsFetchingNextPage}
                      pagination={pagination}
                    />
                  )}
                </TabPanel>
              </TabContext>
            </Stack>
          </Stack>
        </Paper>
      </Box>
      <Box
        sx={{
          display: "block",
          "@media (min-width: 65.5rem)": {
            display: "none",
          },
        }}
      >
        <Stack gap={2}>
          <Stack gap={2}>
            <Stack
              gap={2}
              sx={{
                "@media (min-width: 65.5rem)": {
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                },
              }}
            >
              <Stack direction={"row"} gap={2} alignItems={"center"}>
                <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                  <InputLabel id="status-outlined-select-label">Sort By</InputLabel>
                  <Select
                    size="small"
                    value={connectionFilter}
                    onChange={handleStatusSelectChange}
                    labelId="status-outlined-select-label"
                    id="status-outlined-select"
                    label="Sort by"
                  >
                    <MenuItem value="none">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"username"}>
                      <Typography variant="subtitle2">Username</Typography>
                    </MenuItem>
                    <MenuItem value={"created_at"}>
                      <Typography variant="subtitle2">Recent</Typography>
                    </MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              {searchComponent}
            </Stack>
          </Stack>
          <Stack>
            <TabContext value={requestTab === null ? "" : requestTab}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={requestTab === null ? "" : requestTab}
                  onChange={handleFollowListTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label="Received" value={"received"} />
                  <Tab label="sent" value={"sent"} />
                </Tabs>
              </Box>

              <TabPanel sx={{ padding: "0rem" }} value={"sent"}>
                {isLoading || (invitationData?.length === 0 && isRefetching) ? (
                  <RequestListSkeleton />
                ) : (
                  <RequestList
                    table={table}
                    columns={columns}
                    getInvitationFetchNextPage={getInvitationFetchNextPage}
                    getInvitationHasNextPage={getInvitationHasNextPage}
                    getInvitationIsFetchingNextPage={getInvitationIsFetchingNextPage}
                    pagination={pagination}
                  />
                )}
              </TabPanel>
              <TabPanel sx={{ padding: "0rem" }} value={"received"}>
                {isLoading || (invitationData?.length === 0 && isRefetching) ? (
                  <RequestListSkeleton />
                ) : (
                  <RequestList
                    table={table}
                    columns={columns}
                    getInvitationFetchNextPage={getInvitationFetchNextPage}
                    getInvitationHasNextPage={getInvitationHasNextPage}
                    getInvitationIsFetchingNextPage={getInvitationIsFetchingNextPage}
                    pagination={pagination}
                  />
                )}
              </TabPanel>
            </TabContext>
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
}
