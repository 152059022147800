import React from "react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

//^ mui
import {
  Box,
  Pagination,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  useMediaQuery,
  Button,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

//^ style variables
import styleVar from "../../assets/scss/_themes-vars.module.scss";
import Search from "../../components/search";
import { FilterAltRounded } from "@mui/icons-material";

export default function DataTable({ columns, data, isCompact = false, searchPlaceholder, searchKey }) {
  const theme = useTheme();
  const lgMatches = useMediaQuery("(min-width: 64rem)");
  // eslint-disable-next-line
  const isDark = theme.palette.mode === "dark";

  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = React.useState([]);
  const [status, setStatus] = React.useState("none");
  const [showFilter, setShowFilter] = React.useState(false);

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
  });

  function paginationChangeHandler(_event, page) {
    const actualPage = page - 1;
    table.setPageIndex(actualPage);
  }

  const pageSizes = [10, 20, 30, 40, 50];

  function handleStatusSelectChange(event) {
    const status = event.target.value;

    if (status !== "none") {
      setShowFilter(true);
    }

    setStatus(status);
    table.getColumn("status-disabled")?.setFilterValue(status === "none" ? "" : status);
  }

  function clearFilterHandler() {
    table.getColumn(searchKey)?.setFilterValue("");
    setStatus("none");
    setShowFilter(false);
    table.getColumn("status-disabled")?.setFilterValue("");
    table.setPageIndex(0);
    table.getState().pagination.pageSize = 10;
  }

  return (
    <>
      <Box>
        <Stack gap={"1rem"}>
          <Box>
            <Stack
              gap={"1rem"}
              justifyContent={"space-between"}
              sx={{
                "@media (min-width: 65.5rem)": {
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "0rem",
                },
              }}
            >
              <Stack
                gap={2}
                sx={{
                  "@media (min-width: 65.5rem)": {
                    flexDirection: "row",
                    alignItems: "center",
                  },
                }}
              >
                <Search
                  placeholder={searchPlaceholder}
                  value={table.getColumn(searchKey)?.getFilterValue() ?? ""}
                  onChange={(event) => {
                    if (event.target.value?.length > 0) {
                      setShowFilter(true);
                    }
                    table.getColumn(searchKey)?.setFilterValue(event.target.value);
                  }}
                  width={"20ch"}
                />
                <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                  <InputLabel id="status-outlined-select-lable">Status</InputLabel>
                  <Select
                    size="small"
                    value={status}
                    onChange={handleStatusSelectChange}
                    labelId="status-outlined-select-lable"
                    id="status-outlined-select"
                    label="Status"
                  >
                    <MenuItem value="none">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"active"}>Active</MenuItem>
                    <MenuItem value={"expired"}>Expired</MenuItem>
                    <MenuItem value={"upcoming"}>Upcoming</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction={lgMatches ? "row" : "column"} gap={2}>
                {showFilter && (
                  <Button variant="outlined" onClick={clearFilterHandler} startIcon={<FilterAltRounded />}>
                    Clear all filters
                  </Button>
                )}
                <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                  <InputLabel id="show-page-outlined-select-lable">Show Page</InputLabel>
                  <Select
                    value={table.getState().pagination.pageSize}
                    onChange={(e) => {
                      if (e.target.value !== 10) {
                        setShowFilter(true);
                      }
                      table.setPageSize(Number(e.target.value));
                    }}
                    labelId="show-page-outlined-select-lable"
                    id="show-page-outlined-select"
                    label="Show Page"
                    sx={{ height: "2.5rem" }}
                  >
                    {pageSizes.map((pageSize, index) => (
                      <MenuItem key={index} value={pageSize}>
                        Show {pageSize}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Stack>
          </Box>

          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" size={isCompact ? "small" : "medium"}>
              <TableHead>
                {table.getHeaderGroups().map((headerGroup) => {
                  return (
                    <>
                      <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          return (
                            <TableCell
                              style={{
                                display:
                                  header.getContext().column.id === "status-disabled" ||
                                  header.getContext().column.id === "plan-hidden"
                                    ? "none"
                                    : "",
                              }}
                              key={header.id}
                              // onClick={header.column.getToggleSortingHandler()}
                            >
                              <Typography variant="subtitle2">
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(header.column.columnDef.header, header.getContext())}

                                {/* {{ asc: "⬆️", desc: "⬇️" }[header.column.getIsSorted() ?? null]} */}
                              </Typography>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </>
                  );
                })}
              </TableHead>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableCell
                            style={{
                              display:
                                cell.getContext().column.id === "status-disabled" ||
                                cell.getContext().column.id === "plan-hidden"
                                  ? "none"
                                  : "",
                            }}
                            key={cell.id}
                          >
                            {row.calories}
                            <Typography variant="subtitle2" fontWeight={400}>
                              {cell.getValue()}
                            </Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} style={{ height: "6rem" }}>
                      <Stack alignItems={"center"} justifyContent={"center"} height={"100%"}>
                        <Typography
                          textTransform={"capitalize"}
                          style={{ color: styleVar.errorMain }}
                          fontWeight={"bold"}
                        >
                          No results.
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Stack direction={"row"} alignItems={"center"} style={{ gap: "1rem" }}></Stack>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"row"} gap={"1rem"}>
              <Pagination
                count={table.getPageCount()}
                onChange={paginationChangeHandler}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
            <Stack direction={"row"} gap={"0.3rem"}>
              <div>Page</div>
              <strong>
                {table.getState().pagination.pageIndex + 1} of {table.getPageCount().toLocaleString()}
              </strong>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
