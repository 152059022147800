import React, { useEffect } from "react";
//^ Table data
import { useQuery } from "@tanstack/react-query";
import { getServices } from "../../http/get-api";
import { columns } from "./columns";
import { DataTable } from "./data-table";
import DataTableSkeletonLoader from "../../components/ui/loader/Skeleton/DataTableSkeletonLoader";
import { Stack } from "@mui/material";
import EmptyFolder from "../../components/svg/empty-folder";
import { responseErrorHandler } from "../../utils/Utils";

export default function ServiceTable() {
  const [services, setServices] = React.useState([]);

  const {
    data: getServicesData,
    isLoading: getServicesIsLoading,
    isRefetching: getServicesIsRefetching,
    isError: getServicesIsError,
    error: getServicesError,
  } = useQuery({
    queryKey: ["get-services"],
    queryFn: getServices,
    gcTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!getServicesIsLoading || !getServicesIsRefetching) {
      if (!getServicesData?.status) {
        if (getServicesData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        }
      } else {
        const serviceFilter = getServicesData?.data?.services.map((service, index) => {
          let count = index;
          count++;

          return {
            srNo: count,
            serviceName: service?.name,
            serviceId: service.id,
            package: {
              name: service?.user_service_status?.package,
            },
            status: service?.user_service_status?.status === "Active" ? "active" : service?.user_service_status?.status,
            subscription: service?.user_service_status?.renew,
            endDate: service?.user_service_status?.end_date,
            plan: {
              name: service?.user_service_status?.plan,
              color: service?.user_service_status?.styles?.hexCode,
            },
            link: service?.link,
            isFree: service?.is_free,
          };
        });

        setServices(serviceFilter || []);
      }
    }
  }, [getServicesData, getServicesIsLoading, getServicesIsRefetching]);

  useEffect(() => {
    responseErrorHandler(getServicesIsError, getServicesError);
  }, [getServicesIsError, getServicesError]);

  return (
    <>
      <div>
        {getServicesIsLoading ? (
          <DataTableSkeletonLoader />
        ) : getServicesData ? (
          <DataTable data={services || []} columns={columns} isCompact={true} />
        ) : (
          <Stack width={"100%"} height={"100%"} alignItems={"center"} justifyContent={"center"}>
            <EmptyFolder />
          </Stack>
        )}
      </div>
    </>
  );
}
