import { useSearchParams } from "react-router-dom";
import { BottomNavigationAction, BottomNavigation as MuiBottomNavigation, Paper } from "@mui/material";

export default function BottomNavigations({ actions, value, setValue, sx, ...props }) {
  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const tab = searchParams.get("tab");

  const handleChange = (event, newValue) => {
    setValue(newValue);

    if (newValue === null) {
      setSearchParams({});
    } else {
      setSearchParams({ tab: newValue });
    }
  };

  return (
    <MuiBottomNavigation component={Paper} sx={sx} value={tab} onChange={handleChange} {...props}>
      {actions.map((action, index) => (
        <BottomNavigationAction key={index} label={action?.label} value={action?.value} icon={action.icon} />
      ))}
    </MuiBottomNavigation>
  );
}
