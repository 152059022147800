import React from "react";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";

//^ mui
import { Box, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

//^ utils
import { isEmptyObject, responseErrorHandler } from "../../utils/Utils";

//^ http request
import { getInvoiceListHandler } from "../../http/post-api";

//^ components
import Head from "../../layout/head/Head";
import InvoicesTable from "../../tables/invoices/table";
import PackageMembershipCard from "../../components/invoices/package-membership-card/PackageMembershipCard";
import PackageMembershipSkeletonLoader from "../../components/ui/loader/Skeleton/PackageMembershipSkeletonLoader";

export default function AccountInvoicesPage() {
  const theme = useTheme();
  const lgMatches = useMediaQuery("(min-width: 64rem)");
  const [packageMembership, setPackageMembership] = React.useState({ currentPackage: {}, upcomingPackage: {} });

  const arePackagesEmpty = (packages) => {
    return !(isEmptyObject(packages.currentPackage) && isEmptyObject(packages.upcomingPackage));
  };

  const {
    data: getInvoiceListData,
    isLoading: getInvoiceListIsLoading,
    isRefetching: getInvoiceListIsRefetching,
    isError: getInvoiceListIsError,
    error: getInvoiceListError,
    refetch: getInvoiceListRefetch,
  } = useQuery({
    queryKey: ["get-invoice-list"],
    queryFn: getInvoiceListHandler,
    refetchOnWindowFocus: false,
    gcTime: 0,
    staleTime: Infinity,
  });

  React.useEffect(() => {
    // Ensure data is loaded and not refetching
    if (!getInvoiceListIsLoading && !getInvoiceListIsRefetching) {
      const invoiceData = getInvoiceListData?.data?.currentInvoiceSummary;

      if (!getInvoiceListData?.status) {
        // Handle error case
        setPackageMembership({ currentPackage: {}, upcomingPackage: {} });
        return;
      }

      if (!invoiceData) return; // Early return if no invoice data

      // Function to format date using Moment.js
      const formatDate = (date) => moment(date, "YYYY-MM-DD").format("DD, MMM YYYY");

      // Initialize package objects
      const currentPackage = invoiceData.is_package
        ? {
            ...invoiceData.current_package,
            start_date: formatDate(invoiceData.current_package.start_date),
            end_date: formatDate(invoiceData.current_package.end_date),
            price: `$${invoiceData?.current_package?.price}`,
          }
        : {};

      const upcomingPackage = invoiceData.is_upcoming
        ? {
            ...invoiceData.upcoming_package,
            start_date: formatDate(invoiceData.upcoming_package.start_date),
            end_date: formatDate(invoiceData.upcoming_package.end_date),
            price: `$${invoiceData?.upcoming_package?.price}`,
          }
        : {};

      // Update state with formatted package membership data
      setPackageMembership({ currentPackage, upcomingPackage });
    }
  }, [getInvoiceListData, getInvoiceListIsLoading, getInvoiceListIsRefetching]);

  React.useEffect(() => {
    responseErrorHandler(getInvoiceListIsError, getInvoiceListError);
  }, [getInvoiceListIsError, getInvoiceListError]);

  return (
    <Box>
      <Head title="Invoices" />
      {/* <Card
        variant={lgMatches ? "elevation" : "outlined"}
        sx={
          lgMatches ? { padding: theme.spacing(2), borderRadius: "0.625rem" } : { border: "none", background: "none" }
        }
        elevation={isDark ? 0.5 : 2}
      > */}
      <Stack gap={4} sx={lgMatches ? { padding: `0 ${theme.spacing(1)}` } : {}}>
        {getInvoiceListIsLoading || getInvoiceListIsRefetching ? (
          <PackageMembershipSkeletonLoader />
        ) : (
          <PackageMembershipCard
            arePackagesEmpty={arePackagesEmpty(packageMembership)}
            package={packageMembership}
            isPackage={getInvoiceListData?.data?.currentInvoiceSummary?.is_package}
            isUpcomingPackage={getInvoiceListData?.data?.currentInvoiceSummary?.is_upcoming}
          />
        )}
        <InvoicesTable
          getInvoiceListData={getInvoiceListData}
          getInvoiceListError={getInvoiceListError}
          getInvoiceListIsError={getInvoiceListIsError}
          getInvoiceListIsLoading={getInvoiceListIsLoading}
          getInvoiceListIsRefetching={getInvoiceListIsRefetching}
          getInvoiceListRefetch={getInvoiceListRefetch}
        />
      </Stack>
      {/* </Card> */}
    </Box>
  );
}
