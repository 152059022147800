import React, { Suspense, useEffect, useState } from "react";
import { Outlet } from "react-router";
import { useQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

//^ http request
import { getUserListsSummaryHandler } from "../../http/post-api";

//^ redux actions
import { userNetworkAction } from "../../store/slices/user-networks-slice";

//^ @mui component
import { PeopleAltRounded, PersonRounded, GroupAddRounded, GroupsRounded, HomeRounded } from "@mui/icons-material";
import { Box, Skeleton, Stack, Tab, Tabs, Typography } from "@mui/material";
import { TabContext } from "@mui/lab";

//^ components
import BottomNavigations from "../../components/navigations/bottom-navigations";
import Head from "../head/Head";
import UserAccountLoader from "../../components/ui/loader/Skeleton/user-account-loader";
import AccountSkeletonLoader from "../../components/ui/loader/Skeleton/account-skeleton-loader";
// import { responseErrorHandler } from "../../utils/Utils";

export default function AccountUserLayout() {
  const dispatch = useDispatch();

  const [userNavList, setUserNavList] = useState([]);
  const [bottomNavVal, setBottomNavVal] = useState("");

  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const tabName = searchParams.get("tab") || "";

  const {
    data: userListSummaryData,
    isLoading: userListSummaryIsLoading,
    // refetch: userListSummaryRefetch,
  } = useQuery({
    queryKey: ["get-user-list-summary"],
    queryFn: getUserListsSummaryHandler,
  });

  useEffect(() => {
    if (!userListSummaryIsLoading) {
      if (!userListSummaryData?.status) {
        if (userListSummaryData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        } else {
          // responseErrorHandler(true, userListSummaryData, false);
        }
      } else {
        const navList = userListSummaryData?.data?.summary.map((summary) => ({
          label: summary?.label === "Following & Follower" ? "Followers" : summary?.label,
          value: summary.label,
          icon:
            summary?.label === "Connections" ? (
              <PeopleAltRounded />
            ) : summary?.label === "Requests" ? (
              <GroupAddRounded />
            ) : summary?.label === "Following & Follower" ? (
              <PersonRounded />
            ) : summary?.label === "Invitations" ? (
              <GroupsRounded />
            ) : (
              ""
            ),
        }));
        navList.unshift({
          label: "Home",
          value: null,
          icon: <HomeRounded />,
        });

        const findConnectionUsers = userListSummaryData?.data?.summary?.find(
          (summary) => summary?.label === "Connections"
        );
        const findRequestedUsers = userListSummaryData?.data?.summary?.find((summary) => summary?.label === "Requests");
        const findFollowerUsers = userListSummaryData?.data?.summary?.find(
          (summary) => summary?.label === "Following & Follower"
        );

        dispatch(
          userNetworkAction.setSummaryValues({
            connectionValues: findConnectionUsers?.values || [],
            requestValues: findRequestedUsers?.values || [],
            followersValues: findFollowerUsers?.values || [],
          })
        );

        setUserNavList(navList);
      }
    }
    // eslint-disable-next-line
  }, [userListSummaryIsLoading, userListSummaryData]);

  function handleAccountUserTabChange(_event, value) {
    if (value !== tabName) {
      setSearchParams({ tab: value });
      if (value === "") {
        setSearchParams({});
      }
    }
  }

  return (
    <>
      <Head title={`${process.env.REACT_APP_PROJECT_SHORT_NAME} Networks`} />
      <Stack height={"100%"}>
        {userListSummaryIsLoading && (
          <Box
            sx={{
              display: "block",
              "@media (min-width: 65.5rem)": {
                display: "none",
              },
            }}
          >
            <UserAccountLoader />
          </Box>
        )}
        <Stack
          width={"100%"}
          height={"100%"}
          gap={2}
          sx={{
            paddingBottom: "4.25rem",
            display: userListSummaryIsLoading ? "none" : "block",
            "@media (min-width: 65.5rem)": {
              paddingBottom: "0rem",
              display: "block",
            },
          }}
        >
          <Stack
            sx={{
              display: "none",
              "@media (min-width: 65.5rem)": {
                display: "block",
              },
            }}
          >
            <TabContext value={tabName}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                {userListSummaryIsLoading ? (
                  <>
                    <Tabs
                      value={tabName}
                      onChange={handleAccountUserTabChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      allowScrollButtonsMobile
                      aria-label="scrollable auto tabs example"
                    >
                      {Array.from({ length: 4 }).map((_, index) => (
                        <Tab
                          key={index}
                          label={
                            <Stack direction={"row"} alignItems={"center"} spacing={1}>
                              <Skeleton variant="circular" sx={{ width: "1.15rem", height: "1.15rem" }} />
                              <Skeleton variant="text" sx={{ width: "3rem" }} />
                            </Stack>
                          }
                          value={""}
                        />
                      ))}
                    </Tabs>
                  </>
                ) : (
                  <Tabs
                    value={tabName}
                    onChange={handleAccountUserTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab
                      label={
                        <Stack direction={"row"} alignItems={"center"} spacing={1}>
                          <HomeRounded sx={{ fontSize: "1.15rem" }} />
                          <Typography>{"Home"}</Typography>
                        </Stack>
                      }
                      value={""}
                    />
                    {userListSummaryData?.data?.summary?.map((userSummary, index) => (
                      <Tab
                        key={index}
                        label={
                          <Stack direction={"row"} alignItems={"center"} spacing={1}>
                            {userSummary?.label === "Connections" ? (
                              <PeopleAltRounded sx={{ fontSize: "1.15rem" }} />
                            ) : userSummary?.label === "Requests" ? (
                              <GroupAddRounded sx={{ fontSize: "1.15rem" }} />
                            ) : userSummary?.label === "Following & Follower" ? (
                              <PersonRounded sx={{ fontSize: "1.15rem" }} />
                            ) : userSummary?.label === "Invitations" ? (
                              <GroupsRounded sx={{ fontSize: "1.15rem" }} />
                            ) : (
                              ""
                            )}
                            <Typography>{userSummary?.label}</Typography>
                          </Stack>
                        }
                        value={userSummary?.label}
                      />
                    ))}
                  </Tabs>
                )}
              </Box>
            </TabContext>
          </Stack>
          <Box
            height={"100%"}
            width={"100%"}
            gap={2}
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr",
              "@media (min-width: 65.5rem)": {
                gridTemplateColumns: "1fr",
                direction: "row",
              },
            }}
          >
            <Box
              sx={{
                display: "none",
                "@media (min-width: 65.5rem)": {
                  display: "block",
                },
              }}
            ></Box>
            <Stack width={"100%"}>
              <Suspense fallback={<AccountSkeletonLoader />}>
                <Outlet />
              </Suspense>
            </Stack>
          </Box>
        </Stack>
        {!userListSummaryIsLoading ? (
          <BottomNavigations
            showLabels
            value={bottomNavVal}
            setValue={setBottomNavVal}
            elevation={1}
            sx={{
              width: "100%",
              position: "fixed",
              bottom: 0,
              left: 0,
              zIndex: 100,
              "@media (min-width: 65.5rem)": {
                display: "none",
              },
              borderRadius: "0rem",
            }}
            actions={userNavList}
          />
        ) : (
          ""
        )}
      </Stack>
    </>
  );
}
