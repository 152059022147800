import React, { Suspense, useLayoutEffect, lazy } from "react";
import { Routes, Route, useLocation } from "react-router-dom";

//^ layouts
import UserLayoutApp from "../layout/user/user-layout-app";
import AccountUserLayout from "../layout/account-user-layout";
import UserProfileSettingLayout from "../layout/user-profile-setting/UserProfileSettingLayout";

//^ pages
import Login from "../pages/auth/Login";
import Register from "../pages/auth/Register";
import ForgotPassword from "../pages/auth/ForgotPassword";
import Success from "../pages/auth/Success";
import Logout from "../pages/auth/Logout";
import VerifyAccount from "../pages/auth/VerifiedAccount";
import ResetPassword from "../pages/auth/ResetPassword";

import LayoutNoSidebar from "../layout/Index-nosidebar";

//^ user pages
import UserProfileRegular from "../pages/pre-built/user-manage/UserProfileRegular";
import UserProfileSetting from "../pages/pre-built/user-manage/UserProfileSetting";
import TwoFactorSettings from "../pages/pre-built/user-manage/TwoFactorSettings/TwoFactorSettings";
import UserProfileNotification from "../pages/pre-built/user-manage/UserProfileNotification";

//^ invoice pages
import Invoice from "../pages/invoices/Invoice";

//^ service pages
import ServicePlans from "../pages/service/service-plans";
import SubscriptionPackageSkeleton from "../components/ui/loader/Skeleton/SubscriptionPackageSkeleton";
import ServiceInvoice from "../pages/service/service-invoice";
import PackageSubscriptionDetailSkeleton from "../components/ui/loader/Skeleton/packageSubscriptionDetailSkeleton";

// import AccountSkeletonLoader from "../components/ui/loader/Skeleton/account-skeleton-loader";
import OutletSkeleton from "../components/ui/loader/Skeleton/OutletSkeleton";
import { CircularProgress, Stack } from "@mui/material";

//^ user-profile
import OTPHome from "../pages/auth/otp/OTPHome";

//^ account users
import AccountUsers from "../pages/account-users/users-page";
import Connections from "../pages/account-users/connections";
import FollowersAndFollowing from "../pages/account-users/FollowersAndFollowing";
import Requests from "../pages/account-users/Requests";
import Invitations from "../pages/account-users/Invitations";

// errors
import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Classic from "../pages/error/504-classic";
import Error504Modern from "../pages/error/504-modern";

// user page

import ServicePlanDetails from "../pages/service/service-plans-details";

import SubscriptionsPage from "../pages/subscriptions/Subscriptions";
import SubscriptionPackageDetails from "../pages/pre-built/package-subscription/SubscriptionPackageDetails";

// eslint-disable-next-line
function RouterLoader() {
  return (
    <Stack width={"100%"} height={"100%"} alignItems={"center"} justifyContent={"center"} padding={"2rem"}>
      <CircularProgress />
    </Stack>
  );
}

const RoutePages = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route
        path="logout"
        element={<Logout />}
        errorElement={
          <Suspense fallback={<OutletSkeleton />}>
            <Error404Modern />
          </Suspense>
        }
      />

      {/* <Route
        path={`${process.env.PUBLIC_URL}`}
        element={<LayoutApp app={{ icon: "user", theme: "bg-success-dim", text: "Account" }} />}
        errorElement={<Error404Modern />}
      >
        <Route path="user-profile-activity" element={<UserProfileActivity />} />
      </Route> */}

      {/* <Route
        path={`${process.env.PUBLIC_URL}`}
        element={<LayoutApp app={{ icon: "user", theme: "bg-success-dim", text: "Account" }} />}
      >
        <Route path="apps" element={<App />} />
      </Route> */}

      <Route
        path={`${process.env.PUBLIC_URL}`}
        element={<UserLayoutApp app={{ icon: "user", theme: "bg-success-dim", text: "Account" }} />}
        errorElement={
          <Suspense fallback={<OutletSkeleton />}>
            <Error404Modern />
          </Suspense>
        }
      >
        {/* <Route path="user-profile">
          <Route path="view">
            <Route index={true} element={<ViewUserProfile />} />
            <Route path={":userId"} element={<ViewUserProfile />} />
          </Route>
        </Route> */}

        {/* 
            //^ user personal information route
        */}
        <Route path="user-profile-regular" element={<UserProfileRegular />} />
        {/* 
            //^ user settings routes
        */}
        <Route path="user-profile-setting" element={<UserProfileSettingLayout />}>
          <Route index={true} element={<UserProfileSetting />} />
          <Route path="two-factor-setting" element={<TwoFactorSettings />} />
          <Route path="user-profile-notification" element={<UserProfileNotification />} />
        </Route>
        <Route path="subscriptions">
          <Route
            index={true}
            element={
              <Suspense fallback={<SubscriptionPackageSkeleton />}>
                <SubscriptionsPage />
              </Suspense>
            }
          />
          {/* 
            //^ packages subscription routes
          */}
          <Route path="subscription-package-details/:plan_id">
            <Route
              index={true}
              element={
                <Suspense fallback={<PackageSubscriptionDetailSkeleton />}>
                  <SubscriptionPackageDetails />
                </Suspense>
              }
            />
            <Route path="print-invoice/:invoiceId" element={<Invoice />} />
          </Route>
          {/* 
            //^ services plan subscription routes
          */}

          <Route path="service-plan/:subscription/:serviceId">
            <Route index={true} element={<ServicePlans />} />
            <Route path=":planId">
              <Route
                index={true}
                element={
                  <Suspense fallback={<PackageSubscriptionDetailSkeleton />}>
                    <ServicePlanDetails />
                  </Suspense>
                }
              />
              <Route path="print-invoice/:serviceInvoiceId" element={<ServiceInvoice />} />
            </Route>
          </Route>
        </Route>
        {/* 
          //^ account-user routes
        */}
        <Route path="account-users" element={<AccountUserLayout />}>
          <Route index={true} element={<AccountUsers />} />
          <Route path={"connections"} element={<Connections />} />
          <Route path={"followers"} element={<FollowersAndFollowing />} />
          <Route path={"requests"} element={<Requests />} />
          <Route path={"invitations"} element={<Invitations />} />
        </Route>
      </Route>

      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
        <Route index element={<Login />} />
        <Route path="login" element={<Login />} />
        <Route
          path="verification/:username"
          element={
            <React.Suspense fallback={<OutletSkeleton />}>
              <OTPHome />
            </React.Suspense>
          }
        />
        <Route path="reset-password/:username" element={<ResetPassword />} />
        <Route path="register">
          <Route index={true} element={<Register />} />
          <Route path=":referCode" element={<Register />} />
        </Route>
        <Route path="verify-account/:token/:code" element={<VerifyAccount type="verify" />} exact />
        <Route path="reset-password/:token/:code" element={<VerifyAccount type="reset" />} exact />
        <Route path="reset" element={<ForgotPassword />} />
        <Route path="success" element={<Success />} />

        <Route path="errors">
          <Route
            path="404-modern"
            element={
              <Suspense fallback={<OutletSkeleton />}>
                <Error404Modern />
              </Suspense>
            }
          />
          <Route
            path="404-classic"
            element={
              <Suspense fallback={<OutletSkeleton />}>
                <Error404Classic />
              </Suspense>
            }
          />
          <Route
            path="504-modern"
            element={
              <Suspense fallback={<OutletSkeleton />}>
                <Error504Modern />
              </Suspense>
            }
          />
          <Route
            path="504-classic"
            element={
              <Suspense fallback={<OutletSkeleton />}>
                <Error504Classic />
              </Suspense>
            }
          />
        </Route>
        <Route path="*" element={<Login />} />
      </Route>
    </Routes>
  );
};
export default RoutePages;
