import { motion } from "framer-motion";
import ServiceInvoiceComp from "./service-invoice-comp";

export default function ServiceInvoice() {
  return (
    <motion.div initial={{ scale: 0.8 }} animate={{ scale: 1 }}>
      <ServiceInvoiceComp />
    </motion.div>
  );
}
