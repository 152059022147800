import React from "react";
import { useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";

//^ @mui components
import {
  Avatar,
  Box,
  Button,
  Card,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

//^ utils
import { getInitials, responseErrorHandler } from "../../../utils/Utils";

//^ http request
import { acceptConnectionHandler, ignoreInvitationHandler, removeInvitationHandler } from "../../../http/post-api";

//^ custom components
import InviteListLoader from "../../../components/ui/loader/Skeleton/InviteListLoader";
import CircularButton from "../../../components/ui/button/CircularButton";
import UserList from "./user-list";
import addNotification from "react-push-notification";
// import Search from "../../../components/search";
// import useDebounce from "../../../hooks/use-debounce";

function InviteUserLists({ view, ...props }) {
  const [showInvitation, setShowInvitation] = React.useState(false);

  const {
    isError: ignoreInviteIsError,
    error: ignoreInviteError,
    mutate: ignoreInviteMutate,
    reset: ignoreInviteReset,
  } = useMutation({
    mutationKey: ["ignore-invitation"],
    mutationFn: ignoreInvitationHandler,
    onSuccess: (data) => {
      if (data?.toast) {
        if (data?.status) {
          addNotification({
            title: "Invitation Ignored",
            message: data?.message,
            native: true,
          });
          ignoreInviteReset();
        } else {
          responseErrorHandler(true, data);
        }
      }
    },
  });

  React.useEffect(() => {
    responseErrorHandler(ignoreInviteIsError, ignoreInviteError);
  }, [ignoreInviteError, ignoreInviteIsError]);

  React.useEffect(() => {
    if (props.props?.inviteLists?.length <= 0 && !props.props?.inviteIsLoading) {
      setShowInvitation(true);
    }
  }, [props.props?.inviteLists, props.props?.inviteIsLoading]);

  return !showInvitation ? (
    <Box
      sx={{
        display: view === "sm" ? "block" : "none",
        "@media (min-width: 65.5rem)": {
          display: view === "sm" ? "none" : "block",
        },
      }}
    >
      <Card
        variant={view === "sm" ? "elevation" : "outlined"}
        sx={
          view === "sm"
            ? {
                borderRadius: "0.325rem",
                width: "100%",
                boxShadow: view === "sm" ? "none" : "unset",
                background: "transparent",
              }
            : {
                borderRadius: "0.325rem",
                width: "100%",
              }
        }
      >
        <List
          sx={
            view === "sm"
              ? {
                  boxShadow: "none",
                  background: "transparent",
                }
              : {
                  width: "100%",
                }
          }
          component={Paper}
          elevation={0.6}
          aria-labelledby="nested-list-subheader"
          subheader={
            <ListSubheader
              component={Paper}
              elevation={0.6}
              sx={
                view === "sm"
                  ? {
                      boxShadow: "none",
                      background: "transparent",
                    }
                  : {
                      boxShadow: "none",
                    }
              }
              id="nested-list-subheader"
            >
              <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                <span>Invitations</span>
                {props.props?.inviteLists?.length > 0 && (
                  <Button
                    onClick={() =>
                      props.setSearchParams({
                        tab: "Requests",
                        requestTab: "received",
                      })
                    }
                    size="small"
                  >
                    See all
                  </Button>
                )}
              </Stack>
            </ListSubheader>
          }
        >
          <Divider />
          {props.props?.inviteIsLoading ? (
            <InviteListLoader />
          ) : props.props?.inviteLists?.length > 0 ? (
            props.props?.inviteLists?.map((inviteList, index) => (
              <React.Fragment key={index}>
                <ListItem>
                  <ListItemText
                    primary={
                      <Stack
                        width={"100%"}
                        gap={2}
                        sx={{
                          "@media (min-width: 65.5rem)": {
                            flexDirection: "row",
                            justifyContent: "space-between",
                          },
                        }}
                      >
                        <Stack direction={"row"} gap={2} alignItems={"center"}>
                          <Avatar>{getInitials(inviteList?.username || "")}</Avatar>
                          <Typography variant="subtitle1" textTransform={"capitalize"}>
                            {inviteList?.username}
                          </Typography>
                        </Stack>
                        <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"} gap={2}>
                          <Button
                            variant="text"
                            size="small"
                            onClick={() => {
                              const connectionId = inviteList?.connection_id;
                              ignoreInviteMutate({ connectionId });
                              props.dispatch({
                                type: "IGNORE_INVITATION",
                                connection_id: connectionId,
                              });
                              props.dispatch({
                                type: "SET_LIMIT",
                                limit: props.inviteLimit,
                              });
                            }}
                          >
                            ignore
                          </Button>
                          <CircularButton
                            variant="contained"
                            sx={{
                              maxWidth: "6rem",
                            }}
                            size="small"
                            onClick={() => {
                              const connectionId = inviteList?.connection_id;
                              props.acceptConnectMutate({
                                connection_id: connectionId,
                              });
                              props.dispatch({
                                type: "IGNORE_INVITATION",
                                connection_id: connectionId,
                              });
                              props.dispatch({
                                type: "SET_LIMIT",
                                limit: props.inviteLimit,
                              });
                            }}
                            text={"Accept"}
                          />
                        </Stack>
                      </Stack>
                    }
                  />
                </ListItem>
                <Divider />
              </React.Fragment>
            ))
          ) : (
            <Stack padding={"1.25rem"} direction={"row"} alignItems={"center"} justifyContent={"center"}>
              <Typography>No Invitations Found</Typography>
            </Stack>
          )}
        </List>
      </Card>
    </Box>
  ) : (
    ""
  );
}

export default function HomeTab({
  notConnectedUsers,
  notConnectedIsLoading,
  dispatch,
  userInvitationFetchNextPage,
  userInvitationHasNextPage,
  userInvitationRefetch,
  followersFetchNextPage,
  followersHasNextPage,
  getUserFetchNextPage,
  getUserHasNextPage,
  inviteInitialLimit,
  followerInitialLimit,
  connectionInitialLimit,
  ...props
}) {
  const invitedUsersLen = props?.inviteLists?.length;
  const notFollowedUsersLen = props?.notFollowedPeoples?.length;
  const notConnectedUsersLen = notConnectedUsers?.length;

  const inviteLimit =
    invitedUsersLen === 0
      ? inviteInitialLimit
      : invitedUsersLen === inviteInitialLimit
      ? 1
      : inviteInitialLimit - invitedUsersLen;
  const followerLimit =
    notFollowedUsersLen === 0
      ? followerInitialLimit
      : notFollowedUsersLen === followerInitialLimit
      ? 1
      : followerInitialLimit - notFollowedUsersLen;
  const connectionLimit =
    notConnectedUsersLen === 0
      ? connectionInitialLimit
      : notConnectedUsersLen === connectionInitialLimit
      ? 1
      : connectionInitialLimit - notConnectedUsersLen;

  // eslint-disable-next-line
  const [_, setSearchParams] = useSearchParams();

  //^ Ignore mutation query fn =================================================================

  const {
    // isPending: removeInvitationIsPending,
    isError: removeInvitationIsError,
    error: removeInvitationError,
    mutate: removeInvitationMutate,
    reset: removeInvitationReset,
  } = useMutation({
    mutationKey: ["ignore-received-invitation"],
    mutationFn: removeInvitationHandler,
    onSuccess: (data) => {
      if (data?.toast) {
        if (data?.status) {
          addNotification({
            title: "Invitation Ignored",
            message: data?.message,
            native: true,
          });
          if (userInvitationHasNextPage) {
            userInvitationFetchNextPage();
          }

          // toast.success(data?.message);
        } else {
          responseErrorHandler(true, data);
        }
      }
      removeInvitationReset();
    },
  });

  React.useEffect(() => {
    if (removeInvitationIsError) {
      responseErrorHandler(true, removeInvitationError);
      removeInvitationReset();
    }
  }, [removeInvitationIsError, removeInvitationError, removeInvitationReset]);

  //^ Ignore mutation query fn =================================================================

  //^ Accept mutation query fn =================================================================

  const {
    // isPending: acceptConnectIsPending,
    isError: acceptConnectIsError,
    error: acceptConnectError,
    mutate: acceptConnectMutate,
    reset: acceptConnectReset,
  } = useMutation({
    mutationKey: ["accept-connection"],
    mutationFn: acceptConnectionHandler,
    onSuccess: (data) => {
      if (data?.toast) {
        if (!data?.status) {
          responseErrorHandler(true, data);
        } else {
          addNotification({
            title: "Invitation Accepted",
            message: data?.message,
            native: true,
          });
          if (userInvitationHasNextPage) {
            userInvitationFetchNextPage();
          }
        }
      }
      acceptConnectReset();
    },
  });

  React.useEffect(() => {
    if (acceptConnectIsError) {
      responseErrorHandler(true, acceptConnectError);
      acceptConnectReset();
    }
  }, [acceptConnectIsError, acceptConnectError, acceptConnectReset]);
  // const [searchText, setSearchText] = React.useState(undefined);
  // const [pagination, setPagination] = React.useState(1);

  // const debounceSearch = useDebounce({ value: searchText, delay: 200 });

  // function handleSearchChange(event) {
  //   const text = event.target.value;
  //   console.log(text);

  //   if (text?.trim()?.length <= 0) {
  //     setSearchText(undefined);
  //     setPagination(1);
  //   } else {
  //     setSearchText(text);
  //     setPagination(0);
  //   }
  // }

  //^ Accept mutation query fn =================================================================

  return (
    <>
      <Stack spacing={1} width={"100%"}>
        {/* <Box display="flex" justifyContent="flex-end">
          <Search width={"20ch"} placeholder={"Search..."} onChange={handleSearchChange} />
        </Box> */}
        <InviteUserLists
          dispatch={dispatch}
          props={props}
          inviteLimit={inviteLimit}
          setSearchParams={setSearchParams}
          removeInvitationMutate={removeInvitationMutate}
          acceptConnectMutate={acceptConnectMutate}
          view={"lg"}
        />
        <InviteUserLists
          dispatch={dispatch}
          props={props}
          inviteLimit={inviteLimit}
          setSearchParams={setSearchParams}
          removeInvitationMutate={removeInvitationMutate}
          acceptConnectMutate={acceptConnectMutate}
          view={"sm"}
        />
        <UserList
          type={"FOLLOW_USER"}
          crossType={"FOLLOW_USER"}
          dispatch={dispatch}
          text={`People you may want to follow`}
          userListIsLoading={props.userListIsLoading}
          notFollowedPeoples={props.notFollowedPeoples}
          buttonText={"follow"}
          limit={"SET_FOLLOW_LIMIT"}
          userLimit={followerLimit}
          followersRefetch={props.followersRefetch}
          followersFetchNextPage={followersFetchNextPage}
          followersHasNextPage={followersHasNextPage}
          seeAllOnclick={() => setSearchParams({ tab: "Following & Follower", followType: "not_followed" })}
          initialLimit={followerInitialLimit}
          emptyText={"No more users to be followed"}
        />
        <UserList
          type={"CONNECT_USER"}
          initialLimit={connectionInitialLimit}
          crossType={"REMOVE_CONNECT"}
          text={"People you may want to connect with"}
          buttonText={"connect"}
          limit={"SET_CONNECT_LIMIT"}
          userLimit={connectionLimit}
          seeAllOnclick={() => setSearchParams({ tab: "Connections", connectType: "not_connected" })}
          dispatch={dispatch}
          userListIsLoading={notConnectedIsLoading}
          notFollowedPeoples={notConnectedUsers}
          getUserFetchNextPage={getUserFetchNextPage}
          getUserHasNextPage={getUserHasNextPage}
          emptyText={"No more users to be connected"}
        />
      </Stack>
    </>
  );
}
