import React from "react";
import { Box, useMediaQuery } from "@mui/material";

import UserListSkeletonLoader from "../UserListSkeletonLoader";

export default function AccountSkeletonLoader() {
  const lgMatches = useMediaQuery("(min-width: 64rem)");
  const tabletMatches = useMediaQuery("(min-width: 48rem)");
  const smMatches = useMediaQuery("(min-width: 23.4375rem)");

  const userListGridStyle = {
    display: "grid",
    gridTemplateColumns: lgMatches
      ? "repeat(5, 1fr)"
      : tabletMatches
      ? "repeat(2, 1fr)"
      : smMatches
      ? "1fr 1fr"
      : "1fr",
    gap: "1rem",
    alignItems: "center",
    justifyContent: "center",
  };

  return (
    <Box width={"100%"} sx={userListGridStyle}>
      {Array.from({ length: 2 }).map((_, index) => (
        <React.Fragment key={index}>
          <UserListSkeletonLoader />
        </React.Fragment>
      ))}
    </Box>
  );
}
