import { getRequest, postRequest } from "../../utils/Utils";

export async function getAllCountries() {
  return await getRequest(`${process.env.REACT_APP_API_URL}api/v1/public/countries`);
}

export async function getStates({ countryId: country_id }) {
  const url = `${process.env.REACT_APP_API_URL}api/v1/public/states?country_id=${country_id}`;

  return await getRequest(url);
}

export async function getCities({ stateId: state_id }) {
  const url = `${process.env.REACT_APP_API_URL}api/v1/public/cities?state_id=${state_id}`;

  return await getRequest(url);
}

export async function getIpv4IpAddress({ signal }) {
  return await getRequest(`https://api.ipify.org?format=json`);
}

export async function getUserProfileHandler({ user_id }) {
  let route = "account/get-profile";

  const url = `${process.env.REACT_APP_API_URL}api/v1/${route}`;
  const data = { user_id };
  return await postRequest(url, data);
}

//^ Subscription http requests =================================================================

export async function getPackagesSubscriptionHandler() {
  return getRequest(`${process.env.REACT_APP_API_URL}api/v1/subscription/get-package-list`);
}

//^ Subscription http requests =================================================================

//^ APPS http requests =================================================================

export async function getAppContent({ app_category = 1 }) {
  return await getRequest(`${process.env.REACT_APP_API_URL}api/v1/public/apps?app_category=${app_category}`);
}

//^ APPS http requests =================================================================

//^ Services http requests =================================================================

export async function getServices({ limit = 100 }) {
  const baseUrl = new URL(`${process.env.REACT_APP_API_URL}api/v1/subscription/get-services`);

  const queryParams = {
    limit: limit,
  };

  for (const [key, value] of Object.entries(queryParams)) {
    baseUrl.searchParams.append(key, value);
  }
  return await getRequest(baseUrl);
}

//^ Services http requests =================================================================
