import styleVar from "../../../assets/scss/_themes-vars.module.scss";

import { Typography } from "@mui/material";

export default function ErrorMessage({ errorMsg }) {
  return (
    <Typography
      component={"p"}
      fontWeight={"600"}
      sx={{ color: styleVar.errorMain }}
      fontStyle={"italic"}
      fontSize={"0.8rem"}
    >
      {errorMsg}
    </Typography>
  );
}
