import { Stack, Skeleton, Box } from "@mui/material";
import Separator from "../../separator/Separator";

export default function OutletSkeleton() {
  return (
    <Stack width={"100%"} spacing={4} padding={2}>
      <Box padding={"0rem 1.25rem"}>
        <Stack spacing={2}>
          <Skeleton variant="text" height={"4.25rem"} animation="pulse" />
          <Separator />
        </Stack>
      </Box>
      <Box padding={"1.25rem"}>
        <Stack spacing={4}>
          <Stack spacing={2}>
            <Skeleton variant="rounded" height={"4.25rem"} width={"100%"} animation="pulse" />
            <Skeleton variant="rounded" height={"3.25rem"} width={"90%"} animation="pulse" />
            <Skeleton variant="rounded" height={"2.25rem"} width={"80%"} animation="pulse" />
            <Skeleton variant="rounded" height={"2.25rem"} width={"70%"} animation="pulse" />
            <Skeleton variant="rounded" height={"2.25rem"} width={"40%"} animation="pulse" />
            <Skeleton variant="rounded" height={"1.85rem"} width={"50%"} animation="pulse" />
          </Stack>
          <Stack spacing={2}>
            <Skeleton variant="rectangular" width={"60%"} height={"1.25rem"} animation="pulse" />
            <Skeleton variant="rectangular" width={"80%"} height={"1.25rem"} animation="pulse" />
            <Skeleton variant="rectangular" width={"70%"} height={"1.25rem"} animation="pulse" />
            <Skeleton variant="rectangular" width={"60%"} height={"1.25rem"} animation="pulse" />
            <Skeleton variant="rectangular" width={"50%"} height={"1.25rem"} animation="pulse" />
            <Skeleton variant="rectangular" width={"40%"} height={"1.25rem"} animation="pulse" />
            <Skeleton variant="rectangular" width={"30%"} height={"1.25rem"} animation="pulse" />
            <Skeleton variant="rectangular" width={"20%"} height={"1.25rem"} animation="pulse" />
          </Stack>
        </Stack>
      </Box>
    </Stack>
  );
}
