import React, { useEffect, useState } from "react";
import Head from "../../../layout/head/Head";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Icon, PreviewCard } from "../../../components/Component";
import Logo from "../../../images/logo.png";
import LogoDark from "../../../images/logo-dark.png";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert } from "reactstrap";
import OTPHomeSkelaton from "./OTPHomeSkelaton";
import OTPSubComponent from "./OTPSubComponent";
import { toast } from "react-toastify";
import { fetchAPI, responseErrorHandler } from "../../../utils/Utils";
const OTPHome = () => {
  // eslint-disable-next-line
  const [errorVal, setError] = useState("");
  const [pageLoading, setPageLoading] = useState(true);
  // eslint-disable-next-line
  const [successVal, setSuccessVal] = useState("");
  const [emailVerifyLoading, setEmailVerifyLoading] = useState(false);
  const [smsVerifyLoading, setSmsVerifyLoading] = useState(false);
  const [subText, setSubText] = useState("OTP were sent to your contacts");
  const [userData, setUserData] = useState([]);
  const [email, setEmail] = useState("");
  const [country, setCountry] = useState("");
  const [phoneNo, setPhoneNo] = useState("");
  const params = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    isValidUser();
    // eslint-disable-next-line
  }, []);
  const verifyEmailOTP = async (otp) => {
    setEmailVerifyLoading(true);
    const url = `${process.env.REACT_APP_API_URL}api/v1/auth/verify-email-otp`;
    const formData = new FormData();
    formData.append("username", params.username);
    formData.append("otp", otp);
    formData.append("email", userData?.email_address);
    let verifyEmailOTPRes = await fetchAPI(url, formData);
    setEmailVerifyLoading(false);
    if (verifyEmailOTPRes === null) {
      toast.error("Something went wrong");
      return false;
    } else {
      if (verifyEmailOTPRes.status) {
        toast.success(verifyEmailOTPRes.message);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        responseErrorHandler(true, verifyEmailOTPRes);
      }
    }
  };
  const verifySMSOTP = async (otp) => {
    setSmsVerifyLoading(true);
    const url = `${process.env.REACT_APP_API_URL}api/v1/auth/verify-sms-otp`;
    const formData = new FormData();
    formData.append("username", params.username);
    formData.append("otp", otp);
    let verifySmsOTPRes = await fetchAPI(url, formData);
    setSmsVerifyLoading(false);
    if (verifySmsOTPRes === null) {
      toast.error("Something went wrong");
      return false;
    } else {
      if (verifySmsOTPRes.status) {
        toast.success(verifySmsOTPRes.message);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      } else {
        responseErrorHandler(true, verifySmsOTPRes);
      }
    }
  };
  const isValidUser = async () => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/auth/is-registered-user`;
    const formData = new FormData();
    formData.append("username", params.username);
    let isRegisteredUserRes = await fetchAPI(url, formData);
    if (isRegisteredUserRes === null) {
      toast.error("Something went wrong");
      return false;
    } else {
      if (isRegisteredUserRes.status) {
        setUserData(isRegisteredUserRes.data);
        if (
          isRegisteredUserRes?.data?.register_sms_otp === "0" &&
          isRegisteredUserRes?.data?.register_email_otp === "0"
        ) {
          toast.info("Please contact admin");
        } else {
          setPageLoading(false);
          const smsSent = isRegisteredUserRes?.data?.register_sms_otp === "1";
          const emailSent = isRegisteredUserRes?.data?.register_email_otp === "1";

          const destinations = [];
          if (smsSent) destinations.push(`phone no. ${isRegisteredUserRes?.data?.masked_phone_number}`);
          if (emailSent) destinations.push(`email ${isRegisteredUserRes?.data?.masked_email_address}`);

          setSubText((prev) => `OTP were sent to ${destinations.join(" and ")}`);

          setPhoneNo(isRegisteredUserRes?.data?.user_clean_phone);
          setEmail(isRegisteredUserRes?.data?.email_address);
          setCountry(isRegisteredUserRes?.data?.user_country_array);
        }
      } else {
        responseErrorHandler(true, isRegisteredUserRes);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    }
  };

  return (
    <>
      <Head title={`Verification`} />
      {!pageLoading ? (
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-2 text-center">
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" loading="lazy" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" loading="lazy" />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4" className={``}>
                  Account Verification
                </BlockTitle>
                <BlockDes className="">
                  <p>
                    Verify your {process.env.REACT_APP_PROJECT_NAME} account
                    <br />
                    <small>{subText}</small>
                  </p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {successVal && (
              <div className="mb-3">
                <Alert color="success" className="alert-icon">
                  <Icon name="alert-circle" /> {successVal}
                </Alert>
              </div>
            )}
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  <Icon name="alert-circle" /> {errorVal}
                </Alert>
              </div>
            )}
            {userData?.register_email_otp === "1" && (
              <OTPSubComponent
                label={`Email OTP`}
                resend_label={`Resend email OTP`}
                tabIndex={`1`}
                button_label={`Verify`}
                username={params.username}
                onVerify={verifyEmailOTP}
                verifyLoading={emailVerifyLoading}
                email={email}
                country={country}
                phone_number={phoneNo}
                type={`email`}
              />
            )}
            {userData?.register_sms_otp === "1" && (
              <OTPSubComponent
                label={`Phone OTP`}
                resend_label={`Resend phone OTP`}
                tabIndex={`2`}
                button_label={`Verify`}
                username={params.username}
                onVerify={verifySMSOTP}
                verifyLoading={smsVerifyLoading}
                email={email}
                country={country}
                phone_number={phoneNo}
                type={`phone`}
              />
            )}
            <div className="form-note-s2 text-center pt-4">
              New on our platform? <Link to={`${process.env.PUBLIC_URL}/register`}>Create an account</Link>
            </div>
          </PreviewCard>
        </Block>
      ) : (
        <OTPHomeSkelaton />
      )}
    </>
  );
};

export default OTPHome;
