import { useState, useEffect } from "react";

function useTimer({ min }) {
  const [time, setTime] = useState(min * 60);
  const [minLeft, setMinLeft] = useState(min);
  const [sec, setSec] = useState(0);
  const [amOrPm, setAmOrPm] = useState("AM");
  const [isTimerInMinutes, setIsTimerInMinutes] = useState(min > 0);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime <= 0) {
          clearInterval(timer);
          setIsCompleted(true);
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    setMinLeft(minutes);
    setSec(seconds);
    setAmOrPm(minutes < 12 ? "AM" : "PM");

    setIsTimerInMinutes(minLeft > 0 || (minLeft === 0 && sec > 0));
  }, [time, minLeft, sec]);

  return { min: minLeft, sec, amorpm: amOrPm, isTimerInMinutes, isCompleted };
}

export default useTimer;
