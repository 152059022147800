import React, { useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { v4 as uuid } from "uuid";

//^ redux action
import { userNetworkAction } from "../../../store/slices/user-networks-slice";

//^ http request
import { getUserListsHandler } from "../../../http/post-api";

//^ mui components
import { Box, Stack } from "@mui/material";

//^ tables content
import FollowerUserLists from "./data-table";
import { columns } from "./columns";
import Search from "../../../components/search";

//^ hooks
import useDebounce from "../../../hooks/use-debounce";
import { responseErrorHandler } from "../../../utils/Utils";

export default function Followers() {
  const [searchText, setSearchText] = useState(undefined);
  const [pagination, setPagination] = useState(1);

  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const followType = searchParams.get("followType") || "followed";

  const dispatch = useDispatch();
  const debounceSearch = useDebounce({ value: searchText, delay: 200 });

  const queryKey = [`get-${followType}-list`, followType, debounceSearch];
  const {
    data: followersData,
    isLoading: followersIsLoading,
    isRefetching: followersIsRefetching,
    isError: followersIsError,
    error: followersError,
    fetchNextPage: followersFetchNextPage,
    isFetchingNextPage: followersIsFetchingNextPage,
    hasNextPage: followersHasNextPage,
  } = useInfiniteQuery({
    queryKey,
    queryFn: (props) =>
      getUserListsHandler({
        queryProps: props,
        page: props.pageParam,
        type: followType,
        pagination,
        limit: 10,
        search: searchText,
      }),
    enabled: !!followType,
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.status ? allPages.length + 1 : undefined;
    },
  });

  React.useEffect(() => {
    if (!followersIsLoading || !followersIsRefetching) {
      dispatch(userNetworkAction.setUserInvitationsHandler({ type: "SET_FOLLOWER_QUERY_KEY", queryKey: queryKey }));

      if (followersData) {
        if (!followersData?.status) {
          if (followersData?.redirect) {
            window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [followersIsLoading, followersIsRefetching, followersData]);

  React.useEffect(() => {
    if (!followersIsLoading || !followersIsRefetching) {
      const flattenedData =
        followersData?.pages
          ?.flatMap((page) => {
            return page?.data?.list || [];
          })
          .filter((item) => item !== undefined) || [];

      if (followType !== null) {
        dispatch(
          userNetworkAction.setUserInvitationsHandler({
            type: "FOLLOWERS",
            followers: flattenedData.map((item) => {
              return {
                ...item,
                randomId: `username-${uuid()}`,
              };
            }),
          })
        );
      }
    }

    // eslint-disable-next-line
  }, [followersData, followersIsLoading, followersIsRefetching]);

  React.useEffect(() => {
    if (followersIsError) {
      responseErrorHandler(followersIsError, followersError);
    }
  }, [followersIsError, followersError]);

  function handleSearchChange(event) {
    const text = event.target.value;

    if (text?.trim()?.length <= 0) {
      setSearchText(undefined);
      setPagination(1);
    } else {
      setPagination(0);
      setSearchText(text);
    }
  }

  return (
    <Box>
      <FollowerUserLists
        isRefetching={followersIsRefetching}
        columns={columns}
        isLoading={followersIsLoading}
        followersIsFetchingNextPage={followersIsFetchingNextPage}
        followersFetchNextPage={followersFetchNextPage}
        followersHasNextPage={followersHasNextPage}
        searchComponent={
          <Stack>
            <Search width={"20ch"} placeholder={"Search..."} onChange={handleSearchChange} />
          </Stack>
        }
        pagination={pagination}
      />
    </Box>
  );
}
