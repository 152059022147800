import React from "react";

export default function Silver() {
  return (
    <svg width="232" height="234" viewBox="0 0 232 234" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="silver">
        <g id="Medal 1" clipPath="url(#clip0_409_52)">
          <g id="Layer_x0020_1">
            <path
              id="Vector"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M115.963 1.03857V37.332L148.164 48.3623L172.182 24.3446L141.76 18.8295L115.963 1.03857Z"
              fill="#F1D4D8"
            />
            <path
              id="Vector_2"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M175.918 28.9702L150.478 54.9449L165.956 85.3673L199.758 85.0115L181.967 59.7484L175.918 28.9702Z"
              fill="#F1D4D8"
            />
            <path
              id="Vector_3"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M199.224 93.7289L162.931 93.373L151.545 125.397L175.207 149.77L181.078 119.348L199.224 93.7289Z"
              fill="#F1D4D8"
            />
            <path
              id="Vector_4"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M171.826 152.439L145.673 127.176L115.429 142.832L115.962 176.634L141.048 158.666L171.826 152.439Z"
              fill="#F1D4D8"
            />
            <path
              id="Vector_5"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M108.847 176.279L108.313 139.807L75.7556 129.489L52.0938 153.684L82.6941 158.844L108.847 176.279Z"
              fill="#F1D4D8"
            />
            <path
              id="Vector_6"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M48.0014 148.881L73.2645 122.728L57.4306 92.4839L23.6279 93.0176L41.7746 118.103L48.0014 148.881Z"
              fill="#F1D4D8"
            />
            <path
              id="Vector_7"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M23.9839 85.0113L60.4552 84.6555L71.1297 52.454L47.112 28.6143L41.7748 59.0367L23.9839 85.0113Z"
              fill="#F1D4D8"
            />
            <path
              id="Vector_8"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M51.3818 24.8784L77.7123 50.1414L107.957 34.3076L107.245 0.504883L82.338 18.6516L51.3818 24.8784Z"
              fill="#F1D4D8"
            />
            <path
              id="Vector_9"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M72.1973 165.782V233.21H83.0497L111.693 212.928L140.692 233.032H151.545C151.545 233.032 152.434 165.604 151.545 165.604C150.655 165.604 139.981 165.604 139.981 165.604L112.049 184.997L83.2276 165.604L72.1973 165.782Z"
              fill="#F1D4D8"
            />
            <path
              id="Vector_10"
              d="M23.806 132.691C27.0485 132.691 29.677 130.063 29.677 126.82C29.677 123.578 27.0485 120.949 23.806 120.949C20.5636 120.949 17.9351 123.578 17.9351 126.82C17.9351 130.063 20.5636 132.691 23.806 132.691Z"
              fill="#F1D4D8"
            />
            <path
              id="Vector_11"
              d="M26.6527 211.682C29.8952 211.682 32.5237 209.054 32.5237 205.811C32.5237 202.569 29.8952 199.94 26.6527 199.94C23.4103 199.94 20.7817 202.569 20.7817 205.811C20.7817 209.054 23.4103 211.682 26.6527 211.682Z"
              fill="#F1D4D8"
            />
            <path
              id="Vector_12"
              d="M194.954 205.812C199.768 205.812 203.671 201.909 203.671 197.094C203.671 192.279 199.768 188.376 194.954 188.376C190.139 188.376 186.236 192.279 186.236 197.094C186.236 201.909 190.139 205.812 194.954 205.812Z"
              stroke="#F2B4BE"
              strokeWidth="2.66628"
            />
            <path
              id="Vector_13"
              d="M222.53 47.8286C227.345 47.8286 231.248 43.9256 231.248 39.1111C231.248 34.2965 227.345 30.3936 222.53 30.3936C217.715 30.3936 213.812 34.2965 213.812 39.1111C213.812 43.9256 217.715 47.8286 222.53 47.8286Z"
              fill="#F1D4D8"
            />
            <path
              id="Vector_14"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.19308 15.2715H12.0641V20.9646H17.7572V26.6576H12.0641V32.5286H6.19308V26.6576H0.5V20.9646H6.19308V15.2715Z"
              fill="#F1D4D8"
            />
            <path
              id="Vector_15"
              d="M111.693 139.986C140.089 139.986 163.109 116.966 163.109 88.5699C163.109 60.1739 140.089 37.1543 111.693 37.1543C83.2969 37.1543 60.2773 60.1739 60.2773 88.5699C60.2773 116.966 83.2969 139.986 111.693 139.986Z"
              fill="#FEFEFE"
              stroke="#F64C5E"
              strokeWidth="5.33296"
            />
            <path
              id="Vector_16"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M89.6324 110.809C94.6138 111.52 100.129 109.919 105.11 108.14C107.423 107.428 110.27 106.717 113.116 106.361C123.791 105.115 128.95 108.852 137.134 107.25C140.336 106.717 143.005 104.582 144.784 101.913C147.809 97.1095 146.385 90.7048 142.827 87.5025C141.048 85.5455 137.312 83.7664 134.643 82.8768C130.374 58.8591 104.755 64.5522 100.663 76.1163C99.0616 73.8035 87.8533 71.6686 86.0742 82.6989C75.7555 84.8338 70.952 108.318 89.6324 110.809ZM131.619 85.5455C147.453 88.392 146.029 104.226 132.153 104.582C125.926 104.76 121.656 102.625 114.895 102.803C102.62 103.158 93.9022 109.741 87.4975 106.539C78.9579 103.336 77.8904 89.8153 87.3196 85.3676C87.8533 85.0117 88.387 85.0117 88.5649 84.6559C87.8533 75.0488 99.0616 76.1163 101.196 79.1407C109.914 61.8836 132.331 70.6011 131.619 85.5455Z"
              fill="#F64C5E"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_409_52">
          <rect width="230.748" height="232.715" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
