import { useSelector } from "react-redux";
import React, { useCallback, useState } from "react";

//^ ui
import { Dialog, DialogTitle, IconButton, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

//^ component
import ImageCropper from "../image-cropper/ImageCropper";

const ProfilePicture = (props) => {
  const theme = useTheme();
  const laptopMatches = useMediaQuery("(min-width: 65.5rem)");
  const tabletMatches = useMediaQuery("(min-width: 48rem)");
  const userWholeProfileData = useSelector((state) => state.userProfile.userWholeProfileData);
  const profilePicture = userWholeProfileData?.profile?.profile_image_path;

  const [dialogOpen, setDialogOpen] = useState(props.isOpen || false);
  // eslint-disable-next-line
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  // eslint-disable-next-line
  const [imageUrl, setImageUrl] = useState(props.imageUrl || "");
  const [originalImageUrl, setOriginalImageUrl] = useState(profilePicture || "");
  const [alreadyImagePresent, setAlreadyImagePresent] = useState(false);
  // eslint-disable-next-line
  const [originalImgFile, setOriginalImgFile] = useState("");

  const initialSate = {
    isLoading: false,
  };

  function reducerHandler(sate, action) {
    switch (action.type) {
      case "setLoading":
        return {
          ...sate,
          isLoading: action.payload,
        };
      default:
        return sate;
    }
  }

  const [state, dispatch] = React.useReducer(reducerHandler, initialSate);

  const handleUpdateAvatar = useCallback((imageUrl, originalImgUrl) => {
    if (imageUrl.trim().length > 0) {
      setImageUrl(imageUrl);
      setOriginalImageUrl(originalImgUrl);
      props.updateProfileImage?.(imageUrl);
    }

    // eslint-disable-next-line
  }, []);

  React.useEffect(() => {
    setOriginalImageUrl(profilePicture);
    if (profilePicture) setAlreadyImagePresent(true);
  }, [profilePicture]);

  function handleClose() {
    setDialogOpen(false);
    props.onModalClose(false);
  }

  const handleGetOriginal = useCallback((originalImg) => {
    setOriginalImgFile(originalImg);
  }, []);

  const handleModelClose = useCallback((isOpen) => {
    setDialogOpen(isOpen);
    props.onModalClose(isOpen);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Dialog
        // fullScreen={fullScreen}
        open={dialogOpen}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          elevation: 1,
          sx: {
            width: laptopMatches ? "50%" : tabletMatches ? "80%" : "100%",
            height: "100%",
            minWidth: "auto",
            maxWidth: "unset",
          },
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
      >
        <DialogTitle>Change Profile</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <ImageCropper
          alreadyImagePresent={alreadyImagePresent}
          handleModalClose={handleModelClose}
          originalImgSrc={originalImageUrl}
          updateAvatar={handleUpdateAvatar}
          getOriginalImgFile={handleGetOriginal}
          onModalClose={handleModelClose}
          dispatch={dispatch}
          isLoading={state.isLoading}
        />
      </Dialog>
    </>
  );
};

export default ProfilePicture;
