import React from "react";
import { Box, OutlinedInput, Stack, useMediaQuery } from "@mui/material";
import { InfoOutlined } from "@mui/icons-material";
import useTheme from "@mui/material/styles/useTheme";
import classes from "../../pages/pre-built/user-manage/UserProfileRegular.module.scss";
import ErrorMessage from "../ui/error-message/ErrorMessage";

export default function InputWrapper({
  label,
  htmlFor,
  showInput = true,
  value,
  onClick,
  onChange,
  onBlur,
  isError,
  errorMsg,
  children,
  name,
  type = "input",
  placeholder,
  inputProps,
  labelProps,
  textareaProps,
  infoTooltip = false,
  tooltip,
  tooltipId,
  inputType,
}) {
  const theme = useTheme();
  const laptopMatches = useMediaQuery("(min-width: 65.5rem)");

  return (
    <Stack gap={"0.325rem"}>
      {laptopMatches && (
        <Stack direction={"row"} spacing={1} alignItems={"center"}>
          <label style={{ fontWeight: 400 }} htmlFor={htmlFor} {...labelProps}>
            {label}
          </label>
          {infoTooltip && (
            <>
              <Box id={tooltipId}>
                <InfoOutlined sx={{ fontSize: "1rem", cursor: "pointer" }} />
              </Box>
              {tooltip}
            </>
          )}
        </Stack>
      )}
      {showInput ? (
        type === "input" ? (
          <OutlinedInput
            type={inputType || "text"}
            id={htmlFor}
            placeholder={placeholder}
            onChange={onChange}
            onBlur={onBlur}
            onClick={onClick}
            value={value}
            name={name}
            size="small"
            {...inputProps}
          />
        ) : (
          type === "textarea" && (
            <textarea
              variant="outlined"
              aria-label={label}
              id={htmlFor}
              placeholder={placeholder}
              value={value}
              name={name}
              onChange={onChange}
              onBlur={onBlur}
              className={`bg-transparent ${classes.textarea} ${theme.palette.mode === "dark" ? classes.dark : ""}`}
              autoComplete="false"
              minRows={2}
              {...textareaProps}
            />
          )
        )
      ) : (
        children
      )}

      {isError && <ErrorMessage errorMsg={errorMsg} />}
    </Stack>
  );
}
