import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  clientId: "",
  merchantId: "",
  showApp: undefined,
  showService: undefined,
  showSubscription: undefined,
  paymentMethod: undefined,
  referAndEarn: null,
  accountNetworks: undefined,
  siteSettingIsLoading: true,
};

const siteSettingSlice = createSlice({
  initialState,
  name: "site-setting",
  reducers: {
    setSiteSettingHandler: (state, { payload }) => {
      state.clientId = payload.clientId;
      state.merchantId = payload.merchantId;
      state.showApp = payload.showApp === "1" ? true : false;
      state.showService = payload.showService === "1" ? true : false;
      state.showSubscription = payload.showSubscription === "1" ? true : false;
      state.paymentMethod = payload.paymentMethod;
      state.referAndEarn = payload.referAndEarn;
      state.accountNetworks = payload.accountNetworks;
      state.siteSettingIsLoading = payload.siteSettingIsLoading;
    },
  },
});

export const siteSettingAction = siteSettingSlice.actions;

const siteSettingReducer = siteSettingSlice.reducer;
export default siteSettingReducer;
