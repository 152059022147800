// import colors from "../assets/scss/_themes-vars.module.scss";

export default function componentStyleOverrides(theme) {
  // const border = `0.0111rem solid ${theme.customization.mode === "dark" ? `${colors.borderDark}` : colors.borderWhite}`;

  return {
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
      styleOverrides: {
        rounded: {
          borderRadius: `${theme?.customization?.borderRadius}px`,
        },
        outlined: {
          // border: `1px solid ${theme?.customization.mode === "dark" ? colors.borderDark : colors.borderWhite}`,
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          paddingTop: "10px",
          paddingBottom: "10px",
          "&.Mui-selected": {
            color: theme.menuSelected,
            backgroundColor: theme.menuSelectedBack,
            "&:hover": {
              backgroundColor: theme.menuSelectedBack,
            },
            "& .MuiListItemIcon-root": {
              color: theme.menuSelected,
            },
          },
          "&:hover": {
            backgroundColor: theme.menuSelectedBack,
            color: theme.menuSelected,
            "& .MuiListItemIcon-root": {
              color: theme.menuSelected,
            },
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: theme.darkTextPrimary,
          minWidth: "36px",
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        // primary: {
        //   color: theme.textDark,
        // },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: theme.textDark,
          "&::placeholder": {
            color: theme.darkTextSecondary,
            fontSize: "0.875rem",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          border: "none",
          "& .MuiOutlinedInput-notchedOutline": { borderWidth: "0.5px", outline: "none" },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            // borderColor: theme.customization.mode === "dark" ? colors.borderDark : colors.borderWhite,
          },
          "&:disabled .MuiOutlinedInput-notchedOutline": { outline: "none", cursor: "not-allowed" },
          borderRadius: "0.225rem",
        },
        // outlined: { border: border },
        // notchedOutline: { border: border },
        input: {
          "&.MuiInputBase-inputSizeSmall": {
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {
          height: "48px",
          borderBottomWidth: "0.5px", // Decrease the thickness of the bottom line
          "&:hover:not($disabled):not($focused):not($error):before": {
            borderBottom: `1px solid red`, // Change border color on hover
          },
          "&$focused:before": {
            borderBottom: `2px solid ${theme?.palette?.primary?.main}`, // Change border color on focus
          },
          "&$error:before": {
            borderBottom: `2px solid ${theme?.palette?.error?.main}`, // Change border color on error
          },
        },
        input: {
          borderRadius: "10px", // Adjust the border radius if needed
          "&.MuiInputBase-inputSizeSmall": {
            padding: "10px 14px",
            "&.MuiInputBase-inputAdornedStart": {
              paddingLeft: 0,
            },
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          // borderColor: theme?.customization.mode === "dark" ? colors.borderDark : colors.borderWhite,
          opacity: 1,
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          color: theme.colors?.primaryDark,
          background: theme.colors?.primary200,
        },
      },
    },
  };
}
