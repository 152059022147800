import React, { useContext } from "react";
import { UserContext } from "../../../pages/pre-built/user-manage/UserContext";
import MuiSwitch from "./MuiSwitch";

const InputSwitch = ({ label, id, onClick = null, name = "" }) => {
  const { loggedInUser } = useContext(UserContext);

  return (
    <React.Fragment>
      <MuiSwitch
        id={id}
        checked={
          name !== "" && loggedInUser?.notifications?.hasOwnProperty(name) && loggedInUser.notifications[name] === "1"
        }
        onClick={onClick}
      >
        {label}
      </MuiSwitch>
      {/* <input
        type="checkbox"
        className="custom-control-input"
        checked={
          name !== "" && loggedInUser?.notifications?.hasOwnProperty(name) && loggedInUser.notifications[name] === "1"
        }
        id={id}
        onClick={onClick}
      />
      <label className="custom-control-label" htmlFor={id}>
        {label}
      </label> */}
    </React.Fragment>
  );
};

export default InputSwitch;
