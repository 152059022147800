import React from "react";

import colors from "../../../assets/scss/_themes-vars.module.scss";

import { useTheme } from "@mui/material/styles";
import { Stack, Typography } from "@mui/material";

export default function Tag({ text }) {
  const isDark = useTheme().palette.mode === "dark";

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      gap={1}
      sx={{
        padding: "0.25rem 0.5rem",
        borderRadius: "0.625rem",
        backgroundColor: isDark ? colors.tagDarkMain : colors.tagMain,
      }}
    >
      <Typography>{text}</Typography>
    </Stack>
  );
}
