import React, { Suspense } from "react";
import { Outlet } from "react-router";
import OutletSkeleton from "../../components/ui/loader/Skeleton/OutletSkeleton";

export default function UserProfileSettingLayout() {
  return (
    <Suspense fallback={<OutletSkeleton />}>
      <Outlet />
    </Suspense>
  );
}
