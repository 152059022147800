import { CircularProgress, Dialog, DialogContent, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";

export default function PaymentLoader({ isOpen }) {
  const lgMatches = useMediaQuery("(min-width: 64rem)");
  const mdMatches = useMediaQuery("(min-width: 48rem)");

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        component: "form",
        elevation: 1,
        sx: {
          width: lgMatches ? "100%" : mdMatches ? "80%" : "100%",
          height: "100%",
          minWidth: "auto",
          maxWidth: "unset",
        },
        onSubmit: (event) => {
          event.preventDefault();
        },
      }}
    >
      <DialogContent sx={{ height: "inherit" }}>
        <Stack justifyContent={"center"} alignItems={"center"} height={"inherit"}>
          <Typography>
            <Stack direction={"row"} alignItems={"center"} gap={2}>
              <CircularProgress />
              <Typography variant="body1">
                Please wait while we process your payment. This may take some time.
              </Typography>
            </Stack>
          </Typography>
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
