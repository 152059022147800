import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  servicePlanValidity: false,
};

const servicePlanSlice = createSlice({
  initialState,
  name: "service-plan-slice",
  reducers: {
    toggleServicePlanValidity: (state) => {
      state.servicePlanValidity = !state.servicePlanValidity;
    },
  },
});

export const servicePlanAction = servicePlanSlice.actions;

const servicePlanReducer = servicePlanSlice.reducer;
export default servicePlanReducer;
