import React, { useEffect } from "react";
import moment from "moment/moment";

//^ mui component
import { Stack } from "@mui/material";

//^ column
import { columns } from "./columns";

//^ Table component
import DataTableSkeletonLoader from "../../components/ui/loader/Skeleton/DataTableSkeletonLoader";
import EmptyFolder from "../../components/svg/empty-folder";
import DataTable from "../invoices/data-table";

export default function InvoicesTable({ getInvoiceListIsLoading, getInvoiceListIsRefetching, getInvoiceListData }) {
  const [billings, setBillings] = React.useState([]);

  useEffect(() => {
    if (!getInvoiceListIsLoading || !getInvoiceListIsRefetching) {
      if (!getInvoiceListData?.status) {
        if (getInvoiceListData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        }
      } else {
        const serviceFilter = getInvoiceListData?.data?.list.map((list, index) => {
          let count = index;
          count++;

          return {
            srNo: count,
            id: list?.id,
            invoiceId: list?.invoiceId,
            tsxId: list?.txn_id,
            plan: list?.plan,
            planId: list?.plan_id,
            serviceId: list?.service_id,
            type: list.type,
            total: (list?.price + list?.auger_price)?.toFixed(2),
            invoiceDate: moment(list?.date).format("DD/MM/YYYY"),
            status: list?.status,
          };
        });

        setBillings(serviceFilter || []);
      }
    }
  }, [getInvoiceListData, getInvoiceListIsLoading, getInvoiceListIsRefetching]);

  return (
    <>
      <div>
        {getInvoiceListIsLoading ? (
          <DataTableSkeletonLoader paper={false} />
        ) : billings ? (
          <DataTable
            data={billings || []}
            columns={columns}
            searchPlaceholder={"Search for plans..."}
            searchKey={"plan-hidden"}
            isCompact={true}
          />
        ) : (
          <Stack width={"100%"} height={"100%"} alignItems={"center"} justifyContent={"center"}>
            <EmptyFolder />
          </Stack>
        )}
      </div>
    </>
  );
}
