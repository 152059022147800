import React from "react";

import { Box, Card, Skeleton, Stack, useMediaQuery } from "@mui/material";

function PackageDetailSkeleton() {
  return (
    <Stack spacing={1}>
      <Skeleton width={"15%"} />
      <Stack spacing={1}>
        <Stack>
          <Skeleton width={"45%"} />
          <Skeleton />
        </Stack>
        <Stack direction={"row"} flexWrap={"wrap"} alignItems={"center"} gap={1}>
          {Array.from({ length: 6 }).map((_, index) => {
            return (
              <React.Fragment key={index}>
                <Skeleton width={"15%"} variant="rounded" height={"2.25rem"} sx={{ borderRadius: "0.625rem" }} />
              </React.Fragment>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default function PackageMembershipSkeletonLoader() {
  const lgMatches = useMediaQuery("(min-width: 64rem)");

  return (
    <Box width={"100%"}>
      <Stack gap={1.5}>
        <Skeleton height={"3rem"} width={"35%"} />
        <Card variant={"outlined"} sx={lgMatches ? { padding: "1rem 0.925rem" } : { padding: "1rem 0.925rem" }}>
          <Stack spacing={2}>
            <PackageDetailSkeleton />
            <PackageDetailSkeleton />
          </Stack>
        </Card>
      </Stack>
    </Box>
  );
}
