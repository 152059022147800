import React from "react";

export default function WhiteCoin({ style, ...props }) {
  return (
    <svg
      width="2209"
      height="2207"
      viewBox="0 0 2209 2207"
      style={style}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clip-path="url(#clip0_234_243)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M2207.51 1334.23V0H821.957C257.474 102.633 257.474 872.383 1078.54 821.066C1489.07 821.066 2053.56 923.699 2207.51 1334.23Z"
          fill="#8094AE"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0.890625 821.066V2206.61H1437.76C2053.56 2103.98 2002.24 1282.92 1181.17 1334.23C719.323 1334.23 154.84 1231.6 0.890625 821.066Z"
          fill="#8094AE"
        />
      </g>
      <defs>
        <clipPath id="clip0_234_243">
          <rect width="2208.39" height="2206.61" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
