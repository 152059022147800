import React, { useEffect, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

//^ redux action
import { userNetworkAction } from "../../../store/slices/user-networks-slice";

//^ http requests
import { getUserListsHandler } from "../../../http/post-api";

//^ @mui component
import { Box, Stack } from "@mui/material";

//^ data table
import DataTable from "./data-table";
import { columns } from "./column";
import Search from "../../../components/search";
import useDebounce from "../../../hooks/use-debounce";
import { responseErrorHandler } from "../../../utils/Utils";

export default function ConnectionTable() {
  const [searchText, setSearchText] = useState(undefined);
  const [pagination, setPagination] = useState(1);

  // eslint-disable-next-line
  const [searchParams, _] = useSearchParams();
  const connectType = searchParams.get("connectType") || "connected";

  const debounceSearch = useDebounce({ value: searchText, delay: 200 });
  const dispatch = useDispatch();

  //^ states
  const [totalConnections, setTotalConnections] = useState(0);

  //^ get connect user list query fn ===============================================================
  const queryKey = [`get-user-${connectType}-list`, connectType, debounceSearch];
  const {
    data: getUserConnectData,
    isLoading: getUserConnectIsLoading,
    isRefetching: getUserConnectIsRefetching,
    isError: getUserConnectIsError,
    error: getUserConnectError,
    fetchNextPage: getUserFetchNextPage,
    isFetchingNextPage: getUserIsFetchingNextPage,
    hasNextPage: getUserHasNextPage,
  } = useInfiniteQuery({
    queryKey,
    queryFn: (props) =>
      getUserListsHandler({
        type: connectType === "notConnected" ? "not_connected" : connectType,
        page: props.pageParam,
        limit: 10,
        pagination,
        search: searchText,
      }),
    initialPageParam: 1,
    enabled: !!connectType,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.status ? allPages.length + 1 : undefined;
    },
  });

  useEffect(() => {
    if (!getUserConnectIsRefetching || !getUserConnectIsLoading) {
      dispatch(
        userNetworkAction.removeUserListHandler({
          type: "CONNECT",
          getConnectionDataQuery: queryKey,
        })
      );

      if (connectType === "connected") {
        const flattenConnectedUsers = getUserConnectData?.pages?.flatMap((page) => {
          return page?.data?.connections || [];
        });

        const connectedUsers =
          flattenConnectedUsers
            ?.map((connection) => ({ ...connection, connection_status: { connected: true } }))
            ?.filter((item) => item !== undefined) || [];

        dispatch(userNetworkAction.setUserInvitationsHandler({ type: "ADD_CONNECTIONS", connections: connectedUsers }));
        setTotalConnections(flattenConnectedUsers?.length || 0);
      } else {
        const flattenConnectedUsers = getUserConnectData?.pages?.flatMap((page) => {
          return page?.data?.user_list || [];
        });

        const notConnectedUsers = flattenConnectedUsers?.filter((user) => !user?.connection_status?.connected);
        dispatch(
          userNetworkAction.setUserInvitationsHandler({ type: "ADD_CONNECTIONS", connections: notConnectedUsers || [] })
        );
      }
    }

    // eslint-disable-next-line
  }, [getUserConnectData, getUserConnectIsLoading, getUserConnectIsRefetching]);

  useEffect(() => {
    if (getUserConnectIsError) {
      responseErrorHandler(getUserConnectIsError, getUserConnectError);
    }
  }, [getUserConnectIsError, getUserConnectError]);

  //^ get connect user list query fn ===============================================================

  function handleSearchChange(event) {
    const text = event.target.value;

    if (text?.trim()?.length <= 0) {
      setSearchText(undefined);
      setPagination(1);
    } else {
      setPagination(0);
      setSearchText(text);
    }
  }

  return (
    <Box>
      <DataTable
        columns={columns}
        connectionCount={totalConnections}
        getUserConnectIsLoading={getUserConnectIsLoading}
        getUserIsFetchingNextPage={getUserIsFetchingNextPage}
        getUserFetchNextPage={getUserFetchNextPage}
        getUserHasNextPage={getUserHasNextPage}
        getUserConnectIsRefetching={getUserConnectIsRefetching}
        searchComponent={
          <Stack>
            <Search width={"20ch"} placeholder={"Search..."} onChange={handleSearchChange} />
          </Stack>
        }
        pagination={pagination}
      />
    </Box>
  );
}
