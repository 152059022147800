import React, { useCallback, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";
import dayjs from "dayjs";
import { Alert } from "reactstrap";

//^ colors
import colors from "../../../../assets/scss/_themes-vars.module.scss";

//^ stylesheets
import classes from "./SubscriptionPackage.module.scss";

//^ utils
import { isDifferenceDays, responseErrorHandler } from "../../../../utils/Utils";

//^ mui
import {
  Box,
  Stack,
  Button,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  useMediaQuery,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

//^ http request
import { getPackagesSubscriptionHandler } from "../../../../http/get-api";
import { downgradePackageHandler, getSubscriptedPackageHandler } from "../../../../http/post-api";

//^ redux slice
import {
  setAugerFee,
  setPlan,
  setPlanList,
  setPurchase,
  setAccountTokens,
  setTokenValue,
  setValidity,
  setPackageSubUpgradePlan,
} from "../../../../store/slices/package-subscription-slice";

//^ components
import Head from "../../../../layout/head/Head";
import { BlockDes, Icon } from "../../../../components/Component";
import Separator from "../../../../components/ui/separator/Separator";
import SubscriptionPackageSkeleton from "../../../../components/ui/loader/Skeleton/SubscriptionPackageSkeleton";
import StartButton from "../../../../components/button/navigate-button";
import moment from "moment";
import SubscriptionPackageDetails from "../SubscriptionPackageDetails";
import S1 from "../../../../components/svg/plans/s1";
import S2 from "../../../../components/svg/plans/s2";
import S3 from "../../../../components/svg/plans/s3";
import EmptyFolder from "../../../../components/svg/empty-folder";

export default function SubscriptionPackage({ subDispatch, showPlanBtn }) {
  const theme = useTheme();
  const planList = useSelector((store) => store.packageSubscription.planList);
  const validity = useSelector((store) => store.packageSubscription.validity);

  // eslint-disable-next-line
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  function subscriptionReducer(state, action) {
    switch (action.type) {
      case "SHOW_PLAN_LIST":
        return {
          ...state,
          showSubscriptionDetail: true,
        };
      case "DO_NOT_PLAN_LIST":
        return {
          ...state,
          showSubscriptionDetail: false,
        };
      default:
        return state;
    }
  }

  const initialState = {
    showSubscriptionDetail: false,
  };

  const [subscriptionState, subscriptionDispatch] = React.useReducer(subscriptionReducer, initialState);
  const [planId, setPlanId] = useState(undefined);
  const [subscribedPackagePrice, setSubscribedPackagePrice] = useState(0);
  // eslint-disable-next-line
  const [subscribedValidity, setSubscribedValidity] = useState("");
  // eslint-disable-next-line
  const [switchClickContent, setSwitchClickContent] = useState({
    packageName: "",
    notIncludedFeatures: [],
    price: 0,
    validity: "",
    selectedPlan: {},
  });

  // eslint-disable-next-line
  const [upgradeSwitchClickContent, setUpgradeSwitchClickContent] = useState({
    packageName: "",
    notIncludedFeatures: [],
    price: 0,
    validity: "",
    selectedPlan: {},
  });
  const [selectedUpgradeOption, setSelectedUpgradeOption] = useState("option1");
  const [date, setDate] = useState(null);

  //^ boolean states
  // eslint-disable-next-line
  const [isSwitchModelOpen, setIsSwitchModelOpen] = React.useState(false);
  const [isUpgradeSwitchModelOpen, setIsUpgradeSwitchModelOpen] = React.useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (showPlanBtn === false) {
      subscriptionDispatch({ type: "DO_NOT_PLAN_LIST" });
    }
  }, [showPlanBtn]);

  const isDark = theme.palette.mode === "dark";

  const {
    data: getPackagesData,
    isLoading: getPackagesIsLoading,
    isError: getPackagesIsError,
    error: getPackagesError,
  } = useQuery({
    queryKey: ["get-subscription-package-lists"],
    queryFn: getPackagesSubscriptionHandler,
    gcTime: 0,
    staleTime: Infinity,
  });

  //^ getting current user's subscription package details query request
  const {
    data: getUserPackageData,
    isLoading: getUserPackageIsLoading,
    isRefetching: getUserPackageIsRefetching,
    isError: getUserPackageIsError,
    error: getUserPackageError,
  } = useQuery({
    queryKey: ["get-current-user-package"],
    queryFn: async () => getSubscriptedPackageHandler(),
  });

  useEffect(() => {
    if (!getUserPackageIsLoading || !getUserPackageIsRefetching) {
      if (!getUserPackageData?.status) {
        if (getUserPackageData?.redirect) {
          window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
        }
      } else {
        setSubscribedPackagePrice(getUserPackageData?.data?.package?.price);
        setSubscribedValidity(getUserPackageData?.data?.package?.validity);
      }
    }
  }, [getUserPackageData, getUserPackageIsLoading, getUserPackageIsRefetching]);

  useEffect(() => {
    if (getUserPackageIsError && getPackagesIsError) {
      responseErrorHandler(getUserPackageIsError, getUserPackageError);
    } else {
      responseErrorHandler(getUserPackageIsError, getUserPackageError);
    }
  }, [getPackagesIsError, getPackagesError, getUserPackageIsError, getUserPackageError]);

  useEffect(() => {
    if (!getPackagesIsLoading) {
      if (getPackagesData?.status) {
        let tempArr = [];

        getPackagesData?.data?.packages.map((pkg) => {
          const tempObj = {
            id: pkg.id,
            title: pkg.name,
            logo: `${process.env.REACT_APP_API_URL}${pkg.logo}`,
            desc: pkg.description,
            monthly_price: pkg.monthly_price,
            yearly_price: pkg.yearly_price,
            services: pkg.services,
            is_subscribed: pkg.is_subscribed,
            subscription_validity: pkg.subscription_validity,
            is_subscribed_end_date: pkg.is_subscribed_end_date,
          };
          tempArr.push(tempObj);
          return null;
        });

        dispatch(setPlanList(tempArr));
        dispatch(setAugerFee(getPackagesData?.data?.auger_fee));
        dispatch(setTokenValue(getPackagesData?.data?.token_value));
        dispatch(setAccountTokens(getPackagesData?.data?.account_tokens));
      } else {
        responseErrorHandler(true, getPackagesData);
      }
    }
  }, [getPackagesData, getPackagesIsLoading, dispatch]);

  //^ downgrade package subscription mutation logic ================================================================
  const {
    // eslint-disable-next-line
    isPending: downgradeIsPending,
    isError: downgradeIsError,
    error: downgradeError,
    // eslint-disable-next-line
    mutate: downgradeMutate,
    reset: downgradeReset,
  } = useMutation({
    mutationKey: ["downgrade-subscription-package"],
    mutationFn: downgradePackageHandler,
    onSuccess: (data) => {
      if (data.toast) {
        if (!data.status) {
          responseErrorHandler(true, data);
        } else {
          toast.success(data?.message);
          handleSwitchModalClose();
          navigate(
            `subscription-package-details/${data?.data?.package?.package_id}/print-invoice/${data?.data?.invoice_id}`
          );
        }
      }

      downgradeReset();
    },
  });

  useEffect(() => {
    responseErrorHandler(downgradeIsError, downgradeError);
  }, [downgradeIsError, downgradeError]);

  //^ downgrade package subscription mutation logic ================================================================

  // eslint-disable-next-line
  const validityClick = useCallback(
    (isChecked) => {
      if (isChecked) {
        dispatch(setValidity(true));
      } else {
        dispatch(setValidity(false));
      }
    },
    [dispatch]
  );

  const selectPlan = (plan) => {
    dispatch(setPurchase(true));
    dispatch(setPlan(plan));
    subDispatch({ type: "DISABLE_SWITCH" });
    subscriptionDispatch({ type: "SHOW_PLAN_LIST" });
    setPlanId(plan.id);
  };

  const textMotion = {
    hover: {
      x: 3,
    },
  };

  function handleSwitchModalClose() {
    setIsSwitchModelOpen(false);
  }

  function handleUpgradeSwitchModalClose() {
    setIsUpgradeSwitchModelOpen(false);
    setSelectedUpgradeOption("option1");
  }

  const handleOptionChange = (event) => {
    setSelectedUpgradeOption(event.target.value);
  };

  const handleDateChangeHandler = (date) => {
    setDate(date);
  };

  function handleSwitchPackageHandler(_event, packageName, notIncludedFeatures, price, validity, selectPlan) {
    setSwitchClickContent({
      packageName,
      notIncludedFeatures,
      price: price,
      validity,
      selectedPlan: selectPlan,
    });

    setIsSwitchModelOpen(true);
  }

  function handleUpgradeSwitchPackageHandler(_event, packageName, notIncludedFeatures, price, validity, selectPlan) {
    setUpgradeSwitchClickContent({
      packageName: packageName,
      notIncludedFeatures,
      price: price,
      validity,
      selectedPlan: selectPlan,
    });

    setIsUpgradeSwitchModelOpen(true);
  }

  return (
    <>
      <Head title="Subscriptions" />
      {subscriptionState.showSubscriptionDetail ? (
        <SubscriptionPackageDetails planId={planId} subscriptionDispatch={subscriptionDispatch} />
      ) : (
        <>
          {/* <Dialog
            open={isSwitchModelOpen}
            aria-labelledby="switch-dialog-title"
            onClose={handleSwitchModalClose}
            // fullScreen={fullScreen}
            PaperProps={{
              elevation: 1,
              component: "form",
              sx: {
                width: "100%",
                maxWidth: "37.5rem",
              },
              onSubmit: (event) => {
                event.preventDefault();
                downgradeMutate({
                  id: switchClickContent.selectedPlan?.id,
                  validity: switchClickContent.validity ? "12" : "1",
                });
              },
            }}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="switch-dialog-title">
              {"Are you sure you want to switch subscription?"}
              <IconButton
                aria-label="close"
                onClick={handleSwitchModalClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: (theme) => theme.palette.grey[500],
                }}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <Typography id="switch-subscription-description" variant="subtitle1">
                {`Switching will change your subscription from ${getUserPackageData?.data?.package?.package_data?.name}`}
                <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                  {` ($${subscribedPackagePrice}/${
                    subscribedValidity === "1" ? "mo" : subscribedValidity === "12" ? "yr" : ""
                  }) `}
                </Typography>
                {`to ${switchClickContent?.packageName}`}
                <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                  {` ($${switchClickContent?.price}/${switchClickContent?.validity ? "yr" : "mo"})`}
                </Typography>
                {`. `}
                {getUserPackageData?.data?.package?.package_id === switchClickContent?.selectedPlan?.id ? (
                  `The features of your subscription will remain the same, only the validity will change. If you switch from a ${
                    subscribedValidity === "1" ? "monthly" : subscribedValidity === "12" ? "yearly" : ""
                  } subscription to a ${
                    switchClickContent?.validity ? "yearly" : "monthly"
                  } one, your validity will decrease, and vice versa.`
                ) : (
                  <>
                    {switchClickContent.notIncludedFeatures.length === 0 ? (
                      `Only your validity will change.`
                    ) : (
                      <>
                        {`Some features available in ${getUserPackageData?.data?.package?.package_data?.name}`}
                        <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                          {` (${subscribedValidity === "1" ? "monthly" : "yearly"}) `}
                        </Typography>
                        {`will not be included in the ${switchClickContent?.packageName}`}
                        <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                          {` (${switchClickContent?.validity ? "yearly" : "monthly"}) `}
                        </Typography>
                        {`package. These features are:`}
                      </>
                    )}
                  </>
                )}
              </Typography>

              {getUserPackageData?.data?.package?.package_id !== switchClickContent?.selectedPlan?.id &&
                switchClickContent.notIncludedFeatures.length > 0 && (
                  <List>
                    {switchClickContent.notIncludedFeatures.map((feature, index) => (
                      <ListItem key={index}>
                        <Typography variant="subtitle2">- {feature?.name}</Typography>
                      </ListItem>
                    ))}
                  </List>
                )}
              <Typography variant="subtitle1">
                If you are sure about this change, please confirm by clicking the{" "}
                <Typography variant="button" component={"span"}>
                  "Confirm"
                </Typography>{" "}
                button below.
              </Typography>
              <Alert color="warning" style={{ marginTop: "1rem", padding: "1rem" }}>
                <Stack direction={"row"} gap={2}>
                  <ReportProblemRounded color="warning" />
                  <Typography variant="body2">
                    When switching from {getUserPackageData?.data?.package?.package_data?.name}{" "}
                    <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                      {` ($${subscribedPackagePrice}/${
                        subscribedValidity === "1" ? "mo" : subscribedValidity === "12" ? "yr" : ""
                      }) `}
                    </Typography>{" "}
                    to {switchClickContent?.packageName}{" "}
                    <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                      {` ($${switchClickContent?.price}/${switchClickContent?.validity ? "yr" : "mo"}) `}
                    </Typography>
                    , the payment will remain the same as for {getUserPackageData?.data?.package?.package_data?.name},
                    with only the services changing to those of the {switchClickContent?.packageName} package. While the
                    plan will transition, it's important to note that even though{" "}
                    {getUserPackageData?.data?.package?.package_data?.name} costs{" "}
                    <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                      ${subscribedPackagePrice}
                    </Typography>{" "}
                    and {switchClickContent?.packageName} costs{" "}
                    <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                      ${switchClickContent?.price}
                    </Typography>
                    , the remaining{" "}
                    <Typography component={"span"} variant="subtitle2" fontWeight={"600"}>
                      ${(subscribedPackagePrice - switchClickContent?.price).toFixed(2)}
                    </Typography>{" "}
                    will not be refunded.
                  </Typography>
                </Stack>
              </Alert>
            </DialogContent>
            <DialogActions>
              <Button
                startIcon={downgradeIsPending ? <CircularProgress size={17} color="inherit" /> : ""}
                color="primary"
                type="submit"
              >
                Confirm
              </Button>
              <Button color="secondary" onClick={handleSwitchModalClose} sx={{ color: isDark ? "white" : "black" }}>
                Close
              </Button>
            </DialogActions>
          </Dialog> */}
          <Dialog
            open={isUpgradeSwitchModelOpen}
            aria-labelledby="switch-dialog-title"
            onClose={handleUpgradeSwitchModalClose}
            // fullScreen={fullScreen}
            PaperProps={{
              elevation: 1,
              component: "form",
              sx: {
                width: "100%",
                maxWidth: "37.5rem",
              },
              onSubmit: (e) => {
                e.preventDefault();
                const currentDate = moment().format("YYYY-MM-DD");

                selectPlan(upgradeSwitchClickContent.selectedPlan);
                setSelectedUpgradeOption("option1");
                handleUpgradeSwitchModalClose();
                dispatch(
                  setPackageSubUpgradePlan(
                    selectedUpgradeOption === "option1"
                      ? currentDate
                      : selectedUpgradeOption === "option2"
                      ? getUserPackageData?.data?.package?.end_date
                      : dayjs(date).format("YYYY-MM-DD")
                  )
                );
              },
            }}
          >
            <DialogTitle sx={{ m: 0, p: 2 }} id="switch-dialog-title">
              {"Are you sure you want to upgrade subscription?"}
              <IconButton
                aria-label="close"
                onClick={handleUpgradeSwitchModalClose}
                sx={{
                  position: "absolute",
                  right: 8,
                  top: 8,
                  color: theme.palette.grey[500],
                }}
              >
                <Close />
              </IconButton>
            </DialogTitle>
            <DialogContent dividers>
              <RadioGroup value={selectedUpgradeOption} onChange={handleOptionChange}>
                <Stack gap={2}>
                  <FormControlLabel
                    value="option1"
                    control={<Radio />}
                    label={`Do you want to upgrade to ${upgradeSwitchClickContent?.packageName} from ${getUserPackageData?.data?.package?.package_data?.name} starting today?`}
                    required
                  />
                  <FormControlLabel
                    value="option2"
                    control={<Radio />}
                    label="Upgrade the subscription when the current subscription expires."
                    required
                  />
                  <FormControlLabel
                    value="option3"
                    control={<Radio />}
                    label="From which date do you want to upgrade the plan?"
                    required
                  />
                </Stack>
              </RadioGroup>
              {selectedUpgradeOption === "option3" && (
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    variant="standard"
                    value={date}
                    id={"dob"}
                    onChange={handleDateChangeHandler}
                    sx={{
                      width: "100%",
                      "& .MuiOutlinedInput-root": {
                        height: "2.5rem",
                        "& .MuiOutlinedInput-notchedOutline": {
                          border: `0.0111rem solid ${
                            theme.palette.mode === "dark" ? colors.borderDark : colors.borderWhite
                          }`,
                          borderWidth: "0.5px",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {},
                        borderRadius: "0.425rem",
                      },
                    }}
                    format="DD/MM/YYYY"
                    minDate={dayjs()}
                  />
                </LocalizationProvider>
              )}
            </DialogContent>
            <DialogActions>
              <Button color="primary" type="submit">
                Confirm
              </Button>
              <Button
                color="secondary"
                onClick={handleUpgradeSwitchModalClose}
                sx={{ color: theme.palette.mode === "dark" ? "white" : "black" }}
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <Stack gap={1} width={"100%"} height={"100%"}>
            {getPackagesData?.data?.alert_data?.length > 0 &&
              getPackagesData?.data?.alert_data?.some((data) => data?.status === true) &&
              getPackagesData?.data?.alert_data?.map((data, index) => {
                return (
                  <Alert key={index} color={data?.type}>
                    {data?.message}
                  </Alert>
                );
              })}
            <div className={`${planList?.length > 0 ? classes["subscription-packages-list"] : ""} w-100 h-100`}>
              {getPackagesIsLoading || getUserPackageIsLoading ? (
                <div className="d-flex w-100" style={{ height: "100%", gridColumn: "span 3" }}>
                  <Box width={"100%"}>
                    <SubscriptionPackageSkeleton />
                  </Box>
                </div>
              ) : planList?.length > 0 ? (
                planList?.map((plan) => {
                  const planPrice = !validity ? plan.monthly_price : plan.yearly_price;
                  const validityString = validity ? "12" : "1";
                  const subscriptionValidity = validityString === plan?.subscription_validity;
                  const isFourDayDifference = isDifferenceDays(plan.is_subscribed_end_date, 4);
                  const packageName = plan?.title || "";
                  const notIncludedFeatures = plan?.services?.filter((service) => !service.include) || [];
                  const selectedPlan = plan;

                  return (
                    <>
                      <div
                        className={`${
                          getUserPackageData?.status
                            ? plan.is_subscribed &&
                              plan.subscription_validity === validityString &&
                              plan.id === getUserPackageData.data.package.package_id &&
                              "bg-primary d-flex flex-column"
                            : ""
                        }`}
                        style={
                          getUserPackageData?.status
                            ? plan.is_subscribed &&
                              plan.subscription_validity === validityString &&
                              plan.id === getUserPackageData.data.package.package_id
                              ? {
                                  borderRadius: "0.625rem",
                                  paddingRight: "0.225rem",
                                  paddingLeft: "0.225rem",
                                  paddingBottom: "0.225rem",
                                }
                              : {}
                            : {}
                        }
                      >
                        {getUserPackageData?.status ? (
                          plan.is_subscribed &&
                          plan.subscription_validity === validityString &&
                          plan.id === getUserPackageData.data.package.package_id ? (
                            <Typography
                              textAlign={"center"}
                              variant="overline"
                              fontWeight={"600"}
                              color={"white"}
                              fontSize={14}
                              // padding={"0.725rem 0rem"}
                            >
                              Current Subscription
                            </Typography>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        <Paper
                          variant={isDark ? "elevation" : "outlined"}
                          elevation={isDark ? 0.4 : 3}
                          className={`pricing ${plan.tags ? "recommend" : ""} pb-2 position-relative`}
                          sx={{ borderRadius: "0.625rem" }}
                        >
                          <div className={`px-2 ${classes["pricing-details"]}`} style={{ paddingBottom: "0.225rem" }}>
                            <div className="d-flex flex-column">
                              <div className={`${classes["subscription-title"]}`}>
                                <div>
                                  <h5 className="title">{plan.title}</h5>
                                  <span className="sub-text">{plan.desc}</span>
                                </div>
                                <div
                                  className={`${
                                    getUserPackageData?.status
                                      ? plan.id === getUserPackageData.data.package.package_id
                                        ? "pt-2"
                                        : ""
                                      : ""
                                  }`}
                                  style={{ width: "7rem", height: "7rem" }}
                                >
                                  {plan.title === "Bronze" ? (
                                    <S1 />
                                  ) : plan.title === "Silver" ? (
                                    <S2 />
                                  ) : plan.title === "Gold" ? (
                                    <S3 />
                                  ) : null}
                                  {/* <img loading="lazy" src={plan.logo} alt={plan.title} /> */}
                                </div>
                              </div>
                              <div className="pricing-amount px-2">
                                <div className="amount">
                                  ${!validity ? plan.monthly_price : plan.yearly_price}{" "}
                                  <span style={{ fontSize: "12px" }}>{`/ ${!validity ? "mo" : "yr"}`}</span>
                                </div>
                                <Separator />

                                <div>
                                  <p>Includes</p>
                                  <p className="d-flex flex-column fs-5" style={{ gap: "1rem" }}>
                                    {plan && plan.services && plan?.services?.length > 0
                                      ? plan.services.map((service, index) => {
                                          return (
                                            <span
                                              key={index}
                                              className="d-flex align-items-center"
                                              style={{ gap: "0.625rem" }}
                                            >
                                              {service.include ? (
                                                <Icon name="check-thick" className={"text-success"} />
                                              ) : (
                                                <Icon name="cross" className={"text-danger fw-bold"} />
                                              )}

                                              <BlockDes>
                                                <Typography variant="subtitle2" sx={{ fontWeight: "400" }}>
                                                  {service.name}
                                                </Typography>
                                              </BlockDes>
                                            </span>
                                          );
                                        })
                                      : null}
                                  </p>
                                </div>
                              </div>
                            </div>
                            <div className="pricing-action d-flex justify-content-center">
                              {!subscriptionValidity &&
                              getUserPackageData?.status &&
                              subscribedPackagePrice > planPrice ? (
                                <StartButton
                                  // startIcon={downgradeIsPending ? <CircularProgress size={18} color="inherit" /> : ""}
                                  title={"Subscribe"}
                                  variant={"contained"}
                                  color={"primary"}
                                  type={"button"}
                                  onClick={(event) =>
                                    handleSwitchPackageHandler(
                                      event,
                                      packageName,
                                      notIncludedFeatures,
                                      planPrice,
                                      validity,
                                      selectedPlan
                                    )
                                  }
                                  disabled
                                />
                              ) : !subscriptionValidity &&
                                getUserPackageData?.status &&
                                subscribedPackagePrice < planPrice ? (
                                <StartButton
                                  // startIcon={downgradeIsPending ? <CircularProgress size={18} color="inherit" /> : ""}
                                  title={"Upgrade Subscription"}
                                  variant={"contained"}
                                  color={"primary"}
                                  type={"button"}
                                  onClick={(event) =>
                                    handleUpgradeSwitchPackageHandler(
                                      event,
                                      packageName,
                                      notIncludedFeatures,
                                      planPrice,
                                      validity,
                                      selectedPlan
                                    )
                                  }
                                />
                              ) : (
                                <motion.div whileHover={"hover"}>
                                  <Button
                                    disabled={
                                      !subscriptionValidity
                                        ? getUserPackageData?.status
                                          ? plan.id === getUserPackageData.data.package.package_id &&
                                            subscriptionValidity
                                            ? false
                                            : getUserPackageData?.status
                                            ? subscribedPackagePrice > planPrice
                                              ? false
                                              : false
                                            : false
                                          : false
                                        : isFourDayDifference
                                        ? false
                                        : true
                                    }
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    onClick={() => {
                                      dispatch(setPackageSubUpgradePlan(moment().format("YYYY-MM-DD")));
                                      selectPlan(plan);
                                    }}
                                  >
                                    <span className={`d-flex align-items-center`} style={{ gap: "0.625rem" }}>
                                      <Typography variant="subtitle2" component={"span"}>
                                        {!subscriptionValidity
                                          ? getUserPackageData?.status
                                            ? plan.id === getUserPackageData.data.package.package_id &&
                                              subscriptionValidity
                                              ? "Renew"
                                              : getUserPackageData?.status
                                              ? subscribedPackagePrice > planPrice
                                                ? "Buy Subscription"
                                                : "Upgrade Subscription"
                                              : "Buy Now"
                                            : "Buy Now"
                                          : "Renew Now"}
                                      </Typography>
                                      <motion.span
                                        variants={textMotion}
                                        className="d-flex justify-content-center align-items-center"
                                      >
                                        <Icon name="arrow-right" />
                                      </motion.span>
                                    </span>
                                  </Button>
                                </motion.div>
                              )}
                            </div>
                          </div>
                        </Paper>
                      </div>
                    </>
                  );
                })
              ) : (
                <Stack width={"100%"} height={"100%"} alignItems={"center"} justifyContent={"center"}>
                  <EmptyFolder />
                </Stack>
              )}
            </div>
          </Stack>
        </>
      )}
    </>
  );
}
