import { useState } from "react";

//^ utils
import { Stack, Typography } from "@mui/material";
import { IOSSwitch } from "../../input/switch/MuiSwitch";

export default function Switch({
  checkedLabel,
  unCheckedLabel,
  checked,
  onClick,
  size,
  spacing,
  translateY,
  translateX,
  checkedTranslateX,
  checkedTranslateY,
  defaultChecked = false,
}) {
  const [isChecked, setIsChecked] = useState(checked || false);

  function toggleCheckHandler() {
    setIsChecked((prevChecked) => !prevChecked);
    onClick(!isChecked);
  }

  return (
    <>
      <Stack direction="row" spacing={spacing || 1} alignItems="center" height={"100%"}>
        <Typography variant={size === "sm" ? "body2" : "body2"}>{checkedLabel}</Typography>
        <IOSSwitch
          translateY={translateY}
          translateX={translateX}
          checkedTranslateX={checkedTranslateX}
          checkedTranslateY={checkedTranslateY}
          size={"small"}
          checked={isChecked}
          onClick={toggleCheckHandler}
        />
        <Typography variant={size === "sm" ? "body2" : "body2"}>{unCheckedLabel}</Typography>
      </Stack>
      {/* <div
        className={`${classes["btn-frame"]} ${isChecked ? classes["checked"] : ""} ${isDark ? classes["dark"] : ""} ${
          classes[`${size}`]
        }`}
      >
        <input
          id="toggle-on"
          className={`${classes["toggle"]} ${classes["toggle-left"]}`}
          name="toggle"
          value="false"
          type="radio"
          checked={isChecked}
          onChange={toggleCheckHandler}
        />
        <label htmlFor="toggle-on" className={`${classes["btn"]} ${classes["left"]}`}>
          {checkedLabel}
        </label>
        <input
          id="toggle-off"
          className={`${classes["toggle"]} ${classes["toggle-right"]}`}
          name="toggle"
          value="true"
          type="radio"
          checked={!isChecked}
          onChange={toggleCheckHandler}
        />
        <label htmlFor="toggle-off" className={`${classes["btn"]} ${classes["right"]}`}>
          {unCheckedLabel}
        </label>
      </div> */}
    </>
  );
}
