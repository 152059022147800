import React, { useEffect, useState, useReducer } from "react";
import { useSearchParams } from "react-router-dom";
import { useInfiniteQuery } from "@tanstack/react-query";

//^ utils
import { generateLinearGradient, responseErrorHandler } from "../../utils/Utils";

//^ http request
import { getUserInvitationHandler, getUserListsHandler } from "../../http/post-api";
import { queryClient } from "../../http";

//^ @mui component
import { Dialog, DialogContent, IconButton, useMediaQuery } from "@mui/material";
import { Close } from "@mui/icons-material";
import { useTheme } from "@mui/material/styles";

//^ tabs
import HomeTab from "./home-tab";
import ConnectionTab from "./connections";
import RequestTab from "./Requests";
import FollowingAndFollowerTab from "./FollowersAndFollowing";
import InvitationTab from "./Invitations";
import UserListProfile from "../../components/ui/modals/user-list-profile/UserListProfile";

export default function UserPage() {
  const theme = useTheme();
  // eslint-disable-next-line
  const fullscreen = useMediaQuery(theme.breakpoints.down("lg"));
  const laptopMatches = useMediaQuery("(min-width: 64rem)");

  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const tabName = searchParams.get("tab");

  //^ states
  const [tabContent, setTabContent] = useState("");

  function userListsReducer(state, action) {
    switch (action.type) {
      case "SET_INVITATIONS":
        return {
          ...state,
          inviteLists: action.payload,
        };
      case "SET_NOT_FOLLOWED":
        return {
          ...state,
          notFollowedUsers: action.payload,
        };
      case "SET_NOT_CONNECTED_USER":
        return {
          ...state,
          notConnectedUsers: action.payload,
        };
      case "IGNORE_INVITATION":
      case "ACCEPT_INVITATION":
        let cachedDataAccept = queryClient.getQueryData(["get-user-invitations"]);
        if (cachedDataAccept) {
          const updatedPages = cachedDataAccept.pages.map((page) => {
            return {
              ...page,
              data: {
                ...page.data,
                invitations: page.data.invitations.filter((item) => item.user_id !== action.user_id),
              },
            };
          });
          queryClient.setQueryData(["get-user-invitations"], { ...cachedDataAccept, pages: updatedPages });
        }

        return {
          ...state,
          inviteLists: state.inviteLists?.filter((list) => list.connection_id !== action.connection_id),
        };
      case "FOLLOW_USER":
        let cachedDataFollow = queryClient.getQueryData(["follow-user"]);
        if (cachedDataFollow) {
          const updatedPages = cachedDataFollow.pages.map((page) => {
            return {
              ...page,
              data: {
                ...page.data,
                list: page.data?.list?.filter((item) => item.user_id !== action.user_id),
              },
            };
          });
          queryClient.setQueryData(["follow-user"], { ...cachedDataFollow, pages: updatedPages });
        }

        const updatedFollowedUsers = state.notFollowedUsers?.filter((list) => list.user_id !== action.user_id);
        const userProfileData = updatedFollowedUsers?.[0];

        const otherUserLists = userProfileData
          ? updatedFollowedUsers.filter((item) => item.user_id !== userProfileData.user_id)
          : updatedFollowedUsers;

        return {
          ...state,
          notFollowedUsers: updatedFollowedUsers,
          userProfileData,
          otherUserLists,
        };
      case "CONNECT_USER":
        let cachedDataConnect = queryClient.getQueryData(["get-not-connected-user"]);
        if (cachedDataConnect) {
          const updatedPages = cachedDataConnect.pages.map((page) => {
            return {
              ...page,
              data: {
                ...page.data,
                user_list: page.data.user_list.map((item) => {
                  if (item.user_id !== action.user_id) return item;
                  return {
                    ...item,
                    connection_status: {
                      connected: false,
                      message: "Pending",
                      status: "0",
                    },
                  };
                }),
              },
            };
          });
          queryClient.setQueryData(["get-not-connected-user"], { ...cachedDataConnect, pages: updatedPages });
        }

        const updatedNotConnectedUsers = state.notConnectedUsers?.map((user) => {
          if (user.user_id !== action.user_id) return user;
          return {
            ...user,
            connection_status: {
              connected: false,
              message: "Pending",
              status: "0",
            },
          };
        });

        let otherNotConnectUser = state?.userProfileData;
        let otherConnectUsers = state?.otherUserLists;

        if (state?.userProfileData?.user_id === action?.user_id) {
          otherNotConnectUser = {
            ...state?.userProfileData,
            connection_status: {
              connected: false,
              message: "Pending",
              status: "0",
            },
          };
        } else {
          otherConnectUsers = state?.otherUserLists?.map((user) => {
            if (user.user_id !== action.user_id) return user;
            return {
              ...user,
              connection_status: {
                connected: false,
                message: "Pending",
                status: "0",
              },
            };
          });
        }

        return {
          ...state,
          notConnectedUsers: updatedNotConnectedUsers,
          userProfileData: otherNotConnectUser,
          otherUserLists: otherConnectUsers,
        };
      case "REMOVE_CONNECT":
        let cachedNotConnectedUser = queryClient.getQueryData(["get-not-connected-user"]);
        if (cachedNotConnectedUser) {
          const updatedPages = cachedNotConnectedUser.pages.map((page) => {
            return {
              ...page,
              data: {
                ...page.data,
                user_list: page.data.user_list.filter((item) => item.user_id !== action.user_id),
              },
            };
          });
          queryClient.setQueryData(["get-not-connected-user"], { ...cachedNotConnectedUser, pages: updatedPages });
        }

        return {
          ...state,
          notConnectedUsers: state.notConnectedUsers?.filter((user) => user.user_id !== action.user_id),
        };
      case "SET_LIMIT":
        return {
          ...state,
          inviteLimit: action.limit,
        };
      case "SET_FOLLOW_LIMIT":
        return {
          ...state,
          followLimit: action.limit,
        };
      case "SET_CONNECT_LIMIT":
        return {
          ...state,
          connectLimit: action.limit,
        };
      case "USER_PROFILE":
        if (action.userCategory === "FOLLOW_USER") {
          let cachedFollowerList = queryClient.getQueryData([`follow-user`, tabContent]);
          if (cachedFollowerList) {
            const userListProfile = cachedFollowerList.pages.find((page) => {
              return page.data.list.find((item) => item.user_id === action.user_id);
            });

            if (userListProfile) {
              const filteredUserLists = action.userLists?.filter((item) => item.user_id !== action.user_id);
              state.otherUserLists = filteredUserLists || [];

              return {
                ...state,
                showUserProfile: action.showUserProfile,
                userId: action.user_id,
                userProfileData: action.userProfileData,
                type: action.userCategory,
              };
            }
          }
        } else if (action.userCategory === "CONNECT_USER") {
          let cachedConnectList = queryClient.getQueryData([`get-not-connected-user`, tabContent]);
          if (cachedConnectList) {
            const userListProfile = cachedConnectList.pages.find((page) => {
              return page.data.user_list?.find((item) => item.user_id === action.user_id);
            });

            if (userListProfile) {
              const filteredUserLists = action.userLists?.filter((item) => item.user_id !== action.user_id);
              state.otherUserLists = filteredUserLists || [];

              return {
                ...state,
                showUserProfile: action.showUserProfile,
                userId: action.user_id,
                type: action.userCategory,
                userProfileData: action.userProfileData,
              };
            }
          }
        }

        return {
          ...state,
          showUserProfile: action.showUserProfile,
          userId: action.user_id,
          type: action.userCategory,
        };

      default:
        return state;
    }
  }

  const initialState = {
    inviteLists: [],
    notFollowedUsers: [],
    notConnectedUsers: [],
    inviteLimit: 5,
    followLimit: 5,
    connectLimit: 5,
    userId: 0,
    showUserProfile: false,
    userProfileData: {},
    otherUserLists: [],
    type: "",
  };

  //^ states

  const [state, dispatch] = useReducer(userListsReducer, initialState);

  useEffect(() => {
    switch (tabName) {
      case "Connections":
        setTabContent(<ConnectionTab />);
        break;
      case "Requests":
        setTabContent(<RequestTab />);
        break;
      case "Following & Follower":
        setTabContent(<FollowingAndFollowerTab />);
        break;
      case "Invitations":
        setTabContent(<InvitationTab />);
        break;
      default:
        setTabContent("");
        break;
    }
  }, [tabName]);

  //^ get invitations query fn ===================================================================================
  const {
    data: userInvitationData,
    isLoading: userInvitationIsLoading,
    isRefetching: userInvitationIsRefetching,
    hasNextPage: userInvitationHasNextPage,
    fetchNextPage: userInvitationFetchNextPage,
    refetch: userInvitationRefetch,
  } = useInfiniteQuery({
    queryKey: ["get-user-invitations", tabContent],
    queryFn: async ({ pageParam }) => {
      return await getUserInvitationHandler({
        limit: state.inviteLimit,
        page: pageParam,
        type: "received",
        tab: "home",
      });
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.status ? allPages.length + 1 : undefined;
    },
    gcTime: 0,
    staleTime: Infinity,
  });

  //^ get invitations query fn ===================================================================================

  //^ get not followed peoples query fn ==========================================================================
  const {
    data: followersData,
    isLoading: followersIsLoading,
    isRefetching: followersIsRefetching,
    fetchNextPage: followersFetchNextPage,
    hasNextPage: followersHasNextPage,
    refetch: followersRefetch,
  } = useInfiniteQuery({
    queryKey: [`follow-user`, tabContent],
    queryFn: (props) =>
      getUserListsHandler({
        page: props.pageParam,
        type: "not_followed",
        pagination: 1,
        limit: state.followLimit,
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.status ? allPages.length + 1 : undefined;
    },
    gcTime: 0,
    staleTime: Infinity,
  });

  //^ get not followed peoples query fn ==========================================================================

  //^ get not connected peoples query fn ==========================================================================
  const {
    data: getUserConnectData,
    isLoading: getUserConnectIsLoading,
    isRefetching: getUserConnectIsRefetching,
    isError: getUserConnectIsError,
    error: getUserConnectError,
    fetchNextPage: getUserFetchNextPage,
    hasNextPage: getUserHasNextPage,
  } = useInfiniteQuery({
    queryKey: ["get-not-connected-user", tabContent],
    queryFn: (props) =>
      getUserListsHandler({
        type: "not_connected",
        page: props.pageParam,
        limit: state.connectLimit,
        pagination: 1,
        include: "pending",
      }),
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.status ? allPages.length + 1 : undefined;
    },
    gcTime: 0,
    staleTime: Infinity,
  });

  useEffect(() => {
    if (!getUserConnectIsLoading || !getUserConnectIsRefetching) {
      const notConnectedUserLists = getUserConnectData?.pages
        ?.map((page) => page?.data?.user_list)
        .flat()
        .filter((page) => page !== undefined);

      const notConnectedUserList = notConnectedUserLists?.map((userList) => {
        const gradientString = generateLinearGradient();

        return {
          bannerImgString: `${gradientString}`,
          ...userList,
        };
      });
      // .filter((userList) => userList?.follow_status.following === false);

      dispatch({
        type: "SET_NOT_CONNECTED_USER",
        payload: notConnectedUserList,
      });
    }

    if (!followersIsLoading || !followersIsRefetching) {
      const flattenedFollowersLists = followersData?.pages
        ?.map((page) => page?.data?.list)
        ?.flat()
        ?.filter((page) => page !== undefined);

      const followUserLists = flattenedFollowersLists?.map((userList) => {
        const gradientString = generateLinearGradient();

        return {
          bannerImgString: `${gradientString}`,
          ...userList,
        };
      });

      dispatch({
        type: "SET_NOT_FOLLOWED",
        payload: followUserLists,
      });
    }

    if (!userInvitationIsLoading || !userInvitationIsRefetching) {
      const invitationData = userInvitationData?.pages
        .map((page) => page?.data?.requests)
        .flat()
        .filter((page) => page !== undefined);

      dispatch({ type: "SET_INVITATIONS", payload: invitationData });
    }
  }, [
    getUserConnectIsLoading,
    getUserConnectData,
    getUserConnectIsRefetching,
    followersIsLoading,
    followersIsRefetching,
    userInvitationIsLoading,
    userInvitationIsRefetching,
    followersData,
    userInvitationData,
  ]);

  useEffect(() => {
    responseErrorHandler(getUserConnectIsError, getUserConnectError);
  }, [getUserConnectIsError, getUserConnectError]);

  //^ get not connected peoples query fn ==========================================================================

  function handleUserProfileModalClose() {
    dispatch({ type: "USER_PROFILE", user_id: 0, showUserProfile: false, userCategory: "" });
  }

  return (
    <>
      {state.showUserProfile && (
        <Dialog
          fullScreen={fullscreen}
          open={state.showUserProfile}
          onClose={handleUserProfileModalClose}
          PaperProps={{
            component: "form",
            elevation: 4,
            sx: {
              width: laptopMatches ? "70%" : "100%",
              height: laptopMatches ? "90%" : "100%",
              minWidth: "unset",
              maxWidth: "unset",
            },
          }}
        >
          <IconButton
            aria-label="close"
            onClick={handleUserProfileModalClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </IconButton>
          <DialogContent sx={{ padding: theme.spacing(0), paddingTop: theme.spacing(7) }}>
            <UserListProfile
              isFetchNextPage={true}
              fetchNextPage={state?.type === "FOLLOW_USER" ? followersRefetch : getUserConnectIsRefetching}
              hasNextPage={state?.type === "FOLLOW_USER" ? followersHasNextPage : userInvitationHasNextPage}
              dispatch={dispatch}
              type={state?.type}
              otherUserLists={state?.otherUserLists}
              userProfileData={state.userProfileData}
            />
          </DialogContent>
        </Dialog>
      )}

      {tabName === null ? (
        <HomeTab
          dispatch={dispatch}
          //^ invitation section props
          inviteLists={state.inviteLists}
          userInvitationHasNextPage={userInvitationHasNextPage}
          userInvitationFetchNextPage={userInvitationFetchNextPage}
          userInvitationRefetch={userInvitationRefetch}
          inviteIsLoading={userInvitationIsLoading || userInvitationIsRefetching}
          inviteInitialLimit={initialState.connectLimit}
          notFollowedPeoples={state.notFollowedUsers}
          //^ follower section props
          followersHasNextPage={followersHasNextPage}
          followersRefetch={followersRefetch}
          followersFetchNextPage={followersFetchNextPage}
          userListIsLoading={followersIsLoading}
          followerInitialLimit={initialState.followLimit}
          //^ connection section props
          notConnectedIsLoading={getUserConnectIsLoading}
          notConnectedUsers={state.notConnectedUsers}
          getUserFetchNextPage={getUserFetchNextPage}
          getUserHasNextPage={getUserHasNextPage}
          connectionInitialLimit={initialState.connectLimit}
        />
      ) : (
        ""
      )}
      {tabContent}
    </>
  );
}
