import { Box, Card, CardActions, Skeleton, Stack } from "@mui/material";

export default function UserListSkeletonLoader({ length }) {
  return Array.from({ length: length ? length : 5 }).map((_, index) => (
    <Box key={index}>
      <Stack alignItems={"center"} justifyContent={"center"}>
        <Card
          variant="outlined"
          elevation={3}
          sx={{
            height: "100%",
            width: "100%",
            borderRadius: "0.725rem",
          }}
        >
          <Stack height={"100%"} justifyContent={"space-between"} gap={3}>
            <Stack position={"relative"} width={"100%"}>
              <Skeleton animation={"pulse"} variant="rectangular" width={"100%"} height={"6rem"} />
              <Stack
                direction={"row"}
                position={"absolute"}
                zIndex={1}
                bottom={"-2rem"}
                width={"100%"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <Box>
                  <Skeleton variant="circular" animation="pulse" width={78} height={78} />
                </Box>
              </Stack>
            </Stack>
            <CardActions
              sx={{
                padding: "1.25rem",
                paddingTop: "0rem",
                width: "100%",
              }}
            >
              <Stack spacing={4} alignItems={"center"} width={"100%"} pt={"1.5rem"}>
                <Skeleton variant="text" animation="pulse" width={"60%"} height={"1.9rem"} />
                <Skeleton
                  variant="rounded"
                  animation="pulse"
                  sx={{
                    width: "7.5rem",
                    height: "2.25rem",
                    borderRadius: "10rem",
                  }}
                />
              </Stack>
            </CardActions>
          </Stack>
        </Card>
      </Stack>
    </Box>
  ));
}
