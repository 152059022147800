import React, { useState, createContext } from "react";
import { Outlet } from "react-router-dom";

const todays = new Date();
const todaysDay = todays.getDate() < 10 ? "0" + todays.getDate() : todays.getDate();
const todaysYear = todays.getFullYear();
let todaysMonth = todays.getMonth() + 1;
todaysMonth = todaysMonth < 10 ? "0" + todaysMonth : todaysMonth;
export const todaysDate = `${todaysYear}-${todaysMonth}-${todaysDay}`;

export const UserContext = createContext({
  first_name: "",
  last_name: "",
  phone_number: "",
  dob: todaysDate,
  address_1: "",
  address_2: "",
  state: "",
  country: "US",
  country_value: 231,
  account_tokens: 0,
  notifications: [],
  two_fact_phone: "",
  two_fact_email: "",
});

export const UserContextProvider = (props) => {
  const [data, setData] = useState(null);

  return (
    <UserContext.Provider value={{ loggedInUser: data, setData }}>
      <Outlet />
    </UserContext.Provider>
  );
};
