import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  userProfile: undefined,
};

const twoFactorUserProfileSlice = createSlice({
  initialState,
  name: "twoFactor-user-profile-slice",
  reducers: {
    setUserProfileHandler: (state, { payload }) => {
      state.userProfile = payload;
    },
  },
});

export const twoFactorServicePlanAction = twoFactorUserProfileSlice.actions;

const twoFactorServicePlanReducer = twoFactorUserProfileSlice.reducer;
export default twoFactorServicePlanReducer;
