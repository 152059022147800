//^ @mui component
import { Stack, Box, Skeleton, Divider } from "@mui/material";

export default function InviteListLoader() {
  return (
    <Box padding={"0rem 1rem"} width={"100%"}>
      <Stack width={"100%"}>
        <Divider />
        {Array.from({ length: 4 }).map((_, index) => (
          <Stack width={"100%"} key={index}>
            <Stack
              gap={2}
              sx={{
                "@media (min-width: 65.5rem)": {
                  flexDirection: "row",
                },
              }}
            >
              <Stack direction={"row"} gap={2} alignItems={"center"} width={"100%"} padding={"0.625rem 0rem"}>
                <Skeleton animation="pulse" variant="circular" width={"2.5rem"} height={"2.5rem"} />
                <Skeleton
                  variant="text"
                  height={"2.25rem"}
                  sx={{
                    width: "100%",
                    "@media (min-width: 65.5rem)": {
                      width: "30%",
                    },
                  }}
                  animation="pulse"
                />
              </Stack>
              <Stack
                direction={"row"}
                gap={2}
                sx={{
                  alignItems: "flex-end",
                  justifyContent: "flex-end",
                  width: "100%",
                  paddingBottom: "1rem",
                  "@media (min-width: 65.5rem)": {
                    alignItems: "center",
                    paddingBottom: "0rem",
                  },
                }}
              >
                <Skeleton variant="rounded" height={"2rem"} width={"5rem"} animation="pulse" />
                <Skeleton
                  variant="rounded"
                  height={"2rem"}
                  sx={{ borderRadius: "10rem" }}
                  width={"5rem"}
                  animation="pulse"
                />
              </Stack>
            </Stack>
            <Divider />
          </Stack>
        ))}
      </Stack>
    </Box>
  );
}
