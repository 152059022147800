export default function Avatar({ style, className }) {
  return (
    <svg
      width="291"
      height="291"
      style={style}
      className={className}
      viewBox="0 0 291 291"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_229_252)">
        <path
          d="M245.844 245.845C220.758 270.931 185.637 291 145.499 291C105.361 291 70.2405 270.931 45.1543 245.845L50.1715 240.828C70.2405 265.914 105.361 280.966 145.499 280.966C180.62 280.966 215.741 265.914 240.827 240.828L245.844 245.845Z"
          fill="#00AFEF"
        />
        <path
          d="M245.845 45.1553C270.932 70.2415 291.001 105.362 291.001 145.5C291.001 180.621 275.949 215.741 250.863 240.828L245.845 235.81C270.932 210.724 280.966 180.621 280.966 145.5C280.966 105.362 265.914 70.2415 240.828 50.1725L245.845 45.1553Z"
          fill="#F58634"
        />
        <path
          d="M45.1552 50.1725C20.069 70.2415 5.01724 105.362 5.01724 145.5C5.01724 180.621 20.069 210.724 45.1552 235.81L35.1207 240.828C15.0517 215.741 0 180.621 0 145.5C0 105.362 15.0517 70.2415 40.1379 45.1553L45.1552 50.1725Z"
          fill="#00A859"
        />
        <path
          d="M145.499 0C180.62 0 215.741 15.0517 240.827 35.1207L235.809 45.1552C210.723 20.069 180.62 5.01724 145.499 5.01724C110.378 5.01724 75.2577 20.069 50.1715 45.1552L45.1543 35.1207C75.2577 15.0517 105.361 0 145.499 0Z"
          fill="#ED3237"
        />
        <path
          d="M145.499 275.948C217.544 275.948 275.947 217.545 275.947 145.5C275.947 73.4554 217.544 15.0518 145.499 15.0518C73.4545 15.0518 15.0508 73.4554 15.0508 145.5C15.0508 217.545 73.4545 275.948 145.499 275.948Z"
          fill="#2B2A29"
        />
        <path
          d="M145.501 150.517C170.439 150.517 190.656 130.301 190.656 105.362C190.656 80.4237 170.439 60.207 145.501 60.207C120.562 60.207 100.346 80.4237 100.346 105.362C100.346 130.301 120.562 150.517 145.501 150.517Z"
          fill="#FEFEFE"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M180.621 150.518C205.707 165.569 225.776 195.673 225.776 225.776V230.793C205.707 250.862 175.604 260.897 145.5 260.897C110.379 260.897 85.2932 250.862 60.207 230.793V225.776C60.207 195.673 80.276 165.569 110.379 150.518C120.414 160.552 130.448 165.569 145.5 165.569C155.535 165.569 170.586 160.552 180.621 150.518Z"
          fill="#FEFEFE"
        />
      </g>
      <defs>
        <clipPath id="clip0_229_252">
          <rect width="291" height="291" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
