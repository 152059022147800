import React, { useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
//eslint-disable-next-line
import { useSearchParams, Link as MUILink } from "react-router-dom";

//^ redux action
import { userNetworkAction } from "../../../store/slices/user-networks-slice";

//^ http requests
import { deleteConnectionHandler, requestConnectionHandler } from "../../../http/post-api";
import { queryClient } from "../../../http";

//^ @mui component
import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Stack,
  useMediaQuery,
} from "@mui/material";
import ImageWithPlaceholder from "../../../components/ui/auth-otp/image/image-with-placeholder";
import { AccessTime, MoreVert, PersonAddRounded, PersonRemoveRounded } from "@mui/icons-material";
import { Box, CircularProgress, Menu, MenuItem, Typography } from "@mui/material";
import { responseErrorHandler, shortenString } from "../../../utils/Utils";
import { TooltipComponent } from "../../../components/Component";
import { useSelector } from "react-redux";

export const circularBtnStyle = {
  borderRadius: "10rem",
  padding: "0.25rem 1rem",
  minWidth: "7rem",
};

function UserList(props) {
  //eslint-disable-next-line
  const matches = useMediaQuery("(min-width: 65.5rem)");

  const connectionMutationProgress = useSelector((state) => state.userNetwork.connectionMutationProgress);

  // eslint-disable-next-line
  const [searchParams, _setSearchParams] = useSearchParams();
  const connectType = searchParams.get("connectType") || "connected";

  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //^ delete connection mutation fn ================================================================

  const {
    isPending: deleteConnectionIsPending,
    isError: deleteConnectionIsError,
    error: deleteConnectionError,
    mutate: deleteConnectionMutate,
    reset: deleteConnectionReset,
  } = useMutation({
    mutationKey: ["delete-connection"],
    mutationFn: deleteConnectionHandler,
    onSuccess: async (data) => {
      if (data?.toast) {
        if (data?.status) {
          // toast.success(data?.message);
          await queryClient.refetchQueries({
            queryKey: ["get-user-list-summary"],
            type: "active",
            exact: true,
          });
        } else {
          responseErrorHandler(true, data);
        }
      }
      deleteConnectionReset();
    },
    onSettled: () => {
      dispatch(
        userNetworkAction.setNetworkMutationProgressValue({ stateValue: "connectionMutationProgress", status: false })
      );
    },
  });

  useEffect(() => {
    if (deleteConnectionIsError) {
      responseErrorHandler(deleteConnectionIsError, deleteConnectionError);
      deleteConnectionReset();
    }
  }, [deleteConnectionIsError, deleteConnectionError, deleteConnectionReset]);

  //^ delete connection mutation fn ================================================================

  //^ connection mutation fn ================================================================

  const {
    isPending: requestConnectionIsPending,
    isError: requestConnectionIsError,
    error: requestConnectionError,
    mutate: requestConnectionMutate,
    reset: requestConnectionReset,
  } = useMutation({
    mutationKey: ["request-connection"],
    mutationFn: requestConnectionHandler,
    onSuccess: async (data) => {
      if (data?.toast) {
        if (data?.status) {
          // toast.success(data?.message);
          await queryClient.refetchQueries({
            queryKey: ["get-user-list-summary"],
            type: "active",
            exact: true,
          });
        } else {
          responseErrorHandler(true, data);
        }
      }
      requestConnectionReset();
    },
    onSettled: () => {
      dispatch(
        userNetworkAction.setNetworkMutationProgressValue({ stateValue: "connectionMutationProgress", status: false })
      );
    },
  });

  useEffect(() => {
    if (requestConnectionIsError) {
      responseErrorHandler(true, requestConnectionError);
      requestConnectionReset();
    }
  }, [requestConnectionIsError, requestConnectionError, requestConnectionReset]);

  //^ connection mutation fn ================================================================

  const handleDeleteConnection = (event, connection_id) => {
    if (connectionMutationProgress) return;
    dispatch(
      userNetworkAction.setNetworkMutationProgressValue({ stateValue: "connectionMutationProgress", status: true })
    );

    dispatch(
      userNetworkAction.removeUserListHandler({
        type: "CONNECT",
        connection_id,
      })
    );
    deleteConnectionMutate({ connection_id: connection_id?.toString(), type: "single" });
    handleClose();
  };
  const handleRequestConnection = (event, user_id) => {
    if (connectionMutationProgress) return;
    dispatch(
      userNetworkAction.setNetworkMutationProgressValue({ stateValue: "connectionMutationProgress", status: true })
    );

    dispatch(
      userNetworkAction.removeUserListHandler({
        type: "NOT_CONNECTED",
        user_id,
      })
    );
    requestConnectionMutate({ user_id });
    handleClose();
  };

  return (
    <List
      sx={{
        width: "100%",
      }}
    >
      <ListItem
        secondaryAction={
          <Stack direction={"row"} alignItems={"center"} gap={2} width={"100%"}>
            <Box>
              {/* <Button
                type={"button"}
                sx={circularBtnStyle}
                variant="outlined"
                size="small"
                startIcon={<RemoveRounded />}
                onClick={() =>
                  removeItemHandler({ id: connectType === "connected" ? props.row.connection_id : props.row.user_id })
                }
              >
                Remove
              </Button> */}
            </Box>
            <Stack direction={"row"} alignItems={"center"} gap={2} width={"100%"}>
              {props?.row?.connection_status?.message !== "Pending" ? (
                props?.row?.connection_status?.connected === true ? (
                  <Box sx={{ display: "none", "@media (min-width: 65.5rem)": { display: "block" } }}>
                    <Button
                      startIcon={<PersonRemoveRounded />}
                      endIcon={deleteConnectionIsPending && <CircularProgress size={16} color="inherit" />}
                      variant="contained"
                      sx={circularBtnStyle}
                      size="small"
                      onClick={(event) => handleDeleteConnection(event, props.row.connection_id)}
                      disabled={connectionMutationProgress}
                    >
                      remove Connection
                    </Button>
                  </Box>
                ) : (
                  <Box sx={{ display: "none", "@media (min-width: 65.5rem)": { display: "block" } }}>
                    <Button
                      startIcon={<PersonAddRounded />}
                      endIcon={requestConnectionIsPending && <CircularProgress size={16} color="inherit" />}
                      variant="contained"
                      sx={circularBtnStyle}
                      size="small"
                      onClick={(event) => handleRequestConnection(event, props.row.user_id)}
                      disabled={connectionMutationProgress}
                    >
                      connect
                    </Button>
                  </Box>
                )
              ) : (
                <Stack direction={"row"} justifyContent={"flex-end"} width={"100%"} alignContent={"flex-end"}>
                  <Button startIcon={<AccessTime />} variant="outlined" disabled sx={circularBtnStyle}>
                    {props?.row?.connection_status?.message}
                  </Button>
                </Stack>
              )}
              {(props?.row?.connection_status?.connected === true ||
                props?.row?.connection_status?.message !== "Pending") && (
                <Box sx={{ display: "block", "@media (min-width: 65.5rem)": { display: "none" } }}>
                  {(props?.row?.connection_status?.connected === true ||
                    props?.row?.connection_status?.message !== "Pending") && (
                    <IconButton onClick={handleClick}>
                      <MoreVert />
                    </IconButton>
                  )}
                  {(props?.row?.connection_status?.connected === true ||
                    props?.row?.connection_status?.message !== "Pending") && (
                    <Menu
                      id="long-menu"
                      MenuListProps={{ "aria-labelledby": "long-button" }}
                      anchorEl={anchorEl}
                      open={open}
                      onClose={handleClose}
                      PaperProps={{ style: { maxHeight: 48 * 4.5, minWidth: "20ch" } }}
                    >
                      {props?.row?.connection_status?.connected === true && (
                        <MenuItem onClick={(event) => handleDeleteConnection(event, props.row.connection_id)}>
                          <Typography
                            component={Stack}
                            direction={"row"}
                            alignItems={"center"}
                            gap={2}
                            variant="subtitle2"
                          >
                            <PersonRemoveRounded sx={{ fontSize: "1.25rem" }} />
                            <span>Remove Connection</span>
                          </Typography>
                        </MenuItem>
                      )}
                      {props?.row?.connection_status?.message !== "Pending" && connectType === "notConnected" && (
                        <MenuItem onClick={(event) => handleRequestConnection(event, props.row.user_id)}>
                          <Typography
                            component={Stack}
                            direction={"row"}
                            alignItems={"center"}
                            gap={2}
                            variant="subtitle2"
                          >
                            <PersonAddRounded sx={{ fontSize: "1.25rem" }} />
                            <span>Connect</span>
                          </Typography>
                        </MenuItem>
                      )}
                    </Menu>
                  )}
                </Box>
              )}
            </Stack>
          </Stack>
        }
      >
        <ListItemAvatar>
          <ImageWithPlaceholder
            showAvatarPlaceholder={true}
            avatar={true}
            alt={props.row.username}
            src={props.row.avatar_url}
            style={{ borderRadius: "50%" }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            props.row.username?.length > 12 ? (
              <TooltipComponent
                type="text"
                content={
                  <Typography variant="subtitle1" noWrap>
                    {shortenString(props.row.username, 12)}
                  </Typography>
                }
                direction="top"
                id={`username-${connectType === "connected" ? props.row.connection_id : props.row.user_id}`}
                text={props.row.username}
              />
            ) : (
              <Typography variant="subtitle1" noWrap>
                {props.row.username}
              </Typography>
            )
          }
          primaryTypographyProps={{ noWrap: true }}
        />
      </ListItem>

      <Divider variant="inset" component="li" />
    </List>
  );
}

export const columns = [
  {
    accessorKey: "userList",
    header: "User List",
    /**
     * Returns a JSX element representing the UserList component with the provided row data.
     *
     * @param {Object} row - The data object for the current row.
     * @return {JSX.Element} A JSX element representing the UserList component.
     */
    accessorFn: (row) => {
      return <UserList row={row} />;
    },
  },
  { accessorKey: "username" },
  { accessorKey: "lastName" },
  { accessorKey: "email" },
  { accessorKey: "created_at" },
];
