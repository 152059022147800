import { useMediaQuery, Stack, Button, OutlinedInput } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import * as yup from "yup";

//^ http request
import { validatePromoCodeHandler } from "../../http/post-api";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import Loader from "../ui/loader/Loader";
import ErrorMessage from "../ui/error-message/ErrorMessage";
import { responseErrorHandler } from "../../utils/Utils";

export default function PromoCode({ onPromoCode, promoCodeDetails = [] }) {
  const [alreadyPresentPromo, setAlreadyPresentPromo] = useState(false);
  // eslint-disable-next-line
  const lgMatches = useMediaQuery("(min-width: 65.5rem)");

  const formik = useFormik({
    initialValues: {
      promoCode: "",
    },
    validationSchema: yup.object({
      promoCode: yup
        .string()
        .required("Promo code is required")
        .matches(/^[a-zA-Z0-9]{4,10}$/, "Promo code must be between 4 and 10 characters"),
    }),
    onSubmit: (values) => {
      validatePromoMutate(values);
    },
  });

  const {
    isPending: validatePromoIsPending,
    isError: validatePromoIsError,
    error: validatePromoError,
    mutate: validatePromoMutate,
    reset: validatePromoReset,
  } = useMutation({
    mutationKey: ["validate-promo-code"],
    mutationFn: validatePromoCodeHandler,
    onSuccess: (data) => {
      if (data?.toast) {
        if (data?.status) {
          onPromoCode(data, formik);
          toast.success(data?.message);
        } else {
          if (data?.redirect) {
            window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
          } else {
            responseErrorHandler(true, data);
          }
        }
      }
      validatePromoReset();
    },
  });

  useEffect(() => {
    responseErrorHandler(validatePromoIsError, validatePromoError);
  }, [validatePromoIsError, validatePromoError]);

  const promoCodeChangeHandler = (e) => {
    const value = e.target.value;
    const promoCodeNm = promoCodeDetails.some((promoCode) => promoCode.name === value);

    if (promoCodeNm) {
      setAlreadyPresentPromo(true);
    } else {
      setAlreadyPresentPromo(false);
    }
    formik?.handleChange(e);
  };

  useEffect(() => {
    const promoCodeNm = promoCodeDetails.some((promoCode) => promoCode.name === formik?.values?.promoCode);

    if (promoCodeNm) {
      setAlreadyPresentPromo(true);
      formik.setFieldValue("promoCode", "");
    } else {
      setAlreadyPresentPromo(false);
    }

    //eslint-disable-next-line
  }, [promoCodeDetails, formik.values.promoCode]);

  return (
    <Stack>
      <form onSubmit={formik?.handleSubmit}>
        <Stack gap={"0.325rem"}>
          <Stack
            gap={lgMatches ? "0.435rem" : "1rem"}
            direction={lgMatches ? "row" : "column"}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Stack gap={"0.025rem"} width={"100%"}>
              <Stack gap={"0.325rem"}>
                <OutlinedInput
                  type="text"
                  id="promo-code"
                  name={"promoCode"}
                  value={formik.values.promoCode}
                  onChange={promoCodeChangeHandler}
                  onBlur={formik?.handleBlur}
                  size="small"
                  placeholder="Enter Promo Code"
                  fullWidth
                  // disabled={isPromoCodeApplied}
                />
                {!lgMatches && formik?.errors["promoCode"] && formik?.touched["promoCode"] ? (
                  <ErrorMessage errorMsg={formik?.errors["promoCode"]} />
                ) : alreadyPresentPromo ? (
                  ""
                ) : (
                  ""
                )}
              </Stack>
            </Stack>
            <Stack
              sx={{
                width: "100%",
                display: "block",
                "@media (min-width: 65.5rem)": {
                  width: "auto",
                  display: "none",
                },
              }}
            >
              <Button
                variant="contained"
                type="submit"
                disabled={!formik.isValid || validatePromoIsPending || alreadyPresentPromo}
                fullWidth
              >
                Apply
              </Button>
            </Stack>
            <Stack
              sx={{
                width: "100%",
                display: "none",
                "@media (min-width: 65.5rem)": {
                  width: "auto",
                  display: "block",
                },
              }}
            >
              <Button
                variant="contained"
                disabled={!formik.isValid || validatePromoIsPending || alreadyPresentPromo}
                type="submit"
                startIcon={validatePromoIsPending ? <Loader /> : ""}
                size="large"
              >
                {alreadyPresentPromo ? "Applied" : "Apply"}
              </Button>
            </Stack>
          </Stack>
          {lgMatches && formik?.errors["promoCode"] && formik?.touched["promoCode"] ? (
            <ErrorMessage errorMsg={formik?.errors["promoCode"]} />
          ) : alreadyPresentPromo ? (
            ""
          ) : (
            ""
          )}
        </Stack>
      </form>
    </Stack>
  );
}
