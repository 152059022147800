import React, { forwardRef } from "react";
import { Box, Skeleton } from "@mui/material";
import useImageLoader from "../../../../hooks/user-image-loader";
import Avatar from "../../../svg/avatar";

const ImageWithPlaceholder = forwardRef(
  ({ src, alt, style, width, height, avatar = false, showAvatarPlaceholder = false, ...props }, ref) => {
    const { loaded, currentSrc } = useImageLoader(src);

    return (
      <Box style={{ width: "100%", height: "100%" }}>
        {!loaded &&
          (showAvatarPlaceholder ? (
            <Avatar style={{ height: avatar ? 40 : height, width: avatar ? 40 : width }} />
          ) : (
            <Skeleton
              variant="rectangular"
              sx={{
                width: avatar ? 40 : width || "100%",
                height: avatar ? 40 : height || "100%",
                ...style,
              }}
            />
          ))}
        {loaded && (
          <img
            loading="lazy"
            src={currentSrc}
            alt={alt}
            ref={ref}
            width={width}
            height={height}
            style={{
              width: avatar ? 40 : width || "100%",
              height: avatar ? 40 : height || "100%",
              transition: "opacity 0.5s ease",
              opacity: loaded ? 1 : 0,
              ...style,
            }}
            {...props}
          />
        )}
      </Box>
    );
  }
);

export default ImageWithPlaceholder;
