import { Box, Button, Card, Grid, Skeleton, Stack, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

export function InputWrapperSkeleton({ height }) {
  return (
    <Stack gap={"0.325rem"}>
      <Skeleton width={"40%"} />
      <Skeleton variant="rounded" width={"100%"} height={height ? height : "2.5rem"} />
    </Stack>
  );
}

export default function PersonalInfoFormSkeleton() {
  const isDark = useTheme().palette.mode === "dark";
  const laptopMatches = useMediaQuery("(min-width: 65.5rem)");

  return (
    <Card
      sx={laptopMatches ? {} : { background: "transparent", border: "none" }}
      elevation={isDark ? 0.6 : 0}
      variant={isDark ? "elevation" : "outlined"}
    >
      <Box width={"100%"}>
        <Grid padding={laptopMatches ? "2.5rem 2.5125rem" : "0rem"} container spacing={5} rowSpacing={1.5}>
          {Array.from({ length: 10 }).map((_, index) => (
            <Grid key={index} item xs={12} md={6}>
              <InputWrapperSkeleton />
            </Grid>
          ))}
          <Grid item xs={12} md={6}>
            <InputWrapperSkeleton height={"4.5rem"} />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputWrapperSkeleton height={"4.5rem"} />
          </Grid>
        </Grid>
        <Stack width="100%" alignItems={"center"} padding={laptopMatches ? "0rem 0rem 1.25rem 0rem" : "1.25rem 0rem"}>
          <Box sx={{ width: laptopMatches ? "unset" : "100%" }}>
            <Button disabled={true} variant="contained" type="submit" fullWidth={laptopMatches ? false : true}>
              Update
            </Button>
          </Box>
        </Stack>
      </Box>
    </Card>
  );
}
