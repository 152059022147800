import React, { useState } from "react";
import { useQuery } from "@tanstack/react-query";

//^ http request
import { getAllCountries, getUserProfileHandler } from "../../../http/get-api";

//^ lib
import Head from "../../../layout/head/Head";
import { isEmptyObject, responseErrorHandler } from "../../../utils/Utils";

//^ mui components
import { Box, Grid } from "@mui/material";

//^ components
import UserCard from "../../../components/UserCard";
import Title from "../../../components/title/Title";
import PersonalInfoForm from "../../../components/personal-info-form/PersonalInfoForm";
import PersonalInfoFormSkeleton from "../../../components/ui/loader/Skeleton/PersonalInfoFormSkeleton";
import EditProfileDetails from "./EditProfileDetails";
import BackButton from "../../../components/button/back-button";

const UserProfileRegularPage = () => {
  const [editForm, setEditForm] = useState(false);
  const [userInfo, setUserInfo] = React.useState({});
  const [connectionSummary, setConnectionSummary] = React.useState({
    totalConnections: 0,
    totalRequests: 0,
    totalFollowers: 0,
    totalFollowings: 0,
  });

  const {
    data: userInfoData,
    isLoading: userInfoIsLoading,
    isRefetching: userInfoIsRefetching,
    // isError: userInfoIsError,
    // error: userInfoError,
  } = useQuery({
    queryKey: ["get-user-information"],
    queryFn: getUserProfileHandler,
  });

  React.useEffect(() => {
    if (!userInfoIsLoading && !userInfoIsRefetching) {
      if (!userInfoData?.status) {
        setUserInfo({});
        responseErrorHandler(true, userInfoData);
      } else {
        setUserInfo(userInfoData);

        function findAmount(valArr, value) {
          const findValue = valArr?.find((val) => val?.value === value);
          return findValue?.amount;
        }
        let totalConnections = 0;
        let totalRequests = 0;
        let totalFollowers = 0;
        let totalFollowing = 0;
        userInfoData?.data?.profile?.connection_summary?.forEach((summary) => {
          switch (summary?.label) {
            case "Connections":
              const connectionValue = findAmount(summary?.values, "connected");
              totalConnections = connectionValue;
              break;
            case "Requests":
              const sentValue = findAmount(summary?.values, "sent");
              const receivedValue = findAmount(summary?.values, "received");
              totalRequests = sentValue + receivedValue;
              break;
            case "Following":
              const followingValue = findAmount(summary?.values, "followed");
              totalFollowing = followingValue;
              break;
            case "Followers":
              const followerValue = findAmount(summary?.values, "followers");
              totalFollowers = followerValue;
              break;
            default:
              break;
          }
        });

        setConnectionSummary({
          totalConnections,
          totalRequests,
          totalFollowers,
          totalFollowings: totalFollowing,
        });
      }
    }
  }, [userInfoData, userInfoIsLoading, userInfoIsRefetching]);
  return (
    <React.Fragment>
      <Head title="User Profile" />
      <Title
        title={"Personal Information"}
        endComponent={
          <BackButton isEditArrow="no" title={"Edit"} variant={"text"} onClick={() => setEditForm(!editForm)} />
        }
      />
      <Box width={"100%"}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
            <UserCard
              avatarImg={userInfoData?.data?.profile?.profile_image_path}
              coverImg={userInfoData?.data?.profile?.cover_img}
              username={userInfoData?.data?.profile?.username}
              subscriptionCategory={userInfoData?.data?.profile?.member}
              showConnection={true}
              connectionCounts={connectionSummary.totalConnections}
              requestCounts={connectionSummary.totalRequests}
              followerCounts={connectionSummary.totalFollowers}
              followingCounts={connectionSummary.totalFollowings}
              isLoading={userInfoIsLoading}
            />
          </Grid>
          <Grid item xs={12} md={9}>
            {userInfoIsLoading || isEmptyObject(userInfo) ? (
              <PersonalInfoFormSkeleton isLoading={userInfoIsLoading} />
            ) : editForm ? (
              <PersonalInfoForm
                countries={userInfoData?.data?.profile?.country || ""}
                isLoading={userInfoIsLoading}
                firstName={userInfo?.data?.profile?.first_name}
                lastName={userInfo?.data?.profile?.last_name}
                email={userInfo?.data?.profile?.email}
                aboutMe={userInfo?.data?.profile?.about_me}
                address1={userInfo?.data?.profile?.address_1}
                countryValue={userInfo?.data?.profile?.country}
                dob={userInfo?.data?.profile?.dob}
                phoneNumber={userInfo?.data?.profile?.phone_number}
                state={userInfo?.data?.profile?.state}
                pinCode={userInfo?.data?.profile?.pin_code}
                profileIso={parseInt(userInfo?.data?.profile?.profileIso)}
                countryId={userInfo?.data?.profile?.country_value}
                city={userInfo?.data?.profile?.city}
                username={userInfo?.data?.profile?.username}
                setEditForm={setEditForm}
              />
            ) : (
              <EditProfileDetails
                userInfoData={userInfoData}
                userInfoIsRefetching={userInfoIsRefetching}
                userInfoIsLoading={userInfoIsLoading}
              />
            )}
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default UserProfileRegularPage;
