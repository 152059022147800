import React from "react";
import classNames from "classnames";

import colors from "../../assets/scss/_themes-vars.module.scss";

import { Paper, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";

function AppWrap({ className, ...props }) {
  const theme = useTheme();
  const lgMatches = useMediaQuery("(min-width: 64rem)");

  const compClass = classNames({
    "nk-wrap": true,
    [`${className}`]: className,
  });

  return (
    <Paper
      className={compClass}
      sx={{
        width: "100%",
        background:
          theme.palette.mode === "dark" ? colors.darkModeColor : lgMatches ? colors.bodyBackgroundColor : colors.bodyBackgroundColor,
      }}
    >
      {props.children}
    </Paper>
  );
}

export default AppWrap;
