import React, { useEffect, useRef, useState } from "react";
import Head from "../../layout/head/Head";
import { Button, Stack } from "@mui/material";
import { fetchAPI, onlyNumberAllow, responseErrorHandler } from "../../utils/Utils";
import { Block, BlockContent, BlockDes, BlockHead, BlockTitle, Icon, PreviewCard } from "../../components/Component";
import OTPHomeSkelaton from "./otp/OTPHomeSkelaton";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert, Form, Spinner } from "reactstrap";
import Logo from "../../images/logo.png";
import LogoDark from "../../images/logo-dark.png";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useForm } from "react-hook-form";

const ResetPassword = () => {
  const [pageLoading, setPageLoading] = useState(true);
  const [errorVal, setError] = useState("");
  const [successVal, setSuccessVal] = useState("");
  const [verifyOTPLoading, setVerifyOTPLoading] = useState(false);
  const [resendOTPLoading, setResendOTPLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const [resendDisabled, setResendDisabled] = useState(false);
  const [subText, setSubText] = useState("OTP were sent to your contacts");
  const params = useParams();
  const navigate = useNavigate();
  const [passState, setPassState] = useState(false);
  const [confirmPassState, setConfirmPassState] = useState(false);

  useEffect(() => {
    isValidUser();
    // eslint-disable-next-line
  }, []);
  const isValidUser = async () => {
    const url = `${process.env.REACT_APP_API_URL}api/v1/auth/is-registered-user`;
    const formData = new FormData();
    formData.append("username", params.username);
    let isRegisteredUserRes = await fetchAPI(url, formData);
    if (isRegisteredUserRes === null) {
      toast.error("Something went wrong");
      return false;
    } else {
      if (isRegisteredUserRes?.data?.user_exists === "1") {
        setPageLoading(false);
        // const smsSent = isRegisteredUserRes?.data?.register_sms_otp === "1";
        // const emailSent = isRegisteredUserRes?.data?.register_email_otp === "1";

        const destinations = [];
        destinations.push(`phone no. ${isRegisteredUserRes?.data?.masked_phone_number}`);
        destinations.push(`email ${isRegisteredUserRes?.data?.masked_email_address}`);

        setSubText((prev) => `OTP were sent to ${destinations.join(" and ")}`);
      } else {
        responseErrorHandler(true, isRegisteredUserRes);
        setTimeout(() => {
          navigate("/");
        }, 2000);
      }
    }
  };
  let refResendTimer = useRef(null);
  if (resendTimer < 1) {
    clearInterval(refResendTimer.current);
    setResendDisabled(false);
    setResendTimer(60);
  }
  const resendOTP = async () => {
    setResendOTPLoading(true);
    const url = `${process.env.REACT_APP_API_URL}api/v1/auth/forgot-password`;
    const formData = new FormData();
    formData.append("username", params.username);
    let resendOTPRes = await fetchAPI(url, formData);
    setResendOTPLoading(false);
    if (resendOTPRes == null) {
      toast.error("Something went wrong");
    } else {
      if (resendOTPRes.status) {
        setSuccessVal(resendOTPRes.message);
        setError("");
        setResendDisabled(true);
        refResendTimer.current = setInterval(() => {
          setResendTimer((prev) => prev - 1);
        }, 1000);
      } else {
        setError(resendOTPRes.message);
        setSuccessVal("");
      }
    }
  };
  const onFormSubmit = async (formData) => {
    setVerifyOTPLoading(true);
    // let verifyAccountRes = null;

    const url = `${process.env.REACT_APP_API_URL}api/v1/auth/reset-password`;
    const fd = new FormData();
    fd.append("username", params.username);
    fd.append("otp", formData.otp);
    fd.append("password", formData.password);
    fd.append("password_confirmation", formData.confirmPassword);
    let resetPasswordRes = await fetchAPI(url, fd);

    setVerifyOTPLoading(false);
    if (resetPasswordRes == null) {
      setError("Something went wrong");
      setSuccessVal("");
      return false;
    }
    if (resetPasswordRes.status) {
      setSuccessVal(resetPasswordRes.message);
      setError(false);
      reset();

      if (resetPasswordRes?.data?.authToken) {
        const now = new Date();
        const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
        Cookies.set("authToken", resetPasswordRes.data.authToken, {
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
          expires: expires,
        });
        Cookies.set("username", resetPasswordRes.data.username, {
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
          expires: expires,
        });
        Cookies.set("email", resetPasswordRes.data.email, {
          domain: process.env.REACT_APP_COOKIE_DOMAIN,
          expires: expires,
        });
        setTimeout(() => {
          window.location = process.env.REACT_APP_DEFAULT_REDIRECT_URL;
        }, 2000);
      } else {
        navigate("/");
      }
    } else {
      setError(resetPasswordRes.message);
      setSuccessVal("");
    }
  };
  const {
    register,
    handleSubmit,
    reset,
    watch,
    formState: { errors },
  } = useForm();
  const password = watch("password");
  return (
    <>
      <Head title={`Verification`} />
      {!pageLoading ? (
        <Block className="nk-block-middle nk-auth-body  wide-xs">
          <div className="brand-logo pb-2 text-center">
            <Link to={`${process.env.PUBLIC_URL}/`} className="logo-link">
              <img className="logo-light logo-img logo-img-lg" src={Logo} alt="logo" loading="lazy" />
              <img className="logo-dark logo-img logo-img-lg" src={LogoDark} alt="logo-dark" loading="lazy" />
            </Link>
          </div>
          <PreviewCard className="card-bordered" bodyClass="card-inner-lg">
            <BlockHead>
              <BlockContent>
                <BlockTitle tag="h4" className={``}>
                  Forgot Password
                </BlockTitle>
                <BlockDes className="">
                  <p>
                    Reset your {process.env.REACT_APP_PROJECT_NAME} account Password
                    <br />
                    <small>{subText}</small>
                  </p>
                </BlockDes>
              </BlockContent>
            </BlockHead>
            {successVal && (
              <div className="mb-3">
                <Alert color="success" className="alert-icon">
                  <Icon name="alert-circle" /> {successVal}
                </Alert>
              </div>
            )}
            {errorVal && (
              <div className="mb-3">
                <Alert color="danger" className="alert-icon">
                  <Icon name="alert-circle" /> {errorVal}
                </Alert>
              </div>
            )}
            <Form className="is-alter" onSubmit={handleSubmit(onFormSubmit)}>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    OTP
                  </label>
                  <button
                    type="button"
                    className={`link link-primary link-sm`}
                    style={{ cursor: `${resendDisabled || resendOTPLoading ? "wait" : "pointer"}` }}
                    disabled={resendDisabled || resendOTPLoading}
                    onClick={resendOTP}
                  >
                    {resendDisabled
                      ? `Resend  OTP in ${resendTimer} seconds`
                      : resendOTPLoading
                      ? `Resending  OTP`
                      : `Resend OTP`}
                  </button>
                </div>
                <div className="form-control-wrap">
                  <input
                    autoComplete="off"
                    type="number"
                    id="otp"
                    onKeyDown={onlyNumberAllow}
                    tabIndex={1}
                    placeholder="1234"
                    className="form-control-lg bg-transparent border form-control me-1"
                    {...register("otp", {
                      required: "otp is required",
                    })}
                  />
                  {errors.otp && <span className="invalid">{errors.otp.message}</span>}
                </div>
              </div>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    New password
                  </label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#password"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setPassState(!passState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${passState ? "is-hidden" : "is-shown"}`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                  <input
                    autoComplete="off"
                    type={passState ? "text" : "password"}
                    id="password"
                    tabIndex={2}
                    className={`form-control-lg bg-transparent border form-control me-1 ${
                      passState ? "is-hidden" : "is-shown"
                    }`}
                    {...register("password", {
                      required: "password is required",
                      pattern: {
                        value: /^(?=.*?[A-Z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/,
                        message: "Enter strong password(eg. Abc@1234)",
                      },
                      maxLength: { value: 16, message: "Password must not exceed 16 characters" },
                    })}
                    placeholder="Enter your password"
                  />
                  {errors.password && <span className="invalid">{errors.password.message}</span>}
                </div>
              </div>
              <div className="form-group">
                <div className="form-label-group">
                  <label className="form-label" htmlFor="default-01">
                    Confirm Password
                  </label>
                </div>
                <div className="form-control-wrap">
                  <a
                    href="#confirmPassword"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setConfirmPassState(!confirmPassState);
                    }}
                    className={`form-icon lg form-icon-right passcode-switch ${
                      confirmPassState ? "is-hidden" : "is-shown"
                    }`}
                  >
                    <Icon name="eye" className="passcode-icon icon-show"></Icon>

                    <Icon name="eye-off" className="passcode-icon icon-hide"></Icon>
                  </a>
                  <input
                    autoComplete="off"
                    type={confirmPassState ? "text" : "password"}
                    id="confirmPassword"
                    tabIndex={3}
                    {...register("confirmPassword", {
                      required: "This field is required",
                      validate: (value) => value === password || "Passwords do not match",
                    })}
                    placeholder="Enter confirm password"
                    className={`form-control-lg form-control ${confirmPassState ? "is-hidden" : "is-shown"}`}
                  />
                  {errors.confirmPassword && <span className="invalid">{errors.confirmPassword.message}</span>}
                </div>
              </div>
              <div className="form-group">
                <Stack>
                  <Button
                    fullWidth="true"
                    variant={"contained"}
                    size="large"
                    type="submit"
                    tabIndex={4}
                    color="primary"
                    disabled={verifyOTPLoading}
                    startIcon={
                      verifyOTPLoading ? <Spinner size="sm" style={{ borderWidth: "2px", color: "inherit" }} /> : ""
                    }
                  >
                    Reset Password
                  </Button>
                </Stack>
              </div>
            </Form>
            <div className="form-note-s2 text-center pt-4">
              New on our platform? <Link to={`${process.env.PUBLIC_URL}/register`}>Create an account</Link>
            </div>
          </PreviewCard>
        </Block>
      ) : (
        <OTPHomeSkelaton />
      )}
    </>
  );
};

export default ResetPassword;
