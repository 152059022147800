import React, { useEffect, useReducer, useState } from "react";
import { useMutation, useInfiniteQuery } from "@tanstack/react-query";
import { DropdownToggle, DropdownMenu, Dropdown, Spinner } from "reactstrap";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import moment from "moment/moment";
import SimpleBar from "simplebar-react";

//^ stylesheets
import styles from "../../../../assets/scss/_themes-vars.module.scss";
import colors from "../../../../assets/scss/_themes-vars.module.scss";

//^ http request
import { getAllNotificationHandler, setNotificationCountHandler } from "../../../../http/post-api";
import { queryClient } from "../../../../http";

//^ mui
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Badge, Box, Button, IconButton, MenuItem, MenuList, Stack, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";

import Icon from "../../../../components/icon/Icon";
import data from "./NotificationData";
import { useThemeUpdate } from "../../../provider/Theme";

import { responseErrorHandler, setOffset, shortenString } from "../../../../utils/Utils";
import { TooltipComponent } from "../../../../components/Component";
import NotificationSkeletonLoader from "../../../../components/ui/loader/Skeleton/NotificationSkeletonLoader";
// import Switch from "../../../../components/ui/switch/Switch";

function NotificationItem({ title, time, isSender, link, updatedAt, module, limit, index, id, dispatch }) {
  const navigate = useNavigate();

  const { mutate: setNotificationMutate, reset: setNotificationReset } = useMutation({
    mutationKey: ["set-notification"],
    mutationFn: setNotificationCountHandler,
    onSuccess: async (data) => {
      if (data?.toast) {
        if (data?.status) {
          await queryClient.refetchQueries({
            queryKey: ["get-all-notification", limit, module],
            type: "active",
            exact: true,
          });
        } else {
          responseErrorHandler(true, data);
        }
      }
      setNotificationReset();
    },
  });

  return (
    <>
      <MenuList component={"li"}>
        <MenuItem
          sx={{ padding: "0.6rem 1rem" }}
          onClick={() => {
            dispatch({ type: "CLOSE_MODAL" });
            setNotificationMutate({ notification_id: id, status: "1" });
            navigate(`${link}`);
          }}
        >
          <Stack justifyContent={"space-between"} alignItems={"flex-start"} direction={"row"} width={"100%"}>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <Box className="nk-notification-icon">
                <Icon
                  name={isSender ? "curve-down-right" : "curve-down-left"}
                  className={[`icon-circle ${isSender ? "bg-primary-dim" : "bg-success-dim"}`]}
                />
              </Box>
              <Stack className="nk-notification-content">
                <Typography
                  sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                  variant="subtitle2"
                  className="nk-notification-text"
                >
                  {title?.length > 27 ? (
                    <TooltipComponent
                      type="text"
                      content={shortenString(title, 27)}
                      direction="top"
                      id={`notification-${index}`}
                      text={title}
                    />
                  ) : (
                    title
                  )}
                </Typography>
                <Typography className="nk-notification-time" variant="caption">
                  {time}
                </Typography>
              </Stack>
            </Stack>
            <Stack justifyContent={"flex-start"}>
              <Typography variant="caption" fontWeight={"600"}>
                {moment(updatedAt).format("h:MM A")}
              </Typography>
            </Stack>
          </Stack>
        </MenuItem>
      </MenuList>
    </>
  );
}

const Notification = () => {
  const themeUpdate = useThemeUpdate();
  const isDark = useTheme().palette.mode === "dark";

  const limit = 10;
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationData, setNotificationData] = useState({ notifications: [] });
  const [notificationIds, setNotificationIds] = useState([]);
  // const [module, setModule] = useState("1");

  const initialState = {
    open: false,
  };

  function notificationReducer(state, action) {
    switch (action.type) {
      case "OPEN_MODAL":
        return {
          ...state,
          open: true,
        };
      case "CLOSE_MODAL": {
        return {
          ...state,
          open: false,
        };
      }
      case "TOGGLE_MODAL": {
        return {
          ...state,
          open: !state.open,
        };
      }
      default:
        return state;
    }
  }

  const [state, dispatch] = useReducer(notificationReducer, initialState);

  // function notificationToggleHandler(isChecked) {
  //   if (isChecked) {
  //     setModule(undefined);
  //   } else {
  //     setModule("1");
  //   }
  // }

  const toggle = () => {
    themeUpdate.sidebarHide();
    dispatch({ type: "TOGGLE_MODAL" });
  };

  // Fetch notifications
  const {
    data: allNotificationData,
    isLoading: allNotificationIsLoading,
    isRefetching: allNotificationIsRefetching,
    fetchNextPage: allNotificationFetchNextPage,
    hasNextPage: allNotificationHasNextPage,
    isFetchingNextPage: allNotificationIsFetchingNextPage,
    refetch: allNotificationRefetch,
  } = useInfiniteQuery({
    queryKey: ["get-all-notification", limit],
    queryFn: async (props) => {
      const offSet = setOffset({ limit, page: props.pageParam });

      try {
        return await getAllNotificationHandler({ limit: limit, offset: offSet, module: "1" });
      } catch (error) {
        console.error("Error in query function:", error);
        throw error; // Ensure that the error is thrown so that the `isError` state is set
      }
    },
    initialPageParam: 1,
    getNextPageParam: (lastPage, allPages) => {
      return lastPage?.status ? allPages.length + 1 : undefined;
    },
    gcTime: 0,
    staleTime: Infinity,
  });

  //^ Reading all the unread notifications
  const {
    mutate: readUnreadNotificationsMutate,
    reset: readUnreadNotificationsReset,
    error: readUnreadNotificationsError,
    isError: readUnreadNotificationsIsError,
  } = useMutation({
    mutationKey: ["set-notification-count-for-unread-msg"],
    mutationFn: setNotificationCountHandler,
    onSuccess: () => {
      readUnreadNotificationsReset();
      allNotificationRefetch();
    },
  });

  useEffect(() => {
    if (readUnreadNotificationsIsError) {
      console.log(readUnreadNotificationsError?.info);
    }
  }, [readUnreadNotificationsError, readUnreadNotificationsIsError]);

  // Mark all notifications as read mutation
  const { isPending: markAllReadIsPending, mutate: markAllReadMutate } = useMutation({
    mutationKey: ["mark-all-as-read"],
    mutationFn: setNotificationCountHandler,
    onSuccess: (data) => {
      if (data.toast) {
        if (!data.status) {
          responseErrorHandler(true, data);
          if (data?.redirect) {
            window.location.href = `${process.env.REACT_APP_ACCOUNT_LOGIN_URL}`;
          }
        } else {
          allNotificationRefetch();
          toast.success(data?.message);
        }
      }
    },
  });

  const flattenedNotificationData = allNotificationData?.pages
    ?.flatMap((page) => page?.data?.notifications)
    .filter((page) => page !== undefined);

  useEffect(() => {
    if (!allNotificationIsLoading || !allNotificationIsRefetching) {
      const unReadNotifications = Array.isArray(flattenedNotificationData)
        ? flattenedNotificationData.filter((notification) => notification?.seen === "0")
        : [];
      setNotificationCount(unReadNotifications.length || 0);

      setNotificationData({ notifications: flattenedNotificationData });
    }

    // eslint-disable-next-line
  }, [allNotificationData, allNotificationIsLoading, allNotificationIsRefetching]);

  useEffect(() => {
    if (!allNotificationIsLoading) {
      const notifications =
        allNotificationData?.pages
          ?.flatMap((page) => {
            return page?.data?.notifications || [];
          })
          .filter((item) => item !== undefined) || [];
      const notificationsId = notifications
        ?.filter((notification) => notification?.seen === "0")
        .map((notification) => notification.id);
      setNotificationIds(notificationsId);
    }

    // eslint-disable-next-line
  }, [allNotificationData, allNotificationIsLoading]);

  const loadMoreNotifications = async () => {
    allNotificationFetchNextPage();
  };

  function readUnreadNotificationsHandler() {
    if (notificationIds?.length > 0) {
      readUnreadNotificationsMutate({ notification_id: notificationIds, status: "1" });
    }
  }

  return (
    <>
      {/* Dropdown menu */}
      <Dropdown
        onBlur={() => readUnreadNotificationsHandler()}
        isOpen={state.open}
        className="user-dropdown"
        toggle={toggle}
      >
        <DropdownToggle tag="a" className="dropdown-toggle">
          <IconButton
            sx={{ "&:hover": { color: colors.errorMain, backgroundColor: isDark ? colors.dark : colors.light200 } }}
          >
            <Stack alignItems={"center"} justifyContent={"center"}>
              <Badge badgeContent={allNotificationIsLoading ? 0 : notificationCount} color="primary">
                <NotificationsNoneIcon />
              </Badge>
            </Stack>
          </IconButton>
        </DropdownToggle>
        <DropdownMenu end className="dropdown-menu-xl dropdown-menu-s1">
          {/* Header */}
          <div className="dropdown-head">
            <Typography variant="subtitle2">{data.title}</Typography>
            <Stack direction={"row"} alignItems={"center"} spacing={1}>
              {/* <Switch
                spacing={0.5}
                checked={false}
                onClick={notificationToggleHandler}
                unCheckedLabel={"All"}
                size="sm"
                defaultChecked={true}
              /> */}
              <Button
                size="small"
                onClick={() => {
                  if (notificationCount <= 0) {
                    toast.error("There are no unread messages.");
                  } else {
                    markAllReadMutate({ notification_id: 0, status: 1 });
                  }
                }}
                startIcon={
                  markAllReadIsPending ? <Spinner size={"sm"} color="primary" style={{ borderWidth: "1px" }} /> : ""
                }
                disabled={notificationCount <= 0}
              >
                Mark All as Read
              </Button>
            </Stack>
          </div>

          {/* Body */}
          <div className="dropdown-body">
            <div className="nk-notification">
              {allNotificationIsLoading ? (
                <NotificationSkeletonLoader />
              ) : allNotificationData?.code === 500 ? (
                <>
                  <Typography
                    padding={"1.25rem"}
                    textAlign={"center"}
                    sx={{ color: colors.errorMain }}
                    fontWeight={"600"}
                    textTransform={"capitalize"}
                  >
                    {allNotificationData?.message}
                  </Typography>
                </>
              ) : notificationData?.notifications?.length > 0 ? (
                <>
                  <SimpleBar>
                    {notificationData?.notifications?.map((notification, index) => {
                      return (
                        <React.Fragment key={index}>
                          <NotificationItem
                            dispatch={dispatch}
                            index={index}
                            id={notification?.id}
                            key={notification?.id}
                            title={notification?.title}
                            time={moment(notification?.created_at).format("DD MMM")}
                            isSender={notification?.is_sender}
                            link={notification?.link}
                            updatedAt={notification?.updated_at}
                            recent={notification?.recent}
                            limit={limit}
                            module={module}
                          />
                        </React.Fragment>
                      );
                    })}
                    {/* View More button */}
                    {allNotificationHasNextPage && notificationData?.notifications?.length > 10 && (
                      <div className="dropdown-foot center">
                        <Button
                          endIcon={
                            allNotificationIsFetchingNextPage && (
                              <Spinner size="sm" style={{ borderWidth: "1px", color: "inherit" }} />
                            )
                          }
                          onClick={loadMoreNotifications}
                          disabled={allNotificationIsFetchingNextPage || !allNotificationHasNextPage}
                        >
                          {"View More"}
                        </Button>
                      </div>
                    )}
                  </SimpleBar>
                </>
              ) : (
                // No notifications found
                <Stack justifyContent={"center"} alignItems={"center"}>
                  <Typography variant="subtitle1" sx={{ padding: "1.25rem", color: styles.primaryMain }}>
                    No Notification Found
                  </Typography>
                </Stack>
              )}
            </div>
          </div>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

export default Notification;
