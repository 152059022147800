import React, { useEffect } from "react";
import Head from "./head/Head";
import { Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useTheme } from "../layout/provider/Theme";

import colors from "../assets/scss/_themes-vars.module.scss";

//^ redux actions
import { muiAction } from "../store/slices/mui-slice";

//^ mui component
import { useMediaQuery } from "@mui/material";
import { useTheme as useMuiTheme } from "@mui/material/styles";

const Layout = ({ title }) => {
  const lgMatches = useMediaQuery("(min-width: 64rem)");
  const muiTheme = useSelector((state) => state.mui.theme);
  const MUITheme = useMuiTheme();
  const dispatch = useDispatch();

  const theme = useTheme();

  useEffect(() => {
    if (theme.skin !== muiTheme) {
      dispatch(muiAction.setTheme(theme.skin));
      document.documentElement.style.setProperty("--bar-color", theme.skin === "dark" ? "#666" : "#ccc");
      document.documentElement.style.setProperty("--track-color", theme.skin === "dark" ? "#333" : "#f0f0f0");
    }
  }, [theme.skin, dispatch, muiTheme]);

  return (
    <>
      <Head title={!title && "Loading"} />
      <div className="nk-app-root">
        <div className="nk-wrap-nosidebar">
          <div
            className="nk-content"
            style={{
              width: "100%",
              background:
                MUITheme.palette.mode === "dark"
                  ? colors.darkModeColor
                  : lgMatches
                  ? colors.bodyBackgroundColor
                  : colors.bodyBackgroundColor,
            }}
          >
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
export default Layout;
