import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";

// import colors from "../../../assets/scss/_themes-vars.module.scss";

//^ http request
import { followUserHandler } from "../../../http/post-api";
import { queryClient } from "../../../http";

//^ redux actions
import { userNetworkAction } from "../../../store/slices/user-networks-slice";

import ImageWithPlaceholder from "../../../components/ui/auth-otp/image/image-with-placeholder";

//^ @mui components
import { MoreVert, PersonAddRounded, PersonRemoveRounded, RemoveRounded } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { responseErrorHandler, shortenString } from "../../../utils/Utils";
import { TooltipComponent } from "../../../components/Component";
import { useSelector } from "react-redux";

function CircularButton({ text, variant = "outlined", size = "small", onClick, ...props }) {
  const followUnFollowButtonDisabled = useSelector((state) => state.userNetwork.followUnFollowMutationProgress);
  return (
    <Box
      sx={{
        display: "none",
        "@media (min-width: 65.5rem)": {
          display: "block",
        },
      }}
    >
      <Button
        variant={variant}
        sx={{
          borderRadius: "10rem",
          width: "100%",
          minWidth: "7rem",
        }}
        size={size}
        onClick={onClick}
        {...props}
        disabled={followUnFollowButtonDisabled}
      >
        {text}
      </Button>
    </Box>
  );
}

function UserList(props) {
  // eslint-disable-next-line
  const dispatch = useDispatch();
  const matches = useMediaQuery("(min-width: 65.5rem)");

  const followUnFollowMutationProgress = useSelector((state) => state.userNetwork.followUnFollowMutationProgress);

  // eslint-disable-next-line
  const [searchParams, _setSearchParams] = useSearchParams();
  const followType = searchParams.get("followType") || "following";

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  //^ follow user mutation fn ================================================================

  const {
    // isPending: followUserIsPending,
    isError: followUserIsError,
    error: followUserError,
    mutate: followUserMutate,
    reset: followUserReset,
  } = useMutation({
    mutationKey: ["follow-user"],
    mutationFn: followUserHandler,
    onSuccess: async (data) => {
      if (data.toast) {
        if (!data.status) {
          responseErrorHandler(true, data);
        } else {
          await queryClient.refetchQueries({
            queryKey: ["get-user-list-summary"],
            type: "active",
            exact: true,
          });
        }
      }
      followUserReset();
    },
    onSettled: () => {
      dispatch(
        userNetworkAction.setNetworkMutationProgressValue({
          stateValue: "followUnFollowMutationProgress",
          status: false,
        })
      );
    },
  });

  useEffect(() => {
    if (followUserIsError) {
      responseErrorHandler(true, followUserError);
      followUserReset();
    }
  }, [followUserIsError, followUserError, followUserReset]);

  //^ follow user mutation fn ================================================================

  function handleFollowUser(_event, userId, status, type = "FOLLOWERS") {
    if (followUnFollowMutationProgress) return;
    dispatch(
      userNetworkAction.setNetworkMutationProgressValue({ stateValue: "followUnFollowMutationProgress", status: true })
    );

    followUserMutate({ userId, status, type: "single" });

    dispatch(userNetworkAction.removeUserListHandler({ user_id: userId, type }));
    handleClose();
  }

  function handleRemoveItemHandler({ id }) {
    dispatch(
      userNetworkAction.removeUserListHandler({
        type: "FOLLOWERS",
        user_id: id,
        followType,
      })
    );
  }

  return (
    <List
      sx={{
        width: "100%",
      }}
    >
      <ListItem
        alignItems="center"
        secondaryAction={
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <Stack direction={"row"} alignItems={"center"} gap={2}>
              {followType === "not_followed" ? (
                <CircularButton
                  startIcon={<PersonAddRounded />}
                  text={"Follow"}
                  variant="contained"
                  onClick={(e) => handleFollowUser(e, props.row?.user_id, 1)}
                />
              ) : followType === "followed" ? (
                <CircularButton
                  startIcon={<PersonRemoveRounded />}
                  text={"Unfollow"}
                  variant="contained"
                  onClick={(e) => handleFollowUser(e, props.row?.user_id, 2)}
                />
              ) : followType === "followers" && !props.row.following ? (
                <CircularButton
                  startIcon={<PersonAddRounded />}
                  text={"Follow"}
                  variant="contained"
                  onClick={(e) => handleFollowUser(e, props.row?.user_id, 1, "FOLLOW")}
                />
              ) : (
                <CircularButton
                  startIcon={<PersonRemoveRounded />}
                  text={"Unfollow"}
                  onClick={(e) => handleFollowUser(e, props.row?.user_id, 2, "UNFOLLOW")}
                />
              )}

              <Box sx={{ display: "block", "@media(min-width: 65.5rem)": { display: "none" } }}>
                <IconButton onClick={handleClick}>
                  <MoreVert />
                </IconButton>
                <Menu
                  id="long-menu"
                  MenuListProps={{
                    "aria-labelledby": "long-button",
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      maxHeight: 48 * 4.5,
                      width: "20ch",
                    },
                  }}
                >
                  {followType === "not_followed" ? (
                    <Box display={matches ? "none" : "block"}>
                      <MenuItem onClick={(e) => handleFollowUser(e, props.row?.user_id, 1)}>
                        <Typography
                          component={Stack}
                          direction={"row"}
                          alignItems={"center"}
                          gap={2}
                          variant="subtitle2"
                        >
                          <PersonAddRounded sx={{ fontSize: "1.25rem" }} />
                          <span>Follow</span>
                        </Typography>
                      </MenuItem>
                    </Box>
                  ) : followType === "followed" ? (
                    <Box display={matches ? "none" : "block"}>
                      <MenuItem onClick={(e) => handleFollowUser(e, props.row?.user_id, 2)}>
                        <Typography
                          component={Stack}
                          direction={"row"}
                          alignItems={"center"}
                          gap={2}
                          variant="subtitle2"
                        >
                          <PersonRemoveRounded sx={{ fontSize: "1.25rem" }} />
                          <span>Unfollow</span>
                        </Typography>
                      </MenuItem>
                    </Box>
                  ) : followType === "followers" && !props.row.following ? (
                    <Box display={matches ? "none" : "block"}>
                      <MenuItem onClick={(e) => handleFollowUser(e, props.row?.user_id, 1, "FOLLOW")}>
                        {" "}
                        <Typography
                          component={Stack}
                          direction={"row"}
                          alignItems={"center"}
                          gap={2}
                          variant="subtitle2"
                        >
                          <PersonAddRounded sx={{ fontSize: "1.25rem" }} />
                          <span>Follow</span>
                        </Typography>
                      </MenuItem>
                    </Box>
                  ) : (
                    <Box display={matches ? "none" : "block"}>
                      <MenuItem onClick={(e) => handleFollowUser(e, props.row?.user_id, 2, "UNFOLLOW")}>
                        {" "}
                        <Typography
                          component={Stack}
                          direction={"row"}
                          alignItems={"center"}
                          gap={2}
                          variant="subtitle2"
                        >
                          <PersonRemoveRounded sx={{ fontSize: "1.25rem" }} />
                          <span>unFollow</span>
                        </Typography>
                      </MenuItem>
                    </Box>
                  )}
                </Menu>
              </Box>
            </Stack>
          </Stack>
        }
      >
        <ListItemAvatar>
          {console.log(282, props.row)}
          <ImageWithPlaceholder
            showAvatarPlaceholder={true}
            avatar={true}
            alt={props?.row?.username || ""}
            src={props.row?.avatar_url}
            style={{ borderRadius: "50%" }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              {props.row.username?.length > 12 ? (
                <>
                  <TooltipComponent
                    type="text"
                    content={
                      <Typography variant="subtitle1" noWrap>
                        {shortenString(props.row.username, 12)}
                      </Typography>
                    }
                    direction="top"
                    id={`username-${props.row?.user_id}`}
                    text={props.row.username}
                  />
                </>
              ) : (
                <Typography variant="subtitle1">{props.row.username}</Typography>
              )}
            </>
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </List>
  );
}

export const columns = [
  {
    accessorKey: "userList",
    header: "User List",
    accessorFn: (row) => {
      return <UserList row={row} />;
    },
  },
  { accessorKey: "following" },
  { accessorKey: "username" },
  { accessorKey: "lastName" },
  { accessorKey: "created_at" },
  { accessorKey: "email" },
];
