import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router";

import Head from "../../../layout/head/Head";

import { Typography } from "@mui/material";

import {
  BlockBetween,
  BlockContent,
  BlockHead,
  BlockHeadContent,
  InputSwitch,
  MuiSwitch,
} from "../../../components/Component";
import { setNotification } from "../../../controller/profile_controller";
import { UserContext } from "./UserContext";
import Title from "../../../components/title/Title";
import BackButton from "../../../components/button/back-button";

const UserProfileNotificationPage = () => {
  const [sm, updateSm] = useState(false);
  const [mobileView, setMobileView] = useState(false);

  const navigate = useNavigate();

  //^ context states
  const { loggedInUser, setData } = useContext(UserContext);

  // function to change the design view under 990 px
  const viewChange = () => {
    if (window.innerWidth < 990) {
      setMobileView(true);
    } else {
      setMobileView(false);
      updateSm(false);
    }
  };

  useEffect(() => {
    viewChange();
    window.addEventListener("load", viewChange);
    window.addEventListener("resize", viewChange);
    document.getElementsByClassName("nk-header")[0].addEventListener("click", function () {
      updateSm(false);
    });
    return () => {
      window.removeEventListener("resize", viewChange);
      window.removeEventListener("load", viewChange);
    };
  }, []);

  return (
    <React.Fragment>
      <Head title="User Notification"></Head>
      {sm && mobileView && <div className="toggle-overlay" onClick={() => updateSm(!sm)}></div>}

      <BlockHead>
        <BlockBetween>
          <BlockHeadContent className={"w-100"}>
            <Title
              title={"Notification Settings"}
              endComponent={<BackButton title={"back"} variant={"text"} onClick={() => navigate(-1)} />}
            />
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <Typography variant="h6">Security Alerts</Typography>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <BlockContent>
        <div className="g-item">
          <InputSwitch
            id="custom-switch"
            name="unusual_activity"
            label="Notify Email Updates"
            onClick={(e) => setNotification(e, "unusual_activity", loggedInUser, setData)}
          />
        </div>
      </BlockContent>

      <BlockHead size="sm">
        <BlockBetween>
          <BlockHeadContent>
            <Typography variant="h6">News</Typography>
          </BlockHeadContent>
        </BlockBetween>
      </BlockHead>

      <BlockContent>
        <div className="gy-3">
          <div className="g-item">
            <InputSwitch
              id="custom-switch3"
              name="sales"
              label="Email Sales Updates"
              onClick={(e) => setNotification(e, "sales", loggedInUser, setData)}
            />
          </div>

          <div className="g-item">
            <InputSwitch
              id="feature-update"
              name="features"
              label="Feature Updates via Email"
              onClick={(e) => setNotification(e, "features", loggedInUser, setData)}
            />
          </div>
          <div className="g-item">
            <InputSwitch
              id="tips"
              name="tips"
              label="Account Tips Email"
              onClick={(e) => setNotification(e, "tips", loggedInUser, setData)}
            />
          </div>
        </div>
      </BlockContent>
    </React.Fragment>
  );
};

export default UserProfileNotificationPage;
