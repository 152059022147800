import React from "react";
import { getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";
import SimpleBar from "simplebar-react";

//^ @mui components
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { useTheme } from "@mui/material/styles";

//^ components
import ConnectedListSkeleton from "../../../components/ui/loader/Skeleton/follow-list-skeleton";
import NoUsers from "../../../components/svg/account-network/no-invitations";
import NoUserDark from "../../../components/svg/account-network/no-users-dark";

import UserListSkeleton from "../../../components/ui/loader/Skeleton/follow-list-skeleton";

function ConnectionList({ getUserIsFetchingNextPage, getUserFetchNextPage, getUserHasNextPage, pagination, ...props }) {
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
  const { ref, inView } = useInView();
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  React.useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    if (inView && getUserHasNextPage) {
      getUserFetchNextPage();
    }

    // eslint-disable-next-line
  }, [inView, getUserFetchNextPage]);

  const adjustedHeight = screenHeight - 21.9 * parseFloat(getComputedStyle(document.documentElement).fontSize);

  return (
    <>
      <SimpleBar style={{ maxHeight: `${adjustedHeight}px`, width: "100%" }}>
        {props.table.getRowModel()?.rows?.length ? (
          props.table.getRowModel()?.rows?.map((row) =>
            row?.getVisibleCells()?.map((cell, index) => {
              if (row?.getVisibleCells()?.length === index - 1) {
                <Box
                  ref={ref}
                  key={cell.id}
                  sx={{
                    display:
                      cell.getContext().column.id === "username" ||
                      cell.getContext().column.id === "lastName" ||
                      cell.getContext().column.id === "created_at" ||
                      cell.getContext().column.id === "email"
                        ? "none"
                        : "",
                  }}
                >
                  <Typography variant="subtitle2" fontWeight={400}>
                    {cell.getValue()}
                  </Typography>
                </Box>;
              }

              return (
                <Box
                  key={cell.id}
                  sx={{
                    display:
                      cell.getContext().column.id === "username" ||
                      cell.getContext().column.id === "lastName" ||
                      cell.getContext().column.id === "created_at" ||
                      cell.getContext().column.id === "email"
                        ? "none"
                        : "",
                  }}
                >
                  <Typography variant="subtitle2" fontWeight={400}>
                    {cell.getValue()}
                  </Typography>
                </Box>
              );
            })
          )
        ) : (
          <Box colSpan={props.columns.length} sx={{ height: "30rem", padding: "1.25rem" }}>
            {getUserIsFetchingNextPage ? (
              ""
            ) : (
              <Stack alignItems={"center"} justifyContent={"center"} height={"100%"}>
                {isDark ? <NoUserDark /> : <NoUsers />}
              </Stack>
            )}
          </Box>
        )}
        {getUserHasNextPage && pagination === 1 && (
          <Stack ref={ref} direction={"row"} padding={"1.25rem"} width={"100%"}>
            {getUserIsFetchingNextPage ? (
              <Stack width={"100%"}>
                <UserListSkeleton length={1} padding />
              </Stack>
            ) : (
              ""
            )}
          </Stack>
        )}
      </SimpleBar>
    </>
  );
}

export default function DataTable({
  columns,
  connectionCount,
  getUserConnectIsLoading,
  getUserIsFetchingNextPage,
  getUserFetchNextPage,
  getUserHasNextPage,
  getUserConnectIsRefetching,
  pagination,
  searchComponent,
}) {
  // eslint-disable-next-line
  const lgMatches = useMediaQuery("(min-width: 64rem)");

  const connectionUsersData = useSelector((state) => state.userNetwork.connections);
  const connectionUserValues = useSelector((state) => state.userNetwork.connectionValues);

  const [connectionFilter, setConnectionFilter] = React.useState("none");
  const [sorting, setSorting] = React.useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const connectionType = searchParams.get("connectType") || "connected";

  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const addConnectionTypeParam = ({ value, type = "append" }) => {
    if (type === "append") {
      const params = new URLSearchParams(searchParams);
      params.append("connectType", value);
      setSearchParams(params.toString());
    } else if (type === "set") {
      const params = new URLSearchParams(searchParams);
      params.set("connectType", value);
      setSearchParams(params.toString());
    }
  };

  function handleStatusSelectChange(event) {
    const recentValues = event.target.value;

    setConnectionFilter(recentValues);
    if (recentValues !== "none") {
      setSorting([
        {
          id: recentValues,
          asc: true,
        },
      ]);
    } else {
      setSorting([]);
    }
  }

  const table = useReactTable({
    data: connectionUsersData || [],
    columns: columns || [],
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  function handleConnectionListTabChange(_event, value) {
    if (value !== connectionType) {
      addConnectionTypeParam({ value, type: "set" });
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "none",
          "@media (min-width: 65.5rem)": {
            display: "block",
          },
        }}
      >
        <Stack>
          <Paper
            variant={isDark ? "elevation" : "outlined"}
            elevation={isDark ? 0.6 : 3}
            sx={{ padding: "1.25rem", borderRadius: "0.425rem" }}
          >
            <Stack gap={2}>
              <Stack
                gap={2}
                sx={{
                  "@media (min-width: 65.5rem)": {
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                  },
                }}
              >
                <Stack direction={"row"} gap={2} alignItems={"center"}>
                  <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                    <InputLabel id="status-outlined-select-label">Sort By</InputLabel>
                    <Select
                      size="small"
                      value={connectionFilter}
                      onChange={handleStatusSelectChange}
                      labelId="status-outlined-select-label"
                      id="status-outlined-select"
                      label="Sort by"
                    >
                      <MenuItem value="none">
                        <Typography component={"em"} variant="subtitle2">
                          None
                        </Typography>
                      </MenuItem>
                      <MenuItem value={"username"}>
                        <Typography variant="subtitle2">Username</Typography>
                      </MenuItem>
                      <MenuItem value={"created_at"}>
                        <Typography variant="subtitle2">Recent</Typography>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                {searchComponent}
              </Stack>
              <Stack>
                <TabContext value={connectionType === null ? "" : connectionType}>
                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      value={connectionType === null ? "" : connectionType}
                      onChange={handleConnectionListTabChange}
                      variant="scrollable"
                      scrollButtons="auto"
                      allowScrollButtonsMobile
                      aria-label="scrollable auto tabs example"
                    >
                      {connectionUserValues?.map((connectionUsers, index) => (
                        <Tab
                          key={index}
                          label={
                            <Stack alignItems={"center"} direction={"row"} gap={2}>
                              <Typography variant="subtitle2" fontWeight={400} textTransform={"capitalize"}>
                                {connectionUsers?.label}
                              </Typography>
                              <Chip
                                sx={{ minWidth: "1.25rem", height: "1.45rem" }}
                                label={`${connectionUsers?.amount || 0}`}
                              />
                            </Stack>
                          }
                          value={connectionUsers?.value}
                        />
                      ))}
                    </Tabs>
                  </Box>
                  <TabPanel sx={{ padding: "0rem" }} value={"connected"}>
                    {getUserConnectIsLoading || (connectionUsersData?.length === 0 && getUserConnectIsRefetching) ? (
                      <ConnectedListSkeleton />
                    ) : (
                      <ConnectionList
                        table={table}
                        columns={columns}
                        getUserFetchNextPage={getUserFetchNextPage}
                        getUserHasNextPage={getUserHasNextPage}
                        getUserIsFetchingNextPage={getUserIsFetchingNextPage}
                        pagination={pagination}
                      />
                    )}
                  </TabPanel>
                  <TabPanel sx={{ padding: "0rem" }} value={"not_connected"}>
                    {getUserConnectIsLoading || (connectionUsersData?.length === 0 && getUserConnectIsRefetching) ? (
                      <ConnectedListSkeleton />
                    ) : (
                      <ConnectionList
                        table={table}
                        columns={columns}
                        getUserFetchNextPage={getUserFetchNextPage}
                        getUserHasNextPage={getUserHasNextPage}
                        getUserIsFetchingNextPage={getUserIsFetchingNextPage}
                        pagination={pagination}
                      />
                    )}
                  </TabPanel>
                </TabContext>
              </Stack>
            </Stack>
          </Paper>
        </Stack>
      </Box>
      <Box
        sx={{
          display: "block",
          "@media (min-width: 65.5rem)": {
            display: "none",
          },
        }}
      >
        <Stack>
          <Stack gap={2}>
            <Stack gap={2}>
              <Typography variant="h5" fontWeight={400} textTransform={"capitalize"}>
                {connectionCount} Connected Users
              </Typography>
              <Stack
                gap={2}
                sx={{
                  "@media (min-width: 65.5rem)": {
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                  },
                }}
              >
                <Stack direction={"row"} gap={2} alignItems={"center"}>
                  <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                    <InputLabel id="status-outlined-select-label">Sort By</InputLabel>
                    <Select
                      size="small"
                      value={connectionFilter}
                      onChange={handleStatusSelectChange}
                      labelId="status-outlined-select-label"
                      id="status-outlined-select"
                      label="Sort by"
                    >
                      <MenuItem value="none">
                        <em>None</em>
                      </MenuItem>
                      <MenuItem value={"username"}>Username</MenuItem>
                      <MenuItem value={"email"}>Email</MenuItem>
                      <MenuItem value={"created_at"}>Recent</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                {searchComponent}
              </Stack>
            </Stack>
            <Stack>
              <TabContext value={connectionType === null ? "" : connectionType}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={connectionType === null ? "" : connectionType}
                    onChange={handleConnectionListTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab label="Connections" value={"connected"} />
                    <Tab label="Suggestions" value={"not_connected"} />
                  </Tabs>
                </Box>
                <TabPanel sx={{ padding: "0rem" }} value={"connected"}>
                  {getUserConnectIsLoading || (connectionUsersData?.length === 0 && getUserConnectIsRefetching) ? (
                    <ConnectedListSkeleton />
                  ) : (
                    <ConnectionList
                      table={table}
                      columns={columns}
                      getUserFetchNextPage={getUserFetchNextPage}
                      getUserHasNextPage={getUserHasNextPage}
                      getUserIsFetchingNextPage={getUserIsFetchingNextPage}
                      pagination={pagination}
                    />
                  )}
                </TabPanel>
                <TabPanel sx={{ padding: "0rem" }} value={"not_connected"}>
                  {getUserConnectIsLoading || (connectionUsersData?.length === 0 && getUserConnectIsRefetching) ? (
                    <ConnectedListSkeleton />
                  ) : (
                    <ConnectionList
                      table={table}
                      columns={columns}
                      getUserFetchNextPage={getUserFetchNextPage}
                      getUserHasNextPage={getUserHasNextPage}
                      getUserIsFetchingNextPage={getUserIsFetchingNextPage}
                      pagination={pagination}
                    />
                  )}
                </TabPanel>
              </TabContext>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
