import { useFormik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { parsePhoneNumber } from "libphonenumber-js";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import * as yup from "yup";
import Cookies from "js-cookie";

//^ utils
import { queryClient } from "../../../../../http";

//^ http requests
import { postVerifyOTP } from "../../../../../http/post-api";

//^ components
import { BlockDes, Button } from "../../../../../components/Component";
import { Box, Stack } from "@mui/material";
import { responseErrorHandler } from "../../../../../utils/Utils";

let currentOtpInx = 0;

function VerifyOtp({
  twoFactEmail,
  twoFactPhoneNumber,
  type = "email",
  otpSize = 4,
  onVerifyOtp,
  buttonLabel = "Verify",
}) {
  //^ otp states
  const [otp, setOtp] = useState(new Array(otpSize).fill(""));
  const [activeOtpIdx, setActiveOtpIdx] = useState(0);

  //^ error states

  //^ refs
  const inputRef = useRef();
  const submitBtnRef = useRef();

  const isOtpFilled = otp.every((item) => item !== "");

  const schema = yup.object().shape({
    inputOneVal: yup.string(),
    inputTwoVal: yup.string(),
    inputThreeVal: yup.string(),
    inputFourVal: yup.string(),
  });

  let initialValues = {};

  useEffect(() => {
    otp.forEach((_, index) => {
      initialValues[`input${index}Val`] = null;
    });
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOtpIdx]);

  //^ verify 2fa otp mutation query
  const {
    isPending: verifyOtpIsPending,
    isError: verifyOtpIsError,
    error: verifyOtpError,
    mutate: verifyOtpMutate,
    reset: verifyOtpReset,
  } = useMutation({
    mutationKey: ["verify-otp"],
    mutationFn: postVerifyOTP,
    onSuccess: (data) => {
      if (data.toast) {
        if (data.type === "error") {
          responseErrorHandler(true, data);
        } else if (data.type === "success") {
          const now = new Date();
          const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);

          onVerifyOtp({ verifiedType: data.data.verifiedType, twoFAStatus: data.data["2fa"] });
          toast.success(data?.message);
          queryClient.invalidateQueries({ queryKey: ["get-user-profile"], exact: true });

          Cookies.set("twoFaData", data?.data?.twoFaData, {
            domain: process.env.REACT_APP_COOKIE_DOMAIN,
            expires: expires,
          });

        } else if (data.message === "Validation failed") {
          responseErrorHandler(true, data);
        } else if (data.type === "info") {
          onVerifyOtp({ verifiedType: data.data.verifiedType, twoFAStatus: data.data["2fa"] });
          toast.info(data?.message);
        }
      }
      verifyOtpReset();
    },
  });

  useEffect(() => {
    responseErrorHandler(verifyOtpIsError, verifyOtpError);
  }, [verifyOtpIsError, verifyOtpError]);

  const formik = useFormik({
    initialValues,
    validationSchema: schema,

    onSubmit() {
      const otpCode = parseInt(otp.join(""));
      let mobileNumber = null;
      let number = null;
      let phoneCode = null;

      if (type === "email") {
        verifyOtpMutate({ otp: otpCode, factType: type, twoFactEmail: twoFactEmail });
      } else if (type === "mobile") {
        mobileNumber = parsePhoneNumber(twoFactPhoneNumber);
        number = mobileNumber.nationalNumber;
        phoneCode = mobileNumber.countryCallingCode;
        verifyOtpMutate({ otp: otpCode, factType: type, twoFactPhone: number, phoneCode });
      }
    },
  });

  const inputChangeHandler = (event, fieldName) => {
    const value = event.target.value;
    const val = value.substring(value.length - 1);

    const newOTP = [...otp];
    newOTP[currentOtpInx] = val;

    setOtp(newOTP);

    if (!value) setActiveOtpIdx(currentOtpInx - 1);
    else setActiveOtpIdx(currentOtpInx + 1);

    formik.setFieldValue(fieldName, val);

    if (currentOtpInx === otp.length - 1) {
      if (val.trim().length > 0) {
        submitBtnRef.current.click();
      }
    }
  };

  function handleOnKeyDown(event, index) {
    const key = event.key;

    currentOtpInx = index;

    if (key === "Backspace") {
      setActiveOtpIdx(currentOtpInx - 1);
    }
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="d-flex flex-column" style={{ gap: "0.8rem" }}>
        <BlockDes>
          <p>
            Enter the code we just send on your{" "}
            {`${type === "email"
              ? "email"
              : type === "mobile"
                ? "mobile phone"
                : type === "both"
                  ? "mobile phone and email"
                  : ""
              }`}{" "}
            <strong>{type === "email" ? twoFactEmail : "mobile" ? twoFactPhoneNumber : ""}</strong>
          </p>
        </BlockDes>
        <Stack
          direction={"column"}
          gap={"1rem"}
          sx={{
            "@media (min-width: 65.5rem)": {
              flexDirection: "row",
            },
          }}
        >
          <Box>
            <div className="d-flex flex-row" style={{ gap: "0.5rem", width: "15rem" }}>
              {otp.map((_, index) => {
                return (
                  <input
                    ref={index === activeOtpIdx ? inputRef : null}
                    key={index}
                    type="number"
                    name={`input${index}Val`}
                    defaultValue={formik.values[`input${index}Val`]}
                    className="form-control spin-button-none text-center"
                    autoFocus=""
                    onClick={(event) => inputChangeHandler(event, `input${index}Val`)}
                    onChange={(event) => inputChangeHandler(event, `input${index}Val`)}
                    onKeyDown={(event) => handleOnKeyDown(event, index)}
                    value={otp[index]}
                    required
                  />
                );
              })}
            </div>
          </Box>
          <Box>
            <Button
              ref={submitBtnRef}
              type={"submit"}
              color={"primary"}
              className={"d-flex"}
              style={{
                gap: "0.5rem",
                alignItems: "center",
                justifyContent: "center",
                cursor: verifyOtpIsPending ? "not-allowed" : "",
                whiteSpace: "nowrap",
              }}
              disabled={!isOtpFilled || verifyOtpIsPending}
            >
              {verifyOtpIsPending ? <Spinner color="light" size={"sm"} /> : ""}
              <span>{buttonLabel}</span>
            </Button>
          </Box>
        </Stack>
      </form>
    </>
  );
}

export default function BothOtpVerify({ emailId, phoneNumber }) {
  const navigate = useNavigate();

  const [twoFAStatus, setTwoFAStatus] = useState(false);
  const [verifiedType, setVerifiedType] = useState({
    email: true,
    phone: true,
  });

  function getVerificationOtpHandler({ twoFAStatus, verifiedType }) {
    setVerifiedType((prevVerifiedType) => {
      if (verifiedType === "email") {
        return {
          ...prevVerifiedType,
          email: false,
        };
      }
      if (verifiedType === "mobile") {
        return {
          ...prevVerifiedType,
          phone: false,
        };
      }
      return prevVerifiedType;
    });

    setTwoFAStatus(twoFAStatus);
  }

  useEffect(() => {
    if (twoFAStatus) {
      navigate(-1);
    }
  }, [twoFAStatus, navigate]);

  return (
    <Stack gap={"1.5rem"}>
      {verifiedType.email ? (
        <VerifyOtp buttonLabel="Verify" type="email" onVerifyOtp={getVerificationOtpHandler} twoFactEmail={emailId} />
      ) : (
        ""
      )}
      {verifiedType.phone ? (
        <VerifyOtp
          buttonLabel="Verify"
          type="mobile"
          onVerifyOtp={getVerificationOtpHandler}
          twoFactPhoneNumber={phoneNumber}
        />
      ) : (
        ""
      )}
    </Stack>
  );
}
