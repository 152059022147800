import { Button } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { fetchAPI, onlyNumberAllow, responseErrorHandler } from "../../../utils/Utils";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";

const OTPSubComponent = ({
  label,
  resend_label,
  tabIndex,
  button_label,
  username,
  onVerify,
  verifyLoading,
  type,
  email,
  country,
  phone_number,
}) => {
  const apiRoute = type === "email" ? "resend-email-otp" : "resend-sms-otp";
  const [resendLoading, setResendLoading] = useState(false);
  const [resendTimer, setResendTimer] = useState(60);
  const [resendDisabled, setResendDisabled] = useState(false);
  let refResendTimer = useRef(null);
  const inputRef = useRef();
  const resendOTP = async () => {
    setResendLoading(true);
    const url = `${process.env.REACT_APP_API_URL}api/v1/auth/${apiRoute}`;
    const formData = new FormData();
    formData.append("username", username);
    if (type === "email") {
      formData.append("email", email);
    } else {
      formData.append("phone_number", phone_number);
      formData.append("country", country);
    }
    let resendOTPRes = await fetchAPI(url, formData);
    setResendLoading(false);
    if (resendOTPRes == null) {
      toast.error("Something went wrong");
    } else {
      if (resendOTPRes.status) {
        toast.success(resendOTPRes.message);
        setResendDisabled(true);
        refResendTimer.current = setInterval(() => {
          setResendTimer((prev) => prev - 1);
        }, 1000);
      } else {
        responseErrorHandler(true, resendOTPRes);
      }
    }
  };
  if (resendTimer < 1) {
    clearInterval(refResendTimer.current);
    setResendDisabled(false);
    setResendTimer(60);
  }
  const verifyOTP = () => {
    const OTP = inputRef.current.value;
    if (!OTP.length > 0) {
      toast.error(`Enter ${label}`);
      return false;
    }
    onVerify(OTP);
  };
  const debounceEnterClickMethod = function (fn, delay) {
    let timer;
    return function () {
      let context = this;
      let args = arguments;
      console.log(args);
      clearTimeout(timer);
      timer = setTimeout(() => {
        let e = args[0];
        if (e.key === "Enter") fn.apply(context, args);
      }, delay);
    };
  };
  const handleEnterClick = debounceEnterClickMethod(verifyOTP, 300);

  useEffect(() => {}, []);
  return (
    <div className="form-group">
      <div className="form-label-group">
        <label className="form-label" htmlFor="default-01">
          {label}
        </label>
        <button
          type="button"
          className={`link link-primary link-sm`}
          style={{ cursor: `${resendDisabled || resendLoading ? "wait" : "pointer"}` }}
          disabled={resendDisabled || resendLoading}
          onClick={resendOTP}
        >
          {resendDisabled
            ? `Resend ${type} OTP in ${resendTimer} seconds`
            : resendLoading
            ? `Resending ${type} OTP`
            : `${resend_label}`}
        </button>
      </div>
      <div className="form-control-wrap d-flex">
        <input
          type="number"
          id="email_otp"
          onKeyDown={onlyNumberAllow}
          tabIndex={tabIndex}
          placeholder="1234"
          ref={inputRef}
          onKeyUp={(e) => handleEnterClick(e)}
          className="form-control-lg bg-transparent border form-control me-1"
        />
        <Button
          variant={"contained"}
          size="large"
          type="button"
          color="primary"
          disabled={verifyLoading}
          onClick={verifyOTP}
          startIcon={verifyLoading ? <Spinner size="sm" style={{ borderWidth: "2px", color: "inherit" }} /> : ""}
        >
          {button_label}
        </Button>
      </div>
    </div>
  );
};

export default OTPSubComponent;
