import React, { useState } from "react";

//^ http request

//^ portals
import ImageModal from "../../../components/ui/modals/image-modal";
import { AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";
import ImageWithPlaceholder from "../../../components/ui/auth-otp/image/image-with-placeholder";
import useImageLoader from "../../../hooks/user-image-loader";
import Avatar from "../../../components/svg/avatar";

const AccountAvatar = ({ profileName, showImg = false, width = 40, height = 40, style, ...props }) => {
  const [showImgModal, setShowImgModal] = useState(false);

  const profileData = useSelector((state) => state.userProfile.profileData);
  const { loaded, currentSrc } = useImageLoader(profileData?.profile_image_path);

  function toggleImageModalHandler() {
    setShowImgModal((prevShowImgModal) => !prevShowImgModal);
  }

  return (
    <>
      <AnimatePresence>
        {showImgModal && (
          <ImageModal image={profileData?.profile_image_path || ""} onBackdrop={toggleImageModalHandler} />
        )}
      </AnimatePresence>
      {showImg && profileData?.profile_image_path ? (
        !profileData?.profile_image_path ? (
          <>
            <ImageWithPlaceholder
              src={profileData?.profile_image_path}
              alt={profileName}
              height={height}
              width={width}
              style={{ objectFit: "cover", borderRadius: "50%", ...style }}
              showAvatarPlaceholder={true}
              {...props}
            />
          </>
        ) : (
          <>
            {!loaded ? <Avatar style={{ height: height, width: width }} /> : ""}
            {loaded && (
              <img
                avatar={true}
                src={currentSrc}
                alt={profileName}
                height={height}
                width={width}
                style={{ objectFit: "cover", borderRadius: "50%", ...style }}
                showAvatarPlaceholder={true}
                onClick={() => toggleImageModalHandler()}
                {...props}
              />
            )}
          </>
        )
      ) : (
        <ImageWithPlaceholder
          src={profileData?.profile_image_path}
          alt={profileName}
          height={height}
          width={width}
          style={{ objectFit: "cover", borderRadius: "50%", ...style }}
          showAvatarPlaceholder={true}
          {...props}
        />
      )}
    </>
  );
};

export default AccountAvatar;
