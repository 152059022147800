import React, { memo } from "react";
import { Box, Divider, Stack, Typography } from "@mui/material";
import Separator from "../ui/separator/Separator";

const Title = memo(({ title, divider = true, endComponent, column = false, spacing = true }) => {
  return (
    <>
      <Stack
        width={"100%"}
        justifyContent={"space-between"}
        sx={{
          gap: "1rem",
          flexDirection: column ? "column" : "row",
          "@media (min-width: 65.5rem)": {
            alignItems: "center",
            flexDirection: "row",
          },
        }}
      >
        <Box width={"100%"}>
          <Typography
            variant="h6"
            sx={{
              fontSize: "1.25rem",
              fontWeight: 400,
            }}
          >
            {title}
          </Typography>
        </Box>
        {endComponent && (
          <Stack justifyContent={"flex-start"} direction={"row"}>
            {endComponent ? endComponent : ""}
          </Stack>
        )}
      </Stack>
      {divider ? spacing ? <Separator /> : <Divider /> : ""}
    </>
  );
});

export default Title;
