import React from "react";
import { useDispatch } from "react-redux";

//^ action
import { userProfileAction } from "../store/slices/user-profile-slice";

import {
  Card,
  Box,
  Stack,
  Typography,
  Divider,
  List,
  ListItemText,
  ListItemIcon,
  ListItem,
  Skeleton,
  useMediaQuery,
  Fab,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { PeopleAltRounded, GroupAddRounded, PersonRounded, Edit, PeopleRounded } from "@mui/icons-material";
import ImageWithPlaceholder from "./ui/auth-otp/image/image-with-placeholder";

function ConnectionListItem({ Icon, listText, count, isLoading }) {
  return (
    <>
      <ListItem>
        <ListItemIcon>
          <Icon
            sx={{
              fontSize: "1.25rem",
            }}
          />
        </ListItemIcon>
        <ListItemText
          primary={
            <Stack direction={"row"} width={"100%"} alignItems={"center"} justifyContent={"space-between"}>
              <Typography variant="subtitle2" fontWeight={400}>
                {listText}
              </Typography>
              {isLoading ? (
                <Skeleton variant="text" width={30} />
              ) : (
                <Typography variant="subtitle2">{count}</Typography>
              )}
            </Stack>
          }
        />
      </ListItem>
    </>
  );
}

export default function UserCard({ isLoading, ...props }) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const laptopMatches = useMediaQuery("(min-width: 65.5rem)");

  const {
    username,
    subscriptionCategory,
    showConnection = false,
    connectionCounts,
    requestCounts,
    followerCounts,
    followingCounts,
    avatarImg,
    coverImg,
  } = props;

  return (
    <Card elevation={isDark ? 0.6 : 0} variant={isDark ? (laptopMatches ? "elevation" : "outlined") : "outlined"}>
      {isLoading ? (
        <Box position={"relative"} width={"100%"}>
          <Skeleton width={"100%"} variant="rectangular" height={"5.5625rem"} />
          <Stack
            direction={"row"}
            position={"absolute"}
            zIndex={1}
            bottom={"-2rem"}
            width={"100%"}
            alignItems={"center"}
          >
            <Stack width={"100%"} alignItems={"center"}>
              <Skeleton variant="circular" width={98} height={98} />
            </Stack>
          </Stack>
        </Box>
      ) : (
        <Box position={"relative"} sx={{ background: `${coverImg}` }} width={"100%"} height={"5.5625rem"}>
          <Stack
            direction={"row"}
            position={"absolute"}
            zIndex={1}
            bottom={"-2rem"}
            width={"100%"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Box position={"relative"}>
              <ImageWithPlaceholder
                showAvatarPlaceholder={true}
                src={avatarImg}
                alt={username}
                height={108}
                width={108}
                style={{ objectFit: "cover", borderRadius: "50%" }}
              />

              <Fab
                onClick={() => dispatch(userProfileAction.setToggleProfileModel(true))}
                sx={{
                  position: "absolute",
                  bottom: "1rem",
                  right: "0rem",
                  boxShadow: "none",
                  width: 20,
                  height: 20,
                  minHeight: 20,
                }}
                size="small"
                color="primary"
              >
                <Edit sx={{ fontSize: "0.7rem" }} />
              </Fab>
            </Box>
          </Stack>
        </Box>
      )}
      <Stack gap={"2.8125rem"}>
        <Box width={"100%"} mt={"3.5rem"}>
          <Stack alignItems={"center"}>
            {isLoading ? <Skeleton width={"50%"} /> : <Typography variant="h6">{username}</Typography>}
            {isLoading ? (
              <Skeleton width={"30%"} height={"1rem"} />
            ) : (
              <Typography textTransform={"capitalize"} variant="body2">
                {subscriptionCategory}
              </Typography>
            )}
          </Stack>
        </Box>
        {showConnection && (
          <Box>
            <Divider />
            <List sx={{ width: "100%" }} component={"ul"} elevation={1}>
              <ConnectionListItem
                isLoading={isLoading}
                Icon={PeopleAltRounded}
                count={connectionCounts}
                listText={"Connections"}
              />
              <Divider />
              <ConnectionListItem
                isLoading={isLoading}
                Icon={GroupAddRounded}
                count={requestCounts}
                listText={"Requests"}
              />
              <Divider />
              <ConnectionListItem
                isLoading={isLoading}
                Icon={PersonRounded}
                count={followingCounts}
                listText={"Following"}
              />
              <Divider />
              <ConnectionListItem
                isLoading={isLoading}
                Icon={PeopleRounded}
                count={followerCounts}
                listText={"Followers"}
              />
            </List>
          </Box>
        )}
      </Stack>
    </Card>
  );
}
