import React from "react";

export default function Bronze() {
  return (
    <svg width="237" height="237" viewBox="0 0 237 237" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Bronze">
        <g id="Medal 3" clipPath="url(#clip0_410_53)">
          <g id="Bronze_2">
            <path
              id="Vector"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M73.2909 52.0786H158.954C160.779 52.0786 162.27 53.5699 162.27 55.3949V118.884C162.27 144.276 141.509 165.037 116.118 165.037C90.7355 165.037 69.9648 144.276 69.9648 118.884V55.3949C69.9648 53.5699 71.466 52.0786 73.2909 52.0786Z"
              fill="#FEFEFE"
              stroke="#F64C5E"
              strokeWidth="5.33323"
            />
            <g id="_3113128525664">
              <path
                id="Vector_2"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M121.336 0.5L121.199 12.048L130.549 18.808L119.531 22.242L115.989 33.2307L109.318 23.8118L97.7695 23.8413L104.667 14.5793L101.076 3.61021L112.016 7.30909L121.336 0.5Z"
                fill="#F64C5E"
              />
              <path
                id="Vector_3"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M85.2505 20.8193L85.1917 26.1077L89.4694 29.2081L84.4264 30.7779L82.7977 35.8111L79.7463 31.4941L74.458 31.5137L77.6173 27.2654L75.969 22.242L80.9826 23.9394L85.2505 20.8193Z"
                fill="#F1D4D8"
              />
              <path
                id="Vector_4"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M152.458 17.7876L152.379 24.4593L157.786 28.3643L151.418 30.3462L149.367 36.6941L145.521 31.2488L138.85 31.2684L142.833 25.9212L140.763 19.5831L147.072 21.722L152.458 17.7876Z"
                fill="#F1D4D8"
              />
            </g>
            <path
              id="Vector_5"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M167.254 63.6856H181.755C181.755 63.6856 185.052 63.5385 184.905 67.934V102.882C184.905 102.882 183.885 123.025 165.714 128.519L167.176 121.2C167.176 121.2 177.576 117.972 178.9 102.146V73.9483C178.9 73.9483 179.263 69.5528 174.574 69.4743H167.254V63.6856Z"
              fill="white"
              stroke="#F64C5E"
              strokeWidth="5.33323"
            />
            <path
              id="Vector_6"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M65.0588 63.6856H50.5478C50.5478 63.6856 47.2512 63.5385 47.3983 67.934V102.882C47.3983 102.882 48.4187 123.025 66.5894 128.519L65.1275 121.2C65.1275 121.2 54.7274 117.972 53.4029 102.146V73.9483C53.4029 73.9483 53.0399 69.5528 57.7297 69.4743H65.0588V63.6856Z"
              fill="white"
              stroke="#F64C5E"
              strokeWidth="5.33323"
            />
            <path
              id="Vector_7"
              d="M161.201 192.146H71.4854C69.3938 192.146 67.6982 193.841 67.6982 195.933V199.2C67.6982 201.291 69.3938 202.987 71.4854 202.987H161.201C163.292 202.987 164.988 201.291 164.988 199.2V195.933C164.988 193.841 163.292 192.146 161.201 192.146Z"
              fill="#F64C5E"
            />
            <path
              id="Vector_8"
              d="M161.573 208.021H71.1126C69.2269 208.021 67.6982 209.549 67.6982 211.435V215.448C67.6982 217.333 69.2269 218.862 71.1126 218.862H161.573C163.459 218.862 164.988 217.333 164.988 215.448V211.435C164.988 209.549 163.459 208.021 161.573 208.021Z"
              fill="#F64C5E"
            />
            <path
              id="Vector_9"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M99.958 171.415V185.7H131.609V171.415C131.609 171.415 119.855 178.322 99.958 171.415Z"
              fill="#F1D4D8"
            />
            <path
              id="Vector_10"
              d="M217.979 200.348C222.813 200.348 226.731 196.43 226.731 191.596C226.731 186.763 222.813 182.845 217.979 182.845C213.146 182.845 209.228 186.763 209.228 191.596C209.228 196.43 213.146 200.348 217.979 200.348Z"
              fill="#F1D4D8"
            />
            <path
              id="Vector_11"
              d="M29.7083 237.004C32.694 237.004 35.1144 234.583 35.1144 231.597C35.1144 228.612 32.694 226.191 29.7083 226.191C26.7226 226.191 24.3022 228.612 24.3022 231.597C24.3022 234.583 26.7226 237.004 29.7083 237.004Z"
              fill="#F1D4D8"
            />
            <path
              id="Vector_12"
              d="M225.926 77.1567C230.895 77.1567 234.923 73.1285 234.923 68.1596C234.923 63.1907 230.895 59.1626 225.926 59.1626C220.957 59.1626 216.929 63.1907 216.929 68.1596C216.929 73.1285 220.957 77.1567 225.926 77.1567Z"
              stroke="#F2B4BE"
              strokeWidth="2.66643"
            />
            <path
              id="Vector_13"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.11287 86.4282H13.765V93.0313H20.368V99.6834H13.765V106.296H7.11287V99.6834H0.5V93.0313H7.11287V86.4282Z"
              fill="#F1D4D8"
            />
            <path
              id="Vector_14"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M94.0026 124.869C99.0162 125.576 104.569 123.839 109.455 122.171C111.918 121.396 114.646 120.778 117.481 120.415C128.215 119.051 133.356 122.858 141.519 121.318C144.845 120.69 147.435 118.629 149.162 115.902C152.164 111.163 150.889 104.717 147.347 101.401C145.414 99.5855 141.676 97.7409 139.066 96.9266C134.808 72.9084 109.132 78.442 105.05 90.0194C103.529 87.7922 92.2758 85.6043 90.4901 96.6911C80.1097 98.8889 75.4689 122.269 94.0026 124.869ZM136.084 99.4972C151.85 102.303 150.428 118.158 136.633 118.551C130.314 118.737 126.154 116.657 119.385 116.834C106.963 117.158 98.3097 123.751 91.8833 120.435C83.4063 117.246 82.3957 103.863 91.7459 99.35C92.315 99.0753 92.9037 98.9281 92.9822 98.5651C92.315 88.999 103.49 90.0881 105.678 93.1983C114.42 75.7831 136.761 84.4956 136.084 99.4972Z"
              fill="#F64C5E"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_410_53">
          <rect width="235.767" height="236.504" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
