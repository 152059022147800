import { motion } from "framer-motion";

import InvoicesComp from "../../components/invoicesComp";

export default function Invoice() {
  return (
    <motion.div initial={{ scale: 0.8 }} animate={{ scale: 1 }}>
      <InvoicesComp />
    </motion.div>
  );
}
