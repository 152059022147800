import React from "react";

//^ mui components
import { Box, Card, Stack, Typography, useMediaQuery } from "@mui/material";

import Tag from "../../ui/tag";
import { isEmptyObject } from "../../../utils/Utils";
import { FiberManualRecord } from "@mui/icons-material";

function PackageDetail({ ...props }) {
  return (
    <Stack spacing={1}>
      <Typography variant="subtitle2" fontWeight={500}>
        {props.subtitle}
      </Typography>
      <Stack spacing={1}>
        <Stack>
          <Typography>
            <strong>{props.name}</strong> <span>{`for ${props.price}`}</span>
            <Typography component={"strong"} variant="subtitle2" fontWeight={700}>{`/${
              props.validity === "1" ? "month" : "year"
            }`}</Typography>
          </Typography>
          <Typography>
            <Stack direction={"row"} alignItems={"center"} gap={1}>
              <span>{props.startDate}</span> <FiberManualRecord sx={{ fontSize: "0.65rem" }} color="disabled" />{" "}
              <span>{props.endDate}</span>
            </Stack>
          </Typography>
        </Stack>
        <Stack direction={"row"} flexWrap={"wrap"} alignItems={"center"} gap={1}>
          {props?.services?.map((service, index) => {
            return (
              <React.Fragment key={index}>
                <Tag text={service} />
              </React.Fragment>
            );
          })}
        </Stack>
      </Stack>
    </Stack>
  );
}

export default function PackageMembershipCard({
  package: packageMembership,
  isPackage,
  isUpcomingPackage,
  arePackagesEmpty,
}) {
  const lgMatches = useMediaQuery("(min-width: 64rem)");

  const { currentPackage, upcomingPackage } = packageMembership;
  const {
    name,
    price: planPrice,
    start_date: planStartDate,
    end_date: planEndDate,
    services: planServices,
    validity: planValidity,
  } = currentPackage;
  const {
    name: nextPlanName,
    price: nextPlanPrice,
    start_date: nextPlanStartDate,
    end_date: nextPlanEndDate,
    services: nextPlanServices,
    validity: nextPlanValidity,
  } = upcomingPackage;

  return (
    <Box>
      {arePackagesEmpty && (
        <Stack gap={1.5}>
          <Typography variant="h5">Package Membership</Typography>
          <Card variant={"outlined"} sx={lgMatches ? { padding: "1rem 0.925rem" } : { padding: "1rem 0.925rem" }}>
            <Stack spacing={2}>
              {(isPackage || isEmptyObject(currentPackage)) && (
                <PackageDetail
                  subtitle={"Your Plan"}
                  name={name || ""}
                  price={planPrice || ""}
                  startDate={planStartDate || ""}
                  endDate={planEndDate || ""}
                  services={planServices || []}
                  validity={planValidity || ""}
                />
              )}
              {(isUpcomingPackage || isEmptyObject(currentPackage)) && (
                <PackageDetail
                  subtitle={"Your next plan"}
                  name={nextPlanName || ""}
                  price={nextPlanPrice || ""}
                  startDate={nextPlanStartDate || ""}
                  endDate={nextPlanEndDate || ""}
                  services={nextPlanServices || []}
                  validity={nextPlanValidity || ""}
                />
              )}
            </Stack>
          </Card>
        </Stack>
      )}
    </Box>
  );
}
