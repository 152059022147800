import React, { useReducer } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";

//^ redux actions
import { setValidity } from "../../store/slices/package-subscription-slice";

//^ mui components
import { Box, Stack, Tab, Tabs, useMediaQuery } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import { CardMembership, Loyalty, Receipt } from "@mui/icons-material";

//^ pages
import SubscriptionPackage from "../pre-built/package-subscription/subscription-packages/SubscriptionPackage";
import ServicePage from "../service/ServicePage";
import AccountInvoicesPage from "../account-invoices-page/AccountInvoicesPage";
import BottomNavigations from "../../components/navigations/bottom-navigations";

//^ components
import Switch from "../../components/ui/switch/Switch";
import BackButton from "../../components/button/back-button";

export default function SubscriptionsPage() {
  const lgMatches = useMediaQuery("(min-width: 64rem)");
  const dispatch = useDispatch();
  const validity = useSelector((store) => store.packageSubscription.validity);
  const showService = useSelector((state) => state.siteSetting.showService);
  const [searchParams, setSearchParams] = useSearchParams({ tab: "packageSubscriptions" });

  const initialValue = {
    showSwitch: true,
    showPlanBtn: undefined,
  };
  function subReducerHandler(state, action) {
    switch (action.type) {
      case "SHOW_SWITCH":
        return {
          ...state,
          showSwitch: true,
          showPlanBtn: false,
        };
      case "DISABLE_SWITCH": {
        return {
          ...state,
          showSwitch: false,
          showPlanBtn: true,
        };
      }
      default:
        return state;
    }
  }

  const [subState, subDispatch] = useReducer(subReducerHandler, initialValue);
  const [bottomNavVal, setBottomNavVal] = React.useState("");

  const tabValue = searchParams.get("tab");

  const handleChange = (_event, newValue) => {
    setSearchParams({ tab: newValue });
  };

  const validityClick = React.useCallback(
    (isChecked) => {
      if (isChecked) {
        dispatch(setValidity(true));
      } else {
        dispatch(setValidity(false));
      }
    },
    [dispatch]
  );

  const navList = [
    {
      label: "Package",
      value: "packageSubscriptions",
      icon: <Loyalty />,
    },
    {
      label: "Service",
      value: "serviceSubscriptions",
      icon: <CardMembership />,
    },
    {
      label: "Invoice",
      value: "invoiceSubscriptions",
      icon: <Receipt />,
    },
  ];

  return (
    <>
      <TabContext value={tabValue}>
        <Box
          sx={{
            display: "none",
            width: "100%",
            borderBottom: 1,
            borderColor: "divider",
            "@media (min-width: 65.5rem)": {
              display: "block",
            },
          }}
        >
          <Stack direction={"row"} alignItems={"center"} width={"100%"} justifyContent={"space-between"}>
            <Tabs
              value={tabValue}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              allowScrollButtonsMobile
              aria-label="scrollable auto tabs example"
            >
              <Tab label="Package Subscriptions" value={"packageSubscriptions"} />
              {showService && <Tab label="Service Subscriptions" value={"serviceSubscriptions"} />}
              <Tab label="Invoices" value={"invoiceSubscriptions"} />
            </Tabs>
            {tabValue === "packageSubscriptions" ? (
              subState.showSwitch ? (
                <Box>
                  <Switch
                    translateY={"translateY(0.4px)"}
                    checkedTranslateY={"translateY(0.4px)"}
                    checkedLabel={"Monthly"}
                    unCheckedLabel={"Yearly"}
                    onClick={validityClick}
                    checked={validity}
                  />
                </Box>
              ) : (
                subState.showPlanBtn && (
                  <Box>
                    <BackButton onClick={() => subDispatch({ type: "SHOW_SWITCH" })} title={"Plans"} variant={"text"} />
                  </Box>
                )
              )
            ) : (
              ""
            )}
          </Stack>
        </Box>
        <Box display={lgMatches ? "none" : "block"}>
          <Stack alignItems={"flex-end"}>
            {tabValue === "packageSubscriptions" && subState.showSwitch ? (
              <Switch
                translateY={"translateY(0.4px)"}
                checkedTranslateY={"translateY(0.4px)"}
                checkedLabel={"Monthly"}
                unCheckedLabel={"Yearly"}
                onClick={validityClick}
                checked={validity}
              />
            ) : (
              subState.showPlanBtn && (
                <Box>
                  <BackButton onClick={() => subDispatch({ type: "SHOW_SWITCH" })} title={"Plans"} variant={"text"} />
                </Box>
              )
            )}
          </Stack>
        </Box>
        <TabPanel
          sx={{
            padding: "1rem 0rem",
            "@media(min-width: 65.5rem)": {
              padding: "1rem 0rem",
              width: "100%",
            },
          }}
          value={"packageSubscriptions"}
        >
          <SubscriptionPackage subDispatch={subDispatch} showPlanBtn={subState.showPlanBtn} />
        </TabPanel>
        {showService && (
          <TabPanel
            sx={{
              padding: "2.25rem 0rem 3.25rem 0rem",
              "@media(min-width: 65.5rem)": {
                padding: "1rem 0rem",
              },
            }}
            value={"serviceSubscriptions"}
          >
            <ServicePage />
          </TabPanel>
        )}
        <TabPanel
          sx={{
            padding: "2.25rem 0rem 3.25rem 0rem",
            "@media(min-width: 65.5rem)": {
              padding: "1rem 0rem",
            },
          }}
          value={"invoiceSubscriptions"}
        >
          <AccountInvoicesPage />
        </TabPanel>
      </TabContext>
      <BottomNavigations
        showLabels
        value={bottomNavVal}
        setValue={setBottomNavVal}
        elevation={1}
        sx={{
          width: "100%",
          position: "fixed",
          bottom: 0,
          left: 0,
          zIndex: 100,
          "@media (min-width: 65.5rem)": {
            display: "none",
          },
          borderRadius: "0rem",
        }}
        actions={navList}
        key={"tab"}
      />
    </>
  );
}
