import React from "react";
import { getCoreRowModel, getFilteredRowModel, getSortedRowModel, useReactTable } from "@tanstack/react-table";
import { useSearchParams } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { useInView } from "react-intersection-observer";
import { useSelector } from "react-redux";

//^ mui components
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { TabContext, TabPanel } from "@mui/lab";
import { useTheme } from "@mui/material/styles";

//^ skeleton
import FollowListSkeleton from "../../../components/ui/loader/Skeleton/follow-list-skeleton";
import NoUsers from "../../../components/svg/account-network/no-invitations";
import NoUserDark from "../../../components/svg/account-network/no-users-dark";

function FollowList({
  table,
  columns,
  followersHasNextPage,
  followersFetchNextPage,
  followersIsFetchingNextPage,
  pagination,
}) {
  const [screenHeight, setScreenHeight] = React.useState(window.innerHeight);
  const { ref, inView } = useInView();
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  React.useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  React.useEffect(() => {
    if (inView && followersHasNextPage) {
      followersFetchNextPage();
    }

    // eslint-disable-next-line
  }, [inView, followersHasNextPage]);

  const adjustedHeight = screenHeight - 21.9 * parseFloat(getComputedStyle(document.documentElement).fontSize);

  return (
    <>
      <SimpleBar style={{ maxHeight: `${adjustedHeight}px`, width: "100%" }}>
        {table.getRowModel()?.rows?.length ? (
          table.getRowModel()?.rows?.map((row) =>
            row?.getVisibleCells()?.map((cell, index) => {
              if (row?.getVisibleCells()?.length === index - 1) {
                <Box
                  ref={ref}
                  key={cell.id}
                  sx={{
                    display:
                      cell.getContext().column.id === "username" ||
                      cell.getContext().column.id === "lastName" ||
                      cell.getContext().column.id === "created_at" ||
                      cell.getContext().column.id === "email"
                        ? "none"
                        : "",
                  }}
                >
                  <Typography variant="subtitle2" fontWeight={400}>
                    {cell.getValue()}
                  </Typography>
                </Box>;
              }

              return (
                <Box
                  key={cell.id}
                  sx={{
                    display:
                      cell.getContext().column.id === "username" ||
                      cell.getContext().column.id === "lastName" ||
                      cell.getContext().column.id === "created_at" ||
                      cell.getContext().column.id === "email"
                        ? "none"
                        : "",
                  }}
                >
                  <Typography variant="subtitle2" fontWeight={400}>
                    {cell.getValue()}
                  </Typography>
                </Box>
              );
            })
          )
        ) : followersIsFetchingNextPage ? (
          ""
        ) : (
          <Box colSpan={columns.length} sx={{ height: "30rem", padding: "1.25rem" }}>
            <Stack alignItems={"center"} justifyContent={"center"} height={"100%"}>
              {isDark ? <NoUserDark /> : <NoUsers />}
            </Stack>
          </Box>
        )}
        {followersHasNextPage && pagination === 1 && (
          <Stack ref={ref} direction={"row"} padding={"1.25rem"} width={"100%"}>
            {followersIsFetchingNextPage ? (
              <Stack width={"100%"}>
                <FollowListSkeleton length={1} padding />
              </Stack>
            ) : (
              ""
            )}
          </Stack>
        )}
      </SimpleBar>
    </>
  );
}

export default function FollowerUserLists({
  isRefetching,
  columns,
  isLoading,
  followersIsFetchingNextPage,
  followersFetchNextPage,
  followersHasNextPage,
  searchComponent,
  pagination,
}) {
  const followListsData = useSelector((state) => state.userNetwork.followers);
  const followerUserLists = useSelector((state) => state.userNetwork.followersValues);

  const [connectionFilter, setConnectionFilter] = React.useState("none");
  const [sorting, setSorting] = React.useState([]);

  const [searchParams, setSearchParams] = useSearchParams();
  const followType = searchParams.get("followType") || "followed";

  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const addFollowTypeParam = ({ value, type = "append" }) => {
    if (type === "append") {
      const params = new URLSearchParams(searchParams);
      params.append("followType", value);
      setSearchParams(params.toString());
    } else if (type === "set") {
      const params = new URLSearchParams(searchParams);
      params.set("followType", value);
      setSearchParams(params.toString());
    }
  };

  React.useEffect(() => {
    if (followType === null) {
      addFollowTypeParam({ value: "followed" });
    } else {
      addFollowTypeParam({ value: followType, type: "set" });
    }
    // eslint-disable-next-line
  }, []);

  const table = useReactTable({
    data: followListsData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onSortingChange: setSorting,
    state: {
      sorting,
    },
  });

  function handleStatusSelectChange(event) {
    const recentValues = event.target.value;

    setConnectionFilter(recentValues);
    if (recentValues !== "none") {
      setSorting([
        {
          id: recentValues,
          asc: true,
        },
      ]);
    } else {
      setSorting([]);
    }
  }
  function handleFollowListTabChange(_event, value) {
    if (value !== followType) {
      addFollowTypeParam({ value, type: "set" });
    }
  }

  return (
    <>
      <Box
        sx={{
          display: "none",
          "@media (min-width: 65.5rem)": {
            display: "block",
          },
        }}
      >
        <Paper
          variant={isDark ? "elevation" : "outlined"}
          elevation={isDark ? 0.6 : 3}
          sx={{
            padding: "1.25rem",
            height: "100%",
            borderRadius: "0.425rem",
            width: "100%",
          }}
        >
          <Stack gap={2}>
            <Stack gap={2}>
              <Stack
                gap={2}
                sx={{
                  "@media (min-width: 65.5rem)": {
                    justifyContent: "space-between",
                    alignItems: "center",
                    flexDirection: "row",
                  },
                }}
              >
                <Stack direction={"row"} gap={2} alignItems={"center"}>
                  <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                    <InputLabel id="status-outlined-select-label">Sort By</InputLabel>
                    <Select
                      size="small"
                      value={connectionFilter}
                      onChange={handleStatusSelectChange}
                      labelId="status-outlined-select-label"
                      id="status-outlined-select"
                      label="Sort by"
                    >
                      <MenuItem value="none">
                        <Typography component={"em"} variant="subtitle2">
                          None
                        </Typography>
                      </MenuItem>
                      <MenuItem value={"username"}>
                        <Typography variant="subtitle2">Username</Typography>
                      </MenuItem>
                      <MenuItem value={"created_at"}>
                        <Typography variant="subtitle2">Recent</Typography>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
                {searchComponent}
              </Stack>
            </Stack>

            <Stack>
              <TabContext value={followType === null ? "" : followType}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={followType === null ? "" : followType}
                    onChange={handleFollowListTabChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                    aria-label="scrollable auto tabs example"
                  >
                    {followerUserLists?.map((followerUser, index) => (
                      <Tab
                        key={index}
                        label={
                          <Stack alignItems={"center"} direction={"row"} gap={2}>
                            <Typography variant="subtitle2" fontWeight={400} textTransform={"capitalize"}>
                              {followerUser?.label}
                            </Typography>
                            <Chip
                              sx={{ minWidth: "1.25rem", height: "1.45rem" }}
                              label={`${followerUser?.amount || 0}`}
                            />
                          </Stack>
                        }
                        value={followerUser?.value}
                      />
                    ))}
                  </Tabs>
                </Box>
                <TabPanel sx={{ padding: "0rem" }} value={"followed"}>
                  {isLoading || (followListsData?.length === 0 && isRefetching) ? (
                    <FollowListSkeleton />
                  ) : (
                    <FollowList
                      followersFetchNextPage={followersFetchNextPage}
                      pagination={pagination}
                      followersHasNextPage={followersHasNextPage}
                      followersIsFetchingNextPage={followersIsFetchingNextPage}
                      table={table}
                      columns={columns}
                    />
                  )}
                </TabPanel>
                <TabPanel sx={{ padding: "0rem" }} value={"followers"}>
                  {isLoading || (followListsData?.length === 0 && isRefetching) ? (
                    <FollowListSkeleton />
                  ) : (
                    <FollowList
                      followersFetchNextPage={followersFetchNextPage}
                      pagination={pagination}
                      followersHasNextPage={followersHasNextPage}
                      followersIsFetchingNextPage={followersIsFetchingNextPage}
                      table={table}
                      columns={columns}
                    />
                  )}
                </TabPanel>
                <TabPanel sx={{ padding: "0rem" }} value={"not_followed"}>
                  {isLoading || (followListsData?.length === 0 && isRefetching) ? (
                    <FollowListSkeleton />
                  ) : (
                    <FollowList
                      followersFetchNextPage={followersFetchNextPage}
                      pagination={pagination}
                      followersHasNextPage={followersHasNextPage}
                      followersIsFetchingNextPage={followersIsFetchingNextPage}
                      table={table}
                      columns={columns}
                    />
                  )}
                </TabPanel>
              </TabContext>
            </Stack>
          </Stack>
        </Paper>
      </Box>
      <Box
        sx={{
          display: "block",
          "@media (min-width: 65.5rem)": {
            display: "none",
          },
        }}
      >
        <Stack gap={2}>
          <Stack gap={2}>
            <Stack
              gap={2}
              sx={{
                "@media (min-width: 65.5rem)": {
                  justifyContent: "space-between",
                  alignItems: "center",
                  flexDirection: "row",
                },
              }}
            >
              <Stack direction={"row"} gap={2} alignItems={"center"}>
                <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                  <InputLabel id="status-outlined-select-label">Sort By</InputLabel>
                  <Select
                    size="small"
                    value={connectionFilter}
                    onChange={handleStatusSelectChange}
                    labelId="status-outlined-select-label"
                    id="status-outlined-select"
                    label="Sort by"
                  >
                    <MenuItem value="none">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={"username"}>Username</MenuItem>
                    <MenuItem value={"recent"}>Recent</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              {searchComponent}
            </Stack>
          </Stack>
          <Stack>
            <TabContext value={followType === null ? "" : followType}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={followType === null ? "" : followType}
                  onChange={handleFollowListTabChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  allowScrollButtonsMobile
                  aria-label="scrollable auto tabs example"
                >
                  <Tab label="Following" value={"followed"} />
                  <Tab label="Followers" value={"followers"} />
                  <Tab label="Not Followed" value={"not_followed"} />
                </Tabs>
              </Box>
              <TabPanel sx={{ padding: "0rem" }} value={"followed"}>
                {isLoading || (followListsData?.length === 0 && isRefetching) ? (
                  <FollowListSkeleton />
                ) : (
                  <FollowList
                    followersFetchNextPage={followersFetchNextPage}
                    followersHasNextPage={followersHasNextPage}
                    followersIsFetchingNextPage={followersIsFetchingNextPage}
                    table={table}
                    columns={columns}
                    pagination={pagination}
                  />
                )}
              </TabPanel>
              <TabPanel sx={{ padding: "0rem" }} value={"followers"}>
                {isLoading || (followListsData?.length === 0 && isRefetching) ? (
                  <FollowListSkeleton />
                ) : (
                  <FollowList
                    followersFetchNextPage={followersFetchNextPage}
                    followersHasNextPage={followersHasNextPage}
                    followersIsFetchingNextPage={followersIsFetchingNextPage}
                    table={table}
                    columns={columns}
                    pagination={pagination}
                  />
                )}
              </TabPanel>
              <TabPanel sx={{ padding: "0rem" }} value={"not_followed"}>
                {isLoading || (followListsData?.length === 0 && isRefetching) ? (
                  <FollowListSkeleton />
                ) : (
                  <FollowList
                    followersFetchNextPage={followersFetchNextPage}
                    followersHasNextPage={followersHasNextPage}
                    followersIsFetchingNextPage={followersIsFetchingNextPage}
                    table={table}
                    columns={columns}
                    pagination={pagination}
                  />
                )}
              </TabPanel>
            </TabContext>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
