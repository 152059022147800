import { createPortal } from "react-dom";
import { motion } from "framer-motion";

import styles from "./ImagePortal.module.scss";

//^ layout
import { useTheme } from "../../../layout/provider/Theme";
import { Avatar } from "@mui/material";
import ImageWithPlaceholder from "../auth-otp/image/image-with-placeholder";

export const Backdrop = ({ onClick }) => {
  const theme = useTheme();
  const isDark = theme.skin === "dark";

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className={`${styles["backdrop"]} ${isDark ? styles["dark"] : ""}`}
      onClick={onClick}
    />
  );
};

export const Model = ({ image }) => {
  const theme = useTheme();
  const isDark = theme.skin === "dark";

  return (
    <>
      <motion.article
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        id={styles["image-article"]}
        className={`image-overlay ${styles["overlay"]} ${isDark ? styles["dark"] : ""}`}
      >
        {image ? (
          <ImageWithPlaceholder loading="lazy" src={image} alt="pic-profile-img" />
        ) : (
          <Avatar sx={{ width: "30rem", height: "30rem" }} />
        )}
      </motion.article>
    </>
  );
};

const ImageModal = ({ children, onBackdrop, image }) => {
  return (
    <>
      {createPortal(<Backdrop onClick={onBackdrop} />, document.querySelector("#backdrop-root"))}
      {createPortal(<Model children={children} image={image} />, document.querySelector("#model-root"))}
    </>
  );
};

export default ImageModal;
