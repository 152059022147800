import { useMutation } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";

//^ action
import { userNetworkAction } from "../../../store/slices/user-networks-slice";

//^ http request
import { acceptConnectionHandler, removeInvitationHandler } from "../../../http/post-api";
import { queryClient } from "../../../http";

import ImageWithPlaceholder from "../../../components/ui/auth-otp/image/image-with-placeholder";

import {
  MoreVert,
  PersonAddRounded,
  PersonRemoveRounded,
  RemoveCircleRounded,
  RemoveRounded,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import addNotification from "react-push-notification";
import { circularBtnStyle } from "../connections/column";
// import { TooltipComponent } from "../../../components/Component";
import { postRequest, responseErrorHandler, shortenString } from "../../../utils/Utils";
import { TooltipComponent } from "../../../components/Component";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function RequestUserList(props) {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const requestMutationProgress = useSelector((state) => state.userNetwork.requestMutationProgress);

  // eslint-disable-next-line
  const [searchParams, setSearchParams] = useSearchParams();
  const requestTab = searchParams.get("requestTab");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const secondaryButtonText =
    requestTab === "received" ? <span>Accept</span> : requestTab === "sent" ? <span>Withdraw</span> : "";

  //^ Ignore mutation query fn =================================================================

  const {
    // isPending: removeInvitationIsPending,
    isError: removeInvitationIsError,
    error: removeInvitationError,
    mutate: removeInvitationMutate,
    reset: removeInvitationReset,
  } = useMutation({
    mutationKey: ["ignore-received-invitation"],
    mutationFn: removeInvitationHandler,
    onSuccess: async (data) => {
      if (data?.toast) {
        if (data?.status) {
          // toast.success(data?.message);
          addNotification({
            title: "Request Removed",
            message: data?.message,
            native: true,
          });
          await queryClient.refetchQueries({
            queryKey: ["get-user-list-summary"],
            type: "active",
            exact: true,
          });
        } else {
          responseErrorHandler(true, data);
        }
      }
      removeInvitationReset();
    },
    onSettled: () => {
      dispatch(
        userNetworkAction.setNetworkMutationProgressValue({ stateValue: "requestMutationProgress", status: false })
      );
    },
  });

  useEffect(() => {
    if (!removeInvitationIsError) {
      responseErrorHandler(removeInvitationIsError, removeInvitationError);
      removeInvitationReset();
    }
  }, [removeInvitationIsError, removeInvitationError, removeInvitationReset]);

  //^ Ignore mutation query fn =================================================================

  //^ Accept mutation query fn =================================================================

  const {
    // isPending: acceptConnectIsPending,
    isError: acceptConnectIsError,
    error: acceptConnectError,
    mutate: acceptConnectMutate,
    reset: acceptConnectReset,
  } = useMutation({
    mutationKey: ["accept-connection"],
    mutationFn: acceptConnectionHandler,
    onSuccess: async (data) => {
      if (data?.toast) {
        if (!data?.status) {
          responseErrorHandler(true, data);
          addNotification({
            title: "Error",
            message: data?.message,
            native: true,
          });
        } else {
          addNotification({
            title: "Sent Request",
            message: data?.message,
            native: true,
          });
          await queryClient.refetchQueries({
            queryKey: ["get-user-list-summary"],
            type: "active",
            exact: true,
          });
        }
      }
      acceptConnectReset();
    },
    onSettled: () => {
      dispatch(
        userNetworkAction.setNetworkMutationProgressValue({ stateValue: "requestMutationProgress", status: false })
      );
    },
  });
  useEffect(() => {
    if (!acceptConnectIsError) {
      responseErrorHandler(acceptConnectIsError, acceptConnectError);
      acceptConnectReset();
    }
  }, [acceptConnectIsError, acceptConnectError, acceptConnectReset]);

  //^ Accept mutation query fn =================================================================

  function handleRemoveUserHandler({ type, connection_id }) {
    if (requestMutationProgress) return;
    dispatch(
      userNetworkAction.setNetworkMutationProgressValue({ stateValue: "requestMutationProgress", status: true })
    );

    if (type !== "ACCEPT") {
      removeInvitationMutate({
        connection_id,
        type: type === "IGNORE" ? "remove" : type === "WITHDRAW" ? "remove" : "",
      });
    } else {
      acceptConnectMutate({ connection_id });
    }
    dispatch(userNetworkAction.removeUserListHandler({ type, connection_id: connection_id }));
    handleClose();
  }

  const removeRequest = async (connection_id, requestTab) => {
    const type = requestTab == "received" ? "reject" : "remove";
    let res = await postRequest(`${process.env.REACT_APP_API_URL}api/v1/connection/reject`, {
      connection_id,
      type,
    });
    if (res && res.status) {
      navigate(0);
    } else {
      return false;
    }
  };

  async function removeItemHandler({ id, requestTab }) {
    console.log("id", id, requestTab);
    const res = await removeRequest(id, requestTab);
    if (res) {
      dispatch(
        userNetworkAction.removeUserListHandler({
          type: "IGNORE",
          connection_id: id,
          requestTab,
        })
      );
    }
  }

  return (
    <List
      sx={{
        width: "100%",
      }}
    >
      <ListItem
        alignItems="center"
        secondaryAction={
          <Stack direction={"row"} alignItems={"center"} gap={2}>
            <Box>
              <Button
                sx={circularBtnStyle}
                variant="outlined"
                type="button"
                onClick={() => removeItemHandler({ id: props.row.connection_id, requestTab })}
                startIcon={<RemoveRounded />}
                size="small"
              >
                Remove
              </Button>
            </Box>
            <Stack direction={"row"} alignItems={"center"} gap={2}>
              {requestTab === "received" && (
                // <Button
                //   size="small"
                //   sx={{
                //     display: "none",
                //     "@media (min-width: 65.5rem)": {
                //       display: "block",
                //     },
                //   }}
                //   onClick={() => handleRemoveUserHandler({ type: "IGNORE", connection_id: props.row.connection_id })}
                // >
                //   Ignore
                // </Button>
                <></>
              )}
              <Box
                sx={{
                  display: "none",
                  "@media (min-width: 65.5rem)": {
                    display: "block",
                  },
                }}
              >
                {" "}
                <Button
                  startIcon={
                    requestTab === "received" ? (
                      <PersonAddRounded />
                    ) : requestTab === "sent" ? (
                      <PersonRemoveRounded />
                    ) : (
                      ""
                    )
                  }
                  variant="contained"
                  sx={{
                    borderRadius: "10rem",
                    width: "7rem",
                  }}
                  size="small"
                  onClick={() =>
                    handleRemoveUserHandler({
                      type: requestTab === "received" ? "ACCEPT" : requestTab === "sent" ? "WITHDRAW" : "",
                      connection_id: props.row.connection_id,
                    })
                  }
                  disabled={requestMutationProgress}
                >
                  {secondaryButtonText}
                </Button>
              </Box>
              <Box
                sx={{
                  display: "block",
                  "@media (min-width: 65.5rem)": {
                    display: "none",
                  },
                }}
              >
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={open ? "long-menu" : undefined}
                  aria-expanded={open ? "true" : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <MoreVert />
                </IconButton>
              </Box>

              <Menu
                id="long-menu"
                MenuListProps={{
                  "aria-labelledby": "long-button",
                }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    maxHeight: 48 * 4.5,
                    width: "20ch",
                  },
                }}
              >
                {requestTab === "received" && (
                  <MenuItem
                    onClick={() => handleRemoveUserHandler({ type: "IGNORE", connection_id: props.row.connection_id })}
                  >
                    <Stack direction={"row"} alignItems={"center"} gap={2}>
                      <RemoveCircleRounded sx={{ fontSize: "1.25rem" }} />
                      <span>Ignore</span>
                    </Stack>
                  </MenuItem>
                )}

                <MenuItem
                  onClick={() =>
                    handleRemoveUserHandler({
                      type: requestTab === "received" ? "ACCEPT" : requestTab === "sent" ? "WITHDRAW" : "",
                      connection_id: props.row.connection_id,
                    })
                  }
                >
                  {requestTab === "received" ? (
                    <Stack direction={"row"} alignItems={"center"} gap={2}>
                      <PersonAddRounded sx={{ fontSize: "1.25rem" }} />
                      <span>Accept</span>
                    </Stack>
                  ) : requestTab === "sent" ? (
                    <Stack direction={"row"} alignItems={"center"} gap={2}>
                      <PersonRemoveRounded sx={{ fontSize: "1.25rem" }} />
                      <span>Withdraw</span>
                    </Stack>
                  ) : (
                    ""
                  )}
                </MenuItem>
              </Menu>
            </Stack>
          </Stack>
        }
      >
        <ListItemAvatar>
          <ImageWithPlaceholder
            showAvatarPlaceholder={true}
            avatar={true}
            alt={props.row.username}
            src={props.row?.avatar_url ? props.row?.avatar_url : props.row?.imgUrl}
            style={{ borderRadius: "50%" }}
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            props.row.username?.length > 12 ? (
              <>
                <TooltipComponent
                  type="text"
                  content={
                    <Typography variant="subtitle1" noWrap>
                      {shortenString(props.row.username, 12)}
                    </Typography>
                  }
                  direction="top"
                  id={`username-${props.row.connection_id}`}
                  text={props.row.username}
                />
              </>
            ) : (
              <Typography variant="subtitle1">{props.row.username}</Typography>
            )
          }
        />
      </ListItem>
      <Divider variant="inset" component="li" />
    </List>
  );
}

export const columns = [
  {
    accessorKey: "userList",
    header: "User List",
    accessorFn: (row) => {
      return <RequestUserList row={row} />;
    },
  },
  { accessorKey: "username" },
  { accessorKey: "created_at" },
  { accessorKey: "email" },
];
