import React from "react";
import { useNavigate } from "react-router-dom";
import classNames from "classnames";

import Toggle from "../sidebar/Toggle";
import User from "./dropdown/user/User";
import Notification from "./dropdown/notification/Notification";

import logo from "../../images/logo.png";
import logoDark from "../../images/logo-dark.png";

import { useTheme, useThemeUpdate } from "../provider/Theme";
import { Box, Paper, Stack, Typography } from "@mui/material";
import { useTheme as muiUseTheme } from "@mui/material/styles";
import AppDropdown from "./dropdown/app/App";
import { account_logo_b, account_logo_w } from "../../images/applogos";

const AppHeader = ({ fixed, className, app }) => {
  const theme = useTheme();
  const muiTheme = muiUseTheme();
  const isDark = muiTheme.palette.mode === "dark";
  const themeUpdate = useThemeUpdate();
  const navigate = useNavigate();

  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });

  return (
    <Paper
      variant="outlined"
      sx={{ zIndex: "1200", borderRadius: "none", border: "none" }}
      className={`${headerClass}`}
    >
      <div className="container-fluid">
        <div className="nk-header-wrap py-1">
          <div className="nk-menu-trigger d-xl-none ms-n1">
            <Toggle className="nk-nav-toggle nk-quick-nav-icon" icon="menu" click={themeUpdate.sidebarVisibility} />
          </div>
          <Stack
            style={{ cursor: "pointer" }}
            onClick={() => navigate(`${process.env.PUBLIC_URL}/user-profile-regular`)}
            direction={"row"}
            spacing={2}
            alignItems={"center"}
          >
            <Box>
              <img
                height="30px"
                src={isDark ? account_logo_w : account_logo_b}
                alt={process.env.REACT_APP_PROJECT_NAME}
                loading="lazy"
              />
            </Box>
            {/* <div className="nk-header-app-info ms-1">
              <Typography component={"p"} variant="h5" fontSize={"1rem"} fontWeight={500}>
                {app.text}
              </Typography>
              <Typography component={"p"} variant="h5" fontSize={"0.8rem"} className="sub-text">
                By Silocloud
              </Typography>
            </div> */}
          </Stack>
          <div className="nk-header-tools py-0">
            <Stack direction={"row"} gap={0.5} alignItems={"center"}>
              <Notification />
              <Stack direction={"row"} gap={2} alignItems={"center"}>
                <AppDropdown />
                <User />
              </Stack>
            </Stack>
          </div>
        </div>
      </div>
    </Paper>
  );
};
export default AppHeader;
