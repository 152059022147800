import React from "react";
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

//^ mui
import {
  Input,
  Box,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

//^ style variables
import styleVar from "../../assets/scss/_themes-vars.module.scss";
import Search from "../../components/search";

export function DataTable({ columns, data, isCompact = false }) {
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";

  const [pagination, setPagination] = React.useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const table = useReactTable({
    data: data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onPaginationChange: setPagination,
    state: {
      pagination,
    },
  });

  function paginationChangeHandler(_event, page) {
    const actualPage = page - 1;
    table.setPageIndex(actualPage);
  }

  const pageSizes = [10, 20, 30, 40, 50];

  return (
    <>
      <Box
        sx={{
          display: "none",
          "@media (min-width: 65.5rem)": {
            display: "block",
          },
        }}
      >
        <Paper
          variant={isDark ? "elevation" : "outlined"}
          elevation={isDark ? 0.6 : 3}
          sx={{ borderRadius: "0.625rem", padding: "1.25rem" }}
        >
          <Stack gap={"1rem"}>
            <Box>
              <Stack
                gap={"1rem"}
                justifyContent={"space-between"}
                sx={{
                  "@media (min-width: 65.5rem)": {
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "0rem",
                  },
                }}
              >
                <Search
                  placeholder="Search..."
                  value={table.getColumn("serviceName2")?.getFilterValue() ?? ""}
                  onChange={(event) => table.getColumn("serviceName2")?.setFilterValue(event.target.value)}
                  width={"20ch"}
                />
                <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                  <InputLabel id="show-page-outlined-select-lable">Show Page</InputLabel>
                  <Select
                    value={table.getState().pagination.pageSize}
                    onChange={(e) => {
                      table.setPageSize(Number(e.target.value));
                    }}
                    labelId="show-page-outlined-select-lable"
                    id="show-page-outlined-select"
                    label="Show Page"
                    sx={{ height: "2.5rem" }}
                  >
                    {pageSizes.map((pageSize, index) => (
                      <MenuItem key={index} value={pageSize}>
                        Show {pageSize}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
            </Box>

            <TableContainer>
              <Table sx={{ minWidth: 650 }} aria-label="simple table" size={isCompact ? "small" : "medium"}>
                <TableHead>
                  {table.getHeaderGroups().map((headerGroup) => {
                    return (
                      <>
                        <TableRow key={headerGroup.id}>
                          {headerGroup.headers.map((header) => {
                            return (
                              <TableCell
                                style={{ display: header.getContext().column.id === "serviceName2" ? "none" : "" }}
                                key={header.id}
                              >
                                <Typography variant="subtitle2">
                                  {header.isPlaceholder
                                    ? null
                                    : flexRender(header.column.columnDef.header, header.getContext())}
                                </Typography>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      </>
                    );
                  })}
                </TableHead>
                <TableBody>
                  {table.getRowModel().rows?.length ? (
                    table.getRowModel().rows.map((row) => (
                      <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                        {row.getVisibleCells().map((cell) => {
                          return (
                            <TableCell
                              style={{ display: cell.getContext().column.id === "serviceName2" ? "none" : "" }}
                              key={cell.id}
                            >
                              {row.calories}
                              <Typography variant="subtitle2" fontWeight={400}>
                                {cell.getValue()}
                              </Typography>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={columns.length} style={{ height: "6rem" }}>
                        <Stack alignItems={"center"} justifyContent={"center"} height={"100%"}>
                          <Typography
                            textTransform={"capitalize"}
                            style={{ color: styleVar.errorMain }}
                            fontWeight={"bold"}
                          >
                            No results.
                          </Typography>
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>

            <Stack direction={"row"} alignItems={"center"} style={{ gap: "1rem" }}></Stack>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Stack direction={"row"} gap={"1rem"}>
                <Pagination
                  count={table.getPageCount()}
                  onChange={paginationChangeHandler}
                  variant="outlined"
                  shape="rounded"
                />
              </Stack>
              <Stack direction={"row"} gap={"0.3rem"}>
                <div>Page</div>
                <strong>
                  {table.getState().pagination.pageIndex + 1} of {table.getPageCount().toLocaleString()}
                </strong>
              </Stack>
            </Stack>
          </Stack>
        </Paper>
      </Box>
      <Box
        sx={{
          display: "block",
          "@media (min-width: 65.5rem)": {
            display: "none",
          },
        }}
      >
        <Stack gap={"1rem"}>
          <Box>
            <Stack
              gap={"1rem"}
              justifyContent={"space-between"}
              sx={{
                "@media (min-width: 65.5rem)": {
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "0rem",
                },
              }}
            >
              <Input
                placeholder="Search for service names..."
                value={table.getColumn("serviceName2")?.getFilterValue() ?? ""}
                onChange={(event) => table.getColumn("serviceName2")?.setFilterValue(event.target.value)}
                className="form-control bg-transparent"
                sx={{
                  width: "100%",
                  "@media (min-width: 65.5rem)": {
                    width: "20rem",
                  },
                }}
              />
              <FormControl variant="outlined" sx={{ minWidth: 120 }}>
                <InputLabel id="show-page-outlined-select-lable">Show Page</InputLabel>
                <Select
                  value={table.getState().pagination.pageSize}
                  onChange={(e) => {
                    table.setPageSize(Number(e.target.value));
                  }}
                  labelId="show-page-outlined-select-lable"
                  id="show-page-outlined-select"
                  label="Show Page"
                >
                  {pageSizes.map((pageSize, index) => (
                    <MenuItem key={index} value={pageSize}>
                      Show {pageSize}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Box>

          <TableContainer>
            <Table sx={{ minWidth: 650 }} aria-label="simple table" size={isCompact ? "small" : "medium"}>
              <TableHead>
                {table.getHeaderGroups().map((headerGroup) => {
                  return (
                    <>
                      <TableRow key={headerGroup.id}>
                        {headerGroup.headers.map((header) => {
                          return (
                            <TableCell
                              style={{ display: header.getContext().column.id === "serviceName2" ? "none" : "" }}
                              key={header.id}
                            >
                              <Typography variant="subtitle2">
                                {header.isPlaceholder
                                  ? null
                                  : flexRender(header.column.columnDef.header, header.getContext())}
                              </Typography>
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    </>
                  );
                })}
              </TableHead>
              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row) => (
                    <TableRow key={row.id} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                      {row.getVisibleCells().map((cell) => {
                        return (
                          <TableCell
                            style={{ display: cell.getContext().column.id === "serviceName2" ? "none" : "" }}
                            key={cell.id}
                          >
                            {row.calories}
                            <Typography variant="subtitle2" fontWeight={400}>
                              {cell.getValue()}
                            </Typography>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns.length} style={{ height: "6rem" }}>
                      <Stack alignItems={"center"} justifyContent={"center"} height={"100%"}>
                        <Typography
                          textTransform={"capitalize"}
                          style={{ color: styleVar.errorMain }}
                          fontWeight={"bold"}
                        >
                          No results.
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <Stack direction={"row"} alignItems={"center"} style={{ gap: "1rem" }}></Stack>
          <Stack direction={"row"} justifyContent={"space-between"}>
            <Stack direction={"row"} gap={"1rem"}>
              <Pagination
                count={table.getPageCount()}
                onChange={paginationChangeHandler}
                variant="outlined"
                shape="rounded"
              />
            </Stack>
            <Stack direction={"row"} gap={"0.3rem"}>
              <div>Page</div>
              <strong>
                {table.getState().pagination.pageIndex + 1} of {table.getPageCount().toLocaleString()}
              </strong>
            </Stack>
          </Stack>
        </Stack>
      </Box>
    </>
  );
}
