import { createSlice } from "@reduxjs/toolkit";
import { queryClient } from "../../http";

const initialState = {
  invitations: [],
  followers: [],
  connections: [],
  homeInvitations: [],
  homeNotFollowed: [],
  homeNotConnected: [],

  connectionValues: [],
  requestValues: [],
  followersValues: [],

  getConnectionDataQuery: [],
  invitationQueryKey: [],
  followerQueryKey: [],

  followUnFollowMutationProgress: false,
  requestMutationProgress: false,
  connectionMutationProgress: false,
  followAndConnectionOnHomeMutationProgress: false,
};

const userNetworkSlice = createSlice({
  initialState,
  name: "site-setting",
  reducers: {
    setUserInvitationsHandler: (state, { payload }) => {
      switch (payload.type) {
        case "RECEIVED":
          state.invitations = payload.received;
          break;

        case "SENT":
          state.invitations = payload.sent;
          break;

        case "FOLLOWERS":
          state.followers = payload.followers;
          break;

        case "ADD_CONNECTIONS":
          state.connections = payload.connections;
          break;

        case "HOME_INVITATIONS":
          state.homeInvitations = payload.homeInvitations;
          break;

        case "HOME_NOT_FOLLOWED":
          state.homeNotFollowed = payload.homeNotFollowed;
          break;

        case "HOME_NOT_CONNECTED":
          state.homeNotConnected = payload.homeNotConnected;
          break;

        case "SET_INVITATION_QUERY_KEY":
          state.invitationQueryKey = payload.queryKey;
          break;

        case "SET_FOLLOWER_QUERY_KEY":
          state.followerQueryKey = payload.queryKey;
          break;

        default:
          return state;
      }
    },
    removeUserListHandler: (state, { payload }) => {
      switch (payload.type) {
        case "IGNORE":
        case "ACCEPT":
        case "WITHDRAW":
          let cachedInvitationData = queryClient.getQueryData(state.invitationQueryKey);
          if (cachedInvitationData) {
            const updatedPages = cachedInvitationData?.pages?.map((page) => {
              return {
                ...page,
                data: {
                  ...page.data,
                  requests: page?.data?.requests?.filter((item) => item.connection_id !== payload.connection_id),
                },
              };
            });
            queryClient.setQueryData(state.invitationQueryKey, { ...cachedInvitationData, pages: updatedPages });
          }

          break;

        case "FOLLOWERS":
          let cachedFollowersData = queryClient.getQueryData(state.followerQueryKey);
          if (cachedFollowersData) {
            const updatedPages = cachedFollowersData?.pages?.map((page) => {
              return {
                ...page,
                data: {
                  ...page.data,
                  list: page?.data?.list?.filter((item) => item.user_id !== payload.user_id),
                },
              };
            });
            queryClient.setQueryData(state.followerQueryKey, { ...cachedFollowersData, pages: updatedPages });
          }
          break;

        case "UNFOLLOW":
          let cachedUnFollowersData = queryClient.getQueryData(state.followerQueryKey);
          if (cachedUnFollowersData) {
            const updatedPages = cachedUnFollowersData?.pages?.map((page) => {
              return {
                ...page,
                data: {
                  ...page.data,
                  list: page?.data?.list?.map((item) => {
                    if (item.user_id !== payload.user_id) return item;
                    return { ...item, following: false };
                  }),
                },
              };
            });
            queryClient.setQueryData(state.followerQueryKey, { ...cachedUnFollowersData, pages: updatedPages });
          }
          break;

        case "FOLLOW":
          let cachedFollowData = queryClient.getQueryData(state.followerQueryKey);
          if (cachedFollowData) {
            const updatedPages = cachedFollowData?.pages?.map((page) => {
              return {
                ...page,
                data: {
                  ...page.data,
                  list: page?.data?.list?.map((item) => {
                    if (item.user_id !== payload.user_id) return item;
                    return { ...item, following: true };
                  }),
                },
              };
            });
            queryClient.setQueryData(state.followerQueryKey, { ...cachedFollowData, pages: updatedPages });
          }
          break;

        case "CONNECT":
          let cachedConnectedUserData = queryClient.getQueryData(state.getConnectionDataQuery);
          if (payload.getConnectionDataQuery) {
            state.getConnectionDataQuery = payload.getConnectionDataQuery;
          } else {
            if (payload.connectType === "connected") {
              if (cachedConnectedUserData) {
                const updatedPages = cachedConnectedUserData.pages.map((page) => {
                  return {
                    ...page,
                    data: {
                      ...page.data,
                      connections: page.data?.connections?.filter(
                        (item) => item.connection_id !== payload.id
                      ),
                    },
                  };
                });
                queryClient.setQueryData(state.getConnectionDataQuery, {
                  ...cachedConnectedUserData,
                  pages: updatedPages,
                });
              }
            } else if (payload.connectType === "not_connected") {
              const updatedPages = cachedConnectedUserData.pages.map((page) => {
                return {
                  ...page,
                  data: {
                    ...page.data,
                    user_list: page.data?.user_list?.filter((item) => item.user_id !== payload.id),
                  },
                };
              });
              queryClient.setQueryData(state.getConnectionDataQuery, {
                ...cachedConnectedUserData,
                pages: updatedPages,
              });
            } else {
              const updatedPages = cachedConnectedUserData.pages.map((page) => {
                return {
                  ...page,
                  data: {
                    ...page.data,
                    connections: page.data?.connections?.filter((item) => item.connection_id !== payload.connection_id),
                  },
                };
              });
              queryClient.setQueryData(state.getConnectionDataQuery, {
                ...cachedConnectedUserData,
                pages: updatedPages,
              });
            }
          }
          break;

        case "NOT_CONNECTED":
          let cachedNotConnectedData = queryClient.getQueryData(state.getConnectionDataQuery);
          if (cachedNotConnectedData) {
            const updatedPages = cachedNotConnectedData.pages.map((page) => {
              return {
                ...page,
                data: {
                  ...page.data,
                  user_list: page.data?.user_list?.map((item) => {
                    if (item.user_id !== payload.user_id) return item;
                    return {
                      ...item,
                      connection_status: {
                        connected: false,
                        message: "Pending",
                        status: "0",
                      },
                    };
                  }),
                },
              };
            });
            queryClient.setQueryData(state.getConnectionDataQuery, {
              ...cachedNotConnectedData,
              pages: updatedPages,
            });
          }
          break;

        case "HOME_INVITATIONS":
          state.homeInvitations = state.homeInvitations.filter((item) => item.connection_id !== payload.connection_id);
          break;

        case "HOME_NOT_FOLLOWED":
          state.homeNotFollowed = state.homeNotFollowed.filter((item) => item.user_id !== payload.user_id);
          break;

        case "HOME_NOT_CONNECTED":
          state.homeNotConnected = state.homeNotConnected.filter((item) => item.user_id !== payload.user_id);
          break;

        default:
          return state;
      }
    },
    setSummaryValues: (state, { payload }) => {
      state.connectionValues = payload.connectionValues;
      state.requestValues = payload.requestValues;
      state.followersValues = payload.followersValues;
    },
    setNetworkMutationProgressValue: (state, { payload }) => {
      state[payload.stateValue] = payload.status;
    },
  },
});

export const userNetworkAction = userNetworkSlice.actions;

const userNetworkReducer = userNetworkSlice.reducer;
export default userNetworkReducer;
