import React from "react";

export default function Golden() {
  return (
    <svg width="242" height="243" viewBox="0 0 242 243" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Gold">
        <g id="Medal 2" clipPath="url(#clip0_410_54)">
          <g id="Layer_x0020_1">
            <path
              id="Vector"
              d="M219.773 30.7729H30.7637C27.978 30.7729 25.7197 32.9901 25.7197 35.7252V49.573C25.7197 52.3081 27.978 54.5253 30.7637 54.5253H219.773C222.559 54.5253 224.817 52.3081 224.817 49.573V35.7252C224.817 32.9901 222.559 30.7729 219.773 30.7729Z"
              stroke="#F64C5E"
              strokeWidth="5.33385"
            />
            <path
              id="Vector_2"
              d="M125.222 236.84C156.979 236.84 182.723 211.096 182.723 179.34C182.723 147.583 156.979 121.839 125.222 121.839C93.4657 121.839 67.7217 147.583 67.7217 179.34C67.7217 211.096 93.4657 236.84 125.222 236.84Z"
              fill="#FEFEFE"
            />
            <path
              id="Vector_3"
              d="M125.222 232.989C154.852 232.989 178.871 208.969 178.871 179.339C178.871 149.71 154.852 125.69 125.222 125.69C95.5927 125.69 71.5732 149.71 71.5732 179.339C71.5732 208.969 95.5927 232.989 125.222 232.989Z"
              stroke="#F64C5E"
              strokeWidth="5.33385"
            />
            <path
              id="Vector_4"
              d="M231.42 82.3127C236.232 82.3127 240.132 78.4121 240.132 73.6004C240.132 68.7888 236.232 64.8882 231.42 64.8882C226.609 64.8882 222.708 68.7888 222.708 73.6004C222.708 78.4121 226.609 82.3127 231.42 82.3127Z"
              stroke="#F2B4BE"
              strokeWidth="2.66674"
            />
            <path
              id="Vector_5"
              d="M63.6868 17.9338C68.4985 17.9338 72.3991 14.0331 72.3991 9.22151C72.3991 4.40988 68.4985 0.509277 63.6868 0.509277C58.8752 0.509277 54.9746 4.40988 54.9746 9.22151C54.9746 14.0331 58.8752 17.9338 63.6868 17.9338Z"
              fill="#F2B4BE"
            />
            <path
              id="Vector_6"
              d="M25.995 242.343C28.8313 242.343 31.1306 240.043 31.1306 237.207C31.1306 234.371 28.8313 232.071 25.995 232.071C23.1587 232.071 20.8594 234.371 20.8594 237.207C20.8594 240.043 23.1587 242.343 25.995 242.343Z"
              fill="#F1D4D8"
            />
            <path
              id="Vector_7"
              d="M234.446 193.279C237.283 193.279 239.582 190.98 239.582 188.143C239.582 185.307 237.283 183.008 234.446 183.008C231.61 183.008 229.311 185.307 229.311 188.143C229.311 190.98 231.61 193.279 234.446 193.279Z"
              fill="#F1D4D8"
            />
            <path
              id="Vector_8"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M6.27759 123.581H12.0552V129.359H17.8328V135.136H12.0552V140.822H6.27759V135.136H0.5V129.359H6.27759V123.581Z"
              fill="#F1D4D8"
            />
            <path
              id="Vector_9"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M50.6641 54.4336H199.781V121.747L180.339 140.272C156.036 110.834 106.698 98.8202 69.6476 140.272L50.6641 121.564V54.4336Z"
              fill="#F1D4D8"
            />
            <g id="Clip path group">
              <mask
                id="mask0_410_54"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="50"
                y="54"
                width="150"
                height="87"
              >
                <g id="id0">
                  <path
                    id="Vector_10"
                    d="M50.6641 54.4336H199.781V121.747L180.339 140.272C156.036 110.834 106.698 98.8202 69.6476 140.272L50.6641 121.564V54.4336Z"
                    fill="white"
                  />
                </g>
              </mask>
              <g mask="url(#mask0_410_54)">
                <g id="Group">
                  <g id="_3113289124320">
                    <path
                      id="Vector_11"
                      d="M150.443 54.4336H100.37V118.446H150.443V54.4336Z"
                      fill="#F1E3E5"
                      stroke="#F64C5E"
                      strokeWidth="5.33385"
                    />
                  </g>
                </g>
              </g>
            </g>
            <path
              id="Vector_12"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M50.6641 54.4336H199.781V121.747L180.339 140.272C156.036 110.834 106.698 98.8202 69.6476 140.272L50.6641 121.564V54.4336Z"
              stroke="#F64C5E"
              strokeWidth="5.33385"
            />
            <path
              id="Vector_13"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M103.121 201.624C108.073 202.266 113.667 200.524 118.527 198.873C121.004 198.139 123.755 197.497 126.598 197.131C137.328 195.755 142.463 199.607 150.625 198.048C153.927 197.406 156.494 195.388 158.237 192.637C161.263 187.868 159.979 181.449 156.403 178.147C154.477 176.313 150.809 174.479 148.149 173.653C143.93 149.626 118.252 155.128 114.125 166.775C112.658 164.483 101.378 162.282 99.5439 173.378C89.1809 175.579 84.5038 198.965 103.121 201.624ZM145.214 176.221C160.896 179.064 159.521 194.838 145.765 195.296C139.437 195.48 135.218 193.371 128.432 193.554C116.051 193.921 107.431 200.432 101.011 197.131C92.4824 194.013 91.4736 180.623 100.828 176.038C101.378 175.763 102.02 175.671 102.112 175.304C101.378 165.767 112.566 166.775 114.767 169.893C123.48 152.469 145.856 161.181 145.214 176.221Z"
              fill="#F64C5E"
            />
          </g>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_410_54">
          <rect width="241.008" height="241.852" fill="white" transform="translate(0.5 0.5)" />
        </clipPath>
      </defs>
    </svg>
  );
}
