export const loginRequest = async (username, password) => {
  let loginForm = new FormData();
  loginForm.append("username", username);
  loginForm.append("password", password);

  let res = await fetch(`${process.env.REACT_APP_API_URL}api/v1/auth/login`, {
    method: "POST",
    body: loginForm,
    cache: "no-store",
  });
  return await res.json();
};

export const registerRequest = async (
  username,
  email,
  password,
  password_confirmation,
  g_recaptcha_response,
  phone_no,
  country_code,
  referCode
) => {
  let signupForm = new FormData();
  signupForm.append("username", username);
  signupForm.append("email", email);
  signupForm.append("password", password);
  signupForm.append("password_confirmation", password_confirmation);
  signupForm.append("g_recaptcha_response", g_recaptcha_response);
  signupForm.append("register_type", "platform");
  signupForm.append("phone_no", phone_no);
  signupForm.append("country_code", country_code);

  if (referCode) {
    signupForm.append("refer_code", referCode);
  }

  let res = await fetch(`${process.env.REACT_APP_API_URL}api/v1/auth/register`, {
    method: "POST",
    body: signupForm,
    cache: "no-store",
  });

  return await res.json();
};
export const verifyLinkRequest = async (token, code) => {
  let verifyLinkForm = new FormData();
  verifyLinkForm.append("verify_token", token);
  verifyLinkForm.append("verify_code", code);
  verifyLinkForm.append("verification_type", "link");

  let res = await fetch(`${process.env.REACT_APP_API_URL}api/v1/auth/verify-link`, {
    method: "POST",
    body: verifyLinkForm,
    cache: "no-store",
  });

  return await res.json();
};
export const verifyAccountRequest = async (token, code, password) => {
  let verifyAccountForm = new FormData();
  verifyAccountForm.append("verify_token", token);
  verifyAccountForm.append("verify_code", code);
  verifyAccountForm.append("verification_type", "account");
  verifyAccountForm.append("password", password);

  let res = await fetch(`${process.env.REACT_APP_API_URL}api/v1/auth/verify-account`, {
    method: "POST",
    body: verifyAccountForm,
    cache: "no-store",
  });

  return await res.json();
};
export const resendLinkRequest = async (username) => {
  let verifyAccountForm = new FormData();
  verifyAccountForm.append("username", username);

  let res = await fetch(`${process.env.REACT_APP_API_URL}api/v1/auth/resend-link`, {
    method: "POST",
    body: verifyAccountForm,
    cache: "no-store",
  });

  return await res.json();
};
export const resetLinkRequest = async (username) => {
  let resetLinkForm = new FormData();
  resetLinkForm.append("username", username);

  let res = await fetch(`${process.env.REACT_APP_API_URL}api/v1/auth/forgot-password`, {
    method: "POST",
    body: resetLinkForm,
    cache: "no-store",
  });

  return await res.json();
};
export const resetPassword = async (token, code, password, confirm_password) => {
  let resetPasswordForm = new FormData();
  resetPasswordForm.append("verify_token", token);
  resetPasswordForm.append("verify_code", code);
  resetPasswordForm.append("password", password);
  resetPasswordForm.append("password_confirmation", confirm_password);

  let res = await fetch(`${process.env.REACT_APP_API_URL}api/v1/auth/reset-password`, {
    method: "POST",
    body: resetPasswordForm,
    cache: "no-store",
  });

  return await res.json();
};
