import { Stack, Skeleton, Box } from "@mui/material";

export default function FollowListSkeleton({ padding = false, length = 10 }) {
  return (
    <Stack gap={2} padding={!padding ? "1.25rem 0rem" : ""}>
      {Array.from({ length }).map((_, index) => (
        <Stack key={index} direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
          <Stack direction={"row"} alignItems={"center"} width={"100%"} gap={1}>
            <Box>
              <Skeleton variant="circular" width={"2.8rem"} height={"2.8rem"} animation="pulse" />
            </Box>
            <Stack marginLeft={"1rem"} width={"100%"} gap={1}>
              <Skeleton
                variant="rounded"
                sx={{
                  width: "80%",
                  "@media (min-width:65.5rem)": {
                    width: "30%",
                  },
                }}
                height={"1.5rem"}
                animation="pulse"
              />
              <Skeleton
                variant="rounded"
                sx={{
                  width: "60%",
                  "@media (min-width:65.5rem)": {
                    width: "20%",
                  },
                }}
                height={"1rem"}
                animation="pulse"
              />
            </Stack>
          </Stack>
          <Stack gap={2} alignItems="center" direction={"row"}>
            <Skeleton
              variant="rounded"
              width={"7rem"}
              height={"1.5rem"}
              animation="pulse"
              sx={{
                borderRadius: "0.625rem",
                display: "none",
                "@media (min-width: 65.5rem)": {
                  display: "block",
                },
              }}
            />

            <Skeleton variant="rectangular" width={"1.5rem"} height={"1.5rem"} animation="pulse" />
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
}
