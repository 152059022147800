import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import Input, { getCountries as getPhoneCountries } from "react-phone-number-input/input";
import { isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input";
import { toast } from "react-toastify";
import { setProfileInfoHandler } from "../../http/post-api";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { ArrowDropDown, LockOutlined } from "@mui/icons-material";
import { Tooltip } from "reactstrap";

//^ styles
import styles from "../../pages/pre-built/user-manage/UserProfileRegular.module.scss";

//^ colors
// import colors from "../../assets/scss/_themes-vars.module.scss";

// mui component
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Card,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker as MuiDatePicker } from "@mui/x-date-pickers/DatePicker";

//^ custom component
import InputWrapper from "../input-wrapper/InputWrapper";
import ErrorMessage from "../ui/error-message/ErrorMessage";
import Loader from "../ui/loader/Loader";
import { InputWrapperSkeleton } from "../ui/loader/Skeleton/PersonalInfoFormSkeleton";
import { responseErrorHandler } from "../../utils/Utils";
import { getAllCountries, getCities, getStates } from "../../http/get-api";

export function MenuWrapper({ defaultMenu = true, menus, menuItems, placeholder, value, onChange, id, disabled }) {
  const isDark = useTheme().palette.mode === "dark";

  const menuSelectFormControlStyle = {
    width: "100%",
    "& .MuiOutlinedInput-root": {
      outline: "none",
      border: "none",
      height: "2.5rem",
      "& .MuiOutlinedInput-notchedOutline": {
        borderWidth: "0.5px",
        outline: "none",
      },
      "&:disabled .MuiOutlinedInput-notchedOutline": {
        outline: "none",
        cursor: "not-allowed",
      },
      borderRadius: "0.225rem",
    },
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: isDark
      ? {
        elevation: 0.9,
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      }
      : {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
  };

  return (
    <FormControl disabled={disabled} size="small" sx={menuSelectFormControlStyle} id={id}>
      <Select
        variant="outlined"
        id={id}
        displayEmpty
        value={value}
        onChange={onChange}
        MenuProps={MenuProps}
        size="small"
        IconComponent={!isDark ? () => null : ArrowDropDown}
      >
        <MenuItem disabled value="">
          <em>{placeholder}</em>
        </MenuItem>
        {defaultMenu
          ? menuItems?.map((item, index) => {
            return (
              <MenuItem key={index} id={id} value={item.name}>
                <Typography>{item.name}</Typography>
              </MenuItem>
            );
          })
          : menus}
      </Select>
    </FormControl>
  );
}

export default function PersonalInfoForm({
  countries,
  firstName,
  lastName,
  phoneNumber,
  email,
  address1,
  address2,
  state,
  dob,
  aboutMe,
  pinCode,
  profileIso,
  city,
  isLoading,
  username,
  countryId,
  setEditForm,
}) {
  const queryClient = useQueryClient();
  const theme = useTheme();
  const isDark = theme.palette.mode === "dark";
  const laptopMatches = useMediaQuery("(min-width: 65.5rem)");

  const [countryName, setCountryName] = React.useState("");
  const [phoneError, setPhoneError] = React.useState("");
  const [phoneCode, setPhoneCode] = React.useState("");
  const [states, setStates] = React.useState([]);
  const [stateId, setStateId] = React.useState(-1);
  const [cities, setCities] = React.useState([]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().max(50, "First name cannot be longer than 50 characters").nullable(),
    lastName: Yup.string().max(50, "Last name cannot be longer than 50 characters").nullable(),
    email: Yup.string()
      .email()
      .required()
      .matches(/^\w.+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/, "Invalid email format"),
    phone_number: Yup.string().max(250, "Phone number cannot be longer than 250 characters").nullable(),
    address_1: Yup.string().max(250, "Address cannot be longer than 250 characters").nullable(),
    address_2: Yup.string().max(250, "Address cannot be longer than 250 characters").nullable(),
    state: Yup.string().max(250, "State cannot be longer than 250 characters").nullable(),
    about_me: Yup.string().max(250, "About me cannot be longer than 250 characters").nullable(),
    dob: Yup.date().max(dayjs(), "Date of birth cannot be a future date").nullable(),
    pinCode: Yup.string().max(20, "Pin code cannot be longer than 20 digits").nullable(),
    username: Yup.string().max(250, "Pin code cannot be longer than 250 characters").nullable(),
  });

  //^ tooltip module ==================================================================================

  const STATE_TOOLTIP = {
    id: "state-tooltip",
    content: "Before selecting state please make sure to select your country",
  };
  const CITY_TOOLTIP = {
    id: "city-tooltip",
    content: "Before selecting city please make sure to select your state",
  };
  const PINCODE_TOOLTIP = {
    id: "pincode-tooltip",
    content: "Before selecting pincode please make sure to select your city",
  };

  const [stateTooltip, setStateTooltip] = useState(false);
  const [cityTooltip, setCityTooltip] = useState(false);
  const [pincodeTooltip, setPincodeTooltip] = useState(false);

  function toggleTooltip(setTooltip, tooltip) {
    setTooltip(!tooltip);
  }

  //^ tooltip module ==================================================================================

  //^ update personal information module ==================================================================================
  const {
    isPending: setProfileIsPending,
    isError: setProfileIsError,
    error: setProfileError,
    mutate: setProfileMutate,
    reset: setProfileReset,
  } = useMutation({
    mutationKey: ["set-profile-information"],
    mutationFn: setProfileInfoHandler,
    onSuccess: (data) => {
      if (data?.toast) {
        if (data?.status) {
          toast.success(data?.message);
          setEditForm(false);
        } else {
          responseErrorHandler(true, data);
        }
      }
      // Invalidate the already get user information and refetch the updated user data.
      queryClient.invalidateQueries('get-user-information');
    },
  });

  useEffect(() => {
    if (setProfileIsError) {
      responseErrorHandler(setProfileIsError, setProfileError);
      setProfileReset();
    }
  }, [setProfileIsError, setProfileError, setProfileReset]);
  //^ update personal information module ==================================================================================

  const DOB = dob ? dayjs(dob) : null;
  const formik = useFormik({
    initialValues: {
      firstName: firstName || "",
      lastName: lastName || "",
      email: email || "",
      phone_number: "",
      address_1: address1 || "",
      address_2: address2 || "",
      state: state || "",
      country: profileIso || "",
      dob: DOB || null,
      about_me: aboutMe || "",
      pinCode: pinCode || "",
      city: city || "",
      username: username || "",
      countryId,
    },
    validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();

      formData.append("first_name", values.firstName);
      formData.append("last_name", values.lastName);
      formData.append("username", values.username);
      formData.append("dob", values.dob ? values.dob.format("YYYY-MM-DD") : null);
      formData.append("phone_number", values.phone_number);
      formData.append("email", values.email);
      formData.append("profileIso", values.country);
      formData.append("state", values.state);
      formData.append("city", values.city);
      formData.append("pin_code", Number(values.pinCode));
      formData.append("address_1", values.address_1);
      formData.append("about_me", values.about_me);

      const data = {
        first_name: values.firstName,
        last_name: values.lastName,
        username: values.username,
        dob: values.dob ? values.dob.format("YYYY-MM-DD") : null,
        phone_number: values.phone_number,
        email: values.email,
        profileIso: values.country,
        state: values.state,
        city: values.city,
        pin_code: Number(values.pinCode),
        address_1: values.address_1,
        about_me: values.about_me,
        countryId: values.countryId,
      };

      setProfileMutate({ formData: data });
    },
  });

  //^ get states query fn
  const {
    data: getStatesData,
    isLoading: getStatesIsLoading,
    isRefetching: getStatesIsRefetching,
  } = useQuery({
    queryKey: ["get-states", { countryId: formik.values.countryId }],
    queryFn: ({ queryKey }) => getStates({ countryId: queryKey[1].countryId }),
  });

  //^ get states query fn
  const {
    data: getCitiesData,
    isLoading: getCitiesIsLoading,
    isRefetching: getCitiesIsRefetching,
  } = useQuery({
    queryKey: ["get-cities", { stateId }],
    queryFn: ({ queryKey }) => getCities({ stateId: queryKey[1].stateId }),
  });

  useEffect(() => {
    if (!getStatesIsLoading || !getStatesIsRefetching) {
      setStates(getStatesData?.data?.states || []);
    }
  }, [getStatesIsLoading, getStatesData, getStatesIsRefetching]);

  useEffect(() => {
    if (!getCitiesIsLoading || !getCitiesIsRefetching) {
      setCities(getCitiesData?.data?.cities || []);
    }
  }, [getCitiesIsLoading, getCitiesData, getCitiesIsRefetching]);

  React.useEffect(() => {
    const phoneCountries = getPhoneCountries();

    const selectedCountry = allCountriesData?.data?.countries.find((item) => item.phonecode === formik.values.country);

    if (selectedCountry) {
      const countryKey = phoneCountries.find((item) => item === selectedCountry.shortname);
      if (countryKey) {
        setPhoneCode(selectedCountry.phonecode);
        setCountryName(countryKey);
      }
    }

    // eslint-disable-next-line
  }, [formik.values.country, phoneNumber]);

  React.useEffect(() => {
    let phonenumber = `+${phoneCode}${phoneNumber}`;
    if (typeof phonenumber === "string") {
      if (isValidPhoneNumber(phonenumber)) {
        setPhoneError("");
        try {
          const parsedPhoneNumber = parsePhoneNumber(phonenumber);
          formik.setFieldValue("phone_number", parsedPhoneNumber?.nationalNumber);
        } catch (error) {
          console.error("Failed to parse phone number:", error);
        }
      }
    }

    // eslint-disable-next-line
  }, [phoneNumber, phoneCode]);

  const handlePhoneChangeHandler = (event) => {
    if (typeof event === "string") {
      if (isValidPhoneNumber(event)) {
        setPhoneError("");
        try {
          const parsedPhoneNumber = parsePhoneNumber(event);
          formik.setFieldValue("phone_number", parsedPhoneNumber?.nationalNumber);
          const selectedCountry = allCountriesData?.data?.countries.find(
            (country) => country.isoCode === parsedPhoneNumber?.country
          );
          formik.setFieldValue("country", selectedCountry.phonecode);
          // Handle the parsed phone number
        } catch (error) {
          console.error("Failed to parse phone number:", error);
        }
      } else {
        setPhoneError("Please enter a valid country wise phone number.");
      }
    } else {
      setPhoneError("");
      console.error("Invalid input: inputValue is not a string");
    }
  };

  const handleCountryChangeHandler = (event) => {
    const value = event.target.value;
    formik.setFieldValue("state", "");
    formik.setFieldValue("city", "");
    const currentCountry = allCountriesData?.data?.countries.find((country) => country.phonecode === value);
    formik.setFieldValue("countryId", currentCountry?.id);

    formik.setFieldValue("country", value);
  };
  function getStyles(name, personName, theme) {
    return {
      fontWeight:
        personName.indexOf(name) === -1 ? theme.typography.fontWeightRegular : theme.typography.fontWeightMedium,
    };
  }

  React.useEffect(() => {
    const currentState = states.find((item) => item.name === formik.values.state);
    setStateId(currentState?.id);
  }, [states, formik.values.state]);

  const handleStateChangeHandler = (event) => {
    const value = event.target.value;
    const currentState = states.find((item) => item.name === value);
    setStateId(currentState?.id);

    formik.setFieldValue("state", value);
    formik.setFieldValue("city", "");
  };

  const handleDateChangeHandler = (date) => {
    formik.setFieldValue("dob", date);
  };
  const handleCityChangeHandler = (event) => {
    const value = event.target.value;

    formik.setFieldValue("city", value);
  };

  // added sourabh

  //^ get-all-countries
  const { data: allCountriesData, isLoading: allCountriesIsLoading } = useQuery({
    queryKey: ["get-all-countries"],
    queryFn: getAllCountries,
    staleTime: Infinity,
  });

  return (
    <Card
      sx={laptopMatches ? {} : { background: "transparent", border: "none" }}
      elevation={isDark ? 0.6 : 0}
      variant={isDark ? "elevation" : "outlined"}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box width={"100%"}>
          <Grid padding={laptopMatches ? ".8rem 2.5125rem" : "0rem"} container spacing={3} rowSpacing={1.3}>
            <Grid item xs={12} md={6}>
              {isLoading ? (
                <InputWrapperSkeleton />
              ) : (
                <InputWrapper
                  placeholder={laptopMatches ? undefined : "First Name"}
                  label={"First Name"}
                  htmlFor={"firstName"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.firstName}
                  name={"firstName"}
                  isError={formik.touched.firstName && formik.errors.firstName}
                  errorMsg={formik.errors.firstName}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {isLoading ? (
                <InputWrapperSkeleton />
              ) : (
                <InputWrapper
                  placeholder={laptopMatches ? undefined : "Last Name"}
                  label={"Last Name"}
                  htmlFor={"lastname"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.lastName}
                  name={"lastName"}
                  isError={formik.touched.lastName && formik.errors.lastName}
                  errorMsg={formik.errors.lastName}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {isLoading ? (
                <InputWrapperSkeleton />
              ) : (
                <InputWrapper
                  placeholder={laptopMatches ? undefined : "Username"}
                  label={
                    <Stack direction={"row"} alignItems={"center"} spacing={0.5} justifyContent={"center"}>
                      <Typography>{"Username"}</Typography>
                      <LockOutlined sx={{ fontSize: "0.8rem" }} />
                    </Stack>
                  }
                  htmlFor={"username"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.username}
                  name={"username"}
                  isError={formik.touched.username && formik.errors.username}
                  errorMsg={formik.errors.username}
                  inputProps={{ disabled: true }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {isLoading ? (
                <InputWrapperSkeleton />
              ) : (
                <InputWrapper label={"DOB"} showInput={false}>
                  <LocalizationProvider
                    dateAdapter={AdapterDayjs}
                    hasError={formik.errors.dob && formik.touched.dob}
                    errorMsg={formik.errors.dob}
                  >
                    <MuiDatePicker
                      value={formik.values.dob || null}
                      id={"dob"}
                      onChange={handleDateChangeHandler}
                      sx={{
                        width: "100%",
                        "& .MuiOutlinedInput-root": {
                          height: "2.5rem",
                          "& .MuiOutlinedInput-notchedOutline": {
                            borderWidth: "0.5px",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {},
                          borderRadius: "0.425rem",
                        },
                      }}
                      format="DD/MM/YYYY"
                      maxDate={dayjs()}
                      size="small"
                    />
                  </LocalizationProvider>
                </InputWrapper>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {isLoading ? (
                <InputWrapperSkeleton />
              ) : (
                <InputWrapper label={"Phone Number"} showInput={false}>
                  <Input
                    international
                    withCountryCallingCode
                    id="phoneNumber"
                    name="phone_number"
                    placeholder={!laptopMatches ? "Phone Number" : undefined}
                    country={countryName ? countryName : ""}
                    value={String(formik.values.phone_number) || ""}
                    onChange={handlePhoneChangeHandler}
                    onBlur={formik.handleBlur}
                    size="small"
                    style={{ height: "2.5rem", letterSpacing: "0px", boxShadow: "none", fontSize: "1rem" }}
                    className={`${styles.input} form-control bg-transparent`}
                  />
                  {phoneError && <ErrorMessage errorMsg={phoneError} className="text-primary fs-6" />}
                </InputWrapper>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {isLoading ? (
                <InputWrapperSkeleton />
              ) : (
                <InputWrapper
                  placeholder={laptopMatches ? undefined : "Email ID"}
                  label={
                    <Stack direction={"row"} alignItems={"center"} spacing={0.5} justifyContent={"center"}>
                      <Typography>{"Email Address"}</Typography>
                      <LockOutlined sx={{ fontSize: "0.8rem" }} />
                    </Stack>
                  }
                  htmlFor={"emailId"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  name={"email"}
                  isError={formik.touched.email && formik.errors.email}
                  errorMsg={formik.errors.email}
                  inputProps={{ disabled: true }}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {isLoading ? (
                <InputWrapperSkeleton />
              ) : (
                <InputWrapper label={"Country"} showInput={false}>
                  <MenuWrapper
                    menuItems={allCountriesData?.data?.countries}
                    defaultMenu={false}
                    menus={allCountriesData?.data?.countries?.map((item) => {
                      return (
                        <MenuItem
                          id="country"
                          key={item.phonecode}
                          value={item.phonecode}
                          style={getStyles(item.name, formik.values.country?.name || "", theme)}
                        >
                          <Typography>{item.name}</Typography>
                        </MenuItem>
                      );
                    })}
                    onChange={handleCountryChangeHandler}
                    placeholder={"Select Country"}
                    value={formik.values.country}
                    id={"country"}
                  />
                </InputWrapper>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {isLoading ? (
                <InputWrapperSkeleton />
              ) : getStatesIsLoading ? (
                <InputWrapperSkeleton />
              ) : (
                <InputWrapper
                  label={"State"}
                  showInput={false}
                  infoTooltip={
                    countryName === null || !countryName || typeof countryName === "undefined" ? true : false
                  }
                  tooltip={
                    <Tooltip
                      placement="top"
                      isOpen={stateTooltip}
                      target={STATE_TOOLTIP.id}
                      toggle={() => toggleTooltip(setStateTooltip, stateTooltip)}
                    >
                      {STATE_TOOLTIP.content}
                    </Tooltip>
                  }
                  tooltipId={STATE_TOOLTIP.id}
                >
                  {
                    <MenuWrapper
                      disabled={countryName === null || !countryName || typeof countryName === "undefined"}
                      menuItems={states}
                      onChange={handleStateChangeHandler}
                      placeholder={"Select State"}
                      value={formik.values.state}
                      id={"state"}
                    />
                  }
                </InputWrapper>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {getCitiesIsLoading ? (
                <InputWrapperSkeleton />
              ) : (
                <InputWrapper
                  label={"City"}
                  showInput={false}
                  infoTooltip={!formik.values.state || formik.values.state === null}
                  tooltip={
                    <Tooltip
                      placement="top"
                      isOpen={cityTooltip}
                      target={CITY_TOOLTIP.id}
                      toggle={() => toggleTooltip(setCityTooltip, cityTooltip)}
                    >
                      {CITY_TOOLTIP.content}
                    </Tooltip>
                  }
                  tooltipId={CITY_TOOLTIP.id}
                >
                  <MenuWrapper
                    disabled={!formik.values.state || formik.values.state === null}
                    menuItems={cities}
                    onChange={handleCityChangeHandler}
                    placeholder={getCitiesData?.status ? "Select City" : getCitiesData?.message}
                    value={formik.values.city}
                    id={"city"}
                  />
                </InputWrapper>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {isLoading ? (
                <InputWrapperSkeleton />
              ) : (
                <InputWrapper
                  inputType="number"
                  placeholder={laptopMatches ? undefined : "Pin Code"}
                  label={"Pin Code"}
                  htmlFor={"pinCode"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.pinCode}
                  name={"pinCode"}
                  isError={formik.touched.pinCode && formik.errors.pinCode}
                  errorMsg={formik.errors.pinCode}
                  inputProps={{ disabled: !formik.values.city || formik.values.city === null }}
                  infoTooltip={!formik.values.city || formik.values.city === null}
                  tooltip={
                    <Tooltip
                      placement="top"
                      isOpen={pincodeTooltip}
                      target={PINCODE_TOOLTIP.id}
                      toggle={() => toggleTooltip(setPincodeTooltip, pincodeTooltip)}
                    >
                      {PINCODE_TOOLTIP.content}
                    </Tooltip>
                  }
                  tooltipId={PINCODE_TOOLTIP.id}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {isLoading ? (
                <InputWrapperSkeleton height={"4.5rem"} />
              ) : (
                <InputWrapper
                  type="textarea"
                  placeholder={laptopMatches ? undefined : "Address"}
                  label={"Address"}
                  htmlFor={"address"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.address_1}
                  name={"address_1"}
                  isError={formik.touched.address_1 && formik.errors.address_1}
                  errorMsg={formik.errors.address_1}
                />
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              {isLoading ? (
                <InputWrapperSkeleton height={"4.5rem"} />
              ) : (
                <InputWrapper
                  type="textarea"
                  placeholder={laptopMatches ? undefined : "About me"}
                  label={"About me"}
                  htmlFor={"aboutMe"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.about_me}
                  name={"about_me"}
                  isError={formik.touched.about_me && formik.errors.about_me}
                  errorMsg={formik.errors.about_me}
                />
              )}
            </Grid>
          </Grid>
          <Stack width="100%" alignItems={"center"} padding={laptopMatches ? ".25rem 0rem 1rem 0rem" : "1.25rem 0rem"}>
            <Box sx={{ width: laptopMatches ? "unset" : "100%" }}>
              <Button
                startIcon={setProfileIsPending && <Loader />}
                disabled={isLoading || !formik.isValid || setProfileIsPending || !formik.dirty}
                variant="contained"
                type="submit"
                fullWidth={laptopMatches ? false : true}
              >
                Update
              </Button>
            </Box>
          </Stack>
        </Box>
      </form>
    </Card>
  );
}
