import React from "react";

//^ scss variables
import styles from "../../../assets/scss/_themes-vars.module.scss";

//^ mui elements
import { FormControlLabel, Switch, styled } from "@mui/material";

export const IOSSwitch = styled(
  ({ onClick, defaultChecked, size, translateX, translateY, checkedTranslateX, checkedTranslateY, ...props }) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      onClick={onClick}
      defaultChecked={defaultChecked}
      disableRipple
      size={size}
      {...props}
    />
  )
)(({ theme, size, translateX, translateY, checkedTranslateX, checkedTranslateY }) => {
  const isSmall = size === "small";

  return {
    width: isSmall ? 32 : 42,
    height: isSmall ? 20 : 26,
    padding: 0,
    display: "flex",
    alignItems: "center",
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: isSmall ? 1 : 2,
      transitionDuration: "300ms",
      transform: `${translateX ? translateX : `translateX(0.8px)`} ${
        isSmall ? `${translateY ? translateY : "translateY(1px)"}` : translateY ? translateY : ""
      }`,
      "&.Mui-checked": {
        transform: `${checkedTranslateX ? checkedTranslateX : `translateX(${isSmall ? "13.5px" : "16px"})`} ${
          isSmall
            ? `${checkedTranslateY ? checkedTranslateY : "translateY(1px)"}`
            : checkedTranslateY
            ? checkedTranslateY
            : ""
        }`,
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: styles.primaryMain,
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: `6px solid #fff`,
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: isSmall ? 16 : 22,
      height: isSmall ? 16 : 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: isSmall ? 10 : 13,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  };
});

export default function MuiSwitch({ children, checked, defaultChecked, onClick, id, name, size }) {
  return (
    <FormControlLabel
      control={
        <IOSSwitch
          sx={{ m: 1 }}
          defaultChecked={defaultChecked}
          checked={checked}
          id={id}
          name={name}
          onClick={onClick}
          size={size}
        />
      }
      label={children}
      labelPlacement="end"
    />
  );
}
