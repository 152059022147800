import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { PayPalButtons, PayPalScriptProvider } from "@paypal/react-paypal-js";
import { useMutation } from "@tanstack/react-query";
import { Spinner, Tooltip } from "reactstrap";
import { useNavigate, useParams } from "react-router";

//^ utils
import { getCookie, responseErrorHandler } from "../../../utils/Utils";

//^ http request
import { addPaymentHandler, subscribeServiceHandler } from "../../../http/post-api";

//^ mui
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useSelector } from "react-redux";
import { queryClient } from "../../../http";
import PaymentLoader from "../../../components/ui/loader/payment-loader";

export default function ServicePayment({
  payment,
  accountToken,
  onPaymentModelClose,
  isPaymentOpen,
  totalPrice,
  tokenValue,
  augerPrice,
  serviceName,
  planId,
  totalPriceWithSBC,
  augerPriceSBC,
  showSbc,
}) {
  const theme = useTheme();
  const navigate = useNavigate();
  const servicePlanValidity = useSelector((state) => state.servicePlan.servicePlanValidity);
  const paymentMethod = useSelector((state) => state.siteSetting.paymentMethod);
  const upgradeServiceSubscription = useSelector((state) => state.packageSubscription.upgradeServiceSubscription);

  const { serviceId } = useParams();

  //^ boolean states
  const [tooltipInfoOpen, setTooltipTwoFAOpen] = React.useState(false);
  const [paymentModel, setPaymentModel] = React.useState(isPaymentOpen || false);

  //^ other states
  const [inputValue, setInputValue] = React.useState({
    usd: paymentMethod === "2" ? parseFloat(payment.toFixed(2)) : parseFloat(totalPrice).toFixed(2),
    account_tokens: payment / accountToken,
  });
  const [section, setSection] = React.useState();
  const [accountTokenData, setAccountTokenData] = useState(accountToken || 0.0);

  // eslint-disable-next-line
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    if (paymentMethod === "1") {
      setSection("accountTokens");
    } else if (paymentMethod === "2") {
      setSection("walletAccounts");
      setInputValue((prevInputValue) => ({
        ...prevInputValue,
        usd: parseFloat(payment.toFixed(2)),
      }));
    } else if (paymentMethod === "3") {
      setSection("paypal");
    }
  }, [paymentMethod, payment]);

  //^ add payment mutation query
  const {
    isPending: addPaymentIsPending,
    isError: addPaymentIsError,
    error: addPaymentError,
    mutate: addPaymentMutate,
    reset: addPaymentReset,
  } = useMutation({
    mutationKey: ["add-payment-query"],
    mutationFn: addPaymentHandler,
    onSuccess: (responseData) => {
      if (responseData.status) {
        toast.success(responseData.message);
        setAccountTokenData(responseData.data.account_tokens);
        if (paymentMethod === "1") {
          setSection("accountTokens");
        } else if (paymentMethod === "2") {
          setSection("walletAccounts");
        }
        subscribeServicePlan();
      } else {
        responseErrorHandler(true, responseData);
      }
      addPaymentReset();
    },
  });

  //^ buy wallet mutation query
  // const {
  //   isPending: buyWalletIsPending,
  //   isError: buyWalletIsError,
  //   error: buyWalletError,
  //   mutate: buyWalletMutate,
  //   reset: buyWalletReset,
  // } = useMutation({
  //   mutationKey: ["buy-wallet-query"],
  //   mutationFn: buyWalletHandler,
  //   onSuccess: (responseData) => {
  //     if (responseData.status) {
  //       toast.success(responseData.message);
  //       setAccountTokenData(responseData.data.account_tokens);
  //       setSection("accountTokens");
  //       subscribeServicePlan();
  //     } else {
  //       responseErrorHandler(true, responseData);)
  //     }
  //     buyWalletReset();
  //   },
  // });

  //^ subscribe package mutation query
  const {
    isPending: subscribePlanIsPending,
    isError: subscribePlanIsError,
    error: subscribePlanError,
    mutate: subscribeServiceMutate,
    reset: subscribeServiceReset,
  } = useMutation({
    mutationKey: ["subscribe-plan-service"],
    mutationFn: subscribeServiceHandler,
    onSuccess: (data) => {
      if (data.status) {
        toast.success(data.message);
        queryClient.refetchQueries({
          queryKey: ["get-user-profile"],
          type: "active",
          exact: true,
        });
        queryClient.refetchQueries({
          queryKey: ["get-user-profile-index-app"],
          type: "active",
          exact: true,
        });
        queryClient.refetchQueries({
          queryKey: ["get-user-profile"],
          type: "active",
          exact: true,
        });
        queryClient.refetchQueries({
          queryKey: ["get-user-profile-user-layout"],
          type: "active",
          exact: true,
        });
        navigate(`${planId}/print-invoice/${data?.data?.invoice_id}`);
        onPaymentModelClose(false);
      } else {
        responseErrorHandler(true, data);
      }

      subscribeServiceReset();
    },
  });

  useEffect(() => {
    responseErrorHandler(subscribePlanIsError, subscribePlanError);
  }, [subscribePlanIsError, subscribePlanError]);

  const subscribeServicePlan = async () => {
    let data = {
      validity: servicePlanValidity ? "12" : "1",
      plan_id: planId,
      service_id: serviceId,
    };

    if (upgradeServiceSubscription !== null) {
      data["service_start_date"] = upgradeServiceSubscription;
    }

    subscribeServiceMutate(data);
  };

  // useEffect(() => {
  //   if (buyWalletIsError) {
  //     console.log(buyWalletError);
  //   }
  // }, [buyWalletIsError, buyWalletError]);

  useEffect(() => {
    responseErrorHandler(addPaymentIsError, addPaymentError);
  }, [addPaymentIsError, addPaymentError]);

  const toggleTooltipInfo = () => setTooltipTwoFAOpen(!tooltipInfoOpen);

  const handleClose = () => {
    onPaymentModelClose(false);
    setPaymentModel(false);
  };

  React.useEffect(() => {
    let tempusd = inputValue.usd || payment;
    setInputValue({ ...inputValue, account_tokens: tempusd / tokenValue });
    // eslint-disable-next-line
  }, []);

  const onError = (_data, _actions) => {
    toast.error("Please try again.");
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then(function (details) {
      addPayment(details);
    });
  };

  const addPayment = async (payment_response) => {
    let formData = new FormData();
    formData.append("amount", payment_response?.purchase_units[0]?.amount?.value);
    formData.append("note", "package purchase");
    formData.append("payment_txn_id", payment_response?.purchase_units[0]?.payments?.captures[0]?.id);
    formData.append("payment_response", JSON.stringify(payment_response));
    formData.append("status", 3);
    formData.append("mode", 2);
    formData.append("purpose", 1);
    formData.append("buyTokens", 1);

    addPaymentMutate(formData);
  };

  // const purchaseToken = async (responsePaymentId) => {
  //   if (responsePaymentId) {
  //     let formData = new FormData();
  //     formData.append("payment_id", responsePaymentId);

  //     buyWalletMutate(formData);
  //   } else {
  //     toast.error("Invalid payment id");
  //   }
  // };

  const createOrder = (_data, actions) => {
    return actions.order
      .create({
        purchase_units: [
          {
            description: "Order",
            amount: {
              currency_code: "USD",
              value: parseFloat(inputValue.usd).toFixed(2) || payment,
            },
            payee: {
              email_address: getCookie("email", "joe@example.com"),
              merchant_id: process.env.REACT_APP_PAYPAL_MERCHANT_ID,
            },
          },
        ],
      })
      .then((orderID) => {
        return orderID;
      });
  };

  return (
    <>
      <PaymentLoader isOpen={addPaymentIsPending || subscribePlanIsPending} />
      <Dialog
        // fullScreen={fullScreen}
        open={paymentModel}
        onClose={handleClose}
        PaperProps={{
          elevation: 1,
          component: "form",
          sx: {
            width: "100%",
            maxWidth: "37.5rem",
            height: "100%",
            "@media (min-width: 65.5rem)": {
              maxHeight: "22rem",
            },
          },
          onSubmit: (event) => {
            event.preventDefault();
            handleClose();
          },
        }}
      >
        <DialogTitle>{`${serviceName} Purchase: ${
          showSbc ? `SBC ${totalPriceWithSBC}` : `$${totalPrice}`
        }`}</DialogTitle>

        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="radio-buttons-group"
            >
              {paymentMethod !== "3" && (
                <FormControlLabel
                  name="payment-option"
                  id="payment-option-paypal"
                  data-payment-type="paypal"
                  onClick={(e) => {
                    setInputValue({
                      ...inputValue,

                      usd: paymentMethod === "2" ? parseFloat(payment.toFixed(2)) : parseFloat(totalPrice).toFixed(2),
                    });
                    e.target.checked && setSection("paypal");
                  }}
                  checked={section === "paypal"}
                  control={<Radio />}
                  label="Paypal"
                />
              )}
              {paymentMethod === "1" && (
                <FormControlLabel
                  name="payment-option"
                  id="payment-option-account-tokens"
                  data-payment-type="accountTokens"
                  onClick={(e) => e.target.checked && setSection("accountTokens")}
                  checked={section === "accountTokens"}
                  control={<Radio />}
                  label="Account Tokens"
                />
              )}
              {paymentMethod === "2" && (
                <FormControlLabel
                  name="payment-option"
                  id="payment-option-account-tokens"
                  data-payment-type="walletAccounts"
                  onClick={(e) => e.target.checked && setSection("walletAccounts")}
                  checked={section === "walletAccounts"}
                  control={<Radio />}
                  label="Wallet Accounts"
                />
              )}
            </RadioGroup>
          </FormControl>

          {section === "paypal" && (
            <Box sx={{ width: "100%", padding: "1.25rem" }}>
              <Stack gap={"1rem"} justifyContent={"center"} alignItems={"center"}>
                <Stack direction={"row"} gap={"0.325rem"}>
                  <Typography fontWeight={"600"}>{`Live Token Rate 1 SBC ${`\u2248`}`}</Typography>
                  <Typography>{`$${tokenValue.toFixed(6)}`}</Typography>
                </Stack>

                <Stack direction={"row"} gap={"0.325rem"}>
                  <Typography fontWeight={"600"}>{"Purchase Amount($):"}</Typography>
                  <Typography>{inputValue.usd}</Typography>
                </Stack>
              </Stack>
            </Box>
          )}
          <Stack alignItems={"center"} width={"100%"}>
            {section === "paypal" && (
              <>
                <div id="paypal-payment" className="col-md-4 pt-1 text-center">
                  <div id="paypal-button" className="m-auto">
                    <PayPalScriptProvider
                      options={{
                        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                      }}
                    >
                      {
                        <Stack direction={"row"} gap={"1.25rem"} alignItems={"center"} justifyContent={"center"}>
                          <Box>
                            <PayPalButtons
                              style={{ layout: "horizontal" }}
                              createOrder={createOrder}
                              onApprove={onApprove}
                              onError={onError}
                            />
                          </Box>
                          {addPaymentIsPending ? (
                            <Stack direction={"row"} alignItems={"center"} justifyContent={"center"}>
                              <Spinner size={"sm"} style={{ borderWidth: "2px" }} />
                            </Stack>
                          ) : (
                            ""
                          )}
                        </Stack>
                      }
                    </PayPalScriptProvider>
                  </div>
                </div>
              </>
            )}
            {section === "accountTokens" && (
              <>
                <Box width={"100%"} padding={"1.25rem 0.625rem"}>
                  <Stack gap={"1rem"}>
                    <Box>
                      <Grid container spacing={2}>
                        <Grid item xs={12} lg={6}>
                          <Stack direction="row" gap="0.325rem">
                            <Typography fontWeight={"600"} whiteSpace={"nowrap"}>
                              Available Tokens:
                            </Typography>
                            <Typography>{accountTokenData.toFixed(6)}</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Stack direction="row" gap="0.325rem">
                            <Typography fontWeight={"600"}>{"Auger Fee(2.9%):"}</Typography>
                            <Typography>{augerPriceSBC}</Typography>
                          </Stack>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                          <Stack direction={"row"} gap={"0.625rem"}>
                            <Typography>
                              <Typography fontWeight={"600"}>
                                <Stack direction={"row"} gap={"0.325rem"}>
                                  <span style={{ whiteSpace: "nowrap" }}>Required Tokens</span>
                                  <span id="required-token">
                                    <InfoOutlinedIcon fontSize="20" />
                                  </span>
                                  <span>:</span>
                                </Stack>
                              </Typography>
                              <Tooltip
                                placement="top"
                                isOpen={tooltipInfoOpen}
                                target="required-token"
                                toggle={toggleTooltipInfo}
                              >
                                Required tokens with Auger Fee
                              </Tooltip>
                            </Typography>
                            <Typography>{totalPriceWithSBC}</Typography>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Box>
                  </Stack>
                </Box>
              </>
            )}
            {section === "walletAccounts" && (
              <Box width={"100%"} height={"100%"} padding={"1.25rem 0.625rem"}>
                <Stack gap={"1rem"} alignItems={"center"} justifyContent={"center"} height={"100%"}>
                  <Stack direction={"row"} gap={"0.625rem"}>
                    <Typography fontWeight={400} whiteSpace={"nowrap"}>
                      Available Balance:
                    </Typography>
                    <Typography fontWeight={"600"}>{`$${(parseFloat(accountTokenData) * parseFloat(tokenValue)).toFixed(
                      2
                    )}`}</Typography>
                  </Stack>
                  <Stack direction={"row"} gap={"0.625rem"}>
                    <Typography fontWeight={400} whiteSpace={"nowrap"}>
                      Required Amount:
                    </Typography>
                    <Typography fontWeight={"600"}>{`$${payment}`}</Typography>
                  </Stack>
                </Stack>
              </Box>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ padding: theme.spacing(2) }}>
          {section === "accountTokens" && (
            <Box>
              {(payment + (payment * augerPrice) / 100) / tokenValue >= accountToken ? (
                <Button
                  variant="contained"
                  type="button"
                  onClick={() => {
                    let tempUsdInputValue = parseFloat((payment + augerPrice).toFixed(2));
                    setInputValue({ ...inputValue, usd: tempUsdInputValue });
                    setSection("paypal");
                  }}
                >
                  Purchase tokens
                </Button>
              ) : (
                <Box>
                  <Button
                    autoFocus
                    variant="contained"
                    type="button"
                    onClick={() => subscribeServicePlan()}
                    disabled={subscribePlanIsPending}
                    startIcon={subscribePlanIsPending ? <Spinner size={"sm"} style={{ borderWidth: "2px" }} /> : ""}
                  >
                    Pay with Tokens
                  </Button>
                </Box>
              )}
            </Box>
          )}

          {section === "walletAccounts" && (
            <Box>
              {payment >= accountToken * tokenValue ? (
                <Button
                  variant="contained"
                  type="button"
                  onClick={() => {
                    let tempUsdInputValue = parseFloat(payment.toFixed(2));
                    setInputValue({ ...inputValue, usd: tempUsdInputValue });
                    setSection("paypal");
                  }}
                >
                  Purchase Amount
                </Button>
              ) : (
                <Box>
                  <Button
                    autoFocus
                    variant="contained"
                    type="button"
                    onClick={() => subscribeServicePlan()}
                    disabled={subscribePlanIsPending}
                    startIcon={subscribePlanIsPending ? <Spinner size={"sm"} style={{ borderWidth: "2px" }} /> : ""}
                  >
                    Pay with wallet
                  </Button>
                </Box>
              )}
            </Box>
          )}
          <Button variant="contained" type="button" color="secondary" onClick={handleClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
