import Head from "../../layout/head/Head";

//^ request-table
import RequestTable from "../../tables/account-users/requests/table";

export default function Requests() {
  return (
    <>
      <Head title={`${process.env.REACT_APP_PROJECT_SHORT_NAME} Requests`} />
      <RequestTable />
    </>
  );
}
